<template>
  <div class="cal-month">
    <div class="sl-arrows-box">
      <button class="btn slider-arrows" @click="setPrevMonth">
        <svg
          style="transform: rotate(180deg)"
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L9 8.72L2 16"
            stroke="#191D2F"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
      <button class="btn slider-arrows" @click="setNextMonth">
        <svg
          width="11"
          height="18"
          viewBox="0 0 11 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2L9 8.72L2 16"
            stroke="#191D2F"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </button>
    </div>
    <h2>{{ formattedDate }}</h2>
  </div>
</template>

<script>
//mport 'moment/locale/ru';

export default {
  name: 'SwitcherDate',
  props: {
    date: {
      type: Date
    }
  },
  data() {
    return {}
  },
  created() {},
  computed: {
    formattedDate() {
      const month = this.$moment(this.date).format('MMMM')
      return (
        new Date(this.date).getFullYear() +
        ' ' +
        month[0].toUpperCase() +
        month.slice(1)
      )
    },
    currentMonth() {
      return this.date.getMonth()
    },
    currentYear() {
      return this.date.getFullYear()
    }
  },
  methods: {
    setPrevMonth() {
      if (!this.currentMonth) {
        this.date.setMonth(11)
        this.date.setFullYear(this.currentYear - 1)
      } else this.date.setMonth(this.currentMonth - 1)

      this.$emit('change', this.date)
    },
    setNextMonth() {
      if (this.currentMonth === 11) {
        this.date.setMonth(0)
        this.date.setFullYear(this.currentYear + 1)
      } else this.date.setMonth(this.currentMonth + 1)

      this.$emit('change', this.date)
    }
  }
}
</script>

<style lang="scss" scoped>
.cal-month {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  h2 {
    margin-bottom: 0;
    margin-left: 8px;
  }
}

.slider-arrows {
  width: 32px;
  height: 33px;
  border-radius: 12px;
  padding: 0;
  outline: none;
  box-shadow: none;
  display: inline-block;
  background: none;
  border: 2px solid #191d2f;
  margin-left: 8px;
  position: relative;
  background: #fff;
  transition: all 0.2s;
  opacity: 0.3;
  &:hover {
    opacity: 1;
    background: #191d2f;
    path {
      stroke: #fff;
    }
  }
  svg {
    max-width: 8px;
    position: absolute;
    top: 1px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
