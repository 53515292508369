<template>
  <div class="border my-3">
    <div class="p-2 border-bottom title-place">
      <span
        class="d-flex align-items-center pointer"
        @click="showContent = !showContent"
      >
        <img
          class="arrow-block"
          :src="require('@/assets/svg/arrow_right.svg')"
          :class="{ 'arrow-block--rotate': !showContent }"
          alt=""
        />
        Вопрос: {{ miniDescr }}
      </span>
      <remove-btn
        style="width: 16px; margin-right: 6px"
        @click.native="$emit('remove', index)"
      />
    </div>
    <div class="question-create-factory p-2 pt-0" v-show="showContent">
      <div class="my-3">
        <span class="h5 mb-1">Тип задания</span>
        <select
          id="testType"
          class="form-select"
          v-model="selectedType"
          @input="onSelect"
        >
          <option value="checkbox">Задание с выбором ответа</option>
          <option value="text">Задание с текстовым полем</option>
          <option value="file">Задание с файлом</option>
        </select>
      </div>
      <div class="my-3">
        <span class="h5 mb-1">Описание задания/вопрос</span>
        <editor key="1" @input="emitInput" v-model="modelOur.question" />
        <span v-if="isErrorQuestion" class="h5 mb-1 error_message">{{
          question.error.question
        }}</span>
      </div>
      <div class="my-2">
        <span class="h5 mb-1">Описание решения/подсказка</span>
        <editor key="2" @input="emitInput" v-model="modelOur.tip" />
        <span v-if="isErrorTip" class="h5 mb-1 error_message">{{
          question.error.tip
        }}</span>
      </div>
      <input type="file" @change="changeFile" />
      <question
        :type="selectedType"
        :initData="modelQuestion"
        v-model="modelQuestion"
        @input="emitInput"
      />
      <span v-if="isErrorCheckbox" class="h5 mb-1 error_message">{{
        question.error.checkbox
      }}</span>
    </div>
  </div>
</template>

<script>
import Editor from '@/components/admin/text-editore'
import Question from './QuestionCreateFactory'
import RemoveBtn from '@/components/admin/UI/RemoveBtn.vue'

import striptags from 'striptags'
import axios from 'axios'

export default {
  name: 'test-editor',
  components: {
    Editor,
    Question,
    RemoveBtn
  },
  props: {
    question: null,
    index: null
  },
  data() {
    return {
      modelQuestion: {},
      modelOur: {
        question: '',
        tip: '',
        mediaPath: ''
      },
      showContent: true,
      selectedType: ''
    }
  },
  methods: {
    emitInput() {
      if (this.modelOur.question)
        this.$emit('input', {
          ...this.modelQuestion,
          ...this.modelOur,
          id: this.$props.index
        })
    },
    onSelect(e) {
      this.modelQuestion.type = e.target.value
      this.emitInput()
    },
    async changeFile(e) {
      let file = e.target.files[0]
      const formData = new FormData()
      formData.append('file', file)
      let r = await axios.post('https://xacademy.uz:5001/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      if (r?.data?.success) {
        this.modelOur.mediaPath = r.data.absPath
        this.emitInput()
      }
    }
  },
  computed: {
    miniDescr() {
      let strip = striptags(this.modelOur.question)
      let dots = ''
      if (strip.length > 40) dots = '...'
      return (
        strip.replaceAll('&nbsp;', '').split('').slice(0, 40).join('') + dots
      )
    },
    isErrorQuestion() {
      return (
        this.question && this.question.error && !!this.question.error.question
      )
    },
    isErrorTip() {
      return this.question && this.question.error && !!this.question.error.tip
    },
    isErrorCheckbox() {
      return (
        this.question && this.question.error && !!this.question.error.checkbox
      )
    }
  },
  watch: {
    data() {
      this.emitInput()
    }
  },
  beforeMount() {
    this.modelQuestion = this.question
    this.modelOur = {
      tip: this.question.answer_comment,
      question: this.question.question
    }
    let copy = { ...this.question }
    delete copy.tip
    delete copy.question
    this.modelQuestion = { ...copy }
    if (
      this.question.type == 'radiobutton' ||
      this.question.type == 'checkbox'
    ) {
      return (this.selectedType = 'checkbox')
    }
    this.selectedType = this.question.type
  }
}
</script>

<style scoped lang="scss">
.title-place {
  font-size: 17px;
  margin-bottom: 10px;
  grid-template-columns: auto 16px;
  display: grid;
}
.arrow-block {
  transform: rotate(90deg) scale(0.7);
  margin-right: 5px;
  cursor: pointer;
  transition: 0.5s;
  &--rotate {
    transform: rotate(-90deg) scale(0.7);
  }
}
.pointer {
  cursor: pointer;
}

.error_message {
  color: red;
  margin-top: 10px;
  display: inline-block;
  font-size: 1rem;
}
</style>
