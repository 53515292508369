<template>
  <div id="app">
    <router-view></router-view>
    <vue-progress-bar></vue-progress-bar>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import initDataFromLS from '@/store/initDataFromLS.js'

import Api from '@/api/endpoints'

export default {
  name: 'App',
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setChats']),
    ...mapActions(['getProfile', 'initRocket', 'subscribeForChats']),
    //Get all chats from server
    //TODO: Add support chat for get it from server
    async setChatsMethod() {
      let r = await Api.getChats()
      if (Array.isArray(r)) {
        // Filter all chats with curators
        r = r
          .filter((chat) => chat.curators.length)
          .map((chat) => {
            if (chat.curators.length) {
              return { ...chat, isDirect: true }
            }
          })
        this.setChats(r)
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'rocketIsInited', 'rocketInitStart'])
  },
  async beforeMount() {
    initDataFromLS(this.$store)
  },
  async mounted() {
    if (this.$route.name !== 'login' && this.$route.name !== 'register') {
      let r = await this.getProfile()
      // Если токен устарел
      if (!r) {
        localStorage.setItem('token', '')
        await this.$router.push({ name: 'login' })
        // location.reload()
      }
      // Init rocket chat if not yet inited
      if (!this.rocketInitStart) {
        await this.initRocket(this.user.chat_token)
        // get and set all  disciplines chats
        await this.setChatsMethod()
        await this.subscribeForChats()
      }
    }
  }
}
</script>

<style lang="scss">
@import './src/styles/vars.scss';

// $breakpoint_1: 576px;
// $breakpoint_2: 768px;
// $breakpoint_3: 992px;
// $breakpoint_4: 1200px;
// $breakpoint_5: 1400px;

/* Montserrat-Regular */
@font-face {
  font-family: 'Montserrat-Regular';
  src: url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Montserrat-Bold */
@font-face {
  font-family: 'Montserrat-Bold';
  src: url('./assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8ed0f9;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6cbef1;
}

* {
  font-family: Montserrat;
}

// fade toggle
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// slide toggle
.slide-enter-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.2s;
  -webkit-transition-duration: 0.2s;
  -o-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 344px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.v-toast *,
.v-toast {
  font-family: $mb;
  font-weight: 200;
}
// H1 заголовок
h1 {
  font-family: $mb;
  font-size: 32px !important;
  color: #000000;
}

// H2 заголовок
h2 {
  font-family: $mb;
  font-size: 24px !important;
  color: #000000;
}

// H3  заголовок
h3 {
  font-family: $mb;
  font-size: 16px !important;
  color: #000000;
}

// текста
.main--text {
  line-height: 18px;
  letter-spacing: -0.02em;
  font-family: $mr;
  font-size: 14px;
}

// Основная кнопка
.primary--button {
  font-family: $mb !important;
  font-size: 14px;
  line-height: 16px !important;
  letter-spacing: -0.02em !important;
  color: #fff !important;
  display: inline-block;
  padding: 16px 14px !important;
  background: #ffffff !important;
  border: none !important;
  border-radius: 13px !important;
  text-decoration: none !important;
  transition: 0.2s !important;
  text-align: center !important;
  text-decoration: none !important;
  background: #1da1f2 !important;
  overflow: none !important;
  cursor: pointer !important;

  &:hover {
    background: #8ed0f9;
  }
}

// Второстепенная кнопка
.secondary--button {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #191d2f !important;
  display: inline-block;
  padding: 16px 14px;
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid rgba(140, 142, 151, 0.4);
  overflow: none;
  text-decoration: none;
  transition: 0.2s;
  text-decoration: none !important;
  margin: 4px;
  cursor: pointer;

  &:hover {
    border-color: #f0f0f0;
    background: #f0f0f0;
  }
}

body {
  margin: 0;
  padding: 0;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.container {
  max-width: 1014px;
}

#wrapper {
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.slide-enter-to,
.slide-leave {
  max-height: 344px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

.content-column {
  width: calc(100% - 330px);
  // position: relative;
  padding-bottom: 30px;
  // margin-left: auto;
}

.container {
  max-width: 1007px !important;
}
</style>


<style lang="scss">
@import '/src/styles/vars.scss';

.sc-dtm-test-list {
  padding-top: 40px;

  h1 {
    margin-bottom: 16px;
  }
}

.timer-clock {
  display: flex;
}

.countdown {
  display: flex;
}

.timer-item {
  flex-wrap: wrap;
  margin: 0 12px;
  justify-content: center;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  h4 {
    font-family: $mb;
    font-size: 32px;
    color: #191d2f;
    margin-bottom: 0;
    line-height: 29px;
  }

  span {
    font-family: $mr;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8c8e97;
  }
}

.complete {
  display: inline-block;
  margin-left: 15px;
}

.countdown-line {
  width: 53px;
  height: 56px;
  border-radius: 100%;
  transform: rotateY(0) rotateZ(-90deg) scale(1.4);
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.t-end-btn {
  width: 51px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 1px 1px;
  position: relative;
  box-shadow: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    background: #e40a0a;
  }
}

.test-nav-sticky {
  position: sticky;
  z-index: 999;
  top: 79px;
  left: 0;
  padding: 10px 0;
  background: #fff;
}

.test-navigation {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin: 15px 0;
  border: 1px solid #f0f0f0;
  border-radius: 13px;
  height: 49px;
  max-width: fit-content;

  li {
    margin: -1px -1px;
  }

  .active {
    color: #fff;
    background: #1da1f2;
    pointer-events: none;
  }

  a {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
    padding: 8px 32px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    border-radius: 12px;
    transition: 0.2s;
    margin-right: 3px;

    &:hover {
      background: #8ed0f9;
      color: #fff;
    }
  }
}

.test-container {
  max-width: 680px;
  width: 100%;
}

.test-list-box {
  display: inline-block;
  width: 100%;
  padding-left: 15px;

  p,
  textarea {
    font-family: 'Montserrat-Regular';
  }
}

.quests {
  width: 100%;
  display: inline-block;

  padding-left: 0;

  li {
    padding-left: 15px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    font-family: $mr;
    margin-bottom: 25px;
  }

  label {
    position: relative;
    cursor: pointer;

    input[type='radio']:checked + span {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    span {
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #191d2f;
      padding-left: 28px;
      position: relative;

      // &::after {
      //   content: '';
      //   position: absolute;
      //   top: 5px;
      //   left: 5px;
      //   margin: auto;
      //   width: 10px;
      //   height: 10px;
      //   background: #1da1f2;
      //   border-radius: 10px;
      //   opacity: 0;
      //   transform: scale(0);
      //   transition: 0.3s;
      // }

      &:after {
        content: '' !important;
        // position: absolute;
        // width: 20px;
        // height: 20px;
        // border-radius: 100%;
        // top: 0;
        // left: 0;
        // border: 1px solid #191d2f;

        background: #1da1f2 !important;
        border-color: #1da1f2 !important;
        background-image: url(assets/img/checked.svg) !important;
        background-repeat: no-repeat !important;
        background-position: center center !important;
      }
    }

    .span.radio {
      &:after {
        background: #1da1f2 !important;
        background-image: none !important;
      }

      &.correct {
        &:after {
          background: green !important;
        }
      }

      &.false {
        &:after {
          background: #e40a0a !important;
        }
        // &:before{
        //   border: 1px solid #e40a0a !important;
        // }
      }
    }
  }
}

.my-modal {
  max-width: 487px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  border-radius: 24px;
  padding: 40px 48px;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 12px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 16px;
  box-shadow: none;
  opacity: 1;
  font-weight: 100;
  font-size: 35px;
}

.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(25, 29, 47, 0.507);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.test-info {
  margin-top: 12px;
  display: inline-block;
  width: 100%;

  a {
    margin: 0 8px;
  }
}

.complete-button {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 0;
  right: 64px;
  cursor: pointer;

  #wrapper {
    padding: 0;
  }
}

.countdown-line {
  width: 53px;
  height: 56px;
  border-radius: 100%;
  transform: rotateY(0) rotateZ(-90deg) scale(1.4);
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.t-end-btn {
  width: 51px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 1px 1px;
  position: relative;
  box-shadow: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    background: #e40a0a;
  }
}

@media (max-width: 768px) {
  .content-column {
    width: 100%;
  }
}

.modal-footer {
  display: none !important;
}

.modal-header {
  border: none !important;
}
</style>
