import Vue from 'vue'
import VueRouter from 'vue-router'

import MainPage from '@/components/views/MainPage'
import AllCourses from '@/components/views/courses-components/AllCourses'
import Profile from '@/components/views/profile/Profile'
import EditProfile from '@/components/views/profile/tab-content/Information/pages/EditProfile'
import BlogMain from '@/components/views/blog-components/BlogMain'

// Auth
import Register from '@/components/views/auth-components/Register'
import Login from '@/components/views/auth-components/Login'
import CodeIn from '@/components/views/auth-components/CodeIn'
import NewPassword from '@/components/views/auth-components/NewPassword'
import Reqovery from '@/components/views/auth-components/Reqovery'

import Auth from '@/components/templates/Auth'
import AuthLogin from '@/components/templates/AuthLogin'
import Main from '@/components/templates/Main'
import DTMTemplate from '@/components/templates/DTMTemplate'
import PageWithTitle from '@/components/templates/PageWithTitle'
import NotFound from '@/components/templates/NotFound'
import Testing from '@/components/templates/Testing'
import ChatTemplate from '@/components/templates/Chat'

// Modules
import DTM from './dtm'
import Lessons from './lessons'
import Archive from '@/components/views/profile/Archive'
import Homework from './homework'
import Courses from './courses'
import Exams from './exams'
import Olympiads from './olympiads'
import Chat from './chat'
import Olympiad from '@/components/views/olympiads/Olympiad'

import ReferalProgram from '@/components/views/profile/tab-content/Referal/pages/ReferalProgram'
import BonusProgram from '@/components/views/profile/tab-content/Referal/pages/BonusProgram'
import testEditor from './testEditor'
import ViewResults from '@/components/UI/question/ViewResults'
import Report from '@/components/views/homework/Report'
import Landing from '@/components/views/mini-landings/'

import store from '@/store'
import i18 from '@/i18n'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
    children: [
      {
        name: 'home',
        path: '/',
        component: MainPage,
        meta: {
          requireAuth: true,
          title: i18.t('data.2')
        }
      },
      {
        path: 'profile',
        name: 'profile',
        component: Profile,
        meta: {
          requireAuth: true
        }
      },
      {
        name: 'profile-edit',
        path: 'profile-edit',
        component: EditProfile,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'archive',
        name: 'archive',
        component: Archive,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'referal-program',
        name: 'referal-program',
        component: ReferalProgram,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'bonus-program',
        name: 'bonus-program',
        component: BonusProgram,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'courses',
        component: PageWithTitle,
        children: Courses,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'all-courses',
        component: PageWithTitle,
        meta: {
          requireAuth: true
        },
        children: [
          {
            name: 'all-courses',
            path: '',
            component: AllCourses
          }
        ]
      },
      {
        path: 'dtm',
        component: DTMTemplate,
        children: DTM
      },
      {
        path: 'lessons',
        component: PageWithTitle,
        children: Lessons,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'homework',
        component: PageWithTitle,
        children: Homework,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'exams',
        component: PageWithTitle,
        children: Exams,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'view-results/:testGroupId',
        component: PageWithTitle,
        children: [
          {
            name: 'view-results',
            path: '',
            component: ViewResults,
            meta: {
              title: i18.t('rezultaty-testirovaniya')
            }
          }
        ]
      },
      {
        path: 'landing/:id',
        component: Landing
      },
      {
        path: 'testgroups/:id',
        component: PageWithTitle,
        children: [
          {
            name: 'results',
            path: 'users/:userId/results',
            component: Report,
            meta: {
              title: i18.t('rezultaty-testirovaniya')
            }
          }
        ]
      },
      {
        path: 'olympiads',
        component: PageWithTitle,
        children: Olympiads,
        meta: {
          requireAuth: true
        }
      },
      {
        name: 'olympiad-test',
        path: 'olympiad-test/:id',
        component: Olympiad,
        meta: {
          requireAuth: true
        }
      },
      {
        path: 'blog',
        name: 'blog',
        component: BlogMain
      },
      {
        path: 'testing/:id/:type',
        name: 'testing',
        component: Testing,
        meta: {
          requireAuth: true
        }
      }
    ]
  },
  {
    path: '/chat',
    component: ChatTemplate,
    children: Chat
  },
  {
    path: '/auth',
    component: Auth,
    meta: {
      requiredNotAuth: true
    },
    children: [
      {
        name: 'register',
        path: 'register',
        component: Register
      },
      {
        path: 'login',
        component: AuthLogin,
        children: [
          {
            name: 'login',
            path: '',
            component: Login
          },
          {
            name: 'code-in',
            path: 'code-in',
            component: CodeIn
          },
          {
            name: 'reqovery',
            path: 'reqovery',
            component: Reqovery,
            props: true
          },
          {
            name: 'new-password',
            path: 'new-password',
            component: NewPassword
          }
        ]
      }
    ]
  },
  {
    path: '/test-editor',
    name: '',
    component: PageWithTitle,
    children: testEditor,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '*',
    name: 'not-found',
    component: NotFound
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta?.requireAuth)) {
    if (!localStorage.getItem('token')) {
      next({ name: 'login' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
  if (to.matched.some(record => record.meta?.requiredNotAuth)) {
    if (localStorage.getItem('token')) {
      next({ name: 'home' })
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
  if (to.meta.title) store.commit('setPageTitle', to.meta.title)
  if (to.meta.subtitle) store.commit('setPageTitle', to.meta.title)
  next()
})

export default router
