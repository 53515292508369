import Rocket from '@/utils/rocket'

export default {
  state: {
    chats: [],
    activeChat: {},
    driver: {},
    logginedInChat: false,
    rocketIsInited: false,
    rocketInitStart: false,
    chatReady: false,
    currentMsgs: []
  },
  mutations: {
    //add new chats to existing chats array
    setChats(s, chats) {
      const newChats = chats.map(e => {
        return { ...e, subject: e.name }
      })
      s.chats = [...s.chats, ...newChats]
    },
    //remove chat by id from chats array.
    unsetChats(s, chat_id) {
      s.chats = s.chats.filter(chat => {
        if (chat.curators.length) {
          return chat.curators[0].chat_id !== chat_id
        }
      })
    },
    setActiveChat(s, chat) {
      s.activeChat = chat
    },
    setDriver(s, driver) {
      s.driver = driver
    },
    setLogginedInChat(s, state) {
      s.logginedInChat = state
    },
    rocketInited(s, state = true) {
      s.rocketIsInited = state
    },
    rocketStartInit(s) {
      s.rocketInitStart = true
    },
    chatReady(s, state = true) {
      s.chatReady = state
    },
    setCurrentMsgs(s) {
      s.currentMsgs = s.driver.channels[s.activeChat.chatId].msgs
    }
  },
  actions: {
    //chat initialization
    async initRocket({ commit }, token) {
      commit('rocketStartInit')
      const driver = Rocket.driver
      await driver.connect(process.env.ROCKET_URL || 'wss://rocket-xacademy.axiomica.io')
      return new Promise((res, rej) => {
        driver.user
          .login({
            token
          })
          .then(() => {
            commit('setLogginedInChat', true)
            commit('setDriver', driver)
            commit('rocketInited')
            return res()
          })
          .catch(err => {
            console.error(err)
            return rej()
          })
      })
    },
    toggleChat({ commit }, chat) {
      commit('setActiveChat', chat)
      commit('setCurrentMsgs')
    },
    async subscribeForChats({ state, commit, getters }) {
      //subscribe for new chats
      const driver = state.driver
      if (driver.hasOwnProperty('connection')) {
        commit('chatReady', false)
        await driver.subscribe(getters.chats)
        let loadedHistory = 0
        return new Promise((resolve, reject) => {
          Object.values(driver.channels).forEach(async channel => {
            await channel.loadHistory(1)
            loadedHistory++
            if (loadedHistory === Object.values(driver.channels).length) {
              commit('chatReady')
              resolve({ success: true })
            }
          })
        })
      }
    },
    // Uncubscribe from chat by chat id
    async unsubscribeFromChat({ state, commit }, chat_id = null) {
      commit('setActiveChat', {})
      if (chat_id) {
        commit('unsetChats', chat_id)
        await state.driver.unsubscribe(chat_id)
      }
    }
  },
  getters: {
    chats: s => {
      const support = {
        curators: [
          {
            chat_id: 'LzyEBqwjSfmsBprHg',
            firstName: 'Тех.',
            lastName: 'Поддержка',
            image: 'https://xacademy.uz/img/chat-support.png'
          }
        ],
        isDirect: true,
        name: 'Тех. поддержка',
        subject: 'Тех. поддержка',
        image_path: 'https://xacademy.uz/img/chat-support.png'
      }
      return [...s.chats, support]
    },
    activeChat: s => {
      return s.activeChat
    },
    driver: s => s.driver,
    logginedInChat: s => s.logginedInChat,
    rocketIsInited: s => s.rocketIsInited,
    currentMsgs: s => s.currentMsgs,
    chatReady: s => s.chatReady,
    rocketInitStart: s => s.rocketInitStart
  }
}
