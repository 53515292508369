<template>
  <div class="login vue-component form-box">
    <h2>{{ $t('data.72') }}</h2>
    <form>
      <!--      <div class="field-box">-->
      <!--        <p>{{ $t('data.21') }}<span>*</span></p>-->
      <!--        <phone-input :info="form.phone" v-model="form.phone.model" />-->
      <!--      </div>-->
      <input-form :item="form.phone" />
      <input-form @regOrAuth="loginMethod" :item="form.password" />
      <div class="submit-box">
        <span @click.prevent="loginMethod" class="primary--button">{{
          $t('data.75')
        }}</span>
      </div>
      <div class="text-center">
        <router-link :to="{ name: 'reqovery' }" href="#" class="forget-pass"
          >{{ $t('data.107') }}
        </router-link>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import LangSelect from '../../UI/small-components/LangSelect.vue'
import InputForm from '@/components/UI/input-form/InputForm'

export default {
  name: 'login',
  data: function () {
    return {
      form: {
        phone: {
          type: 'text',
          placeholder: '+998 (XX) XXX-XX-XX',
          mask: '+998 (##) ###-##-##',
          model: '',
          isPhone: true,
          required: true,
          label: this.$t('vvedite-nomer-telefona'),
          class: '',
          errorMessage: ''
        },
        password: {
          type: 'password',
          placeholder: '************',
          model: '',
          required: true,
          label: this.$t('data.74'),
          class: '',
          errorMessage: '',
          event: 'submit'
        }
      }
    }
  },
  methods: {
    ...mapActions(['login', 'getProfile']),
    loginMethod() {
      if (this.validationForm(this.form)) {
        const res = this.login({
          phone: this.form.phone.model,
          password: this.form.password.model
        })
      }
    }
  },
  watch: {
    // If have token redirect to homepage
    token() {
      if (localStorage.getItem('token')) {
        // Set loading state
        this.$store.commit('setLoadingState', true)
        this.$router.push({ name: 'home' })
        window.location.reload()
      }
    }
  },
  computed: {
    ...mapGetters(['token'])
  },
  components: {
    InputForm,
    LangSelect
  },
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';
</style>
