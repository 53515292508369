<template>
  <section class="sc-lessons-online">
    <div class="">
      <x-title v-if="videoData && videoData.name">{{
        setPageTitle(videoData.name)
      }}</x-title>
      <subtitle v-if="videoData && videoData.theme">{{
        setPageSubTitle(videoData.theme)
      }}</subtitle>
      <div class="row">
        <div class="onlone-lesson">
          <p class="main--text">
            <img :src="require('@/assets/svg/lesson-online.svg')" alt="" />
            {{ $t('onlain-urok') }}
          </p>
        </div>
        <div
          class="wrapper-stream-chat"
          ref="videobox"
          :class="{
            'wrapper-fullscreen': fullScreen,
            'wrapper-fullscreen--moz': mozFullscreen
          }"
        >
          <div class="wrapper-fullscreen-video">
            <div class="teacher-info" v-if="fullScreen">
              <div class="teacher-avatar">
                <img
                  v-if="videoData && videoData.hasOwnProperty('teacher')"
                  src="../../../assets/svg/user.svg"
                  alt="Avatar"
                />
                <img v-else src="@/assets/img/teacher.png" alt="Avatar" />
              </div>
              <div
                class="teacher-name"
                v-if="videoData && videoData.hasOwnProperty('teacher')"
              >
                {{ videoData.teacher.fullName }}
              </div>
            </div>

            <div
              class="chat-box"
              v-if="!showChat && fullScreen"
              @click.prevent="showChatFullScreen"
            >
              <div class="chat-box-text">
                <span class="chat-box-arrow">
                  <svg
                    data-v-5b8a57f4=""
                    width="11"
                    height="16"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      data-v-5b8a57f4=""
                      d="M2 2L9 8.72L2 16"
                      stroke="#1DA1F2"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </span>
                <span></span>
              </div>
            </div>
            <stream class="stream" />
          </div>
          <div
            class="position-relative text-left mt-1 wrapper-fullscreen-chat"
            :class="{ 'chat-hided': !showChat, 'chat-default': !fullScreen }"
          >
            <div
              class="chat-header"
              :class="{ 'chat-header-fullscreen': fullScreen }"
            >
              <span
                v-if="fullScreen"
                class="chat-box-arrow chat-hide-icon"
                @click.prevent="hideChatOnFullScreen"
              >
                <svg
                  data-v-5b8a57f4=""
                  width="11"
                  height="16"
                  viewBox="0 0 11 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    data-v-5b8a57f4=""
                    d="M2 2L9 8.72L2 16"
                    stroke="#1DA1F2"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </span>
              <p v-show="rocketIsInited">Онлайн чат</p>
            </div>
            <div
              class="chat-component"
              v-if="rocketIsInited && chatReady && activeChat"
            >
              <chat
                :chatmode="chatMode"
                :chats="roomChat"
                class="chat vue-component"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Stream from '@/components/views/stream-components'
import HTTP from '@/api/endpoints'
import { mapMutations, mapGetters, mapActions } from 'vuex'
import Chat from '@/components/chat'

export default {
  name: 'LessonPros',
  data() {
    return {
      listener: null,
      chatMode: 'room',
      paused: true,
      videoData: null,
      chats: [],
      chat_id: null,
      mozFullscreen: false
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'driver',
      'rocketIsInited',
      'chatReady',
      'activeChat'
    ]),
    roomChat() {
      return this.chats
    },

    // Current fullscreen mode
    fullScreen() {
      return this.$store.getters.fullScreenMode
    },
    // current chat visibility
    showChat() {
      return this.$store.getters.showChat
    }
  },
  watch: {
    // Watch when trigger fullscreen on/ off mode
    fullScreen(newState, oldState) {
      if (this.fullScreen) {
        this.fullScreenHandler(true)
      } else {
        this.fullScreenHandler(false)
      }
    },
    chatReady(newState, oldState) {
      if (newState) {
        this.setCurrentChatToActive()
      }
    }
  },
  methods: {
    ...mapMutations(['setPageTitle', 'setPageSubTitle', 'setChats']),
    ...mapActions([
      'getProfile',
      'initRocket',
      'unsubscribeFromChat',
      'subscribeForChats',
      'toggleChat'
    ]),

    // show chat in full screen mode
    showChatFullScreen() {
      if (!this.showChat) {
        this.$store.commit('setFullScreenChatMode', true)
      } else {
        this.$store.commit('setFullScreenChatMode', false)
      }
    },
    // Open-close fullscreen by buttom
    fullScreenHandler(val) {
      // const isInFullScreen =
      //   document.fullscreenElement ||
      //   document.webkitFullscreenElement ||
      //   document.mozFullScreenElement ||
      //   document.msFullscreenElement
      if (val) {
        const elem = this.$refs.videobox
        // Add event listener if user exit by escape button or close fullscreen
        this.addExitListeners()
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen()
        } else if (elem.msFullscreenElement) {
          elem.msFullscreenElement()
        } else {
          this.mozFullscreen = true
        }
      } else {
        this.mozFullscreen = false
        this.exitFullScreenHandler()
      }
    },
    addExitListeners() {
      document.addEventListener('fullscreenchange', this.fullScreenExitHandler)
    },
    // Separate listener for remove after exit
    fullScreenExitHandler() {
      const isInFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      if (!isInFullScreen) {
        this.$store.commit('setFullScreenMode', false)
      }
    },
    // Handler for exit from fullScreen mode
    exitFullScreenHandler() {
      // Check if have active listener and remove it
      document.removeEventListener(
        'fullscreenchange',
        this.fullScreenExitHandler
      )
      // Check if we in fullscreen mode
      const isInFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      if (isInFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    },
    // Hide chat on full screen by click arrow inside chat wrapper
    hideChatOnFullScreen() {
      this.$store.commit('setFullScreenChatMode', false)
    },
    // Get lesson data for this room
    async initData() {
      await HTTP.getLesson(this.$route.params.id)
        .then((data) => {
          this.videoData = data
          // if have chat it create our chat room config
          if (data?.hasOwnProperty('chat_id')) {
            this.chat_id = data.chat_id
            const ident =
              this.$route.params.id + Math.ceil(Math.random(0, 1) * 1000)
            const payload = {
              id: ident,
              name: `Stream group chat for room: ${this.$route.params.id}`,
              image: '',
              image_path: '',
              lang: null,
              isDirect: false,
              curators: [
                {
                  firstName: `Stream group chat for room: ${this.$route.params.id}`,
                  chat_id: this.chat_id,
                  id: ident,
                  image: '',
                  lastName: '',
                  middleName: ''
                }
              ]
            }
            this.chats.push(payload)
            this.setChats(this.chats)
          }
        })
        .catch((e) => console.log('Error get lesson data', e))
    },
    async setCurrentChatToActive() {
      //if rocket connected and chat ready
      if (
        this?.driver.hasOwnProperty('connection') &&
        this?.driver.channels[this.chat_id]
      ) {
        //get existing messages for this room
        const msgs = this.driver.channels[this.chat_id].msgs
        const subject = {
          name: `Stream group chat for room: ${this.$route.params.id}`,
          avatar: '',
          time: '',
          msg: {
            ...msgs[msgs.length - 1],
            itsMe: this.user?.chat_id === msgs[msgs.length - 1]?.u?._id
          },
          chatId: this.chat_id,
          subject: `Stream group chat for room: ${this.$route.params.id}`
        }
        this.toggleChat(subject)
      }
    }
  },
  async created() {},
  async mounted() {
    await this.initData()
    // Init rocket if not yet inited
    // if (!this.rocketIsInited) {
    //   await this.getProfile()
    //   await this.initRocket(this.user.chat_token)
    // }
    // Subscribe for chat
    await this.subscribeForChats()
    await this.setCurrentChatToActive()
  },
  components: {
    Stream,
    Chat
  },
  beforeDestroy() {
    // Unsubscribe from current chat when exit page
    this.unsubscribeFromChat(this.chat_id)
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.onlone-lesson {
  padding-left: 32px;
  p {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    img {
      margin-right: 9px;
    }
  }
}
.subtitle {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #8c8e97;
}

.stream {
  width: 100%;
}

.scroll-content {
  overflow-x: auto;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fcfcfc;
  border-radius: 12px;
}

.msg-box {
  max-width: 488px;
  display: flex;
  position: relative;
  margin: 15px 0;
}

.msg-icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  position: relative;
  overflow: hidden;
  align-self: flex-end;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.msg-text {
  border-radius: 12px;
  position: relative;
  padding-right: 68px;
  padding-left: 24px;
  padding-top: 16px;
  max-width: 448px;
  h4 {
    margin-bottom: 8px;
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #000000;
  }
  p {
    margin-bottom: 16px;
  }
}

.msg-time {
  position: absolute;
  bottom: 16px;
  right: 16px;
  display: inline-block;
  font-family: $mr;
  font-size: 10px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #191d2f;
}

.msg-first {
  .msg-icon {
    margin-right: 8px;
  }

  .msg-text {
    background: #f0f0f0;
  }
}

.msg-last {
  margin-left: auto;
  justify-content: flex-end;
  .msg-text {
    background: rgba(142, 208, 249, 0.3);
  }
  .msg-icon {
    margin-left: 8px;
  }
}

.msg-write {
  display: flex;
  width: 100%;
  height: 56px;
  padding: 4px 4px;
  background: #f0f0f0;
  border-radius: 6px 6px 11px 11px;
  align-items: center;
  textarea {
    max-width: 914px;
    width: 100%;
    resize: none;
    height: 48px;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 13px;
    padding: 16px 16px;
    outline: none;
    font-family: $mr;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.02em;
    color: #000;
  }
}

.send-icon {
  margin-left: 10px;
  transition: 0.2s;
  &:hover {
    opacity: 0.7;
  }
}
.chat-header-fullscreen {
  position: absolute;
  z-index: 100;
}
.chat-header {
  display: flex;
  align-items: baseline;
  span {
    cursor: pointer;
    padding: 0 15px;
    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.1);
    }
  }

  p {
    font-weight: bold;
    font-size: 28px;
    margin-bottom: 25px;
    position: relative;
    display: inline-block;
    &:before {
      content: '';
      position: absolute;
      right: -20px;
      top: 20px;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #00af07;
    }
  }
}

.chat-component {
  background: #f0f0f01f;
  border: 1px solid #8c8e971f;
  box-sizing: border-box;
  border-radius: 12px;
  padding-top: 50px;
  // overflow: auto;
}
.chat,
.chat-component {
  height: 100%;
}
.chat-loader {
  justify-self: center;
}

.wrapper-fullscreen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 768px) {
    display: block;
    max-height: 100vh;
  }

  &--moz {
    background: #000;
    position: absolute;
    top: 0;
    z-index: 100000;
  }

  .wrapper-fullscreen-video {
    flex: 2;
    .chat-box {
      background-color: white;
      display: flex;
      position: absolute;
      top: -45px;
      right: -45px;
      width: 127px;
      border-top-right-radius: 0px;
      height: 127px;
      border-radius: 50%;
      text-align: center;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      z-index: 100;
      opacity: 1;
      transition: all 0.3s;
      .chat-box-text {
        display: flex;
        align-items: center;
        margin-right: 40px;
        margin-top: 30px;

        .chat-box-arrow {
          padding: 0px 5px;
          transform: rotate(180deg);
        }
        span {
          font-weight: bold;
          color: #1da1f2;
        }
      }
      &:hover {
        box-shadow: 1px 1px 7px 3px #1da1f2;
      }
    }
    .teacher-info {
      display: flex;
      align-items: center;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: 24px;
      margin-left: 24px;
      z-index: 20;
      .teacher-avatar {
        height: 32px;
        width: 32px;
        border: 1px solid white;
        border-radius: 50%;
        img {
          width: 100%;
          height: 100%;
          border-radius: inherit;
        }
      }
      .teacher-name {
        color: white;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
        margin-left: 20px;
      }
    }
  }
  .wrapper-fullscreen-chat {
    flex: 1;
    height: 100%;
    background-color: white;
    transition: all 0.3s;
    border-radius: 10px;
  }
}
.chat-hided {
  flex: none !important;
  width: 0;
  opacity: 0 !important;
}
.chat-default {
  height: 420px;
  .chat-component {
    padding-top: 0;
  }
}
@media (max-width: 768px) {
  .chat-header {
    p {
      font-size: 24px;
    }
    &:before {
      top: 16px;
    }
  }
}
</style>
