<template>
  <transition name="fade">
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  name: 'dtm-template',
  data: function() {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped="scoped" lang="sass"></style>
