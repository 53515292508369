<template>
  <div class="files-view vue-component">
    <div class="files-view__wrap">
      <file
        class="files-view__item"
        v-for="(file, i) in files"
        :key="i"
        :file="file"
        @delete="$emit('delete', file)"
      />
    </div>
  </div>
</template>

<script>
import File from './File.vue'

export default {
  name: 'file-view',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {
    File
  },
  props: {
    files: {
      required: true,
      type: Array
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.files-view {
  background: #f0f0f0;
  flex: 1;
  display: flex;
  overflow: auto;
  max-width: 100%;
  &::-webkit-scrollbar {
    height: 6px !important;
  }
  &__wrap {
    display: flex;
    min-height: min-content;
  }
  &__item {
    max-width: 277px;
    margin-right: 30px;
    max-height: 80px;
  }
}
</style>
