<template>
  <svg
    width="29"
    height="39"
    viewBox="0 0 29 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.25 38H25.75C26.9926 38 28 36.8168 28 35.3571V8.92857L21.25 1H3.25C2.00736 1 1 2.18325 1 3.64286V35.3571C1 36.8168 2.00736 38 3.25 38Z"
      stroke="#191D2F"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'file-icon',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='sass'></style>
