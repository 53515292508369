<template>
  <div class="timer vue-component">
    <counter class="counter" :current-time="currentTimeData" />
    <div @click="finish">
      <circular-timer :all-time="allTime" :current-time="currentTimeData" />
    </div>
    <div v-if="type == 'olympiad'" class="end-text">Завершить олимпиаду</div>
  </div>
</template>

<script>
import Counter from './Counter'
import CircularTimer from './CircularTimer'

export default {
  name: 'timer',
  data: function () {
    return {
      currentTimeData: 0,
      tickIntervalId: undefined
    }
  },
  methods: {
    finish() {
      if (this.$props.type == 'olympiad') {
        this.$emit('userEnd')
      }
      this.$emit('ended')
    },
    tick() {
      if (this.currentTimeData >= 1) {
        this.currentTimeData--
        this.$emit('tick', this.currentTimeData)
      } else if (this.currentTimeData == 0) {
        clearInterval(this.tickIntervalId)
        this.$emit('ended')
      }
    }
  },
  computed: {},
  watch: {
    allTime() {
      this.currentTimeData = this.allTime
    }
  },
  components: {
    Counter,
    CircularTimer
  },
  model: {
    prop: 'currentTime',
    event: 'tick'
  },
  props: {
    allTime: {
      required: true
    },
    initialTime: {},
    type: {
      required: false,
      default: 'normal'
    }
  },
  mixins: {},
  beforeMount() {
    if (this.initialTime) this.currentTimeData = this.initialTime
    else this.currentTimeData = this.allTime
  },
  mounted() {
    this.tickIntervalId = setInterval(this.tick, 1000)
  }
}
</script>

<style scoped='scoped' lang='scss'>
.timer {
  display: flex;
  align-items: center;
}
.counter {
  margin-right: 45px;
}
.end-text {
  margin-left: 13px;
}
</style>
