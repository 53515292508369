<template>
  <div class="create-btn vue-component">
    <span style="margin-top: -10px">+</span>
  </div>
</template>

<script>
export default {
  name: "create-btn",
  data: function () {
    return {};
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {},
};
</script>

<style scoped='scoped' lang='scss'>
.create-btn {
  border: 3px dashed #8c8e97;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 73px;
  line-height: 0;
  height: 70px;
  color: #8c8e97;
  cursor: pointer;
}
</style>
