import MyCourse from '@/components/views/courses-components/MyCourse'
import MyCoursesDetail from "@/components/views/courses-components/MyCoursesDetail";

export default [
  {
    name: 'my-course',
    path: 'my-course/:id',
    component: MyCourse,
    meta: {
      title: 'привет'
    }
  },
  {
    name: 'my-courses-detail',
    path: '',
    component: MyCoursesDetail,
    meta: {
      title: 'Главная'
    }
  },
]
