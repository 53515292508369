<template>
  <div class="tests-wrapper vue-component">
    <div class="container">
      <div class="titles-wrap">
        <x-title v-if="title">{{ title }}</x-title>
        <subtitle v-if="subtitle">{{ subtitle }}</subtitle>
      </div>
      <div
        class="d-flex timer-wrap align-items-center"
        v-if="!isReportPage"
      >
        <timer
        v-if='hardMode'
          :all-time="allTime"
          :initialTime="initialTime"
          @ended="allTestsFinish"
          v-model="currentTime"
        >
        </timer>
        <button v-else class="end primary--button" @click="allTestsFinish">
          {{ $t('zavershit-testirovanie') }}
        </button>
      </div>
      <tabs :tabs="testsNameArray" :double="true" v-if="tests.length != 1">
        <template v-if="!isReportPage">
          <div v-for="(test, i) in tests" :slot="test.subjectName" :key="i">
            <div>
              <test
                v-if="test"
                :test="test.test"
                v-model="results[i]"
                @change="onChange"
                :isReportPage="isReportPage"
                :isPreview="isPreview"
              />
            </div>
          </div>
        </template>

        <template v-else>
          <div v-for="(test, i) in tests" :slot="test.subjectName" :key="i">
            <div>
              <test
                v-if="test"
                :test="test"
                v-model="results[i]"
                @change="onChange"
                :isReportPage="isReportPage"
                :isPreview="isPreview"
              />
            </div>
          </div>
        </template>
      </tabs>
      <test
        v-else
        :test="tests[0].test"
        v-model="results[0]"
        :isReportPage="isReportPage"
        :isPreview="isPreview"
        :isSoloTest="true"
        :answers="tests[0]"
        @change="onChange"
      />
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
import Test from '@/components/UI/question/Test'
import Timer from './Timer'
import { mapActions, mapGetters, mapMutations } from 'vuex'
export default {
  name: 'tests-wrapper',
  data: function () {
    return {
      time: '',
      currentTime: 0,
      results: []
    }
  },
  model: {
    prop: 'results',
    event: 'change'
  },
  methods: {
    ...mapActions(['checkTestGroup']),
    ...mapMutations(['clearDtmResults']),
    async allTestsFinish() {
      await this.checkTests()
      this.$emit('finish')
    },
    checkTests() {
      this.clearDtmResults()
      return this.checkTestGroup(this.tests)
    },
    onChange() {
      this.$emit('change', this.results)
      window.objectStore.testsData = this.testsData
    },
    setTimeStartTest() {
      if (!window.objectStore.timers) window.objectStore.timers = []
      if (window.objectStore.timers[this.$route.params.id]) return
      window.objectStore.timers[this.$route.params.id] = +new Date()
    },
    setTimerTime() {}
  },
  computed: {
    ...mapGetters(['testsData']),
    testsNameArray() {
      return this.tests?.map((t) => t.subjectName) || []
    },
    // проверяю что я на стринице отчета - этот флаг будет ключевой в переключении теста и отчета
    isReportPage() {
      return (
        this.$route.name === 'dtm-report' ||
        this.$route.name === 'report-homework' ||
        this.$route.name === 'exams-homework'
      )
    },
    initialTime() {
      return Math.floor(
        this.allTime -
          (+new Date() - window.objectStore.timers[this.$route.params.id]) /
            1000
      )
    }
  },
  components: {
    Test,
    Timer
  },
  props: {
    title: {
      type: String
    },
    subtitle: {
      type: String
    },
    tests: {
      type: Array,
      required: true
    },
    hardMode: {
      type: Boolean,
      default: false
    },
    testId: {},
    allTime: {
      type: Number,
      default: -1
    },
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  mixins: {},
  created() {
    this.$parent.$on('finishTest', this.allTestsFinish)
    this.setTimeStartTest()
    this.setTimerTime()
  }
}
</script>
<style lang="scss" scoped>
.titles-wrap {
  max-width: 500px;
}
.timer-wrap {
  margin-bottom: 33px;
  margin-top: 16px;
}
.end {
  margin-left: 13px;
  cursor: pointer;
}
.tests-wrapper {
  padding-top: 40px;
  padding-bottom: 30px;
}

@media (max-width: 992px) {
  .timer-wrap {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start;
  }

  .end {
    margin-left: 0;
    margin-top: 15px;
  }
}
</style>