<template>
  <div class="auth-links text-lg-right vue-component">
    <a
      v-if="$route.name !== 'login' || isTablet"
      @click="toLogin"
      :class="['secondary--button', 'ml-1', $route.name === 'login' ? 'active-btn' : '']"
      >{{ $t('data.75') }}</a
    >
    <a
      v-if="$route.name !== 'register' || isTablet"
      @click="toRegister"
      :class="['secondary--button', 'ml-1', $route.name === 'register' ? 'active-btn' : '']"
      >{{ $t('data.77') }}</a
    >
  </div>
</template>

<script>
export default {
  name: 'auth-links',
  data: function() {
    return {
      documentWidth: 0,
      tabletWidth: 768,
      isTablet: false
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
  },
  methods: {
    toLogin(){
      this.$router.push({name: 'login'})
      this.$emit('isOpenMenu', false)
    },
    toRegister(){
      this.$router.push({name: 'register'})
      this.$emit('isOpenMenu', false)
    },
    updateWidth(){
      this.documentWidth = window.innerWidth;
      if(this.documentWidth <= this.tabletWidth){
        this.isTablet = true;
      }else{
        this.isTablet = false;
      }
    }
  },
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style lang="scss" scoped>
  @import './src/styles/vars.scss';

  @media (max-width: 768px){
    .secondary--button{
      color: #1DA1F2 !important;
      font-weight: bold;
      letter-spacing: -0.02em;

      min-width: 185px;
      text-align: center;
    }


  }

  @media (max-width: 576px){
    .auth-links{
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .active-btn{
    background: #1DA1F2;
    color: #fff!important;
    transition: .2s;
  }
</style>
