<template>
  <div class="timeline-container">
    <div class="timeline-header">
      <h2>О чем занятие?</h2>
    </div>
    <div
      class="timeline-content"
      @click="timeLineSetHandler(value.timestamp)"
      v-for="(value, index) in timeline"
      :key="index"
    >
      <div class="timeline-time" :data-time="value.timestamp">
        {{ calcTime(value.timestamp) }}
      </div>
      <div class="timeline-desc">
        {{ value.description }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'

export default {
  name: 'Timeline',
  props: {
    timeline: {
      type: Array,
      required: true
    }
  },
  data() {
    return {}
  },
  methods: {
    ...mapMutations(['setTimeForVideo']),
    // Calculate time from seconds for player in format hh mm ss
    calcTime(timestamp) {
      const utcDate = timestamp.substring(0, timestamp.length - 1)
      return utcDate.split('T').pop().slice(0, -4)
    },
    // Set new currunt time for video in player
    timeLineSetHandler(timestamp) {
      if (!timestamp) return
      this.setTimeForVideo(+new Date(timestamp) / 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-container {
  margin-top: 40px;
  .timeline-header {
    font-family: Montserrat;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-bottom: 20px;
  }
  .timeline-content {
    display: flex;
    margin-bottom: 10px;
    .timeline-time {
      user-select: none;
      cursor: pointer;
      color: #1da1f2;
      width: 80px;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      /* identical to box height, or 129% */

      display: flex;
      align-items: center;
      letter-spacing: -0.02em;
    }
    .timeline-desc {
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      text-align: left;
    }
  }
}
</style>