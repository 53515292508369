<template>
  <div class="empty-list vue-component"><slot /></div>
</template>

<script>
export default {
  name: 'empty-list',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.empty-list {
  font-family: 'Montserrat-Bold';
  text-align: center;
  font-size: 22px;
  padding: 30px 32px;
  font-size: 16px;
  border-radius: 12px;
  border: 1px solid #f0f0f0;
  margin-top: 8px;
}
</style>
