export default {
  data() {
    return {
      dataForFiltering: {}
    }
  },
  computed: {
    filteredData() {
      let filteredData = this.tasksData
      for (let prop in this.dataForFiltering) {
        if (!this.dataForFiltering[prop]) continue
        filteredData = filteredData.filter(task => {
          if (prop === 'expiried_at') {
            return this.checkDate(task, 'expiried_at')
          }

          return task[prop] === this.dataForFiltering[prop]
        })
      }

      return filteredData
    }
  },
  methods: {
    updateTasks(dataForFiltering) {
      this.dataForFiltering = dataForFiltering
    },
    sortTasks(dataForSorting) {
      this.tasksData.sort((a, b) => {
        const firstValue = a[dataForSorting.name]
          .split('.')
          .reverse()
          .join('.')
        const secondValue = b[dataForSorting.name]
          .split('.')
          .reverse()
          .join('.')

        if (dataForSorting.value === 'asc') {
          if (firstValue < secondValue) return -1
          if (firstValue > secondValue) return 1
        } else {
          if (secondValue < firstValue) return -1
          if (secondValue > firstValue) return 1
        }

        return 0
      })
    },
    checkDate(task, prop) {
      const date = this.dataForFiltering[prop]
      let startDate = ''
      let endDate = ''

      if (
        typeof date === 'object' &&
        date.startDate === '' &&
        date.endDate === ''
      )
        return true

      if (date.startDate)
        startDate = date.startDate
          .split('.')
          .reverse()
          .join('.')
      else startDate = new Date().getFullYear() + '.' + date + '.01'

      if (date.endDate)
        endDate = date.endDate
          .split('.')
          .reverse()
          .join('.')
      else endDate = new Date().getFullYear() + '.' + date + '.31'

      const currentDate = task[prop]
        .split('.')
        .reverse()
        .join('.')

      return currentDate >= startDate && currentDate <= endDate
    }
  }
}
