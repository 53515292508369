<template>
  <div class="stream">
    <video-player
      :playerMode="'record'"
      :streamStatus="streamStatus"
      :muted="false"
      ref="videoPlayer"
    >
    </video-player>
    <template v-if="showCtrlPanel">
      <button
        class="primary--button mr-2"
        v-if="streamStatus === 'before start' || streamStatus === 'ended'"
        @click="startStream"
      >
        {{ $t('traslirovat-kameru') }}
      </button>
      <button
        class="primary--button mr-2"
        v-if="streamStatus === 'started'"
        @click="stopStream"
      >
        {{ $t('zakonchit-translyaciyu') }}
      </button>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VideoPlayer from '@/components/UI/small-components/Player'
// import flv from 'flv.js'
import videojs from 'video.js'
import Hls from 'hls.js'

import io from 'socket.io-client'

export default {
  name: 'stream',
  components: {
    VideoPlayer
  },
  props: {
    showButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['user']),
    showCtrlPanel() {
      const acessRolesList = [1, 2, 4]
      return this?.user?.roles?.filter((r) => acessRolesList.includes(r.id))
        .length
    }
  },
  mixins: [],
  data() {
    return {
      videoFlow: null,
      streamStatus: 'before start',
      socket: null,
      videoJsOptions: {
        autoplay: true,
        controls: true,
        sources: [
          {
            src: 'https://xacademy.axiomica.io:8000/live/3/index.m3u8',
            type: 'application/x-mpegURL'
          }
        ],
        fluid: true
      }
    }
  },
  methods: {
    startStream() {
      this.socket.emit('start stream', this.$route.params.id)
      this.onStartStream(this.$route.params.id)
    },
    stopStream() {
      this.socket.emit('end stream', this.$route.params.id)
      this.onEndStream(this.$route.params.id)
    },
    setRmtpStreamToVideo(streamName) {
      const video = this.$refs.videoPlayer.$el.querySelector('video')
      const videoSrc = `https://rtmp-streaming.axiomica.io/live/${streamName}/index.m3u8`
      if (Hls.isSupported()) {
        const hls = new Hls()
        hls.loadSource(videoSrc)
        hls.attachMedia(video)
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc
      } else {
        alert('Ваш браузер не поддерживает прямой эфир. Пожалуйста, обновитесь')
      }
      // let c = 0
      let lastDuration = 0
      video.addEventListener('durationchange', () => {
        let d = video.duration - lastDuration
        lastDuration = video.duration
        // if (c < 2) video.currentTime = video.duration - 1
        // c++
        // Если текущее время видео меньше, чем время помледнего чанка умноженного на 2
        // То мы перематываем видео на предпоследний чанк
        // if (video.currentTime < video.duration - 2 * d)
        //   video.currentTime = video.duration - d
      })
      // Боюсь убирать setTimeout
      setTimeout(() => {
        video.play().catch(() => {
          this.$toast.open({
            message: this.$t(
              'chtoby-prisoedinitsya-k-translyacie-zapustite-video'
            )
          })
        })
      })
      // video.muted = false
    },
    videoStop() {
      const videoElement = this.$refs.videoPlayer.$el.querySelector('video')
    },
    eventStartStream() {},
    onGetStreamsList(list) {
      this.onStartStream(this.$route.params.id)
      let status = list[this.$route.params.id]
      if (!status) return
      if (status === 'started') this.onStartStream(this.$route.params.id)
      if (status === 'ended') this.onEndStream(this.$route.params.id)
    },
    onStartStream(rid) {
      if (rid !== this.$route.params.id) return
      console.log('stream started')
      this.streamStatus = 'started'
      this.setRmtpStreamToVideo(this.$route.params.id)
    },
    onEndStream(rid) {
      console.log('stream ended')
      if (rid !== this.$route.params.id) return
      this.streamStatus = 'ended'
      console.log('stream ended')
      this.videoStop()
    }
  },
  watch: {},
  mounted() {
    this.socket = io('https://xacademy.uz:4999', {
      transports: ['websocket'],
      secure: false
    })
    this.onStartStream(this.$route.params.id)
    // this.socket.on('send streams list', this.onGetStreamsList)
    // this.socket.on('stream started', this.onStartStream)
    // this.socket.on('stream ended', this.onEndStream)
  }
}
</script>

<style lang="scss" scoped>
.stream {
  margin-top: 32px;
}
.button-end {
  background: red !important;
}
button {
  margin-top: 10px;
}
@media (max-width: 768px) {
  button {
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  button {
    font-size: 11px;
  }
}
</style>
