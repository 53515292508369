<template>
  <h1 class="title-nav vue-component" @click="go">
    <svg
      v-if="!to"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L9 8.72L2 16"
        stroke="#1DA1F2"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
    <slot></slot>
    <svg
      class="revert"
      v-if="to"
      width="11"
      height="18"
      viewBox="0 0 11 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L9 8.72L2 16"
        :stroke="isBlue ? '#1DA1F2' : '#000'"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  </h1>
</template>

<script>
export default {
  name: 'title-nav',
  data: function () {
    return {}
  },
  methods: {
    go() {
      if (this.$route.name === 'report-homework') {
        this.$router.push({ name: 'homework-list' })
        return
      }

      if (this.to) {
        this.$router.push(this.to)
      } else {
        this.$router.back()
      }
    }
  },
  computed: {},
  components: {},
  props: {
    to: {
      default: false
    },
    isBlue: Boolean || null
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
h1 {
  margin-bottom: 8px;
  cursor: pointer;
  color: #191d2f;
  text-decoration: none;
  display: flex;
  align-items: center;
  &:hover {
    svg {
      transform: translateX(-10px) rotate(180deg);
    }
  }
  svg {
    margin-right: 10px;
    margin-left: 0;
    transition: 0.2s;
    transform: rotate(180deg);
  }
  svg.revert {
    transform: rotate(0);
    margin-right: 0;
    margin-left: 17px;
    height: 40px;
    width: 15px;
    height: 23px;
    margin-bottom: -5px;
  }
  &:hover {
    svg.revert {
      transform: translateX(10px);
    }
  }
}
</style>
