<template>
  <div class="teacher-card vue-component">
    <img class="teacher-picture" src="../../../assets/svg/user.svg" :alt="name" />
    <div>
      <div class="teacher-name">{{ name }}</div>
      <div class="teacher-descr">{{ description }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'teacher-card',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: {
    picture: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    description: {
      required: true,
      type: String
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.teacher-card {
  background: rgba(142, 208, 249, 0.3);
  border: 1px solid #1da1f2;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .teacher-picture {
    width: 96px;
    height: 96px;
    border-radius: 100%;
    margin-right: 24px;
  }
  .teacher-name {
    font-size: 24px;
    line-height: 32px;
    font-weight: bold;
    letter-spacing: -0.02em;
  }
}
</style>
