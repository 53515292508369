import axios from 'axios'
import Vue from 'vue'
import app from '../main'
import VueRouter from '@/router/'
import i18n from '@/i18n'

let AUTH_TOKEN = 'Bearer ' + window.localStorage.getItem('token')

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_HOST_API
})

HTTP.defaults.headers.common['Authorization'] = AUTH_TOKEN
HTTP.defaults.headers.post['Content-Type'] = 'application/json'

HTTP.interceptors.response.use(
  response => {
    app.$Progress.start()
    if (response) {
      app.$Progress.finish()
    }
    return response.data
  },
  error => {
    if (error.response.status === 401) {
      Vue.$toast.open({
        message: error.response.data.error_message
          ? i18n.t('nevernyi-nomer-telefona-ili-parol')
          : i18n.t('data.264'),
        type: error.response.data.error_message ? 'error' : 'warning',
        duration: 5000,
        dismissible: true
      })
      localStorage.setItem('token', '')
      VueRouter.push({
        name: 'login'
      })
      // location.reload()
      return { error: 401 }
    }
    if (error.response.status === 404) {
      console.log('404', error.response.data)
      let errorMessage = error.response.data.message
      // Vue.$toast.open({
      //   message:
      //     errorMessage === 'Промокод не действителен'
      //       ? errorMessage
      //       : '404 Not Found',
      //   type: 'error',
      //   duration: 5000,
      //   dismissible: true
      // })
      return { error: 404 }
    }
    if (error.response.status === 422) {
      console.log('422', error.response.data.errors)
      let isValidPhone = Object.values(error.response.data.errors).map(item => {
        return item.map(val => {
          if (val === 'The phone has already been taken.') {
            return 'Этот номер телефона уже используется'
          }
          if (val === 'The selected phone is invalid.') {
            return i18n.t('data.108')
          }
          if (val === 'wrong old password provided') {
            return i18n.t('data.260')
          }
          if (val.includes('field is required')) {
            return i18n.t('data.261')
          }
          if (val.includes('The selected id is invalid')) {
            return i18n.t('data.262')
          } else {
            return null
          }
        })
      })
      Vue.$toast.open({
        message: isValidPhone[0][0]
          ? isValidPhone[0][0]
          : error.response.data.message,
        type: 'error',
        duration: 5000,
        dismissible: true
      })
      return { error: 422 }
    }
    if (error.response.status === 500) {
      console.log('500', error.response.data.errors.message)
      Vue.$toast.open({
        message: error.response.data.errors.message
          ? error.response.data.errors.message
          : i18n.t('data.263'),
        type: 'error',
        duration: 5000,
        dismissible: true
      })
      return { error: 500 }
    }
    app.$Progress.fail()
    return Promise.reject(error)
  }
)

export default HTTP
