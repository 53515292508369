<template>
  <div class="chat-window vue-component">
    <template v-if="Object.keys(activeChat).length">
      <chat-header
        :chatmode="chatmode"
        v-if="chatmode != 'room'"
        class="chat-window__header"
      />
      <div class="msg-box-wrapper">
        <template v-for="(channel, key) in driver.channels">
          <msg-box
            v-if="key == activeChat.chatId"
            :key="key"
            @on-msg="chatToBottom(key, true)"
            @on-mounted="chatToBottom(key, false)"
            :room-id="key"
            class="chat-window__msg-box"
            ref="msgBox"
          />
        </template>
      </div>
      <div style="margin-top: auto;">
        <file-view
          class="chat-window__file-views"
          v-show="files.length"
          :files="filesComputed"
          @delete="onDeleteFile"
        />
        <chat-input
          :chatmode="chatmode"
          @on-file-input="onFileInput"
          @send-msg="sendMsg"
        />
      </div>
    </template>
  </div>
</template>

<script>
import ChatInput from '@/components/chat/UI/Input'
import ChatHeader from '@/components/chat/header'
import MsgBox from '@/components/chat/msgs/MsgBox'
import FileView from '@/components/chat/Files/FilesView'

import { mapGetters } from 'vuex'

export default {
  name: 'chat-window',
  data: function () {
    return {
      activatedChats: new Set(),
      files: []
    }
  },
  methods: {
    async sendMsg(val) {
      await this.driver.channels[this.activeChat.chatId].sendMessage(
        val,
        this.files
      )
      this.files = []
    },
    chatToBottom(key, smooth) {
      const el = this.$refs?.msgBox[0]?.$el
      if (!el) return
      let conf = {
        top: el.scrollHeight
      }
      if (smooth) {
        conf.behavior = 'smooth'
      }
      el.scrollTo(conf)
    },
    onFileInput(files) {
      this.files = [...files, ...this.files]
    },
    onDeleteFile(file) {
      this.files = this.files.filter(
        (f) => f.name !== file.name || f.type.split('/')[1] !== file.ext
      )
    }
  },
  computed: {
    ...mapGetters(['activeChat', 'driver', 'chats']),
    filesComputed() {
      return this.files.map((f) => {
        return {
          name: f.name,
          ext: f.type.split('/')[1]
        }
      })
    }
  },
  components: {
    ChatInput,
    ChatHeader,
    MsgBox,
    FileView
  },
  watch: {
    activeChat(v) {
      if (Object.keys(this.driver.channels).find((c) => c === v.chatId)) {
        this.activatedChats.add(v.chatId)
      }
    }
  },
  props: ['chatmode'],
  mixins: {},
  mounted() {}
}
</script>

<style scoped='scoped' lang='scss'>
.chat-window {
  height: 100%;
  &__header {
    padding-right: 60px;
  }
  &__file-views {
    padding: 26px 16px 16px 60px;
    max-height: 100px;
  }
  &__msg-box {
    // Не знаю почему, но если убрать высоту, то все ломается
    min-height: 300px;
    width: 100%;
    overflow-y: scroll;
    flex-grow: 1;
    padding-bottom: 0;
    &:first-element {
      margin-top: 24px;
    }
  }
}
.msg-box-wrapper {
  // height: calc(100% - 141px);
  display: flex;
  flex-direction: column;
  overflow: auto;
}
</style>
