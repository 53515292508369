
<template>
    <section class="sc-blog-news">
       <div class="container">
           <div class="row">
                <div class="custom-info-tabs">
                    <button class="btn primary--button">Новости</button>
                    <button class="btn secondary--button">Университет</button>
                </div>
                <div class="col-12 p-0">
                    <div class="row">
                        <div class="col-lg-4 custom--my" v-for="news in blogNewsDatas" :key="news.index">
                            <div class="single-box">
                                <div class="img-box">
                                    <img :src="news.blogNewsImage" alt="">
                                </div>
                                <div class="inner-content">
                                    <div class="text-info">
                                        <h3>{{ news.blogNewsTitle }}</h3>
                                        <p class="main--text">
                                            {{ news.blogNewsText }}
                                        </p>
                                    </div>
                                    <div class="more">
                                        <a :href="news.blogNewsMoreLink" class="secondary--button">Подробнее</a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <pagination />
                    </div>
                </div>
           </div>
       </div>
    </section>
</template>

<script>

    import Pagination from '../../UI/small-components/Pagination.vue'

    export default {
        name: 'BlogNews',
        components: { Pagination },
        data() {
            return {
                blogNewsDatas: [
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/petz.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/lunadio.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/hero-dog.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/feedbear.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/threedee.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/design.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/hero-dog.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                    {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/petz.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                     {
                        blogNewsTitle: 'Заголовок 1',
                        blogNewsText: 'Разнообразный и богатый опыт сложившаяся структура организации требуют от нас анализа соответствующий условий...',
                        blogNewsImage: require('@/assets/img/blog-images/lunadio.jpg'),
                        blogNewsMoreLink: 'http://www.google.com'
                    },
                ]
            }
        }

    }
</script>

<style lang="scss" scoped>

    @import "../../../styles/vars";

    .sc-blog-news{
       padding-top: 41px;
       padding-bottom: 30px;
    }

    .custom--my{
        margin: 12px 0;
    }

    p{
        color: #000;
    }

    .custom-info-tabs{
        display: flex;
        width: 100%;
        border: 1px solid #F0F0F0;
        width: fit-content;
        border-radius: 14px;
        margin-top: 7px;
        margin-bottom: 23px;

        .primary--button, .secondary--button{
            padding-left: 32px;
            padding-right: 32px;
            border:none;
            box-shadow: none;
        }

        .primary--button{
            pointer-events: none;
        }

    }

    .single-box{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        border: 1px solid #F0F0F0;
        border-radius: 12px;
        height: 100%;
        h3{
            margin-bottom: 0;
        }
        p{
            margin-bottom: 0;
            margin-top: 8px;
        }
    }

    .inner-content{
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 32px 24px;
        height: calc(100% - 139px);
    }

    .img-box{
        display: flex;
        width: 100%;
        border-radius: 12px 12px 0px 0px;
        position: relative;
        height: 139px;
        overflow: hidden;
        img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text-info{
        margin-bottom: 12px;
        width: 100%;
        display: inline-block;
    }

    .more{
        margin-top: 12px;
        width: 100%;
        display: flex;
        flex-grow: 1;
        align-items: flex-end;
    }


</style>
