<template>
  <div class="chat-header vue-component">
    <div class="chat-header__teacher teacher">
      <arrow v-show="chatmode=='usual'" @click.native="hiddenChat" class="chat-header__arrow" />
      <img src="../../../assets/svg/user.svg" alt="" class="teacher__avatar" :class="{'teacher__avatar__minimize': chatmode=='mini'}"/>
      <div class="teacher__name">{{ activeChat.name }}</div>
      <!-- <div class="chat-header__comment chat-header__comment--mobile">
        Предмет: {{ activeChat.subject }}
      </div> -->
    </div>
    <!-- <div class="chat-header__comment" v-if="activeChat.subject">
      Преподаватель по предмету {{ activeChat.subject }}
    </div> -->
  </div>
</template>

<script>
import Arrow from '@/components/chat/icons/Arrow'

import EventBus from '@/components/chat/eventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'chat-header',
  data: function () {
    return {}
  },
  methods: {
    hiddenChat() {
      EventBus.$emit('hide-chat-window')
    }
  },
  computed: {
    ...mapGetters(['activeChat'])
  },
  components: {
    Arrow
  },
  props: ['chatmode'],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.chat-header {
  padding: 12px 17px 13px 17px;
  display: flex;
  justify-content: space-between;
  background: #f0f0f0;
  align-items: center;
  &__arrow {
    display: none;
    @media (max-width: 860px) {
      display: block;
      margin-right: 23px;
      transform: rotate(180deg);
    }
  }
}
.teacher {
  display: flex;
  align-items: center;
  &__avatar {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    margin-right: 17px;
  }
  &__name {
    color: #191d2f;
    font-weight: bold;
  }
  &__avatar__minimize{
    width: 32px;
    height: 32px;
  }
}

</style>
