<template>
  <div class="course-card vue-component">
    <router-link :to="course.to" class="lesson-link"></router-link>
    <div class="item-overflow">
      <div class="lesson-info">
        <h2>{{ course.lessonName }}</h2>
        <span class="l-number">{{ course.lessonN }}</span>
        <span class="l-description">{{ course.lessonDescription }}</span>
      </div>
      <h4>{{ course.lessonTeacher }}</h4>
    </div>
    <div class="visit-arrow">
      <svg
        width="11"
        height="18"
        viewBox="0 0 11 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 2L9 8.72L2 16"
          stroke="#fff"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    <!-- тонкая линия с низу -->
    <div class="line-effect"></div>
  </div>
</template>

<script>
export default {
  name: 'my-course-card',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: {
    course: {
      required: true,
      type: Object
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped="scoped" lang="scss">
@import '@/styles/vars.scss';

.course-card {
  display: flex;
  flex-shrink: 0;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 23px;
  margin: 0 13px;
  position: relative;
  background: #fff;
  min-height: 179px;
  &:hover {
    background: #1da1f2;
    border-color: #1da1f2;
    h2,
    h4,
    .l-description,
    .l-number {
      color: #fff;
    }
    &:before {
      top: 10px;
      left: 10px;
      transform: rotate(4deg);
    }
    .line-effect {
      &::after {
        background: #191d2f;
        width: 50%;
      }
    }
    .visit-arrow {
      background: #191d2f;
    }
    h4 {
      &::after {
        background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 0) 0%,
          rgba(29, 160, 242, 0.882) 53%,
          #1da1f2 100%
        );
      }
    }
    .lesson-info {
      &::after {
        background: linear-gradient(
          90deg,
          rgba(2, 0, 36, 0) 0%,
          rgba(29, 160, 242, 0.882) 53%,
          #1da1f2 100%
        );
      }
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 24px;
    z-index: -1;
    transition: 0.2s;
  }
  .line-effect {
    position: relative;
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 3px;
    margin: auto;
    width: calc(100% - 32px);
    background: #f0f0f0;
    border-radius: 78%;
    border-radius: 78%;
    overflow: hidden;
    &::after {
      content: '';
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: -1px;
      width: 25%;
      height: 3px;
      background: #1da1f2;
      transition: 0.5s;
    }
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  .lesson-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
  h2 {
    margin-bottom: 0;
    transition: 0.1s;
  }
  h4 {
    margin-bottom: 0;
    font-family: $mb;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #191d2f;
    transition: 0.2s;
    padding-top: 10px;
    transition: 0.1s;
    padding-right: 30px;
    position: relative;
    width: 100%;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 28px;
      height: 100%;
      width: 50px;
      background: linear-gradient(
        90deg,
        rgba(2, 0, 36, 0) 0%,
        rgba(255, 255, 255, 0.89) 53%,
        rgba(255, 255, 255, 1) 100%
      );
      z-index: 1;
    }
  }
}
.item-overflow {
  overflow: hidden;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-items: center;
  padding: 23px 23px;
}
.lesson-info {
  white-space: nowrap;
  padding-bottom: 10px;
  width: 100%;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: -23px;
    height: 100%;
    width: 50px;
    background: linear-gradient(
      90deg,
      rgba(2, 0, 36, 0) 0%,
      rgba(255, 255, 255, 0.89) 53%,
      rgba(255, 255, 255, 1) 100%
    );
    z-index: 1;
  }
}
.l-number {
  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-family: $mb;
  color: #191d2f;
  margin-right: 16px;
  transition: 0.1s;
}
.l-description {
  font-family: $mr;
  font-size: 14px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #8c8e97;
  transition: 0.1s;
}
.visit-arrow {
  width: 32px;
  height: 32px;
  border-radius: 12px;
  padding: 0;
  outline: none;
  box-shadow: none;
  display: inline-block;
  background: none;
  background: #1da1f2;
  position: absolute;
  bottom: 23px;
  right: 31px;
  transition: 0.1s;
  z-index: 1;
  svg {
    max-width: 9px;
    position: absolute;
    top: 0px;
    right: -1px;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}
</style>
