import HTTP from '@/api'

export default {
  // Correct record lesson duration value on server
  correctDuration(data) {
    return HTTP.post('/lessons/setStreamVideo', JSON.stringify(data))
  },
  // Test
  createTest(data) {
    return HTTP.post('/tests/store', JSON.stringify(data))
  },
  updateTest(data) {
    return HTTP.post('/tests/update', JSON.stringify(data))
  },
  getTestById(id) {
    return HTTP.get(`/tests/byId?test_id=${id}`)
  },
  checkTest(data) {
    console.log('Data to check', data)
    return HTTP.post('/answers/checkTests', JSON.stringify(data)).then(
      r => r[0]
    )
  },
  // get curator for test (use for direct chat)
  getTeacherFromTeacher(test_group_id, student_id) {
    return HTTP.get(
      `/curatorsManagement/testGroupTeacher?test_group_id=${test_group_id}&student_id=${student_id}`
    )
  },
  // Dtm
  generateDtm(subjectId, amount) {
    return HTTP.post(
      '/dtmTests/generateDtm',
      JSON.stringify({
        subject_id: subjectId,
        tasks_amount: amount
      })
    )
  },
  checkDtm(data) {
    return HTTP.post('/answers/checkDTM', JSON.stringify(data))
  },
  // Auth
  login(phone, password) {
    phone = phone.replace(/[^+\d]/g, '').replace('+', '')
    return HTTP.post(
      '/login',
      JSON.stringify({
        phone,
        password
      })
    )
  },
  register(data) {
    let dataC = { ...data }
    if (dataC.phone)
      dataC.phone = dataC.phone.replace(/[^+\d]/g, '').replace('+', '')
    return HTTP.post('/register', JSON.stringify(dataC))
  },
  // Send reg confirmation
  confirmation(data) {
    let dataC = { ...data }
    if (dataC.phone)
      dataC.phone = dataC.phone.replace(/[^+\d]/g, '').replace('+', '')
    return HTTP.post('/regConfirmation', JSON.stringify(dataC))
  },
  // Referal system
  getMyReferalLink() {
    return HTTP.get('/referalSystem/myLink')
  },
  profileUpdate(formData) {
    return HTTP.post('/profileUpdate', formData)
  },
  logout() {
    return HTTP.post('/logout')
  },
  // Referal system
  getMyReferalLink() {
    return HTTP.get('/referalSystem/myLink')
  },
  // olympiads
  getOlympiadData() {
    return HTTP.get('/olympiads/current')
  },
  regForOlympiad(id) {
    return HTTP.post(`/olympiads/${id}/registration`)
  },
  getOlympiadTest(id) {
    return HTTP.get(`/olympiadStages/test/${id}`)
  },
  getStageByStageId(id) {
    return HTTP.get(`/olympiadStages/${id}`)
  },
  getOlympiadResults(id) {
    return HTTP.get(`/olympiads/${id}/grid`)
  },
  //
  getProfile() {
    return HTTP.get('/profile')
  },
  getLessons() {
    return HTTP.get('/lessons/index')
  },
  getLesson(id) {
    return HTTP.get(`/lessons/byId?id=${id}`)
  },
  getAllLessons() {
    return HTTP.get('/lessons/ofUser')
  },
  getCourseLessons(id) {
    return HTTP.get(`/courses/lessons?course_id=${id}`)
  },
  getCoursesOfUser() {
    return HTTP.get('/courses/ofUser')
  },
  getTestGroup(data) {
    return HTTP.post('/tg/testGroups', JSON.stringify(data))
  },
  getGroupTests(id) {
    return HTTP.get(`/tg/groupTests?test_group_id=${id}`)
  },
  addUserToCourse(data) {
    return HTTP.post('/courses/attachToUser', JSON.stringify(data))
  },
  // get total bonuses for all time and referal invitations count
  getXpAndInvites() {
    return HTTP.get('/bonuses/pointsAndInvites')
  },
  // Get user bonuses history
  getBonusesHistory() {
    return HTTP.get('/bonuses/history')
  },
  getAllCourse() {
    return HTTP.get('/courses/index')
  },
  getTestResults(testGroupId, user_id) {
    let searchParams = `?test_group_id=${testGroupId}`
    if (user_id) searchParams += `&student_id=${user_id}`
    return HTTP.get(`/tg/userResults${searchParams}`)
  },
  getCourseById(id) {
    return HTTP.get(`/courses/byId?id=${id}`)
  },
  getSubjects() {
    return HTTP.get('subjects/index')
  },
  getFullSubjects() {
    return HTTP.get(
      '/subjects/index?order_column=name&direction=desc&perPage=9999'
    )
  },
  getSubjectsById(id) {
    return HTTP.get(`/courses/bySubject?subject_id=${id}`)
  },
  getBySubjectDiffUser(id) {
    return HTTP.get(`/courses/bySubjectDiffUser?subject_id=${id}`)
  },
  orders(data) {
    return HTTP.post('/orders', JSON.stringify(data))
  },
  getPay({ payment_method, order_id, promo_code, use_bonuses }) {
    let useBounuses = use_bonuses ? `&use_bonuses=${use_bonuses}` : ''
    let promoCode = promo_code ? `&promo_code=${promo_code}` : ''
    let baseQuery = `/pay?payment_method=${payment_method}&order_id=${order_id}`
    return HTTP.get(`${baseQuery}${useBounuses}${promoCode}`)
  },
  storeTeacherCorrection(data) {
    return HTTP.post(`/answers/teacherCheck`, JSON.stringify(data))
  },
  passwordRecovery(data) {
    return HTTP.post(`passwordRecovery`, JSON.stringify(data))
  },
  getTransactionHistory() {
    return HTTP.get(`/profile/transactions`)
  },
  checkPromo(code) {
    return HTTP.get(`/promos/byCode?promo_code=${code}`)
  },
  getActualPrice(courseId, code) {
    let params = courseId.map(id => `course_ids[]=${id}&`)
    let r = `/pay/getTotal?${params.join('').slice(0, -1)}`
    if (code) r += `&promo_code=${code}`
    return HTTP.get(r)
  },
  payByBalance(data) {
    return HTTP.post('pay/byBalance', JSON.stringify(data))
  },
  getWatchStream(id) {
    return HTTP.post(`/watchStream/${id}`)
  },
  getWatchStreamRecord(id) {
    return HTTP.post(`/watchStreamRecord/${id}`)
  },
  getChats() {
    return HTTP.get('subjects/userChats')
  },
  getStreamTimeline(id) {
    return HTTP.get(`/lessons/streamTimelines/${id}`)
  },
  getPageContent(group) {
    return HTTP.get(`/pageContent/group/${group}`)
  },
  getReport(userId) {
    return HTTP.get(`/report?user_id=${userId}`)
  },
  getChatInfo(userId) {
    console.log(userId)
    return HTTP.get(`/chat/getUser/${userId}`)
  }
}
