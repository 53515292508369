<template>
  <div class="bonus-program">
    <div class="container">
      <title-nav>{{ $t('bonusnaya-programma') }}</title-nav>
      <div class="row">
        <div class="col-12">
          <h2>{{ $t('usloviya') }}</h2>
          <p>
            {{ $t('qwqwe') }}
          </p>
          <p>
            {{ $t('sdgfg') }}
          </p>
          <p>
            {{ $t('sfsdfgfg') }}
            <br>
            {{ $t('gfhg') }}
            <br>
            {{ $t('sddfgghjj') }}
          </p>
          <p>
            <ol>
              <li>{{ $t('hjkhjkhjkhjk') }}</li>
              <li>{{ $t('dfgjkk') }}</li>
              <li>{{ $t('ghjjj') }}</li>
              <li>{{ $t('hhhhhrdhhjm') }}</li>
              <li>{{ $t('ghhhjhj') }} </li>
              <li>{{ $t('jhjhjjhk') }}</li>
            </ol>
            {{ $t('dopolnitelnye-sposoby-polucheniya-xp-i-bonusov') }}
            <ol>
              <li>{{ $t('fghghjgh') }}</li>
              <li>{{ $t('jjjjghbn') }} </li>
              <li>{{ $t('hfghf') }}</li>
              <li>{{ $t('cvbfnhghj') }}</li>
              <li>{{ $t('fghfhhghgh') }}</li>
              <li>{{ $t('hhhfgngnbnjjh') }}</li>
            </ol>
          </p>
          <p>{{ $t('bbbfgh') }}</p>
          <p>{{ $t('bvbvbghjhj') }}</p>
          <p>{{ $t('cvbndfgfhh') }}</p>
        </div>
        <table-data :pagination="true" :fields="fields" :items="tableItems"/>
      </div>

    </div>
  </div>
</template>

<script>
import TableData from '@/components/UI/table-data/TableData'

export default {
  components: { TableData },
  data: () => ({
    tableItems: [
      { params: this.$t('data.310'), plus: '0', minus: '0' },
      { params: this.$t('data.311'), plus: '300 XP', minus: '0' },
      { params: this.$t('data.312'), plus: '1000 XP', minus: '0' },
      { params: this.$t('data.313'), plus: '1500 XP', minus: '' },
      { params: this.$t('data.314'), plus: '150 XP', minus: '0' },
      // {
      //   params: 'Если ученик сдал домашнее задание в промежутке за 24 часа до срока сдачи, количество ХР начисляется',
      //   plus: '90 XP',
      //   minus: '0'
      // },
      { params: this.$t('data.315'), plus: '0', minus: '300 XP' },
      { params: this.$t('data.316'), plus: '0', minus: '10 XP' },
      { params: this.$t('data.317'), plus: '10 000 XP', minus: '0' },
      { params: this.$t('data.318'), plus: '5 000 XP', minus: '0' },
      { params: this.$t('data.319'), plus: '5 000 XP', minus: '0' },
      { params: this.$t('data.320'), plus: '10 000 XP', minus: '0' },
      { params: this.$t('data.321'), plus: '15 000 XP', minus: '0' },
      { params: this.$t('data.322'), plus: '60 000 XP', minus: '0' }
    ],
    fields: [
      { key: 'params', label: 'Условие' },
      { key: 'plus', label: 'Начисляется' },
      { key: 'minus', label: 'Списывается' }
    ]
  })
}
</script>

<style lang="scss">
.bonus-program {
  margin-top: 30px;

  h2 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
</style>
