<template>
  <!-- Чтобы использовать данный компонент
  Надо вызывать его так
  <tabs :tabs="['заголовок первого таба', 'заголовок второго таба']">
    <template slot="заголовок первого таба">
      какой-то html
    </template>
    <template slot="заголовок второго таба">
      какой-то html
    </template>
  </tabs> -->
  <div class="tabs vue-component">
    <div class="tabs-header">
      <button
        :class="getBtnClass(i)"
        v-for="(tabName, i) in tabs"
        :key="i"
        class="btn"
        @click="toggleActiveTab(i)"
      >
        {{ tabName }}
      </button>
    </div>
    <template v-for="(tabName, i) in tabs">
      <div class="tab-wrapper" :key="tabName" v-show="i == activeTabIndex">
        <slot :name="tabName" />
      </div>
    </template>
    <div class="tabs-header tabs-bottom" v-if="double">
      <button
        :class="getBtnClass(i)"
        v-for="(tabName, i) in tabs"
        :key="i"
        class="btn"
        @click="toggleActiveTab(i)"
      >
        {{ tabName }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'tabs',
  data: function () {
    return {
      activeTabIndex: 0
    }
  },
  methods: {
    ...mapMutations(['setActiveTab']),

    getBtnClass(i) {
      return {
        'secondary--button': i != this.activeTabIndex,
        'primary--button': i == this.activeTabIndex,
        disabled: this.activeTabs.length && !this.activeTabs.includes(i)
      }
    },
    toggleActiveTab(i) {
      this.setActiveTab({
        id: i,
        path: this.$route.path
      })
      this.activeTabIndex = i
      this.$emit('change-tab-index', i)
    }
  },
  computed: {
     ...mapGetters(['getActiveTabs'])
  },
  components: {},
  mounted() {
    const path = this.$route.path
    if (this.getActiveTabs && this.getActiveTabs[path] !== undefined && this.getActiveTabs[path] !== null) {
      console.log(this.getActiveTabs)
      this.toggleActiveTab(this.getActiveTabs[path])
    }
  },
  props: {
    activeTab: {
      type: Number,
      required:false
    },
    tabs: {
      required: true,
      type: Array
    },
    double: {
      type: Boolean,
      default: false
    },
    activeTabs: {
      type: Array,
      default: () => []
    }
  },
  mixins: {}
}
</script>

<style scoped='scoped' lang='scss'>
.tabs-header {
  display: flex;
  width: 100%;
  max-width: 100%;
  overflow: auto;
  border: 1px solid #f0f0f0;
  width: fit-content;
  border-radius: 14px;
  margin-top: 7px;
  margin-bottom: 15px;
  &::-webkit-scrollbar {
    height: 6px;
  }
  .primary--button,
  .secondary--button {
    padding-left: 32px !important;
    padding-right: 32px !important;
    border: none;
    box-shadow: none;
  }

  .primary--button {
    pointer-events: none;
  }

  .btn.disabled {
    background: #d8d7d7;
  }
}

.tab-wrapper > div{
  overflow-x: auto;
}

@media (max-width: 1200px) {
  .tabs-header .btn {
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 14px;
  }
}

@media (max-width: 992px) {
  .tabs {
    &-header {
      width: 100%;
      padding-bottom: 18px;

      border: 0;
      border-radius: 0;
      overflow-x: auto;
      white-space: nowrap;
      border-bottom: 1px solid #f0f0f0;

      &::-webkit-scrollbar {
        opacity: 0;
        visibility: hidden;
        display: none;
      }
    }

    .primary--button,
    .secondary--button {
      padding: 0 !important;
      margin-right: 12px;

      color: #000000 !important;
      font-size: 14px;
      letter-spacing: -0.02em;

      &:last-child {
        margin-right: 0;
      }
    }

    .secondary--button {
      font-family: 'Montserrat-Regular';
      font-weight: normal;
    }

    .primary--button {
      position: relative;
      color: #000000 !important;
      font-size: 14px;
      letter-spacing: -0.02em;
      background: 0 !important;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -18px;
        width: 100%;
        height: 2px;
        background: #1da1f2;
      }
    }
  }
}
</style>
