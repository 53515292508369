<template>
  <div class="code-in vue-component form-box">
    <h2>{{ $t('data.82') }}</h2>
    <form @submit.prevent="sendCode">
      <div class="field-box">
        <span class="code-timer"
          >{{ $t('timetoresendmessage-sek-0', [timeToResendMessage]) }}
          <button class="btn" type="button" :disabled="timeToResendMessage > 0">
            <img
              @click="resendCodeToPhone"
              class="resend-img"
              src="../../../assets/svg/repeat-icon-active.svg"
              alt="reset"
            />
          </button>
        </span>
        <input
          type="text"
          required
          :placeholder="$t('data.83')"
          v-model="code"
        />
        <!-- <p v-if="code.length > 4" class="text-danger mt-4">Код должен быть меньше 4 символов</p> -->
      </div>
      <p class="code-sended-msg">
        {{ $t('data.85') }}
      </p>
      <div class="submit-box">
        <a @click="$router.back()" class="secondary--button">{{
          $t('data.80')
        }}</a>
        <input
          type="submit"
          href="#"
          class="primary--button"
          :value="$t('data.81')"
        />
      </div>
    </form>
    <p v-if="!isCodeCorrect" class="text-danger mt-4">
      {{ $t('data.89') }}
    </p>
  </div>
</template>

<script>
import Api from '@/api/endpoints'

export default {
  name: 'code-in',
  data() {
    return {
      code: '',
      timeToResendMessage: 0,
      isCodeDirty: false,
      isCodeCorrect: true
    }
  },

  methods: {
    async sendCode() {
      const phone = this.$router.currentRoute.params.phone
      const code = this.code

      try {
        const resp = await Api.passwordRecovery({ phone, code })

        const success = resp.success

        if (success) {
          const token = resp.token

          this.$router.push({
            name: 'new-password',
            params: {
              phone,
              code,
              token
            }
          })
        } else {
          this.isCodeCorrect = false
          await Api.passwordRecovery({ phone })
        }
      } catch (error) {
        console.log('error occured: ')
        console.log(error)
        this.isCodeCorrect = false
        return
      }
    },

    async resendCodeToPhone() {
      const phone = this.$router.currentRoute.params.phoneNumber

      try {
        const resp = await Api.passwordRecovery({ phone })

        const success = resp.success
        const timeToResendMessage = resp.resend_available_after

        console.log('resp:')
        console.log(resp)

        if (success) {
          this.timeToResendMessage = timeToResendMessage
          this.timer()
        }
      } catch (err) {}
    },

    // validateInput() {
    //   const value = this.code;

    // },

    timer() {
      const timerId = setInterval(() => {
        if (this.timeToResendMessage > 0) {
          this.timeToResendMessage--
        } else {
          clearInterval(timerId)
        }
      }, 1000)
    }
  },

  computed: {},
  components: {},
  props: [],
  mixins: {},

  beforeMount() {},

  mounted() {
    const timeToResendMessage =
      this.$router.currentRoute.params.timeToResendMessage

    this.timeToResendMessage = timeToResendMessage

    this.timer()
  }
}
</script>

<style lang="scss" scoped>
.primary--button {
  padding-top: 0px;
  padding-bottom: 0px;
}

.resend-img {
  transition: transform 0.7s ease-in-out;
}

.resend-img:hover {
  transform: rotate(180deg);
}

input[type='submit'] {
  padding-top: 0px;
  padding-bottom: 0px;
}
</style>
