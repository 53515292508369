export default {
  state: {
    alerts: [
      {
        text: 'Скоро в этом разделе появятся новые предметы'
      },
      {
        text: 'Скоро в этом разделе появятся новые предметы',
        type: 'warn'
      },
      {
        text: 'Вы не приняли соглашение Политики конфиденциальност',
        type: 'warn'
      }
    ]
  },
  mutations: {
    push(name, type) {
      this.alerts.push({
        name,
        type
      })
    },
    delete(index) {
      if (!index) index = this.alerts.length - 1
      if (!index < 0) return { name: '', type: '' }
      return this.alerts.splice(index, 1)
    }
  },
  actions: {},
  getters: {
    alerts: s => s.alerts
  }
}
