<template>
  <div class="test-report">
    <div class="tests-wrapper vue-component">
      <div class="container">
        <div class="titles-wrap">
          <x-title v-if="title">{{ title }}</x-title>
          <subtitle v-if="subtitle">{{ subtitle }}</subtitle>
        </div>
        <tabs :tabs="testGroupNames" :double="true" v-if="testGroups.length">
          <div v-for="(group, i) in testGroups" :slot="getName(i)" :key="i">
            <div>
              <Tasks
                :tasks="group.tasks"
                :testGroupID="testGroupID"
                :testID="group.test_id"
                @store="onStore"
              />
            </div>
          </div>
        </tabs>
      </div>
    </div>
    <chat-modal />
  </div>
</template>


<script>
import Tasks from '@/components/UI/question/Tasks'
import ChatModal from '@/components/chat/ChatModal'
import Api from '@/api/endpoints'

export default {
  name: 'ReportHomeWork',
  components: { Tasks, ChatModal },
  data() {
    return {
      title: '',
      subtitle: '',
      testGroups: [],
      testGroupID: +this.$route.params.id,
      studentID: +this.$route.params.userId
    }
  },
  async created() {
    this.testGroups = await Api.getTestResults(this.testGroupID, this.studentID)
  },
  computed: {
    testGroupNames() {
      return this.testGroups.map(
        (group, index) =>
          this.$t('modul-1') + ' ' + String.fromCharCode(65 + index)
      )
    }
  },
  methods: {
    getName(i) {
      return this.$t('modul') + ' ' + String.fromCharCode(65 + i)
    },
    onStore(data) {
      Api.storeTeacherCorrection(data).then((res) => {
        if (res.success === false) {
          this.$toast.open({
            message: `Что-то пошло не так. Повторите попытку позже`,
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        } else {
          this.$toast.open({
            message: `тест успешно проверен`,
            type: 'success',
            duration: 5000,
            dismissible: true
          })
        }
      })
    }
  }
}
</script>

<style lang="scss">
label .span.correct {
  &:after {
    background-color: green !important;
    border: 1px solid green !important;
  }

  &:before {
    border: 1px solid green !important;
  }
}

label .span.false:after {
  background-color: #e40a0a !important;
  border: 1px solid #e40a0a !important;
}

@media (max-width: 768px) {
  .quests label {
    width: 100%;
  }
}
</style>
