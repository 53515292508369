import { render, staticRenderFns } from "./DtmSoon.vue?vue&type=template&id=5d417d67&scoped=true&"
import script from "./DtmSoon.vue?vue&type=script&lang=js&"
export * from "./DtmSoon.vue?vue&type=script&lang=js&"
import style0 from "./DtmSoon.vue?vue&type=style&index=0&id=5d417d67&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d417d67",
  null
  
)

export default component.exports