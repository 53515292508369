import Api from '@/api/endpoints'

export default {
  state: {
    title: '',
    subtitle: '',
    activeTabs:{}
  },
  mutations: {
    setActiveTab(state, payload) {
      state['activeTabs'][payload.path] = payload.id
    },
    setPageTitle: (s, title) => {
      document.title = title
      s.title = title
    },
    setPageSubTitle: (s, title) => {
      s.subtitle = title
    }
  },
  actions: {},
  getters: {
    title: s => s.title,
    subtitle: s => s.subtitle,
    getActiveTabs: s => s.activeTabs
  }
}
