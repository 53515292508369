<template>
  <div class="all-homework vue-component">
    <list :tasks-data="tasksData" v-if="tasksData.length" />
    <!-- <empty-list v-if="dataLoaded && !tasksData.length">У вас еще нет ни одного домашнего задания</empty-list> -->
  </div>
</template>

<script>
import List from './List'

import Api from '@/api/endpoints'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'all-homework',
  data: function () {
    return {
      dataLoaded: false,
      tasksData: []
    }
  },
  methods: {
    ...mapActions(['getTestGroup']),
    checkIcon(status) {
      if (status === 'Ожидает выполнения') {
        return require('@/assets/svg/on-wait.svg')
      }
      if (status === 'На проверке') {
        return require('@/assets/svg/on-check.svg')
      }
      if (status === 'Не доделаная задача') {
        return require('@/assets/svg/on-loader.svg')
      }
      if (status === 'Отказ') {
        return require('@/assets/svg/on-error.svg')
      } else {
        return require('@/assets/svg/on-wait.svg')
      }
    },
    checkColor(status) {
      if (status === 'Ожидает выполнения') {
        return '#FFA800'
      }
      if (status === 'На проверке') {
        return '#00AF07'
      }
      if (status === 'Не доделаная задача') {
        return '#8C8E97'
      }
      if (status === 'Отказ') {
        return '#E40A0A'
      } else {
        return '#00AF07'
      }
    },
    async setTasksData() {
      // Set loading data flag to false
      this.dataLoaded = false
      let params = {
        test_group_types: ['dz']
      }
      if (this.$route.params.id) {
        params.course_id = this.$route.params.id
      }
      // Add this because
      try {
        await this.getTestGroup(params)

        // const data = await this.getTestGroup(params)
        console.log(this.testGroup)
        this.tasksData = this.testGroup.map((t) => {
          if (t.test_group_status == null) {
            t.statusKey = 'pending'
          } else if (t.test_group_status.includes('На проверке')) {
            t.statusKey = 'checking'
          } else if (t.test_group_status.includes('Не доделанная задача')) {
            t.statusKey = 'not_completed'
          } else if (t.test_group_status.includes('Отказ')) {
            t.statusKey = 'denied'
          } else if (t.test_group_status.includes('Выполнено')) {
            t.statusKey = 'success'
          }
          t.statusName = t.test_group_status
            ? t.test_group_status
            : 'Ожидает выполнения'
          t.statusColor = this.checkColor(
            t.test_group_status ? t.test_group_status : 'Ожидает выполнения'
          )
          t.statusIcon = this.checkIcon(
            t.test_group_status ? t.test_group_status : 'Ожидает выполнения'
          )
          t.themeName = t.lesson_theme
          t.created_at = t.created_at
            .split('T')[0]
            .split('-')
            .reverse()
            .join('.')
          t.expiried_at = t.updated_at
            .split('T')[0]
            .split('-')
            .reverse()
            .join('.')
          t.courseName = t.course_name
          t.to = {
            name: 'home',
            params: {
              id: t.id
            }
          }

          return t
        })
        //Data loading finished
        this.dataLoaded = true
      } catch (e) {
        this.dataLoaded = true
        console.log(`Error get data from API: ${e}`)
      }
    }
  },
  computed: {
    ...mapGetters(['testGroup'])
  },
  components: {
    List
  },
  props: [],
  mixins: {},
  async beforeMount() {
    await this.setTasksData()
  }
}
</script>

<style lang="scss">
@media (max-width: 1340px) {
  // .task-info ul li{
  //   white-space: inherit;
  //   padding-left: 10px;
  //   padding-right: 10px;
  // }
}

@media (max-width: 1200px) {
}

@media (max-width: 992px) {
}

@media (max-width: 576px) {
}
</style>
