<template>
  <div class="new-password form-box vue-component">
    <h2>{{ $t('data.43') }}</h2>
    <form @submit.prevent="setNewPassword">
      <div class="field-box">
        <p>{{ $t('data.43') }}<span>*</span></p>
        <input
          type="password"
          required
          :placeholder="$t('data.74')"
          v-model="password.value"
        />
        <p v-if="password.value.length < 8" class="text-danger py-1">
          {{ $t('data.90') }}
        </p>
      </div>
      <div class="field-box">
        <p>{{ $t('data.44') }}<span>*</span></p>
        <input
          type="password"
          required
          :placeholder="$t('data.74')"
          v-model="repeatedPassword.value"
        />
      </div>
      <p v-if="!isPasswordsEquel" class="text-danger text-center">
        {{ $t('paroli-ne-sovpadayut') }}
      </p>
      <div class="submit-box">
        <a href="#" class="secondary--button">{{ $t('data.40') }}</a>
        <input
          type="submit"
          href="#"
          class="submit-btn primary--button"
          :value="$t('data.25')"
          :disabled="isDisabled"
        />
      </div>
    </form>
  </div>
</template>

<script>
import Api from '@/api/endpoints'

export default {
  name: 'new-password',

  data() {
    return {
      password: {
        value: '',
        isDirty: false,
        isValid: false
      },
      repeatedPassword: {
        value: '',
        isDirty: false
      }
    }
  },

  computed: {
    isPasswordsEquel() {
      return this.password.value === this.repeatedPassword.value
    },

    isDisabled() {
      return this.password.value.length < 8 || !this.isPasswordsEquel
    }
  },

  methods: {
    async setNewPassword() {
      const phone = this.$router.currentRoute.params.phone
      const code = this.$router.currentRoute.params.code
      const token = this.$router.currentRoute.params.token

      const password = this.password.value

      try {
        const resp = await Api.passwordRecovery({
          password,
          token,
          phone,
          code
        })
        const success = resp.success

        if (success) {
          this.$router.push({
            name: 'login'
          })
        }
      } catch (err) {
        console.log(err)
      }
    },

    checkPassword(val) {
      this.password.isValid = val
    },

    makePasswordFieldDirty() {
      this.password.isDirty = true
    },

    makeRepeatedPasswordFieldDirty() {
      this.repeatedPassword.isDirty = true
    }
  }
}
</script>

<style lang="scss" scoped>
input[type='submit'] {
  padding-top: 0px;
  padding-bottom: 0px;
}

input[type='submit']:disabled {
  cursor: unset;
}

.submit-btn:disabled {
  opacity: 0.7;
  cursor: default;
}
</style>
