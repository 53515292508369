<template>
  <div class="phone-input vue-component">
    <input
      :value="val"
      @input.prevent.stop="onchange"
      v-phone-mask="'+998 (##) ###-##-##'"
      required
      :placeholder="placeholder"
      :class="info.class"
    />
    <p class="error">{{ info.errorMessage }}</p>
  </div>
</template>

<script>
import { VueMaskDirective } from 'v-mask'

export default {
  name: 'phone-input',
  data: function () {
    return {
      langs: [
        {
          label: 'uz',
          icon: require('@/assets/svg/uzbekistan.svg')
        },
        {
          label: 'ru',
          icon: require('@/assets/img/ru-lang.jpg')
        }
      ],
      phoneMask: ''
    }
  },
  methods: {
    onToggleLang(data) {
      this.changeMask(data)
    },
    changeMask(data) {
      this.phoneMask = this.getPhoneMask(data)
    },
    getPhoneMask(data) {
      return '+998 (##) ###-##-##'
    },
    activeLang() {
      this.phoneMask = ''
      return (
        this.langs.find((l) => l === this.$detectLanguage()) || this.langs[0]
      )
    },
    onchange({ target }) {
      this.info.errorMessage = ''
      this.info.class = ''
      this.$emit('change', target.value)
    }
  },
  computed: {
    placeholder() {
      return this.phoneMask.replaceAll('#', 'X')
    }
  },
  model: {
    prop: 'val',
    event: 'change'
  },
  components: {},
  directives: {
    'phone-mask': VueMaskDirective
  },
  props: {
    val: String,
    info: Object,
    isEdit: Boolean
  },
  beforeMount() {
    this.phoneMask = this.getPhoneMask(this.activeLang())
  }
}
</script>

<style scoped="scoped" lang="scss"></style>
