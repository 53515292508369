<template>
  <header class="header">
    <div class="row">
      <div class="col-6">
        <div
          class="logo-box d-flex d-lg-none"
          :style="!isAuthPage || 'display: block !important'"
          v-if="showLogo"
        >
          <a
            target="_blank"
            :href="'https://xacademy.uz/' + $detectLanguage()"
            v-if="isAuthPage"
          >
            <img src="@/assets/img/logo.svg" alt="X_Academy" />
          </a>
          <router-link v-else :to="{ name: 'home' }"
            ><img src="@/assets/img/logo.svg" alt="X_Academy"
          /></router-link>
        </div>
      </div>
      <div class="col-6 d-none d-md-block">
        <vue-skeleton-loader
          v-show="loading"
          type="circle"
          :size="50"
          animation="wave"
        />
        <profile-miniature v-if="!loading && user" />
        <auth-links v-if="!loading && !user" />
      </div>
      <div class="col-6 d-flex justify-content-end d-md-none">
        <burger-menu
          @toogleOpenNavBar="toogleOpenNavBar"
          :isOpened="isNavBarOpened"
        />
      </div>
    </div>
    <div
      class="mobile-navs d-block d-md-none"
      :class="['d-block', isNavBarOpened ? 'show' : 'hide']"
    >
      <div class="mobile-navs__inner">
        <div
          class="row d-block d-md-none"
          :class="['d-block', isNavBarOpened ? 'show' : 'hide']"
        >
          <div class="col-12">
            <div
              class="nav-bar-wrapper p-2 w-100 d-flex justify-content-left"
              v-if="user"
            >
              <nav-bar @close_nav="toogleOpenNavBar" />
            </div>

            <div class="mobile-profile">
              <div class="mobile-profile__header">
                <div class="mobile-profile__title">{{ $t('profil') }}</div>
              </div>

              <div class="mobile-profile__body">
                <profile-miniature
                  class="mobile-profile__profile"
                  v-if="user"
                  @closeMenu="toogleOpenNavBar"
                />
                <auth-links
                  @isOpenMenu="isOpenMenu"
                  class="mobile-profile__actions"
                  v-else
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import ProfileMiniature from '@/components/base/header/ProfileMiniature'
import AuthLinks from '@/components/base/header/AuthLinks'
import BurgerMenu from '@/components/UI/BurgerMenu'
import NavBar from '@/components/base/NavBar'
import { mapGetters } from 'vuex'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'Header',
  data() {
    return {
      isNavBarOpened: false
    }
  },
  components: {
    ProfileMiniature,
    AuthLinks,
    BurgerMenu,
    NavBar,
    VueSkeletonLoader
  },
  computed: {
    ...mapGetters(['user']),
    loading() {
      return this.$store.getters['loading']
    },
    isAuthPage() {
      return this.$route.path.split('/').find((v) => v === 'auth')
    }
  },
  props: ['show-logo'],

  methods: {
    toogleOpenNavBar() {
      this.isNavBarOpened = !this.isNavBarOpened
    },
    isOpenMenu(val) {
      this.isNavBarOpened = val
    }
  }
}
</script>

<style lang="scss" scoped>
@import './src/styles/vars.scss';

//====
.header {
  // height: 79px;
  position: sticky;
  top: 0;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  background: #fff;
  z-index: 1000;
  margin: auto;
}

.logo-box {
  // display: flex;
  align-items: center;
  margin-right: 57px;
  height: 100%;
}

.nav-bar-wrapper {
  background-color: rgba(142, 208, 249, 0.1);
  border-radius: 24px;
}

.mobile-profile {
  display: flex;
  align-items: center;
  flex-direction: column;

  padding-top: 10px;

  &__header {
    margin-bottom: 5px;
  }

  &__title {
    display: flex;
    align-items: center;
    text-align: center;

    font-size: 24px;
    line-height: 29px;

    color: #000000;
    letter-spacing: 0.03em;
    text-transform: uppercase;

    font-family: 'Montserrat-Bold';
  }

  &__body {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .mobile-navs {
    position: fixed;
    top: 59px;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;

    &__inner {
      overflow: auto;
      height: 100%;
      padding: 0 15px;
    }
  }

  .logo-box {
    display: flex !important;
  }
  .show {
    transition: 0.5s;
    transform: translateX(0);
  }
  .hide {
    transition: 0.5s;
    transform: translateX(120%);
  }
}
</style>
