var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"table-data"},[_c('b-table',{ref:"b-table",class:['table', 'table-data', _vm.isOlympiad ? 'table-olympiad' : ''],attrs:{"per-page":_vm.perPage,"current-page":_vm.currentPage,"fields":_vm.fields,"items":_vm.detailsItems},scopedSlots:_vm._u([(_vm.isLessons)?{key:"cell(courseSubjectName)",fn:function(data){return [_c('strong',[_vm._v(_vm._s(data.item.subject.name))])]}}:null,{key:"cell(subject)",fn:function(data){return [_c('strong',[_vm._v(_vm._s(data.value))])]}},{key:"cell(status)",fn:function(data){return [_c('span',{staticClass:"status_text",class:_vm.getItemStatus(data.item.status)},[_vm._v(" "+_vm._s(data.item.status)+" ")])]}},{key:"cell(updated_at)",fn:function(data){return [(data.item.updated_at)?_c('span',[_vm._v(" "+_vm._s(data.item.updated_at.split('T')[0].split('-').reverse().join('.'))+" ")]):_vm._e()]}},{key:"cell(test_group_status)",fn:function(data){return [(data.item.hasOwnProperty('mark'))?_c('span',{staticClass:"mark_text",class:[_vm.checkMarkClass(data.item.mark)]},[_vm._v(" "+_vm._s(_vm.checkResult(data.item.mark))+" ")]):_c('span',{class:['status_text'],style:('color:' + _vm.checkColor(data.item.test_group_status))},[_c('img',{attrs:{"src":_vm.checkIcon(data.item.test_group_status),"alt":"status"}}),_vm._v(" "+_vm._s(!data.item.test_group_status ? 'Ожидает выполнения' : data.item.test_group_status)+" ")])]}},{key:"cell(id)",fn:function(data){return [(_vm.isLessons)?_c('div',{staticClass:"d-flex justify-content-end"},[_c('router-link',{staticClass:"checkout secondary--button",attrs:{"to":{ name: 'lesson-record', params: { id: data.item.id } },"href":"#"}},[_vm._v(" "+_vm._s(_vm.$t('posmotret-zapis'))+" ")])],1):_vm._e(),(_vm.isExams || _vm.dz)?_c('div',{staticClass:"d-flex justify-content-end"},[(
            data.item.test_group_status === 'Ожидает выполнения' ||
            data.item.test_group_status === null
          )?_c('router-link',{staticClass:"checkout secondary--button",attrs:{"to":{ name: 'testing', params: { id: data.item.id, type: 'hw' } },"href":"#"}},[_vm._v(" "+_vm._s(_vm.$t('pereiti-na-testirovanie'))+" ")]):_c('router-link',{staticClass:"checkout secondary--button",attrs:{"to":{
            name: _vm.dz ? 'report-homework' : 'exams-homework',
            params: { id: data.item.id }
          },"href":"#"}},[_vm._v(" "+_vm._s(_vm.$t('posmotret-otchet'))+" ")])],1):_vm._e()]}},{key:"cell()",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},(_vm.isLessons)?{key:"row-details",fn:function(data){return [_c('div',{staticClass:"details-wrap"},[_c('span',{staticClass:"time-info"},[(data.item.video_duration)?_c('span',{staticClass:"time-info"},[_vm._v(" "+_vm._s(_vm.videoDuration(data.item.video_duration))+" ")]):_vm._e()]),(data.item.test_groups_ids && data.item.test_groups_ids.length)?_c('span',{class:[
            'homework-loading',
            data.item.dz_completed ? 'complete' : ''
          ],staticStyle:{"float":"right"},on:{"click":function($event){return _vm.toDz(data.item)}}},[(data.item.dz_completed)?_c('router-link',{attrs:{"to":{
              name: 'report-homework',
              params: { id: data.item.test_groups_ids[0] }
            }}},[_vm._v(" "+_vm._s(_vm.$t('domashnee-zadanie'))+" "),_c('img',{attrs:{"src":require('@/assets/svg/double-checked.svg'),"alt":""}})]):_c('router-link',{attrs:{"to":{
              name: 'testing',
              params: { type: 'hw', id: data.item.test_groups_ids[0] }
            }}},[_vm._v(" "+_vm._s(_vm.$t('domashnee-zadanie'))+" "),_c('img',{attrs:{"src":require('@/assets/svg/loading.svg'),"alt":""}})])],1):_vm._e()])]}}:null],null,true)}),(_vm.pagination)?_c('b-pagination',{staticClass:"pagination-box",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"aria-controls":"my-table","first-number":"","last-number":""},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }