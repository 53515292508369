<template>
  <section class="sc-soon">
    <div class="soon-logo">
      <img class="rotating" src="@/assets/svg/soon-text.svg" alt="" />
      <img src="@/assets/svg/soon-x.svg" alt="" />
    </div>
    <h1>{{ $t('dannaya-stranica-ne-naidena') }}</h1>
    <router-link :to="{ name: 'home' }" class="link">{{
      $t('vernutsya-na-glavnuyu')
    }}</router-link>
  </section>
</template>


<script>
export default {
  name: 'Soon',
  data() {
    return {
      links: [
        {
          label: 'ru',
          link: 'https://instagram.com/xacademy_uz'
        },
        {
          label: 'uz',
          link: 'https://instagram.com/xacademy.uz'
        }
      ]
    }
  },
  computed: {
    inst() {
      return (
        this.links.find((l) => l.label == this.$detectLanguage()).link ||
        this.links[0].link
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import './src/styles/vars.scss';
.sc-soon {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: calc(100vh);
  background: #1da1f2;
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  flex-direction: column;
  align-items: center;
  align-content: center;
  *,
  a {
    color: #fff;
  }
}
p {
  font-family: $mr;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #191d2f;
  width: 100%;
  margin: 10px 0;
}
h1 {
  font-size: 54px;
  line-height: 64px;
  margin-bottom: 22px;
}
.soon-logo {
  display: inline-block;
  position: relative;
  width: 154px;
  height: 154px;
  margin-bottom: 40px;
  user-select: none;
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 154px;
  }
}
.rotate-text {
  animation: rotateText duration timing-function delay iteration-count direction
    fill-mode;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}
.insta-link {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  text-decoration: none;
  transition: 0.2s;
  margin: 10px 0;
  display: flex;
  align-items: center;
  &:hover {
    opacity: 0.5;
  }
  img {
    margin-right: 8px;
  }
}
@media (max-width: 744px) {
  h1 {
    font-size: 32px;
    line-height: 36px;
  }
}
.link {
  font-size: 24px;
  text-decoration: underline;
  cursor: pointer;
}
</style>