<template>
  <div class="msg-box vue-component" ref="box">
    <template v-if="driver">
      <msg-template
        v-for="(msg, i) in updateMsgs(currentMsgs)"
        :msg="msg"
        :key="i"
        class="msg-box__template"
      />
    </template>
  </div>
</template>

<script>
import MsgTemplate from './MsgTemplate'
import { mapGetters } from 'vuex'

export default {
  name: 'msg-box',
  data: function () {
    return {}
  },
  methods: {
    initOnScrollEvent() {
      let msgs = this.driver.channels[this.roomId].msgs
      // this.$refs.box.addEventListener('scroll', async (e) => {
      //   if (e.target.scrollTop < 200) {
      //     await this.channel.loadHistory(50, msgs[msgs.length - 1].ts.$date)
      //     console.log('loaded')
      //     this.$forceUpdate()
      //   }
      // })
    },
    updateMsgs(msgs) {
      console.log(msgs)
      return msgs.reduce((unic, current) => {
        if (
          !unic.find((msg) => {
            return current.ts.$date == msg.ts.$date
          })
        ) {
          unic.push({
            ...current,
            itsMe: current.u._id === this.user.chat_id
          })
        }
        return unic
      }, [])
    }
  },
  computed: {
    ...mapGetters(['driver', 'user', 'currentMsgs']),
    channel() {
      return this.driver.channels[this.roomId]
    }
  },
  components: {
    MsgTemplate
  },
  props: {
    roomId: {
      required: true,
      type: String
    }
  },
  mixins: {},
  async beforeMount() {
    this.driver.channels[this.roomId].on('message', (data) => {
      this.$forceUpdate()
      // Без setTimeout страница не успевает обновиться
      setTimeout(() => this.$emit('on-msg', data))
    })
  },
  async mounted() {
    await this.driver.channels[this.roomId].loadHistory(300)
    this.$forceUpdate()
    // Без setTimeout страница не успевает обновиться
    setTimeout(() => this.$emit('on-mounted'))
    this.initOnScrollEvent()
  }
}
</script>

<style scoped='scoped' lang='scss'>
.msg-box {
  padding: 10px 32px 16px 16px;
  &__template {
    margin-bottom: 13px;
    &:last-child {
      margin-bottom: 16px;
    }
  }
}
</style>
