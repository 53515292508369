import TestEditor from '@/components/admin/Admin'
import View from '@/components/admin/ViewTest'

export default [
  {
    name: 'test-editor',
    path: ':id?',
    component: TestEditor,
    meta: {
      title: 'Редактор тестов'
    }
  },
  {
    name: 'test-view',
    path: 'view/:id',
    component: View,
    meta: {
      title: 'Просмотр теста'
    }
  }
]
