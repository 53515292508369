import fa from 'laravel-file-manager/src/lang/fa'
import i18n from '@/i18n'

export default {
  // проверка на валидацию формы (любой)
  validationForm(form, age) {
    // change class, add border
    Object.values(form).map(item => {
      if (
        (item.required && !item.model) ||
        (item.required &&
          item.type === 'phone' &&
          item.model
            .replace(/[^+\d]/g, '')
            .replace('+', '')
            .toString().length !== 12)
      ) {
        item.class = 'border-danger'
        item.errorMessage = i18n.t('data.253')
        if (item.typeValidation === 'birthday') {
          this.validationAge(age, form)
        }
        if (item.type === 'phone') {
          item.errorMessage = i18n.t('data.92')
        }
      } else {
        item.class = ''
        item.errorMessage = ''
      }
    })
    let isMobileValid = true
    const isMobileForm = Object.values(form).filter(
      item => item.type === 'phone'
    )
    if (isMobileForm.length) {
      isMobileValid =
        isMobileForm[0].model
          .replace(/[^+\d]/g, '')
          .replace('+', '')
          .toString().length === 12
    }
    // return true or false
    return (
      !Object.values(form).some(item => item.required && !item.model) &&
      isMobileValid
    )
  },

  //Проверка на возраст, старше 12 лет
  validationAge(age, form) {
    if (!age) {
      form.birthday.errorMessage = 'Вы должны быть старше 12 лет!'
      form.birthday.class = 'border-danger-input'
      return false
    } else {
      form.birthday.errorMessage = ''
      form.birthday.class = ''
      return true
    }
  },

  // Проверка пароль на регулярку (8 символов с цифрами и буквами), и проверка на совпадение паролей
  // p.s нужно поменять консоли на модалки или тултипы с подсказками, в чем ошибка
  checkConfirmationPassword(regPass, form) {
    let isValidPassword = false
    if (!regPass) {
      form.password.isCheckConfirm = true
      form.password.errorMessage = i18n.t('data.90')
      form.password.class = 'border-danger'
      isValidPassword = false
      return false
    }
    // Если проверка включает поле старого пароля то проверяем чтобы старый и новый пароли не совпадали
    if (form.hasOwnProperty('inputOldPass')) {
      if (form.password.model === form.inputOldPass.model) {
        form.password_confirmation.isCheckConfirm = true
        form.password['class'] = 'border-danger'
        form.password.errorMessage = i18n.t('data.269')
        isValidPassword = false
        return false
      }
    }
    if (form.password.model !== form.password_confirmation.model) {
      form.password_confirmation.isCheckConfirm = true
      form.password.class = 'border-danger'
      form.password_confirmation.class = 'border-danger'
      form.password.errorMessage = i18n.t('data.253')
      form.password_confirmation.errorMessage = i18n.t('data.253')
      isValidPassword = false
      return false
    } else {
      form.password.class = ''
      form.password_confirmation.class = ''
      form.password.errorMessage = ''
      form.password_confirmation.errorMessage = ''
      form.password_confirmation.isCheckConfirm = false
      form.password.isCheckConfirm = false
      isValidPassword = true
    }
    return isValidPassword
  }
}
