<template>
  <section v-if="dtmResults.length" class="sc-test-result">
    <div class="container">
      <div class="row">
        <div class="congrats">
          <div class="img-box">
            <img :src="require('@/assets/img/done-guy.jpg')" alt="" />
          </div>
          <h1>
            {{ $t('data.284') }}<br />
            {{ $t('data.285') }}
          </h1>
          <p class="main--text">
            Оно не отправляется кураторам, поэтому предлагаем вам ознакомиться с
            ответами и решениями, чтобы оценить свою работу
          </p>
          <span @click="viewTest" class="test-checkout">{{
            $t('data.286')
          }}</span>
        </div>
        <div class="result-box" v-if="dtmResults.length >= 2">
          <div class="result-info" v-if="outher.length">
            <h3>Обязательные</h3>
            <div class="custom-row row">
              <div class="custom-col col-4" v-for="(res, i) in outher" :key="i">
                <result-card
                  :number-questions="res.results.length"
                  :correct-number="
                    res.results.filter((s) => s.isCorrect).length
                  "
                  :subject="res.subjectName"
                />
              </div>
            </div>
          </div>
          <div class="result-info" v-if="required.length">
            <h3>Основные</h3>
            <div class="custom-row row">
              <div
                class="custom-col col-4"
                v-for="(res, i) in required"
                :key="i"
              >
                <result-card
                  :number-questions="res.numberQuestions"
                  :correct-number="
                    res.results.filter((s) => s.isCorrect).length
                  "
                  :subject="res.subjectName"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center" v-else>
          <div class="single-result-wrapper">
            <result-card
              class=""
              :number-questions="dtmResults[0].results.length"
              :correct-number="
                dtmResults[0].results.filter((s) => s.isCorrect).length
              "
              :subject="dtmResults[0].subjectName"
            />
          </div>
        </div>
        <div class="w-100 text-center">
          <router-link
            :to="{ name: 'dtm' }"
            class="secondary--button close-result"
            >Закрыть результаты
          </router-link>
        </div>
      </div>
    </div>
  </section>
  <section v-else class="sc-test-result">
    <div class="container">
      <div class="row">
        <div class="congrats">
          <div class="img-box">
            <img :src="require('@/assets/img/done-guy.jpg')" alt="" />
          </div>
          <h1>
            {{ $t('data.284') }}<br />
            {{ $t('data.285') }}
          </h1>
          <p class="main--text">
            Оно не отправляется кураторам, поэтому предлагаем вам ознакомиться с
            ответами и решениями, чтобы оценить свою работу
          </p>
          <span @click="viewTest" class="test-checkout">{{
            $t('data.285')
          }}</span>
        </div>
        <div class="d-flex justify-content-center">
          <div
            class="testing-dz single-result-wrapper"
            v-for="(res, index) in correctNumber"
            :key="index"
          >
            <result-card
              :number-questions="res.test.length"
              :correct-number="res.results.filter((s) => s.isCorrect).length"
              :subject="res.subjectName"
            />
          </div>
        </div>
        <div class="w-100 text-center">
          <router-link
            :to="{ name: 'dtm' }"
            class="secondary--button close-result"
            >Закрыть результаты
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ResultCard from '@/components/UI/question/Results/Card'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TestResult',
  data() {
    return {
      id: null
    }
  },
  mounted() {
    this.id = this.$route.params.id
  },
  methods: {
    viewTest() {
      this.$router.push({
        name: 'dtm-report',
        params: { type: this.$route.params?.type, id: this.id }
      })
    }
  },
  computed: {
    ...mapGetters(['results']),
    dtmResults() {
      console.log(this.results)
      return this.results?.dtm || []
    },
    correctNumber() {
      if (!this.results[this.$route.params.id]) return []

      let resArr = []
      this.results[this.$route.params.id].filter((item) => {
        let i = resArr.findIndex((x) => x.id === item.id)

        if (i <= -1) {
          resArr.push(item)
        }

        return null
      })
      return resArr || []
    },
    required() {
      if (!this.dtmResults) return
      return this.results.dtm.filter((s) => s.required)
    },
    outher() {
      if (!this.dtmResults) return
      return this.results.dtm.filter((s) => !s.required)
    }
  },
  components: {
    ResultCard
  }
  // beforeMount() {
  //   this.setResultsFromLS()
  // }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.single-result-wrapper {
  margin-top: 32px;
  margin-bottom: 15px;
}

.sc-test-result {
  padding-top: 42px;
  padding-bottom: 30px;
}

.congrats {
  max-width: 504px;
  width: 100%;
  text-align: center;
  margin: auto;
}

.img-box {
  width: 189px;
  height: 189px;
  border-radius: 100%;
  overflow: hidden;
  border: 2px solid #1da1f2;
  position: relative;
  display: inline-block;
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
  }
}

h1 {
  margin-top: 20px;
  margin-bottom: 16px;
}

.test-checkout {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  text-decoration: none;
  transition: 0.2s;
  margin-bottom: 20px;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.result-box {
  display: inline-block;
  width: 100%;
  max-width: 728px;
  padding: 40px 40px 28px 40px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-shadow: 0px 0px 40px rgba(29, 161, 242, 0.1);
  border-radius: 12px;
  margin: 40px auto 20px auto;
}

.result-info {
  .custom-row {
    margin-left: -12px;
    margin-right: -12px;
    margin-bottom: 12px;
  }

  .custom-col {
    padding: 0 12px;
    margin: 10px 0;
  }

  h3 {
    font-family: $mb;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8c8e97;
    margin-bottom: 6px;
  }
}

.tip-box {
  display: flex;
  align-items: center;
  width: 100%;
  background: #b3e7b5;
  border-radius: 12px;
  padding: 15px 84px 15px 32px;
  position: relative;
  margin-bottom: 32px;

  p {
    margin-bottom: 0;
  }

  .close {
    position: absolute;
    top: 0;
    right: 21px;
    bottom: 0;
    margin: auto;
    box-shadow: none;
    opacity: 1;
    border: none;
    text-shadow: none;
    font-weight: 100;
    font-size: 30px;
  }
}

.single-result {
  background: rgba(142, 208, 249, 0.3);
  border: 1px solid #1da1f2;
  border-radius: 12px;
  min-width: 200px;
  padding: 15px 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  text-align: left;

  &:before {
    content: '';
    height: 40px;
    top: 0;
    right: 0;
    width: 23px;
    position: absolute;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(221, 241, 253, 0.884),
      rgb(221 241 253),
      #ddf1fd
    );
  }
}

.r-rate {
  position: relative;
  text-align: center;
  width: 54px;
  height: 54px;
  margin-right: 16px;
  border-radius: 100%;
  background-color: #1da1f2;
  background: linear-gradient(270deg, #1da1f2 50%, transparent 50%),
    linear-gradient(-50deg, #1da1f2 50%, transparent 50%);
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  top: 2px;
  left: 2px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 16px;
}

.r-lesson {
  h4 {
    font-family: $mb;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #000000;
  }

  p {
    margin: 2px 0;
  }
}
.testing-dz {
  margin-left: 10px;
  margin-right: 10px;
}
.close-result {
  margin-top: 20px;
}

@media (max-width: 992px) {
  .col-4 {
    width: 100%;
  }
}
</style>




