<template>
  <div class="chat-sidebar vue-component">
    <!-- <search /> -->
    <slider v-if='items' :items="items" class="chat-sidebar__slider" />
    <support />
  </div>
</template>

<script>
import Search from './Search.vue'
import Slider from './Slider'
import Support from './Support'
import { mapGetters } from 'vuex'
export default {
  name: 'chatSidebar',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['logginedInChat', 'driver', 'user']),
    items() {
      if (!this.logginedInChat || !this.chats.filter) return []
      let c = this.chats
        .filter((c) => c.curators.length && c.isDirect == true)
        .map((c) => {
          // console.log(
          //   {
          //   name: c.name,
          //   chats: c.curators.map((cur) => {
          //     try{
          //       let msgs = this.driver.channels[cur.chat_id].msgs
          //       return {
          //         name: cur.firstName + ' ' + cur.lastName,
          //         avatar: cur.image,
          //         time: '',
          //         msg: {
          //           ...msgs[msgs.length - 1],
          //           itsMe: this.user?.chat_id === msgs[msgs.length - 1]?.u?._id
          //         },
          //         chatId: cur.chat_id
          //       }
          //     }catch(e){
          //       console.log("Error in sidebar", e)
          //     }
          //     })
          // }
          // )
          return {
            name: c.name,
            chats: c.curators.map((cur) => {
              try{
                let msgs = this.driver.channels[cur.chat_id].msgs
                return {
                  name: cur.firstName + ' ' + cur.lastName,
                  avatar: cur.image,
                  time: '',
                  msg: {
                    ...msgs[msgs.length - 1],
                    itsMe: this.user?.chat_id === msgs[msgs.length - 1]?.u?._id
                  },
                  chatId: cur.chat_id
                }
              }catch(e){
                console.log("Error in sidebar", e)
              }
            })
          }
        })
      return c
    }
  },
  watch: {},
  components: {
    Search,
    Slider,
    Support
  },
  props: {
    chats: {
      type: Array,
      required: true
    }
  },
  mixins: {},
  mounted() {}
}
</script>

<style scoped='scoped' lang='scss'>
.chat-sidebar {
  position: relative;
  background: rgba(240, 240, 240, 0.3);
  box-shadow: inset -1px 0px 0px #c4c4c4;
  &__slider {
    padding-top: 22px;
    border-bottom: 1px solid #f0f0f0;
  }
}
</style>
