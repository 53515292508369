<template>
  <div
    class="chat vue-component"
    :class="{ chat__minimize: chatmode == 'mini' }"
  >
    <sidebar
      :chats="chats"
      v-if="chats.length && chatmode == 'usual'"
      class="chat__sidebar"
    />
    <chat-window
      :chatmode="chatmode"
      v-show="showChatWindow"
      class="chat__window"
      :class="{ padding_mode: chatmode != 'usual' }"
    />
    <img-modal v-if="modalImgSrc" ref="imgModal" :src="modalImgSrc" />
  </div>
</template>

<script>
import Sidebar from '@/components/chat/sidebar/index'
import ChatWindow from '@/components/chat/window/index'
import ImgModal from '@/components/chat/modal/ImgModal'

import EventBus from './eventBus'
import { mapGetters } from 'vuex'

export default {
  name: 'chat',
  data: function () {
    return {
      showChatWindow: this.$props.chatmode == 'usual' ? false : true,
      modalImgSrc: ''
    }
  },
  methods: {},
  computed: {},
  components: {
    Sidebar,
    ChatWindow,
    ImgModal
  },
  props: {
    chatmode: {
      type: String,
      default: 'usual'
    },
    chats: {
      required: true,
      type: Array
    }
  },
  mixins: {},
  beforeMount() {
    EventBus.$on('hide-chat-window', () => (this.showChatWindow = false))
    EventBus.$on('show-chat-window', () => (this.showChatWindow = true))
    EventBus.$on('show-img-modal', (src) => {
      this.modalImgSrc = src
      console.log(src)
      if (!this.$refs.imgModal) return
      this.$refs.imgModal.show()
    })
  }
}
</script>

<style lang="scss">
@media (max-width: 768px) {
  .sidebar-menu--mini {
    display: none !important;
  }
  .chat-template__content {
    width: 100vw !important;
    margin-left: 0 !important;
  }
}
</style>

<style scoped='scoped' lang='scss'>
.padding_mode {
  padding: 5px;
}
.chat {
  display: flex;
  &__sidebar {
    width: 30%;
    min-width: 320px;
  }
  &__window {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
  }
  // height: calc(100vh - 79px);

  @media (max-width: 860px) {
    display: block;
    overflow: hidden;
    position: relative;
    &__sidebar {
      width: 100%;
      height: 100%;
    }
    &__window {
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      position: absolute;
      background: #fefefe;
    }
  }
}
.chat-minimize {
  height: 700px !important;
}
.slide-enter-active {
  animation: from-left 2s;
}
.slide-leave-active {
  animation: from-left 2s reverse;
}
@keyframes from-left {
  0% {
    transform: translate(-100vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
</style>
