<template>
  <div>
    <div class="meta-data">
      <div v-show="videoMeta.date" class="meta-data-date">
        <img src="@/assets/svg/cal_icon.svg" />
        <span>{{ videoMeta.date }}</span>
      </div>
      <div v-show="videoMeta.time" class="meta-data-time">
        <img src="@/assets/svg/time_icon.svg" />
        <span>{{ videoMeta.time }}</span>
      </div>
    </div>
    <div class="wrapper">
      <div class="video-player" ref="videoPlayer">
        <video-player
          v-if="videoInput && videoInput.video_url"
          :videoInput="videoInput"
          :playerMode="playerMode"
          :streamStatus="streamStatus"
          :poster="poster"
        >
        </video-player>
      </div>
    </div>
    <div
      v-if="videoData && videoData.dz != null && videoData.dz.length"
      class="homework-ntf"
    >
      <div class="homework-ntf-left">
        <img src="@/assets/svg/info_icon.svg" alt="info-icon" />
        <span> {{ $t('data.287') }}</span>
      </div>
      <div class="homework-ntf-right" @click="doHomeWorkHandler">
        {{ $t('data.288') }}
      </div>
    </div>
    <div v-if="timeline" class="timeline-navigation">
      <TimeLine :timeline="timeline" />
    </div>
  </div>
</template>

<script>
import HTTP from '@/api/endpoints'
import { mapMutations } from 'vuex'
import VideoPlayer from '@/components/UI/small-components/Player'
import TimeLine from '@/components/UI/small-components/TimeLine'

export default {
  components: { VideoPlayer, TimeLine },
  data() {
    return {
      timeline: null,
      // timeline:{
      //           0: "Начало видео урока",
      //           32: "Середина видео урока",
      //           1455: "Конец видео урока"
      //           },
      videoData: null,
      homeWorkId: null,
      playerMode: 'record',
      videoMeta: {
        date: null,
        time: null
      },
      twentyMin: 1000 * 60 * 20
    }
  },
  methods: {
    ...mapMutations(['setPageTitle', 'setPageSubTitle']),
    doHomeWorkHandler() {
      if (this.homeWorkId) {
        this.$router.push(`/testing/${this.homeWorkId}/hw`)
      }
    },
    // Open-close fullscreen by buttom
    fullScreenHandler(val) {
      const isInFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      if (val && !isInFullScreen) {
        const elem = this.$refs.videoPlayer
        const video = elem.querySelector('video')
        // Add event listener if user exit by escape button or close fullscreen
        this.addExitListeners()
        if (elem.requestFullscreen) {
          elem.requestFullscreen()
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen()
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen()
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen()
        } else if (video.webkitEnterFullscreen) {
          video.webkitEnterFullscreen()
        }
      } else {
        this.exitFullScreenHandler()
      }
    },
    addExitListeners() {
      document.addEventListener('fullscreenchange', this.fullScreenExitHandler)
    },
    // Separate listener for remove after exit
    fullScreenExitHandler() {
      const isInFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      if (!isInFullScreen) {
        this.$store.commit('setFullScreenMode', false)
      }
    },
    // Handler for exit from fullScreen mode
    exitFullScreenHandler() {
      // Check if have active listener and remove it
      document.removeEventListener(
        'fullscreenchange',
        this.fullScreenExitHandler
      )
      // Check if we in fullscreen mode
      const isInFullScreen =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      if (isInFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen()
        }
      }
    }
  },
  mounted() {
    HTTP.getLesson(this.$route.params.id).then((data) => {
      this.videoData = data
      this.setPageTitle(this.videoData.name)
      this.setPageSubTitle(this.videoData.theme)
      // Date and time for video meta information
      if (data.hasOwnProperty('start_time')) {
        this.videoMeta.date = data.start_time
          .match(/^[0-9]{4}-[0-9]{2}-[0-9]{2}/g)[0]
          .split('-')
          .reverse()
          .join('.')

        this.videoMeta.time = data.start_time.match(/.[0-9]:[0-9]\w+/)[0]
      }
      if (data.hasOwnProperty('dz') && data.dz.length) {
        this.homeWorkId = data.dz.reverse()[0].id
      }
    })
    HTTP.getStreamTimeline(this.$route.params.id).then((response) => {
      this.timeline = response.data.timeLines
    })
    setTimeout(() => {
      HTTP.getWatchStreamRecord(this.$route.params.id)
    }, this.twentyMin)
  },
  computed: {
    // Current fullscreen mode
    fullScreen() {
      return this.$store.getters.fullScreenMode
    },
    // poster for record video
    poster() {
      return `https://xacademy.uz/streaming-module/poster/${this.$route.params.id}/poster.png`
    },
    // video data
    videoInput() {
      console.log(this.videoData)
      return this.videoData
    },
    // status of stream
    streamStatus() {
      if (
        this.videoData != null &&
        this.videoData.hasOwnProperty('video_url') &&
        this.videoData.video_url != null
      ) {
        return 'started'
      } else return 'norecord'
    }
  },

  watch: {
    // Watch when trigger fullscreen on/ off mode
    fullScreen(newState, oldState) {
      console.log(this.fullScreen)
      if (this.fullScreen) {
        this.fullScreenHandler(true)
      } else {
        this.fullScreenHandler(false)
      }
    }
  }
}
</script>

<style lang="scss">
@mixin text-style {
  display: inherit;
  align-items: center;
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #8c8e97;
  margin: 0 10px;
}
.meta-data {
  display: flex;
  .meta-data-date {
    display: flex;
    span {
      @include text-style;
    }
  }
  .meta-data-time {
    display: flex;
    margin-left: 25px;
    span {
      @include text-style;
    }
  }
}
.wrapper {
  display: block;
  position: relative;
  z-index: 0;
  margin-top: 32px;

  .video-player {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-lesson {
    width: 100%;
    border-radius: 12px;

    &.none {
      width: 100%;
      height: 100%;
      position: absolute;
      background: #1da1f2;
      z-index: 1;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        font-size: 36px;
      }
    }
  }
}
.homework-ntf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 24px;
  height: 40px;
  width: 100%;
  background: #8ed0f931;
  border-radius: 15px;
  margin-top: 10px;

  .homework-ntf-left {
    display: flex;
    img {
    }
    span {
      margin-left: 14px;
      font-size: 14px;
    }
  }
  .homework-ntf-right {
    user-select: none;
    text-decoration: none;
    color: #1da1f2 !important;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: -0.02em;
    cursor: pointer;
  }
}
@media (max-width: 992px) {
  .video-lesson.none {
    padding: 0 15px;

    p {
      font-size: 25px !important;
    }
  }
}
.video-controls {
  button {
    border: none;
    background: transparent;
  }
}
</style>
