<template>
  <div class="test-report">
    <div class="tests-wrapper vue-component">
      <div class="container">
        <div class="titles-wrap">
          <x-title v-if="title">{{ title }}</x-title>
          <subtitle v-if="subtitle">{{ subtitle }}</subtitle>
        </div>
        <tabs
          :tabs="testGroupNames"
          :double="true"
          v-if="testGroups.length > 1"
        >
          <div
            v-for="(group, i) in testGroups"
            :slot="'Модуль ' + String.fromCharCode(65 + i)"
            :key="i"
          >
            <div>
              <Tasks
                :tasks="group.tasks"
                :testGroupID="testGroupID"
                :testID="group.test_id"
                @store="onStore"
              />
            </div>
          </div>
        </tabs>
      </div>
    </div>
  </div>
</template>


<script>
import Tasks from '@/components/UI/question/Tasks'
import Api from '@/api/endpoints'

export default {
  name: 'ReportExams',
  components: { Tasks },
  data() {
    return {
      title: '',
      subtitle: '',
      testGroups: [],
      testGroupID: +this.$route.params.id
    }
  },
  async created() {
    this.testGroups = await Api.getTestResults(this.testGroupID)
  },
  computed: {
    testGroupNames() {
      return this.testGroups.map(
        (group, index) => this.$t('modul-0') + String.fromCharCode(65 + index)
      )
    }
  },
  methods: {
    onStore(data) {
      Api.storeTeacherCorrection(data)
    }
  }
}
</script>

<style lang="scss">
</style>
