import HomeworkCoursesList from '@/components/views/homework/HomeworkCoursesList'
import AllHomeTaskList from '@/components/views/homework/AllHomeTaskList'
import Report from '@/components/views/homework/Report'
import i18n from '@/i18n'

export default [
  {
    name: 'homework',
    path: '',
    component: HomeworkCoursesList,
    meta: {
      title: i18n.t('data.4'),
      titleI: 'data.4'
    }
  },
  {
    name: 'homework-list',
    path: 'list/:id?',
    component: AllHomeTaskList,
    meta: {
      title: i18n.t('data.51'),
      titleI: 'data.51'
    }
  },
  {
    name: 'report-homework',
    path: 'report/:id',
    component: Report,
    meta: {
      title: i18n.t('rezultaty-testirovaniya')
    }
  }
]
