<template>
  <div class="profile">
    <div class="container">
      <h1>{{ title }}</h1>
      <b-card no-body>
        <tabs @change-tab-index="changeTabIndex" :tabs="[$t('data.17'),$t('data.256')]">
          <template :slot="$t('data.17')">
            <information v-if="user" />
          </template>
          <template :slot="$t('data.256')">
            <referal :userInfo='user'/>
          </template>
          <!-- <template :slot="$t('data.257')"><Archive /></template> -->

        </tabs>
      </b-card>
    </div>
  </div>
</template>

<script>
import Information from '@/components/views/profile/tab-content/Information/Information'
import Referal from '@/components/views/profile/tab-content/Referal/Referal'
// import Archive from '@/components/views/profile/Archive'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { Referal, Information },
  data: () => ({
    tabIndex: 0,
    title: ''
  }),
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    changeTabIndex(val) {
      this.tabIndex = val
    }
  },
  watch: {
    user(val) {
      console.log(val.data)
    },
    tabIndex(val) {
      if (val === 0) {
        this.title = this.$t('data.16')
      }
      if (val === 1) {
        this.title = this.$t('data.290')
      }
      if (val === 2) {
        this.title = this.$t('data.257')
      }
    }
  },
  mounted() {
    
    this.title = this.$t('data.16')
  }
}
</script>

<style lang="scss">
.profile {
  font-family: 'Montserrat-Regular' !important;

  div,
  p,
  li,
  span {
    font-family: 'Montserrat-Regular';
  }

  h1 {
    margin-top: 40px;
  }

  .card {
    border: none;

    &-header {
      border: none;
      background: transparent;

      ul {
        border: 1px solid #dedede;
        border-radius: 12px;
        width: 657px;

        .nav-link {
          border-radius: 12px;
          color: #000;
          font-weight: 700;

          &.active {
            background: #1da1f2;
            color: #fff;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .information .personal-data .data-block {
    padding: 22px;
  }
}

@media (max-width: 992px) {
  .information {
    .personal-data {
      .slider-item {
        width: 100%;
        margin: 12px 0;
      }

      .col-7,
      .col-5 {
        width: 100%;
      }

      .col-7 {
        margin-bottom: 25px;

        &:last-child {
          margin-bottom: 0;
        }
      }

      h1 {
        margin-top: 0;
      }

      .sc-my-courses {
        h1 {
          margin-bottom: 0;
        }
      }
    }
  }

  .table {
    display: block;
    overflow: auto;
    max-width: 100%;

    tr td {
      font-size: 14px;
      white-space: nowrap;
    }

    th {
      font-size: 14px;
      letter-spacing: -0.02em;
    }
  }
}

@media (max-width: 576px) {
  .information {
    .person {
      flex-direction: column;

      .img-block {
        width: 100px;
        margin-right: 0;
        margin-bottom: 10px;

        img {
          width: 100%;
        }
      }

      .fio-block {
        margin-left: 0;
        justify-content: center;
        align-items: center;

        .id {
          display: flex;
          flex-direction: column;
          align-items: center;

          .btn {
            margin-right: 0;
          }
        }

        span {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
