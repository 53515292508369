<template>
  <div class="profile-miniature profile-box vue-component">
    <div class="profile-dropdown" @mouseleave="isShowProf = false">
      <button class="prof-drop-toggle" @mouseover="isShowProf = true">
        {{ user.firstName }} {{ user.lastName }}
        <span class="caret" :class="{ rotate: isShowProf }"></span>
      </button>
      <transition name="slide">
        <div class="prof-dropdown-menu" v-show="isShowProf">
          <div class="inner-drop-content">
            <div class="balance">
              <div>
                <h4>{{ $t('data.26') }}</h4>
                <p>{{ user.balance }} UZS</p>
              </div>
              <div>
                <h4>{{ $t('data.27') }}</h4>
                <p>{{ user.bonus }}</p>
              </div>
            </div>
            <div class="primary--button mt-1" @click="getPayment">
              {{ $t('data.28') }}
            </div>
            <div @click="closeMobileMenu('copy', user.id)" class="id-number">
              <p>
                ID: {{ user.id }}
                <img src="@/assets/svg/copy_icon.svg" alt="" />
              </p>
            </div>
            <ul class="menu-list">
              <li @click="closeMobileMenu">
                <a
                  @click.prevent="$router.push({ name: 'profile' })"
                  href="#"
                  >{{ $t('data.15') }}</a
                >
              </li>
              <!-- <li><a href="#">{{ $t('data.statistic-title') }} профиля</a></li> -->
            </ul>
            <div @click="closeMobileMenu" class="log-out">
              <span @click="logoutHandler">{{ $t('data.16') }}</span>
            </div>
          </div>
        </div>
      </transition>
      <div class="profile-img">
        <router-link :to="{ name: 'profile' }">
          <img v-if="user.image" :src="user.image" alt="" />
          <img v-else src="../../../assets/svg/add-avatar.svg" alt="" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'profile-miniature',
  data: function () {
    return {
      isShowProf: false
    }
  },
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapMutations(['logout']),
    closeMobileMenu(type, data) {
      this.$emit('closeMenu', true)
      if (type === 'copy') {
        this.mixinCopyToClipBoard(data)
      }
    },
    async logoutHandler() {
      await this.logout()
      await this.$router.push({ name: 'login' })
      this.$router.push({
        name: 'login'
      })
    },
    async getPayment() {
      if (this.$route.name !== 'profile') {
        await this.$router.push({ name: 'profile' })
      }
      await this.$store.dispatch('showPayment', true)
      this.$emit('closeMenu', true)
    }
  }
}
</script>

<style scoped="scoped" lang="scss">
@import './src/styles/vars.scss';

.profile-box {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.profile-img {
  display: inline-block;
  position: relative;
  width: 44px;
  height: 44px;
  border-radius: 100%;
  border-radius: 100%;
  overflow: hidden;
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  flex-shrink: 0;
  margin-left: 24px;

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
  }
}

.prof-drop-toggle {
  font-size: 16px;
  line-height: 20px;
  font-family: $mb;
  letter-spacing: 0.02em;
  color: #191d2f;
  border: none;
  outline: none;
  background: none;
  padding: 13px 22px 13px 10px;
  position: relative;

  .caret {
    content: '';
    position: absolute;
    width: 12px;
    height: 7px;
    top: 20px;
    right: 0px;
    background-image: url(../../../assets/svg/down_arrow.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    transition: 0.2s;
  }
}

.profile-dropdown {
  position: relative;
  display: flex;
}

.inner-drop-content {
  display: inline-block;
  width: 100%;
  padding: 22px 24px;
}

.prof-dropdown-menu {
  width: 235px;
  position: absolute;
  left: -50px;
  top: 40px;
  text-align: center;
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(132, 132, 132, 0.05);
  border-radius: 0px 0px 23px 23px;
}

.balance {
  width: 100%;
  background: #ffffff;
  display: inline-block;
  border: 1px solid #f0f0f0;
  border-radius: 37px;
  padding: 6px 13px 5px 13px;
  margin: 5px 0;

  & > * {
    margin: 4px 0;
  }

  h4 {
    font-family: $mr;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8c8e97;
  }

  p {
    font-family: $mb;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #191d2f;
  }
}

.id-number {
  padding: 13px 0;
  display: inline-block;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;

  p {
    padding-right: 22px;
    position: relative;
    font-family: $mb;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #8c8e97;
    margin-bottom: 0;
    text-align: center;
    display: inline-block;

    img {
      position: absolute;
      top: 2px;
      right: 0;
      max-width: 16px;
    }
  }
}

.menu-list {
  padding-left: 0;
  list-style: none;
  text-align: center;

  li {
    padding: 10.5px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  a {
    font-family: $mb;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #191d2f;
    transition: 0.2s;
    text-decoration: none;

    &:hover {
      color: #1da1f2;
    }
  }
}

.log-out {
  display: inline-block;
  width: 100%;
  text-align: center;

  span {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    letter-spacing: -0.02em;
    color: #1da1f2;
    transition: 0.2s;
    text-decoration: none;

    &:hover {
      color: #191d2f;
    }
  }
}

@media (max-width: 768px) {
  .prof-dropdown-menu {
    display: block !important;
    position: static !important;
    width: 100%;
    box-shadow: none;
  }

  .prof-drop-toggle,
  .profile-img {
    display: none;
  }

  .profile-box,
  .profile-dropdown {
    width: 100%;
    justify-content: center;
  }

  .inner-drop-content {
    padding-left: 0;
    padding-top: 0;
    padding-right: 0;
    padding-bottom: 5px;
  }

  .balance {
    display: flex;
    align-self: start;
    justify-content: space-around;

    // padding: 11px 13px;

    background: #1da1f2;
    border-radius: 37px;

    h4 {
      margin-right: 5px;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: -0.02em;
      color: #ffffff;
      text-transform: none;
      line-height: 1.4;

      &::after {
        content: ':';
        display: inline-block;
        font-size: 14px;
        font-weight: bold;
        color: #ffffff;
        margin-left: 2px;
      }
    }

    p {
      margin: 0;
      color: #ffffff;
      font-weight: bold;
      font-size: 14px;
    }
  }

  .id-number {
    padding: 5px 0;
  }

  .log-out {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    width: 170px;
    height: 48px;

    border-radius: 13px;
    background: #ffffff;
    border: 1px solid #f0f0f0;

    span {
      color: #e40a0a !important;
    }
  }
  .menu-list{
    padding: 0px;
    margin-bottom: 5px !important;
  }
  .primary--button {
    width: 100%;
    border-radius: 37px !important;
    padding: 8px 14px !important;
  }
}
</style>
