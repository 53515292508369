<template>
  <section class="sc-free-test-list">
    <div class="test-container">
      <div class="test-list-box">
        <ol class="quests">
          <li v-for="(task, index) in tasks" :key="index">
            <div class="result">
              <div class="answer-block">
                <div
                  v-html="task.question"
                  class="content_question answer-result"
                ></div>
                <div class="img-block">
                  <svg
                    v-if="task.isCorrect"
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.6 7.9999H20C20.5304 7.9999 21.0391 8.21062 21.4142 8.58569C21.7893 8.96076 22 9.46947 22 9.9999V12.1039C22.0003 12.3653 21.9493 12.6241 21.85 12.8659L18.755 20.3809C18.6795 20.5641 18.5513 20.7208 18.3866 20.831C18.2219 20.9412 18.0282 21 17.83 20.9999H1C0.734784 20.9999 0.48043 20.8945 0.292893 20.707C0.105357 20.5195 0 20.2651 0 19.9999V9.9999C0 9.73469 0.105357 9.48033 0.292893 9.2928C0.48043 9.10526 0.734784 8.9999 1 8.9999H4.482C4.6421 8.99994 4.79986 8.96155 4.94203 8.88794C5.0842 8.81433 5.20662 8.70766 5.299 8.5769L10.752 0.849904C10.8209 0.752217 10.9226 0.682464 11.0385 0.653294C11.1545 0.624124 11.277 0.637467 11.384 0.690904L13.198 1.5979C13.7085 1.85307 14.1163 2.27522 14.3537 2.79422C14.5911 3.31322 14.6438 3.89783 14.503 4.4509L13.6 7.9999ZM6 10.5879V18.9999H17.16L20 12.1039V9.9999H13.6C13.2954 9.99986 12.9948 9.93024 12.7212 9.79636C12.4476 9.66248 12.2082 9.46787 12.0213 9.22739C11.8343 8.98691 11.7048 8.7069 11.6425 8.40873C11.5803 8.11056 11.5869 7.80211 11.662 7.5069L12.565 3.9589C12.5933 3.84823 12.5828 3.73122 12.5353 3.62733C12.4878 3.52345 12.4062 3.43895 12.304 3.3879L11.643 3.0579L6.933 9.7299C6.683 10.0839 6.363 10.3739 6 10.5879ZM4 10.9999H2V18.9999H4V10.9999Z"
                      fill="#00AF07"
                    />
                  </svg>
                  <svg
                    v-else
                    width="22"
                    height="21"
                    viewBox="0 0 22 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.6 13.0001H20C20.5304 13.0001 21.0391 12.7894 21.4142 12.4143C21.7893 12.0392 22 11.5305 22 11.0001V8.8961C22.0003 8.63474 21.9493 8.37586 21.85 8.1341L18.755 0.619095C18.6795 0.435877 18.5513 0.279222 18.3866 0.169014C18.2219 0.0588055 18.0282 1.14441e-05 17.83 9.53674e-05H1C0.734784 9.53674e-05 0.48043 0.105452 0.292893 0.292988C0.105357 0.480524 0 0.734879 0 1.0001V11.0001C0 11.2653 0.105357 11.5197 0.292893 11.7072C0.48043 11.8947 0.734784 12.0001 1 12.0001H4.482C4.6421 12.0001 4.79986 12.0385 4.94203 12.1121C5.0842 12.1857 5.20662 12.2923 5.299 12.4231L10.752 20.1501C10.8209 20.2478 10.9226 20.3175 11.0385 20.3467C11.1545 20.3759 11.277 20.3625 11.384 20.3091L13.198 19.4021C13.7085 19.1469 14.1163 18.7248 14.3537 18.2058C14.5911 17.6868 14.6438 17.1022 14.503 16.5491L13.6 13.0001ZM6 10.4121V2.0001H17.16L20 8.8961V11.0001H13.6C13.2954 11.0001 12.9948 11.0698 12.7212 11.2036C12.4476 11.3375 12.2082 11.5321 12.0213 11.7726C11.8343 12.0131 11.7048 12.2931 11.6425 12.5913C11.5803 12.8894 11.5869 13.1979 11.662 13.4931L12.565 17.0411C12.5933 17.1518 12.5828 17.2688 12.5353 17.3727C12.4878 17.4766 12.4062 17.561 12.304 17.6121L11.643 17.9421L6.933 11.2701C6.683 10.9161 6.363 10.6261 6 10.4121ZM4 10.0001H2V2.0001H4V10.0001Z"
                      fill="#E40A0A"
                    />
                  </svg>
                </div>
              </div>
              <file-type
                v-if="task.type === 'file' && false"
                class="answer-result"
                :question="task"
                :isReportPage="true"
              />
              <file-box
                v-if="task.type === 'file' && task.answers.length"
                :question="task"
                :isReportPage="true"
              />
              <label
                v-if="task.type === 'file' && $route.params.userId"
                style="margin-top: 20px; display: block"
              >
                {{ $t('postavit-ocenku-0') }}
                <input
                  type="number"
                  class="input_result_file"
                  v-model.number="task.obtained_score"
                  @input="addToCorrectedDataFileResult(task)"
                />
              </label>
              <text-type
                class="answer-result"
                v-if="task.type === 'text'"
                :question="task"
                v-model="result"
                :index="index"
                :isReportPage="true"
              />
              <button
                v-if="task.type === 'text' && $route.params.userId"
                @click="setCorrectTextData(task)"
                class="primary--button"
                style="display: block"
              >
                {{ $t('otkorrektirovat-otvet') }}
              </button>
              <checkbox-type
                class="answer-result"
                v-if="task.type === 'radiobutton'"
                :question="task"
                :isReportPage="true"
              />
              <button
                v-if="task.type === 'radiobutton' && $route.params.userId"
                @click="setCorrectCheckBoxData(task)"
                class="primary--button"
                style="display: block"
              >
                {{ $t('otkorrektirovat-otvet-0') }}
              </button>
              <checkbox-type
                v-if="task.type === 'checkbox'"
                class="answer-result"
                :question="task"
                :isReportPage="true"
              />
              <button
                v-if="task.type === 'checkbox' && $route.params.userId"
                @click="setCorrectCheckBoxData(task)"
                class="primary--button"
                style="display: block; margin-bottom: 50px"
              >
                {{ $t('otkorrektirovat-otvet-1') }}
              </button>
              <p
                v-if="task.hint"
                class="hint"
                v-b-toggle="'collapse-' + task.id"
              >
                {{ $t('reshenie') }}
                <img src="@/assets/svg/arrow-answer.svg" alt="icon" />
              </p>
              <b-collapse
                v-if="task.hint"
                :id="'collapse-' + task.id"
                class="mt-2"
              >
                <b-card>
                  <p class="card-text" v-html="task.hint"></p>
                </b-card>
              </b-collapse>
            </div>
          </li>
        </ol>
      </div>
    </div>
    <button
      v-if="$route.params.userId"
      @click="storeResults"
      class="primary--button"
      style="display: block; margin-bottom: 50px"
    >
      {{ $t('sokhranit') }}
    </button>
  </section>
</template>
<script>
import FileType from './FileType'
import FileBox from './FileBox'
import TextType from './TextType'
import CheckboxType from './CheckboxType'
import RadioType from './RadioType'

export default {
  name: 'TasksOfTest',
  components: {
    FileType,
    FileBox,
    TextType,
    CheckboxType,
    RadioType
  },
  props: {
    tasks: Array,
    testGroupID: Number,
    testID: Number
  },
  data() {
    return {
      index: 1,
      result: [],
      correctedData: {
        student_id: this.$route.params.userId,
        test_group_id: this.testGroupID,
        files_results: [],
        to_correct: []
      }
    }
  },
  created() {
    this.correctedData.student_id = +this.$route.params.userId
    this.checkIFTaskCorrected()
  },
  computed: {},
  methods: {
    checkIFTaskCorrected() {
      this.tasks.forEach((task, index) => {
        if (task.type === 'file') {
          this.$set(this.tasks[index], 'isCorrect', task.obtained_score > 0)
          return
        }

        if (task.type === 'checkbox' || task.type === 'radiobutton') {
          const numberOfRightAnswers = task.answers.filter(
            (answer) => answer.isCorrect
          ).length
          let counterRightAnswers = 0

          task.answers.forEach((answer) => {
            if (answer.isSelected && answer.isCorrect) ++counterRightAnswers
          })

          this.$set(
            this.tasks[index],
            'isCorrect',
            counterRightAnswers === numberOfRightAnswers
          )
          return
        }

        this.$set(this.tasks[index], 'isCorrect', false)
      })
    },
    addToCorrectedDataFileResult(task) {
      task.obtained_score =
        task.obtained_score > task.available_max_score
          ? task.available_max_score
          : task.obtained_score
      task.obtained_score = task.obtained_score < 0 ? 0 : task.obtained_score

      task.isCorrect = task.obtained_score > 0

      this.correctedData.files_results =
        this.correctedData.files_results.filter(
          (file_result) => file_result.task_id !== task.id
        )

      this.correctedData.files_results.push({
        test_id: this.testID,
        task_id: task.id || 0,
        score: task.obtained_score
      })
    },
    setCorrectCheckBoxData(task) {
      task.answers.forEach((answer) => {
        answer.isSelected = false

        if (answer.isCorrect) answer.isSelected = true
      })

      task.isCorrect = true

      this.addToCorrectedDataCorrect(task.id)
    },
    addToCorrectedDataCorrect(task_id) {
      this.correctedData.to_correct.push({
        test_id: this.testID,
        task_id: task_id
      })
    },
    setCorrectTextData(task) {
      task.model.answer = task.answers.correct_answer
      task.isCorrect = true

      this.addToCorrectedDataCorrect(task.id)
    },
    storeResults() {
      this.$emit('store', this.correctedData)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.test-container {
  max-width: 680px;
  width: 100%;
}

.fixed-top-right {
  position: fixed;
  top: 120px;
  right: 70px;
}

.countdown-line {
  width: 53px;
  height: 56px;
  border-radius: 100%;
  transform: rotateY(0) rotateZ(-90deg) scale(1.4);
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.complete {
  display: inline-block;
  margin-left: 15px;
}

svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #1da1f2;
  fill: none;
  stroke-dashoffset: 50px;
  transform: translate(7.5px, 8px);
}

.t-end-btn {
  width: 51px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 1px 1px;
  position: relative;
  box-shadow: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    background: #e40a0a;
  }
}

.more-quests {
  display: flex;
}

.test-list-box {
  display: inline-block;
  width: 100%;
  max-width: 780px;
  padding-left: 30px;
}

.quests {
  width: 100%;
  display: inline-block;
  padding-left: 0;

  li {
    padding-left: 15px;
    padding-top: 24px;
    padding-bottom: 24px;

    &:first-child {
      padding-top: 0;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    font-family: $mr;
    margin-bottom: 25px;
  }

  label {
    position: relative;
    cursor: pointer;

    input[type='radio']:checked + span {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    span {
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #191d2f;
      padding-left: 28px;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        margin: auto;
        width: 10px;
        height: 10px;
        background: #1da1f2;
        border-radius: 10px;
        opacity: 0;
        transform: scale(0);
        transition: 0.3s;
      }

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        top: 0;
        left: 0;
        border: 1px solid #191d2f;
      }
    }
  }
}

.my-modal {
  max-width: 487px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  border-radius: 24px;
  padding: 40px 48px;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 12px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 16px;
  box-shadow: none;
  opacity: 1;
  font-weight: 100;
  font-size: 35px;
}

.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(25, 29, 47, 0.507);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.test-info {
  margin-top: 12px;
  display: inline-block;
  width: 100%;

  a {
    margin: 0 8px;
  }
}

.answer-result {
  max-width: 550px;
}

.answer-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .img-block {
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 50%;

    img {
      width: 30px;
    }
  }
}
.hint {
  color: #1da1f2;
  cursor: pointer;
}
.collapse {
  .card {
    border: 1px solid #1da1f2 !important;
    background: rgba(142, 208, 249, 0.3) !important;
    border-radius: 12px !important;
  }
}

.quests label .input_result_file {
  position: static;
  opacity: 1;
  width: 50px;
  margin-left: 5px;
}
</style>
