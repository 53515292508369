<template>
  <div class="file__container--files report-page file__container">
    <div class="empty-file">
      <a
        v-for="(answer, i) in filesList"
        :key="i"
        class="file_link"
        :href="answer"
        :download="answer"
        :title="answer"
        target="_blank"
        ><b-icon class="file--image" icon="file-earmark-text"></b-icon
        ><span>{{ getFilename(answer) }}</span></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'FileBox',
  props: {
    question: {}
  },
  data() {
    return {}
  },
  methods: {
    getFilename(answer) {
      return answer.split('/')[answer.split('/').length - 1]
    }
  },
  computed: {
    filesList() {
      return Array.from(new Set(this.question.answers))
    }
  }
}
</script>

<style lang="scss" scoped>
.file__container {
  width: 600px;
  border: 1px dashed rgba(140, 142, 151, 0.4);
  border-radius: 13px;
  padding: 40px;

  &--files {
    .empty-file {
      display: flex;

      .file--image {
        font-size: 48px;
        margin-bottom: 20px;
      }

      .file_link {
        width: 100px;
        margin: 0 5px;

        display: inline-block;
        text-align: center;
        color: #000;
        text-decoration: none;

        svg {
          margin-bottom: 0;
          color: #000;
        }

        span {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;

          font-size: 12px;
          color: #000;
        }
      }
    }

    .file {
      display: flex;

      &--image {
        margin-top: 7px;
        font-size: 32px;
      }
    }
  }
}

.report-page {
  background: rgba(0, 0, 0, 0.1);
  cursor: default;
}

@media (max-width: 1024px) {
  .file__container {
    width: 100%;
  }
}
</style>
