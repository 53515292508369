import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from '@/store/'
import ObjectStore from '@/assets/js/objectStorage'
import i18n from './i18n'
import { detectLanguage } from './i18n'
import '@/components/UI/RegisterComponents'
import axios from 'axios'
import moment from 'moment'
import Vue2Editor from 'vue2-editor'
import VueMathjax from 'vue-mathjax'
import VueProgressBar from 'vue-progressbar'
import VueSceletonLoader from 'skeleton-loader-vue'
import Vueditor from 'vueditor'

import 'vueditor/dist/style/vueditor.min.css'
// your config here
let config = {
  toolbar: [
    'removeFormat', 'undo', '|', 'elements', 'fontName', 'fontSize', 'foreColor', 'backColor'
  ],
  fontName: [
    {val: 'arial black'}, 
    {val: 'times new roman'}, 
    {val: 'Courier New'}
  ],
  fontSize: ['12px', '14px', '16px', '18px', '0.8rem', '1.0rem', '1.2rem', '1.5rem', '2.0rem'],
  uploadUrl: ''
};
Vue.use(Vueditor, config);

moment.locale(i18n.locale)

Vue.component('vue-sceleton-loader', VueSceletonLoader)
Vue.use(require('vue-moment'), {
  moment
})

import methods from '@/mixins/index'

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'

Vue.use(VueToast)

import VueMask from 'v-mask'
Vue.use(VueMask)

import VueCropper from 'vue-cropperjs/VueCropper'
import 'cropperjs/dist/cropper.css'

Vue.component('vue-cropper', VueCropper)

Vue.use(VueMathjax)

Vue.use(VueProgressBar, {
  color: '#1da1f2',
  failedColor: '#ff0000',
  height: '7px'
})

Vue.mixin({ methods })
Vue.use(Vue2Editor)

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// axios.defaults.baseURL = 'https://xacademy.uz/api/'

Vue.prototype.$detectLanguage = detectLanguage
Vue.prototype.$websocketUrl = process.env.VUE_APP_WEBSOCKET_URL
Vue.use(VueRouter)
Vue.config.productionTip = false

// const dataState = createPersistedState({
//   paths: ['test.testsData', 'test.results']
// })

// Результатом выполнения будет объявление глобавльного объекта
// objectStore . Он позволяет взаимодействоватьс localStorage
// как будто бы  обычным объектом
// Статья на хабре откуда взят код
// https://habr.com/ru/post/143597/
ObjectStore()
if (!window.objectStore) window.objectStore = {}

const urlSearchParams = new URLSearchParams(window.location.search)
const params = Object.fromEntries(urlSearchParams.entries())
let resolveLangs = ['ru', 'en']
if (params.lang) {
  localStorage.setItem('lang', resolveLangs.find(l => l == params.lang) || 'uz')
  i18n.locale = localStorage.getItem('lang')
  moment.locale(localStorage.getItem('lang'))
}

export default new Vue({
  router,
  render: h => h(App),
  store,
  i18n
}).$mount('#app')
