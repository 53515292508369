<template>
  <div class="archive">
    <div class="container">
      <title-nav>{{ $t('istoriya-operacii') }}</title-nav>
      <table-data
        v-if="items.length"
        :pagination="true"
        :items="items"
        :fields="fields"
      />
      <empty-list v-else></empty-list>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import TableData from '@/components/UI/table-data/TableData'
import EmptyList from '@/components/UI/EmptyList'
import Api from '@/api/endpoints'
import i18n from '@/i18n'

export default {
  components: { TableData, EmptyList },
  data: () => ({
    items: []
  }),
  computed: {
    fields() {
      return [
        { key: 'date', label: this.$t('data.65') },
        { key: 'sum', label: this.$t('summa-0') },
        { key: 'type', label: this.$t('tip-0') },
        { key: 'status', label: this.$t('data.56') }
      ]
    }
  },
  created() {
    const getDescr = (item) => {
      if (item.type === 'balance' && item.status === 'added')
        return 'Пополение баланса'
      return ''
    }
    Api.getTransactionHistory().then((res) => {
      this.items = res.data.map((item) => {
        let status = 'Не успешно'
        if (item.status === 'pending') status = this.$t('v-obrabotke')
        else if (item.status === 'added') status = this.$t('uspeshno')
        return {
          sum: new Intl.NumberFormat('ru-RU').format(item.amount) + ' UZS',
          type: getDescr(item),
          date: moment(item.created_at).format('DD.MM.YYYY'),
          status: status
        }
      })
    })
  }
}
</script>

<style lang="scss">
.archive {
  margin-top: 40px;
}
// div, p, li, span{
//     font-family: 'Montserrat-Regular';
//   }
</style>
