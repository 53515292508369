<template>
  <div v-if="!isTablet" class="cal-box">
    <ul class="cal-months">
      <li v-for="day in daysOfWeek" :key="day">{{ day }}</li>
    </ul>
    <ul class="cal-days">
      <li
        v-for="data in dataOfCalendar"
        :key="data.day + data.month + data.dayOfWeek + Math.random() * 100"
        class="single-day"
        :class="{ out: isOutDate(data) }"
      >
        <span class="cal-day">{{ data.day }}</span>
        <ul
          v-if="convertedData.front[data.date.format('YYYY.MM.DD')]"
          class="inner-lessons"
        >
          <li
            v-for="item in convertedData.front[data.date.format('YYYY.MM.DD')]"
            :key="item.id + item.order_number + Math.random() * 100"
          >
            <div class="cal-lesson-box">
              <router-link
                :to="{ name: 'lesson-upcoming', params: { id: item.id } }"
                @click.native="setPageTitle(item.theme)"
                class="cal-link"
              >
                <svg
                  width="11"
                  height="18"
                  viewBox="0 0 11 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 2L9 8.72L2 16"
                    stroke="#191D2F"
                    stroke-width="3"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </router-link>
              <span class="time">{{
                $moment(item.start_time).format('HH:mm')
              }}</span>
              <span class="lesson-name">{{ item.name }}</span>
              <span class="lesson-info">{{ item.theme }}</span>
            </div>
          </li>
        </ul>
        <div
          v-if="convertedData.back[data.date.format('YYYY.MM.DD')]"
          class="more-box"
        >
          <span class="more">{{ $t('eshe') }}</span>
          <ul class="inner-more-lessons">
            <li
              v-for="item in convertedData.back[data.date.format('YYYY.MM.DD')]"
              :key="item.id + item.order_number + Math.random() * 100"
            >
              <div class="cal-lesson-box">
                <router-link
                  :to="{ name: 'LessonsUpcoming', params: { id: item.id } }"
                  @click.native="setPageTitle(item.theme)"
                  class="cal-link"
                >
                  <svg
                    width="11"
                    height="18"
                    viewBox="0 0 11 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 2L9 8.72L2 16"
                      stroke="#191D2F"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </router-link>
                <span class="time">{{ item.time }}</span>
                <span class="lesson-name">{{ item.name }}</span>
                <span class="lesson-info">{{ item.theme }}</span>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
  <!-- start lessons-mobile -->
  <div v-else class="lessons-mobile d-md-none">
    <div class="lessons-mobile__body">
      <div class="lessons-mobile__list">
        <!-- lessons-mobile__item -->
        <div v-for="(date, index) in dataOfCalendar" :key="index"  :class="['lessons-mobile__item', isOutDate(date) ? 'out' : '']">
          <div class="lessons-mobile__aside">
            <div class="lessons-mobile__date">
              <div class="lessons-mobile__day">{{ date.day }}</div>
              <div class="lessons-mobile__week">{{ daysOfWeek[date.dayOfWeek] }}</div>
            </div>
          </div>
          <div v-if="convertedData.front[date.date.format('YYYY.MM.DD')]" class="lessons-mobile__main">
            <!-- start card -->
            <a v-for="item in convertedData.front[date.date.format('YYYY.MM.DD')]"
               :key="item.id + item.order_number + Math.random() * 100"
               href="#"
               @click.stop.prevent="showLessons(item)"
               :class="['lessons-mobile-card', 'lessons-mobile__card']">
              <div class="lessons-mobile-card__data">
                <div class="lessons-mobile-card__time">{{ item.time }}</div>
                <div class="lessons-mobile-card__title">{{ item.name }}</div>
              </div>
              <div class="lessons-mobile-card__label">{{ item.theme }}</div>
            </a>
            <!-- end card -->
          </div>
        </div>
        <!-- end lessons-mobile__item -->
      </div>
    </div>
  </div>
  <!-- end lessons-mobile -->
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Calendar',
  components: {},
  props: {
    date: {
      type: Date,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      daysOfWeek: [
        this.$t('data.122'),
        this.$t('data.123'),
        this.$t('data.124'),
        this.$t('data.125'),
        this.$t('data.126'),
        this.$t('data.127'),
        this.$t('data.128')
      ],
      dataOfCalendar: [],
      // currentMonth: (new Date()).getMonth() + 1,
      convertedData: {
        front: {},
        back: {}
      },
      documentWidth: 0,
      tabletWidth: 768,
      isTablet: false
    }
  },
  created() {
    this.setCalendar()
    window.addEventListener('resize', this.updateWidth);
    this.updateWidth();
    // if ( Array.isArray(this.data) ) this.convertData();
  },
  computed: {
    daysInMonth() {
      return this.$moment(this.date).daysInMonth()
    },
    currentMonth() {
      return this.date.getMonth() + 1
    },
    currentYear() {
      return this.date.getFullYear()
    },
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    showLessons(item){
      this.setPageTitle(item.theme);
      this.$router.push({name: 'lesson-upcoming', params: { id: item.id }});
    },
    updateWidth(){
      this.documentWidth = window.innerWidth;
      if(this.documentWidth <= this.tabletWidth){
        this.isTablet = true;
      }else{
        this.isTablet = false;
      }
    },
    isOutDate(date) {
      let da = new Date(date.date._d)

      if (
        da.getDate() === new Date().getDate() &&
        da.getMonth() === new Date().getMonth()
      ) {
        return false
      }
      if (date.month !== this.currentMonth) return true

      if (
        new Date().getMonth() + 1 <= this.currentMonth &&
        new Date().getFullYear() <= this.currentYear
      )
        return false

      if (
        new Date().getMonth() + 1 > this.currentMonth &&
        new Date().getFullYear() < this.currentYear
      )
        return false

      return true
    },
    setCalendar() {
      this.dataOfCalendar = []

      const momentDate = this.$moment(this.date)
      let daysInMonth = momentDate.daysInMonth()
      let day = 1
      const year = this.date.getFullYear()
      const month = this.date.getMonth() + 1

      while (daysInMonth--) {
        const momentDateTemp = this.$moment(new Date(`${year}/${month}/${day}`))
        const dayOfWeek = momentDateTemp.weekday()

        this.dataOfCalendar.push({
          dayOfWeek,
          day,
          month,
          date: momentDateTemp
        })

        day++
      }

      if (this.dataOfCalendar[0].dayOfWeek !== 0) {
        this.addDaysOfMonthBefore()
      }

      if (this.dataOfCalendar[this.dataOfCalendar.length - 1].dayOfWeek !== 6) {
        this.addDaysOfMonthAfter()
      }
    },
    addDaysOfMonthBefore() {
      let { dayOfWeek } = this.dataOfCalendar[0]

      while (dayOfWeek--) {
        const momentDateTemp = this.$moment(
          new Date(this.dataOfCalendar[0].date.format('YYYY/MM/DD'))
        );
        momentDateTemp.subtract(1, 'days')

        this.dataOfCalendar.unshift({
          dayOfWeek: momentDateTemp.weekday(),
          day: momentDateTemp.date(),
          month: momentDateTemp.month() + 1,
          date: momentDateTemp
        })
      }
    },
    addDaysOfMonthAfter() {
      let { dayOfWeek } = this.dataOfCalendar[this.dataOfCalendar.length - 1]

      while (dayOfWeek++ !== 6) {
        const length = this.dataOfCalendar.length
        const momentDateTemp = this.$moment(
          new Date(this.dataOfCalendar[length - 1].date.format('YYYY/MM/DD'))
        )
        momentDateTemp.add(1, 'days')

        this.dataOfCalendar.push({
          dayOfWeek: momentDateTemp.weekday(),
          day: momentDateTemp.date(),
          month: momentDateTemp.month() + 1,
          date: momentDateTemp
        })
      }
    }
  },
  watch: {
    date() {
      this.setCalendar()
    },
    data() {
      this.convertedData = {
        front: {},
        back: {}
      }
      this.data.forEach((date) => {
        if (!this.convertedData.front[date.date])
          this.convertedData.front[date.date] = []

        if (this.convertedData.front[date.date].length < 3)
          this.convertedData.front[date.date].push(date)
        else {
          if (!this.convertedData.back[date.date])
            this.convertedData.back[date.date] = []

          this.convertedData.back[date.date].push(date)
        }
      })
    }
  },
  beforeMount() {
    this.convertedData = {
      front: {},
      back: {}
    }
    this.data.forEach((date) => {
      if (!this.convertedData.front[date.date])
        this.convertedData.front[date.date] = []

      if (this.convertedData.front[date.date].length < 3)
        this.convertedData.front[date.date].push(date)
      else {
        if (!this.convertedData.back[date.date])
          this.convertedData.back[date.date] = []

        this.convertedData.back[date.date].push(date)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.cal-box {
  width: 100%;
}

.cal-months {
  display: grid;
  grid-template-columns: repeat(7, 140px);
  background: rgba(140, 142, 151, 0.1);
  border-radius: 4px 4px 0px 0px;
  li {
    font-family: $mr;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #191d2f;
    padding: 10.5px 11px;
  }
}

.cal-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: 0.2s;

  svg {
    width: 6px;
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    margin: auto;
  }
  path {
    stroke: #8c8e97;
  }
}

.cal-days {
  display: grid;
  grid-template-columns: repeat(7, 140px);
  padding-left: 0;
  border-right: solid 1px #f3f3f4;
}

.cal-day {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #191d2f;
}

.out {
  pointer-events: none;
  & > * {
    opacity: 0.3;
  }
}

.single-day {
  padding-left: 10px;
  padding-top: 12px;
  padding-right: 0px;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  height: 170px;
  position: relative;
}

.inner-lessons {
  padding-left: 0;
  overflow: auto;
  width: 100%;
  height: 129px;
  padding-right: 5px;
  -webkit-overflow-scrolling: touch;
  margin-top: 4px;
}

/* width */
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8ed0f9;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6cbef1;
}

.cal-lesson-box {
  width: 100%;
  padding: 8px 8px;
  background: #fcfcfc;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  margin-bottom: 4px;
  height: 56px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  overflow: hidden;
  &:hover {
    background: #1da1f2;
    & > * {
      color: #fff;
    }
    &:before {
      background: linear-gradient(
        to left,
        #1da1f2,
        #1da1f2,
        #1da0f2d2,
        transparent,
        transparent
      );
    }
    .cal-link {
      path {
        stroke: #fff;
      }
    }
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: -1px;
    height: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    width: 42px;
    background: linear-gradient(
      to left,
      #fcfcfc,
      #fcfcfc,
      rgba(252, 252, 252, 0.827),
      transparent,
      transparent
    );
    z-index: 1;
    transition: 0.2s;
  }
  span {
    display: inline-block;
    width: 100%;
    white-space: nowrap;
    position: relative;
    z-index: 0;
  }
}

.time {
  font-family: $mr;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #191d2f;
}

.lesson-name {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #191d2f;
  margin-bottom: 3px;
}

.lesson-info {
  font-family: $mr;
  font-size: 9px;
  line-height: 10px;
  letter-spacing: -0.02em;
  color: #191d2f;
}

.more-box {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 45px;
  &:hover {
    height: 100%;
    .inner-more-lessons {
      display: block;
    }
  }
}

.inner-more-lessons {
  position: absolute;
  width: 152px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(132, 132, 132, 0.15);
  border-radius: 12px;
  padding: 16px 16px;
  z-index: 3;
  top: 40px;
  right: -112px;
  display: none;
}

.more {
  position: absolute;
  top: 16px;
  right: 10px;
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .cal-box {
    overflow-x: auto;
    border-right: solid 1px #f3f3f4;
  }

  .cal-months {
    position: relative;
    background: 0;
    width: 860px;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: calc(100% + 125px);
      height: 100%;
      background: rgba(140, 142, 151, 0.1);
    }
  }

  .cal-days {
    width: 860px;
    border-right: 0;
  }
}

@media (max-width: 992px) {
  .cal-box {
    padding-bottom: 24px;
  }

  .cal-months {
    &:before {
      width: calc(100% + 180px);
    }
  }

  .cal-days,
  .cal-months {
    width: 800px;
  }

  .single-day {
    height: 100px;
    width: 100%;
  }

  .cal-months li {
    width: 100px;
  }
}

@media (max-width: 992px) {
  ::-webkit-scrollbar {
    height: 6px;
  }
  .single-day {
    overflow: auto;
  }
  .inner-lessons {
    overflow: hidden;
  }
}


.lessons-mobile{
  &__header{
    display: flex;
    align-self: start;

    padding: 20px 0;

    border-bottom: 1px solid #F0F0F0;
  }

  &__mounth{
    margin-right: 15px;

    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #191D2F;
    font-family: "Montserrat-Bold";

    &:last-child{
      margin-right: 0;
    }
  }

  &__navs{
    display: flex;
    align-self: start;
  }

  &__button{
    display: flex;
    align-items: center;
    justify-content: center;

    width: 33px;
    height: 33px;

    margin-right: 7px;

    border-radius: 12px;
    border: 2px solid #BDBDBD;

    &.is-prev{
      &:before{
        content: '';
        width: 7px;
        height: 13px;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12L1 7.05L6 1.6875' stroke='%23BDBDBD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }

    &.is-next{
      &:before{
        content: '';
        display: block;
        width: 7px;
        height: 13px;
        background-image: url("data:image/svg+xml,%3Csvg width='7' height='13' viewBox='0 0 7 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 12L6 7.05L0.999999 1.6875' stroke='%23BDBDBD' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }

    &:last-child{
      margin-right: 0;
    }
  }

  &__body{

  }

  &__item{
    display: flex;
    align-items: flex-start;

    padding: 15px 0;

    border-bottom: 1px solid #F0F0F0;
  }

  &__aside{
    display: flex;
    justify-content: flex-start;

    min-width: 70px;
  }

  &__date{
    display: flex;
    flex-direction: column;

    text-align: start;
  }

  &__day{
    color: #000000;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    font-family: "Montserrat-Bold";
  }

  &__week{
    font-size: 10px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #8C8E97;
    opacity: 0.5;
  }

  &__card{
    margin-bottom: 4px;

    &:last-child{
      margin-bottom: 0;
    }
  }

  &__main{
    flex: 1 1 auto;
    min-width: 0;
  }

  &__footer{
    display: flex;
    align-items: center;

    margin-top: 45px;
  }
}

.lessons-mobile-card{
  display: flex;
  align-items: center;

  width: 100%;

  padding: 8px 15px 8px 8px;

  background: rgba(240, 240, 240, 0.2);
  border: 1px solid #F0F0F0;
  border-radius: 4px;
  text-decoration: none;

  &__data{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__time{
    font-size: 9px;
    line-height: 10px;
    letter-spacing: -0.02em;
    color: #191D2F;
  }

  &__title{
    display: inline-flex;
    align-items: center;

    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191D2F;
    font-family: "Montserrat-Bold";
  }

  &__label{
    margin-left: auto;
    max-width: 65px;
    width: 100px;
    font-size: 8px;
    line-height: 10px;
    letter-spacing: -0.02em;
    color: #191D2F;
    text-align: left;

  }

  &:after{
    content: '';
    display: block;
    flex-shrink: 0;
    margin-left: 8px;
    width: 6px;
    height: 10px;
    background-image: url("data:image/svg+xml,%3Csvg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.999969 9.00002L4.99997 5.16001L0.999969 1.00002' stroke='%238C8E97' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
}
</style>
