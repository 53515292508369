import Api from '@/api/endpoints'

export default {
  state: {
    courses: [],
    course: {},
    subjects: [],
    fullSubjects: [],
    dateCalendar: new Date()
  },
  mutations: {
    setCourses(s, course) {
      s.courses = course
    },
    setCourse(s, course) {
      s.course = course
    },
    setSubjects(s, subject) {
      s.subjects = subject
    },
    SET_DATE_CALENDAR(state, date) {
      state.dateCalendar = date
    },
    setFullSubjects(s, subject) {
      s.fullSubjects = subject
    }
  },
  actions: {
    async allCourse({ commit }) {
      let r = await Api.getAllCourse()
      console.log(r)
      if (r.errors) return
      commit('setCourses', r)
    },
    async getFullSubjects({ commit }) {
      let r = await Api.getFullSubjects()
      if (r.errors) return
      if (r.data) {
        commit(
          'setFullSubjects',
          r.data.filter(subject => {
            return subject.id != 12 && subject.id != 11 && subject.id != 1
          })
        )
      }
    },
    async courseById({ commit }, id) {
      let r = await Api.getCourseById(id)
      if (r.errors) return
      commit('setCourse', r)
    },
    async getSubjects({ commit }) {
      let r = await Api.getSubjects()
      if (r.errors) return
      commit('setSubjects', r)
    },
    setDateCalendar({ commit }, date) {
      // window.localStorage.setItem('dateCalendar', date)
      commit('SET_DATE_CALENDAR', date)
    }
  },
  getters: {
    courses: s => s.courses.data,
    course: s => s.course.data,
    subjects: s => s.subjects.data,
    fullSubjects: s => s.fullSubjects,
    dateCalendar: state => {
      if (state.dateCalendar) return new Date(state.dateCalendar)

      // const dateCalendar = window.localStorage.getItem('dateCalendar')

      if (dateCalendar) return new Date(dateCalendar)

      return new Date()
    }
  }
}
