<template>
  <div class="referal">
    <div class="container">
      <div class="row">
        <div class="col-4">
          <div class="data-block">
            <title-nav class="title" to="/referal-program"
              >{{ $t('data.325') }}</title-nav
            >
            <p class="text">
              {{$t('v-etom-razdele-vi-mozhete-uznat-pravila-nachislenia')}}
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="data-block">
            <title-nav class="title" to="/bonus-program"
              >Бонусная программа</title-nav
            >
            <p class="text">
              {{$t('v-etom-razdele-vi-mozhete-uznat-pravila-nachislenia-za-activnost')}}
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="data-block money">
            <div>
              <p class="title bonus">
                {{ $t('data.27') }}
                <img src="../../../../../assets/svg/bonus.svg" alt="bonus" />
              </p>
              <h2 v-if="userInfo">{{ userInfo.bonus }} {{ $t('data.258') }}</h2>
            </div>
            <title-nav :is-blue="true" class="title-blue" to="/bonus-program">{{
              $t('data.259')
            }}</title-nav>
          </div>
        </div>
      </div>
      <div class="row history">
        <h2>{{ $t('data.292') }}</h2>
        <table-data
          :pagination="true"
          :item-per-page="7"
          :fields="fields"
          :items="tableItems"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TableData from '@/components/UI/table-data/TableData'
import Api from '@/api/endpoints'
export default {
  components: { TableData },
  props: {
    userInfo: Object
  },
  data() {
    return {
      tableItems: [],
      fields: [
        { key: 'date', label: this.$t('data.65') },
        { key: 'time', label: this.$t('vremya') },
        { key: 'points', label: this.$t('summa') },
        { key: 'desc', label: this.$t('tip') }
      ],
      desc_dict: {
        1: this.$t('data.310'), 
        2: this.$t('data.311'),
        3: this.$t('data.312'),
        4: this.$t('data.313'),
        5: this.$t('data.314'),
        6: this.$t('data.315'),
        7: this.$t('data.316'),
        8: this.$t('data.317'),
        9: this.$t('data.318'),
        10: this.$t('data.319'),
        11: this.$t('data.320'),
        12: this.$t('data.321'),
        13: this.$t('data.322'),
      }
    }
  },
  methods: {
    getBonusesHistory() {
      Api.getBonusesHistory().then((res) => {
        if (res && res.length){
          try{
            this.tableItems = res.map(el => {
              el['desc'] = this.desc_dict[el['condition']['id']] || el['condition']['condition'];
              el['date'] = new Date(el['created_at']).toLocaleDateString();
              el['time'] = new Date(el['created_at']).toLocaleTimeString();
              return el;
            }).sort((a, b) => +new Date(a.created_at) > +new Date(b.created_at) ? -1 : 1)
          } catch(e){
            console.log("Error get bonus history",e)        
          }
      }
      })
    }
  },
  created() {
    this.getBonusesHistory()
  }
}
</script>

<style lang="scss">
.referal {
  .history {
    margin-top: 40px;
    h2 {
      margin-bottom: 20px;
    }
  }
  .title,
  .title-blue {
    font-size: 14px !important;
    margin-top: 0 !important;
    margin-bottom: 20px;
    font-weight: 700;

    svg {
      margin-bottom: -2px !important;
      width: 10px !important;
    }
  }

  .title-blue {
    margin-bottom: 0;
    color: #1da1f2;

    svg {
      color: #1da1f2 !important;
    }
  }

  .data-block {
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100%;

    &.money {
      background: rgba(142, 208, 249, 0.1);
      border: 1px solid #1da1f2;
      height: 100%;

      .subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        img {
          margin-left: 10px;
        }

        &.bonus {
          margin-top: 20px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  
  .referal {
    .col-4 {
      flex: inherit;
      width: 100%;
      margin-bottom: 25px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .table {
    display: block;
    overflow: auto;
    max-width: 100%;

    thead,
    tbody,
    tfoot,
    tr,
    td,
    th {
      width: 100%;
    }

    tr td {
      font-size: 14px;
      white-space: nowrap;
    }

    th {
      font-size: 14px;
      letter-spacing: -0.02em;
    }
  }
}
</style>
