<template>
  <div class="filter-box">
    <template v-if="isDate">
      <vd-calendar
        v-if="isDateRange"
        :item="dataForExpiriedDate"
        @change="handleFilter"
        :resetDateRange="resetDateRange"
      ></vd-calendar>
      <vd-dropdown
        v-else
        :title="$t('vyberite-mesyac')"
        :items="dataForSortByMonth"
        :selectedItemIndex="new Date().getMonth() + 1"
        :resetIndex="resetIndex"
        @change="handleFilter"
      ></vd-dropdown>
    </template>
    <vd-dropdown
      :title="$t('data.56')"
      :items="dataForSortByStatus"
      :resetIndex="resetIndex"
      @change="handleFilter"
    ></vd-dropdown>
    <vd-dropdown
      :title="$t('sortirovat')"
      :items="dataForSortByDate"
      @change="handleSort"
    ></vd-dropdown>
    <label v-if="isDate" class="period-checkbox">
      <input v-model="isDateRange" type="checkbox" />
      <span class="main--text">{{ $t('ukazat-period') }}</span>
    </label>
    <div class="filter-options">
      <span href="#" class="clean-filter" @click="clearDataForFiltering"
        >{{ $t('ochistit-filtry') }} <span>&times;</span></span
      >
    </div>
  </div>
</template>

<script>
import VdDropdown from '@/components/UI/small-components/VdDropdown.vue'
import VdCalendar from '@/components/UI/small-components/VdDatePicker.vue'

export default {
  name: 'FilterContainer',
  components: {
    VdDropdown,
    VdCalendar
  },
  props: {
    isDate: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      dataForSortByDate: [
        {
          name: this.$t('bez-vybora'),
          key: 'expiried_at',
          value: ''
        },
        {
          value: 'desc',
          name: this.$t('snachala-poslednie'),
          key: 'expiried_at'
        },
        {
          value: 'asc',
          name: this.$t('snachala-pervye'),
          key: 'expiried_at'
        }
      ],
      dataForSortByStatus: [
        {
          name: this.$t('bez-vybora-0'),
          value: '',
          key: 'statusKey'
        },
        {
          value: 'pending',
          name: this.$t('ozhidanie-vypolneniya-0'),
          key: 'statusKey'
        },
        {
          value: 'checking',
          name: this.$t('na-proverke'),
          key: 'statusKey'
        },
        {
          value: 'success',
          name: this.$t('vypolneno'),
          key: 'statusKey'
        }
      ],
      dataForSortByMonth: [
        {
          value: '',
          name: this.$t('bez-vybora-1'),
          key: 'expiried_at'
        },
        {
          value: '01',
          name: this.$t('data.207'),
          key: 'expiried_at'
        },
        {
          value: '02',
          name: this.$t('data.208'),
          key: 'expiried_at'
        },
        {
          value: '03',
          name: this.$t('data.181'),
          key: 'expiried_at'
        },
        {
          value: '04',
          name: this.$t('data.210'),
          key: 'expiried_at'
        },
        {
          value: '05',
          name: this.$t('data.183'),
          key: 'expiried_at'
        },
        {
          value: '06',
          name: this.$t('data.212'),
          key: 'expiried_at'
        },
        {
          value: '07',
          name: this.$t('data.213'),
          key: 'expiried_at'
        },
        {
          value: '08',
          name: this.$t('data.186'),
          key: 'expiried_at'
        },
        {
          value: '09',
          name: this.$t('data.215'),
          key: 'expiried_at'
        },
        {
          value: '10',
          name: this.$t('data.216'),
          key: 'expiried_at'
        },
        {
          value: '11',
          name: this.$t('data.217'),
          key: 'expiried_at'
        },
        {
          value: '12',
          name: this.$t('data.218'),
          key: 'expiried_at'
        }
      ],
      dataForExpiriedDate: {
        name: 'expiried_at',
        value: {}
      },
      dataForFiltering: {},
      dataForSorting: {},
      resetIndex: false,
      resetDateRange: false,
      isDateRange: false
    }
  },
  methods: {
    handleFilter(data) {
      delete this.dataForFiltering[data.name]
      this.$set(this.dataForFiltering, data.name, data.value)
    },
    handleSort(data) {
      this.dataForSorting = {
        name: data.name,
        value: data.value
      }
    },
    clearDataForFiltering() {
      this.dataForFiltering = {}
    }
  },
  watch: {
    dataForFiltering() {
      if (
        this.dataForFiltering &&
        this.dataForFiltering.constructor === Object &&
        Object.keys(this.dataForFiltering).length === 0
      ) {
        this.resetIndex = true
        this.resetDateRange = true

        setTimeout(() => {
          this.resetIndex = false
          this.resetDateRange = false
        }, 0)
      }
      this.$emit('onFilter', this.dataForFiltering)
    },
    dataForSorting() {
      this.$emit('onSort', this.dataForSorting)
    },
    isDateRange() {
      if (!this.isDateRange) return

      this.$set(
        this.dataForExpiriedDate.value,
        'startDate',
        '01.' +
          (this.dataForFiltering.expiried_at - 1) +
          '.' +
          new Date().getFullYear()
      )
      this.$set(
        this.dataForExpiriedDate.value,
        'endDate',
        '31.' +
          (this.dataForFiltering.expiried_at - 1) +
          '.' +
          new Date().getFullYear()
      )
    }
  },
  beforeMount() {
    this.clearDataForFiltering()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.filter-box {
  display: flex;
  width: 100%;
  background: #f4fafe;
  border-radius: 12px;
  padding: 15px 32px 18px 32px;
  margin-top: 9px;
  margin-bottom: 8px;
  flex-wrap: wrap;
}

.filter-options {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 0px;
  padding-bottom: 12px;
}

.period-checkbox {
  position: relative;
  cursor: pointer;
  display: flex;
  margin-bottom: 0;
  align-items: flex-end;
  margin-top: 12px;
  padding-bottom: 24px;

  &:hover {
    span {
      &:before {
        border-color: #1da1f2;
      }
    }
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
  input[type='checkbox']:checked + span {
    &:before {
      background: #1da1f2;
      border-color: #1da1f2;
      background-image: url(../../../assets/svg/checked.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
  span {
    display: inline-block;
    position: relative;
    padding-left: 28px;
    user-select: none;
    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: 0;
      width: 20px;
      height: 20px;
      border: 1px solid #191d2f;
      border-radius: 2px;
      transition: 0.1s;
    }
  }
}

.clean-filter {
  text-decoration: none;
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  transition: 0.2s;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 26px;
    font-family: $mr;
    margin-left: 10px;
  }
  &:hover {
    opacity: 0.5;
  }
}

@media (max-width: 1200px) {
  .filter-options {
    padding-top: 15px;
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  .filter-options {
    padding-top: 0;
  }
}
</style>
