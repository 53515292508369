import Olympiads from '@/components/views/olympiads/Olympiads'
import i18n from '@/i18n'

export default [
  {
    name: 'olympiads',
    path: '',
    component: Olympiads,
    meta: {
      title: i18n.t('data.7')
    }
  },
  
]
