import Chat from '@/components/chat/ChatPage'
import i18n from '@/i18n'

export default [
  {
    name: 'chat',
    path: '',
    component: Chat,
    meta: {
      title: i18n.t('data.327')
    }
  }
]
