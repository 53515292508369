<template>
  <div>
    <Banner class="banner" :bg-img="require('@/assets/img/banner_bg.png')" />
    <div class="container">
      <MyCourses />
      <AllCourses />
    </div>
    <b-modal
      ref="success-modal"
      hide-footer
      centered
      :title="$t('informaciya-ob-oplate')"
    >
      <div class="d-block text-center">
        <h1 class="success">{{ $t('oplata-proshla-uspeshno') }}</h1>
      </div>
    </b-modal>
    <b-modal
      ref="error-modal"
      hide-footer
      centered
      :title="$t('informaciya-ob-oplat')"
    >
      <div class="d-block text-center">
        <h1 class="error">{{ $t('oplata-ne-proshla-poprobuite-snova') }}</h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
// components
import Banner from '../UI/Banner.vue'
import MyCourses from './courses-components/MyCourses.vue'
import AllCourses from './courses-components/AllCourses.vue'

export default {
  name: 'MainPage',
  components: {
    Banner,
    MyCourses,
    AllCourses
  },
  mounted() {
    if (this.$route.query.pay_status) {
      if (this.$route.query.pay_status === 'success') {
        this.$refs['success-modal'].show()
      }
      if (this.$route.query.pay_status === 'error') {
        this.$refs['error-modal'].show()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.success {
  color: #00af07;
}
.error {
  color: #dc3545;
}
</style>