<template>
  <div class="test-report">
    <tests-wrapper
        v-if="resultDataDtm.length"
        :title="'Отчет по тестированию'"
        :tests="resultDataDtm"
        :test-id="'dtm'">
    </tests-wrapper>
  </div>
</template>


<script>
import TestsWrapper from "@/components/UI/question/TestsWrapper";
import {mapGetters, mapMutations} from "vuex";

export default {
  components: {TestsWrapper},
  created() {

  },
  computed:{
    // получаю результат по тестам и передаю его в test-wrapper дальше вся логика переходит TestWrapper.vue and Test.vue
    ...mapGetters(['results']),
    resultDataDtm() {
      console.log('results');
      return this.results?.dtm || []
    },
  },
  mounted() {
   if(!Array.from(this.results)){
     this.results = [this.results];
   }
  },
  methods:{
    ...mapMutations(['setResultsFromLS']),
  }
}
</script>

<style lang="scss">

</style>
