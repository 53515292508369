<template>
  <div class="lessons-list vue-component">
    <lessons-lists :lessons="lessons" />
  </div>
</template>

<script>
import LessonsLists from '@/components/views/lessons-components/LessonsLists'

import Api from '@/api/endpoints'
import { mapMutations } from 'vuex'
export default {
  name: 'lessons-list',
  data: function () {
    return {
      lessons: []
    }
  },
  methods: {
    ...mapMutations(['setPageTitle', 'setPageSubTitle']),
    getBtnClass(routerName) {
      let isActive = this.$route.name == routerName
      return {
        'secondary--button': !isActive,
        'primary--button': isActive
      }
    },
    async setLesons() {
      let r = await Api.getAllLessons()
      if (!r?.data) return
      this.lessons = r.data
    }
  },
  components: {
    LessonsLists
  },
  props: [],
  mixins: {},
  beforeMount() {
    this.setLesons()
    this.setPageSubTitle('')
    this.setPageTitle(this.$t('uroki'))
  }
}
</script>

<style scoped="scoped" lang="scss">
.lessons-info-tabs {
  display: flex;
  width: 100%;
  border: 1px solid #f0f0f0;
  width: fit-content;
  border-radius: 14px;
  margin-top: 7px;
  margin-bottom: 15px;
  .primary--button,
  .secondary--button {
    padding-left: 32px !important;
    padding-right: 32px !important;
    border: none;
    box-shadow: none;
  }

  .primary--button {
    pointer-events: none;
  }
  .btn {
    height: 100%;
  }
}
</style>
