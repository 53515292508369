import { render, staticRenderFns } from "./File.vue?vue&type=template&id=290a9378&scoped=true&"
import script from "./File.vue?vue&type=script&lang=js&"
export * from "./File.vue?vue&type=script&lang=js&"
import style0 from "./File.vue?vue&type=style&index=0&id=290a9378&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "290a9378",
  null
  
)

export default component.exports