<template>
  <div class="file-type-create"></div>
</template>

<script>
export default {
  name: 'FileType',
  data() {
    return {
      data: {
        type: 'file',
        description: '',
        answer_comment: '',
        question: ''
      }
    }
  },
  watch: {
    'data.question': function () {
      this.$emit('input', this.data)
    },
    'data.answer_comment': function () {
      this.$emit('input', this.data)
    },
    'data.description': function () {
      this.$emit('input', this.data)
    }
  }
}
</script>

<style scoped>
</style>
