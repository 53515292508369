<template>
  <div class="view-results vue-component">
    <!-- <tests-wrapper v-if="testGroup" title="test" /> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TestsWrapper from './TestsWrapper'

export default {
  name: 'view-results',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['results']),
    testGroup() {
      return this.results[this.$route.params.testGroupId]
    },
    resultsComputed() {
      return this.results[this.$route.params.testGroupId].map
    }
  },
  components: {
    TestsWrapper
  },
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='sass'></style>
