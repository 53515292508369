<template>
  <div class="sc-all-task homework-list">
    <div class="row">
      <div class="col-12">
        <filter-container
          :isDate="true"
          @onFilter="updateTasks"
          @onSort="sortTasks"
        >
        </filter-container>
        <table-data
          :dz="true"
          v-if="filteredData.length"
          :fields="fields"
          :items="filteredData"
          :pagination="true"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from '@/components/UI/small-components/Pagination.vue'
import FilterContainer from '@/components/UI/small-components/FilterContainer.vue'

import filter from '../../../mixins/filter.js'
import TableData from '@/components/UI/table-data/TableData'

export default {
  name: 'AllHomeTaskList',
  components: {
    TableData,
    Pagination,
    FilterContainer
  },
  mixins: [filter],
  data() {
    return {
      fields: [
        { key: 'course_name', label: this.$t('data.52'), class: 'is-title' },
        { key: 'themeName', label: this.$t('data.53'), class: 'is-caption' },
        { key: 'finish_to', label: this.$t('data.54'), class: 'is-finish' },
        { key: 'created_at', label: this.$t('data.55'), class: 'is-create' },
        {
          key: 'test_group_status',
          label: this.$t('data.56'),
          class: 'is-status'
        },
        { key: 'id', label: '', class: 'is-button' }
      ]
    }
  },
  methods: {
    checkResult(mark) {
      if (mark === 5) {
        return this.$t('result.1')
      }
      if (mark === 4) {
        return this.$t('result.2')
      }
      if (mark === 3) {
        return this.$t('result.3')
      }
      if (mark === 2) {
        return this.$t('result.4')
      }
      if (mark === 1) {
        return this.$t('result.5')
      }
    },
    checkMarkClass(mark) {
      if (mark === 5) {
        return `excellent`
      }
      if (mark === 4) {
        return `good`
      }
      if (mark === 3) {
        return `norm`
      }
      if (mark === 2) {
        return `bad`
      }
      if (mark === 1) {
        return `fail`
      }
    }
  },
  props: {
    tasksData: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.result {
  font-weight: 900;
}

.excellent {
  color: #00af07;
}

.good {
  color: #871df2;
}

.norm {
  color: #ffa800;
}

.bad {
  color: #e40a0a;
}

.fail {
  color: #8c8e97;
}

.task-box {
  display: inline-block;
  width: 100%;
  margin-top: 8px;
}

.task-head {
  display: grid;
  width: 100%;
  grid-template-columns: 177px 172px 180px 196px auto;
  background: #f4f4f5;
  border-radius: 4px;
  margin-bottom: 4px;

  li {
    padding-left: 33px;
    padding-right: 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
  }
}

.task-info {
  display: inline-block;
  margin: 4px 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;

  ul {
    display: grid;
    min-height: 80px;
    align-items: center;
    width: 100%;
    grid-template-columns: 177px 172px 180px 196px auto;

    li {
      padding-left: 33px;
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      white-space: nowrap;
      position: relative;
      overflow: hidden;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 100%;
        background-image: linear-gradient(to left, #fff, transparent);
      }
    }
  }
}

ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.status-info {
  font-family: 'Montserrat-Regular';
  display: flex;
  img {
    margin-right: 6px;
  }
}

@media (max-width: 1340px) {
  .task-info ul li,
  .task-head li {
    white-space: inherit;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1200px) {
  .task-box {
    overflow: auto;
  }

  .task-head {
    width: fit-content;
    display: flex;
  }

  .task-info {
    width: auto;
  }

  .task-info ul {
    display: flex;
  }

  .task-info ul li,
  .task-head li {
    white-space: nowrap;
    min-width: 180px;

    &:last-child {
      min-width: 240px;
    }
  }

  .filter-options {
    padding-top: 15px;
    justify-content: flex-start;
  }
}

@media (max-width: 992px) {
  ::-webkit-scrollbar {
    height: 6px;
  }
}

@media (max-width: 576px) {
}
</style>
