
<template>
  <section class="sc-dtm-soon">
    <div class="half-images half-girl">
      <img src="@/assets/img/half-girl.png" alt="" />
    </div>
    <div class="half-images half-guy">
      <img src="@/assets/img/half-guy.png" alt="" />
    </div>
    <div class="soon-center">
      <div>
        <div class="soon-logo">
          <img class="rotating" src="@/assets/svg/soon-text.svg" alt="" />
          <img src="@/assets/svg/soon-x.svg" alt="" />
        </div>
        <h2>
          <slot name="title" />
        </h2>
        <p>
          <slot name="text" />
        </p>
      </div>
      <a
        href="https://www.instagram.com/xacademy_uz/"
        target="_blank"
        class="custom-link"
        ><img src="@/assets/svg/instagram.svg" alt="" /> xacademy_uz</a
      >
      <a href="https://t.me/xacademyuzb" target="_blank" class="custom-link"
        ><img src="@/assets/svg/telegram.svg" alt="" /> @xacademyuzb</a
      >
    </div>
  </section>
</template>

<script>
export default {
  name: 'DtmSoon',

  data() {
    return {}
  }
}
</script>

<style lang="scss" scoped>
@import './src/styles/vars.scss';

.sc-dtm-soon {
  position: relative;
}

.half-images {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
}

.half-girl {
  left: 0;
}

.half-guy {
  right: 0;
}

.soon-center {
  max-width: 493px;
  width: 100%;
  height: calc(100vh - 127px);
  justify-content: center;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  align-content: center;
}
p {
  font-family: $mr;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #191d2f;
  width: 100%;
  margin: 10px 0;
}

h2 {
  font-family: $mb;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #191d2f;
}

.soon-logo {
  display: inline-block;
  position: relative;
  width: 154px;
  height: 154px;
  margin-bottom: 40px;
  user-select: none;
  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width: 154px;
  }
}

.rotate-text {
  animation: rotateText duration timing-function delay iteration-count direction
    fill-mode;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}

.custom-link {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #191d2f;
  transition: 0.2s;
  text-decoration: underline;
  display: flex;
  align-items: center;
  margin: 10px 20px;
  &:hover {
    opacity: 0.5;
  }
  img {
    margin-right: 8px;
  }
}
@media (max-width: 1250px) {
  .half-images {
    display: none;
  }
}

@media (max-width: 992px) {
.soon-center{
  padding: 0 20px;
}
}
</style>




