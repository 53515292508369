<template>
  <div class="checkbox-type-create">
    <label class="h5 my-3">Варианты ответа </label><br />
    <div v-for="(option, index) in data.answers" class="py-2" :key="index">
      <div class="input-group">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <label :for="'checkbox' + index" class="p-0 m-0 cursor-pointer"
              >Правильный вариант</label
            >
            <input
              :id="'checkbox' + index"
              class="ml-2"
              type="checkbox"
              name="radioButtonTypeCreate"
              value="1"
              :checked="option.isCorrect"
              @input="onChange(option)"
            />
          </div>
        </div>
        <!-- <input
          type="text"
          class="form-control"
          aria-label="Text input with checkbox"
          v-model="option.variant"
          @input="oninput(data)"
        /> -->
        <div class="input-group-append">
          <button
            class="btn btn-danger"
            type="button"
            @click="deleteVariant(index)"
          >
            X
          </button>
        </div>
      </div>
      <editor
        :inline="true"
        v-model="option.variant"
        @input="oninput(data)"
        class="editor"
      />
    </div>
    <button @click="addNewVariant" class="btn btn-outline-primary">
      Новый вариант</button
    ><br />
  </div>
</template>

<script>
import Editor from '@/components/admin/text-editore'

export default {
  name: 'CheckboxType',
  data() {
    return {
      data: {
        answers: [
          {
            variant: '',
            isCorrect: 0
          }
        ]
      }
    }
  },
  watch: {
    'data.question': function () {
      this.oninput(this.data)
    },
    'data.answers': function () {
      this.oninput(this.data)
    },
    'data.answer_comment': function () {
      this.oninput(this.data)
    },
    variant() {
      this.oninput(this.data)
    },
    isCorrect() {
      this.oninput(this.data)
    },
    'data.description': function () {
      this.oninput(this.data)
    }
  },
  methods: {
    addNewVariant() {
      this.data.answers.push({
        variant: '',
        isCorrect: 0
      })
      this.oninput(this.data)
    },
    deleteVariant(index) {
      this.data.answers.splice(index, 1)
      this.oninput(this.data)
    },
    onChange(option) {
      option.isCorrect = Number(!option.isCorrect)
      option.isSelected = option.isCorrect
      this.oninput(this.data)
    },
    oninput(data) {
      if (data.answers.filter((e) => e.isCorrect).length <= 1)
        data.type = 'radiobutton'
      else data.type = 'checkbox'
      this.$emit('input', data)
    }
  },
  computed: {},
  components: {
    Editor
  },
  props: {
    type: {
      default: 'checkbox'
    },
    initData: {
      default: false
    }
  },
  beforeMount() {
    if (this.initData.answers && Array.isArray(this.initData.answers)) {
      this.data = this.initData
    }
    this.oninput(this.data)
  }
}
</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}
.editor {
  margin-top: 5px;
  border: 1px solid #dedede;
  padding: 20px;
}
</style>
