<template>
  <section class="sc-lessons-upcoming">
    <div class="row">
      <div class="w-100">
        <div class="row row-line">
          <div class="col-lg-6">
            <switcher-date
                :date="date"
                @change="setDateCalendar"
            ></switcher-date>
          </div>
          <div v-if="subjects && !courseById" class="col-lg-6 right">
            <vd-dropdown
                title=""
                :items="subjectFilter"
                @lessonsChange="handleFilter"
            ></vd-dropdown>
          </div>
        </div>
      </div>
      <calendar
          :date="date"
          :data="!lesson.length ? lessonsFiltered : lesson"
      ></calendar>
      <div class="w-100" style="margin-top: 35px">
        <switcher-date
            class="justify-content-end"
            :date="date"
            @change="setDateCalendar"
        ></switcher-date>
      </div>
    </div>
  </section>
</template>

<script>
import SwitcherDate from '@/components/UI/small-components/SwitcherDate.vue'
import Calendar from '@/components/UI/small-components/Calendar.vue'
import VdDropdown from '@/components/UI/small-components/VdDropdown.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'LessonsUpcoming',
  components: {
    SwitcherDate,
    Calendar,
    VdDropdown
  },
  props: {
    lessons: {
      required: true,
      type: Array
    },
    courseById: Boolean || null
  },
  data() {
    return {
      // date: this.$store.getters.dateCalendar new Date(),
      resetIndex: false,
      dataForSortByStatus: [
        {
          name: this.$t('bez-vybora-2'),
          value: '',
          key: 'statusKey'
        },
        {
          value: 'english',
          name: this.$t('data.110'),
          key: 'statusKey'
        },
        {
          value: 'russ',
          name: this.$t('data.222'),
          key: 'statusKey'
        }
      ],
      dataForFiltering: {},
      lessonsFiltered: [],
      lesson: [],
      subjectFilters: []
    }
  },
  computed: {
    ...mapGetters(['subjects']),
    ...mapGetters({
      date: 'dateCalendar'
    }),
    subjectFilter(){
      this.subjectFilters = [];
      this.subjects.map(item => {
        this.lessons.map(({subject}) => {
          if (item.id === subject.id) {
            this.subjectFilters.push(item)
          }
        })
      })
      this.subjectFilters.push({ id: null, name: this.$t('data.189') })
      this.subjectFilters.reverse()
      return Array.from(new Set([...this.subjectFilters]));
    }
  },
  mounted() {
    this.getSubjects();
    this.lessonsFiltered = this.lessons.map((l) => {
      l.date = l.date.split('.').reverse().join('.')
      return l
    })
  },
  methods: {
    ...mapActions(['getSubjects', 'setDateCalendar']),
    handleFilter(data) {
      delete this.dataForFiltering[data.name]
      this.$set(this.dataForFiltering, data.name, data.value)
      if (data.id === null) {
        this.lesson = this.lessonsFiltered
      } else {
        this.lesson = this.lessonsFiltered.filter(
            ({subject}) => subject.id === data.id
        )
      }
      this.$forceUpdate()
    }
  }
}
</script>

<style lang="scss" scoped>
.right {
  text-align: right;
}

.row-line {
  align-items: flex-end;
}

.drop-box {
  padding-bottom: 18px;
}

@media (max-width: 1400px) {
  .cal-box {
    overflow-x: auto;
  }

  .cal-months {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 1000%;
      height: 100%;
      background: rgba(140, 142, 151, 0.1);
    }
  }
}
</style>
