<template>
  <section class="sidebar-menu" :class="{ 'sidebar-menu--mini': miniMode }">
    <div>
      <div class="logo-box">
        <router-link :to="{ name: 'home' }">
          <img v-if="miniMode" src="@/assets/svg/logo_mini.svg" alt="" />
          <img v-else src="@/assets/img/logo.svg" alt="" />
        </router-link>
      </div>
    </div>
    <div class="scroll-content">
      <!-- {{ $t('data.1') }}-->
      <h2 class="nav-bar-title" v-if="!miniMode">{{ $t('data.1') }}</h2>
      <nav-bar :mode="mode" />
      <!-- Нужна помощь?  -->
      <!-- <div class="need-help">
        <h4>Нужна помощь?</h4>
        <p class="main--text">
          Если у вас возникли вопросы или вы столкнулись с трудностями
          использования платформы – обратитесь в службу поддержки
        </p>
        <span class="primary--button">Чат с поддержкой</span>
        <a href="tel:+79600000000" class="tel-number">+7 (960) 000-00-00</a>
        <div class="img-box">
          <img src="../../assets/img/chat_img.png" alt="" />
        </div>
      </div> -->
    </div>
  </section>
</template>

<script>
import NavBar from './NavBar'

export default {
  name: 'SideBarMenu',
  components: {
    NavBar
  },
  data() {
    return {}
  },
  computed: {
    miniMode() {
      return this.mode === 'mini'
    }
  },
  props: ['mode']
}
</script>

<style lang="scss" scoped>
@import './src/styles/vars.scss';

@media (max-width: 992px){
  .sidebar-menu{
    display: none !important; 
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #8ed0f9;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6cbef1;
}

.nav-bar-title {
  margin-bottom: 30px;
}

.sidebar-menu {
  position: fixed;
  top: 0;
  padding-left: 20px;
  width: 300px;
  height: 100%;
  border-right: 1px solid #f0f0f0;
  display: block;
  bottom: 0;
  background: #fff;
  margin-left: 31px;
  display: flex;
  flex-direction: column;
  &--mini {
    width: auto;
    max-width: 84px;
    padding-left: 31px;
    margin-left: 0;
  }
}

.logo-box {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  img {
    max-width: 176px;
  }
}

.scroll-content {
  overflow: auto;
  margin-top: 9px;
  padding-top: 34px;
  padding-bottom: 10px;
  width: 100%;
  padding-right: 13px;
  -webkit-overflow-scrolling: touch;
}

.need-help {
  display: inline-block;
  width: 100%;
  padding: 32px 24px 0px 24px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 24px;
  margin-top: 20px;
  h4 {
    font-family: $mb;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #191d2f;
    margin-bottom: 15px;
  }
  .main--text {
    color: #8c8e97;
  }
  .primary--button {
    margin: 5px 0;
  }
  .tel-number {
    font-family: $mb;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #1da1f2;
    text-decoration: none;
    display: inline-block;
    transition: 0.2s;
    margin: 15px 0;
    &:hover {
      color: #191d2f;
    }
  }
  .img-box {
    display: inline-block;
    width: 100%;
    text-align: center;
    img {
      max-width: 138px;
    }
  }
}
</style>
