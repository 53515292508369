<template>
  <div>
    <div
      v-show="false"
      class="testing-chat__button"
      @click="openChat"
      :class="{ disabled: chatLoading }"
    >
      <img src="@/assets/svg/chat-now.svg" alt="Open chat" />
    </div>
    <div v-if="chatVisible" class="chat-modal__modal vue-component">
      <chat chatmode="mini" class="testing-chat-component" :chats="roomChat">
      </chat>
    </div>
  </div>
</template>

<script>
import Chat from './index.vue'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Api from '@/api/endpoints'

export default {
  name: 'chat-modal',
  data: function () {
    return {
      chatLoading: false,
      chatVisible: false,
      roomChat: [],
      curator: null
    }
  },
  methods: {
    ...mapMutations(['setChats']),
    ...mapActions(['toggleChat', 'unsubscribeFromChat']),
    // open chat
    async openChat() {
      if (this.chatLoading) return
      this.chatLoading = true
      await this.requestTeacher(
        this.$route.params.id,
        this.$route.params.userId
      )
      await this.setCurrentChatToActive()
      this.chatVisible = !this.chatVisible
      this.chatLoading = false
    },
    async requestTeacher(test_id, user_id) {
      console.log(this.user)
      // let r = await Api.getTeacherFromTeacher(test_id, user_id)
      // console.log(r)
      let r = await this.getDataForSub(this.user.id)
      console.log(r)
      if (r != null && r?.chat_id && r.chat_id !== null) {
        this.curator = r
        this.curator.firstName = this.curator.fio.split(' ')[0]
        this.curator.lastName = this.curator.fio.split(' ')[1]
        this.curator.image = this.curator.avatar
        const payload = {
          id: this.$route.params.id,
          // name: this.curator.name,
          image: '',
          image_path: '',
          lang: null,
          isDirect: true,
          curators: [this.curator]
        }
        console.log(payload)
        await this.driver.subscribe([payload])
        console.log('sub')
        this.roomChat.push(payload)
        this.setChats(this.roomChat)
      } else {
        this.$toast.open({
          message: this.$t('data.254'),
          type: 'info',
          duration: 3000,
          dismissible: true
        })
      }
    },
    async getDataForSub(userId) {
      let r = await Api.getChatInfo(userId)
      return r.data
    },
    async setCurrentChatToActive() {
      const d = this.driver
      //if rocket connected and chat ready
      if (
        d != null &&
        this.curator != null &&
        d.hasOwnProperty('connection') &&
        this.curator.chat_id !== null
      ) {
        //get existing messages for this room
        if (d.channels[this.curator.chat_id]) {
          const msgs = d.channels[this.curator.chat_id].msgs
          const subject = {
            name:
              this.curator.firstName && this.curator.lastName
                ? `${this.curator.firstName} ${this.curator.lastName}`
                : this.$t('data.281'),
            avatar: this.curator.image ? this.curator.image : '',
            time: '',
            msg: {
              ...msgs[msgs.length - 1],
              itsMe: this.user?.chat_id === msgs[msgs.length - 1]?.u?._id
            },
            chatId: this.curator.chat_id,
            subject: this.$t('data.282')
          }
          this.toggleChat(subject)
        } else return
      }
    }
  },
  computed: {
    ...mapGetters(['user', 'driver'])
  },
  components: {
    Chat
  },
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style lang='scss'>
.chat-modal__modal {
  z-index: 1000;
  background: #ffffff;
  /* light gray */
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: -5px 8px 19px rgba(203, 203, 203, 0.1);
  border-radius: 8px;
  position: fixed;
  height: 500px;

  /* This is a shorthand of
  translateX(-50%) and translateY(-50%) */
  right: 80px;
  bottom: 20px;
  .testing-chat-component {
    height: 100%;
  }
}
@media (max-width: 768px) {
  .chat-modal__modal {
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    .chat {
      height: 100%;
    }
  }
}
.testing-chat__button {
  z-index: 1001 !important;
  position: fixed;
  bottom: 100px;
  right: 16px;
  width: 51px;
  height: 51px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 10;
  transition: all 0.3s ease;

  img {
    width: 100%;
    height: 100%;
  }
  &:hover {
    transform: rotate(360deg);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  }
}
.disabled {
  opacity: 0.3;
  cursor: progress;
  &:hover {
    cursor: progress !important;
  }
}
</style>
