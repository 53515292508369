<template>
  <transition name="fade">
    <div v-if="show" class="alert vue-component" :style="backgroundComputed">
      <div class="d-flex align-items-center">
        <span v-if="type == 'warn'" class="info-icon"
          ><img src="@/assets/svg/info_icon.svg" alt=""
        /></span>
        <p class="main--text">{{ text }}</p>
      </div>
      <div>
        <slot></slot>
        <button v-if="showClose" class="btn close" @click="show = false">
          &times;
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'alert',
  methods: {},
  computed: {
    backgroundComputed() {
      let bg = 'rgba(0, 175, 6, 0.308)'
      if (this.type == 'warn') bg = 'rgba(142, 208, 249, 0.308)'
      return `background-color: ${bg}`
    }
  },
  components: {},
  props: {
    text: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    showClose: {
      type: Boolean,
      default: true
    },
    show: {
      type: Boolean,
      default: false
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped="scoped" lang="scss">
.alert {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-radius: 12px;
  margin: 8px 0;
  padding: 10px 24px 10px 32px;
  p {
    padding-right: 15px;
    margin-bottom: 0;
  }
  .info-icon {
    flex-shrink: 0;
    margin-right: 14px;
    display: flex;
  }
  .close {
    display: flex;
    box-shadow: none;
    opacity: 1;
    margin-left: auto;
    text-shadow: none;
    padding: 0;
    width: 16px;
    height: 16px;
    line-height: 10px;
    margin-right: -12px;
  }
}
</style>
