<template>
  <div class='chat-support' @click.prevent="openSupportChat" :class="{'active': supportActive}">
    <div class='chat-support__icon'>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.763 14H18V2H2V15.385L3.763 14ZM4.455 16L0 19.5V1C0 0.734784 0.105357 0.48043 0.292893 0.292893C0.48043 0.105357 0.734784 0 1 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V15C20 15.2652 19.8946 15.5196 19.7071 15.7071C19.5196 15.8946 19.2652 16 19 16H4.455ZM9 11H11V13H9V11ZM6.567 5.813C6.69692 5.16279 7.00882 4.56285 7.4664 4.08298C7.92397 3.60311 8.50841 3.26304 9.15171 3.10236C9.79501 2.94167 10.4707 2.96697 11.1002 3.17532C11.7297 3.38366 12.287 3.76647 12.7075 4.27922C13.1279 4.79196 13.394 5.41355 13.475 6.07166C13.5559 6.72976 13.4483 7.39732 13.1646 7.99665C12.881 8.59598 12.433 9.10244 11.8727 9.4571C11.3125 9.81176 10.6631 10 10 10H9V8H10C10.2841 7.99998 10.5623 7.9193 10.8023 7.76733C11.0423 7.61536 11.2343 7.39836 11.3558 7.14158C11.4773 6.8848 11.5234 6.5988 11.4887 6.31684C11.454 6.03489 11.34 5.76858 11.1598 5.54891C10.9797 5.32924 10.7409 5.16523 10.4712 5.07597C10.2015 4.98671 9.91204 4.97587 9.63643 5.04471C9.36081 5.11354 9.11042 5.25923 8.91437 5.46482C8.71832 5.6704 8.58468 5.92743 8.529 6.206L6.567 5.813V5.813Z" fill="white"/>
      </svg>
    </div>
    <div class='chat-support__text'>
      {{ $t('data.242')}}
    </div>
</div>  
</template>

<script>
import { mapGetters, mapActions} from 'vuex';
import EventBus from '@/components/chat/eventBus'

export default {
  name: 'chat-support',
  data: function () {
    return {
      support_id: "xJmEd7ShJPZs2KBn6",
      support_avatar: 'https://xacademy.uz/img/chat-support.png',
      supportActive: false,
      }
  },
  methods: {
    ...mapActions(["toggleChat"]),
    // Open support chat window
    async openSupportChat() {
      //if rocket connected and chat ready
      if (this.driver.hasOwnProperty('connection')) {
        //get existing messages for this room
        const msgs = this.driver.channels[this.support_id].msgs
        const subject = {
          name: this.$t('data.242'),
          avatar: this.support_avatar,
          time: '',
          msg: {
            ...msgs[msgs.length - 1],
            itsMe: this.user?.chat_id === msgs[msgs.length - 1]?.u?._id
          },
          chatId: this.support_id,
          subject: this.$t('data.242'),
          isSupport: true
        }
        this.toggleChat(subject)
        EventBus.$emit('show-chat-window')
      }
    },
  },
  computed: {
    ...mapGetters(['driver' , 'user', 'activeChat'])
  },
  watch: {
    activeChat(){
      if (this.activeChat.hasOwnProperty('isSupport') && this.activeChat.isSupport == true){
        this.supportActive = true
      }else {
        this.supportActive = false
      }
    }
  },
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.active{
  background: #8ED0F9 !important;
}
.chat-support {
  height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    background: #1DA1F2;
    position: absolute;
    bottom: 0;
    left:0;
    width: 100%;
  .chat-support__icon{
    margin-right: 10px;
  }
  .chat-support__text{
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: flex-end;
    text-align: right;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    
  }
  &:hover{
      cursor: pointer;
    }
}

</style>
