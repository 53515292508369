<template>
  <div v-if="question.model">
    <label v-for="(option, index) in question.answers" :key="index">
      <input
        :value="option.id"
        :type="question.type === 'radiobutton' ? 'radio' : 'checkbox'"
        :disabled="isReportPage || isPreview"
        :name="question.question + option.id"
        v-model="option.isSelected"
        @change="changes($event, option.id)"
      />
      <span
        class="checkbox"
        :class="[
          'span',
          question.type === 'radiobutton' ? 'radio' : '',
          isReportPage ? 'report' : '',
          isReportPage && option.isSelected && option.isCorrect
            ? 'correct'
            : '',
          isReportPage && (!option.isSelected || !option.isCorrect)
            ? 'false'
            : '',
          (isReportPage || isPreview) && option.isSelected ? 'selected' : ''
        ]"
      >
        <div v-html="option.variant"></div>
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'CheckboxType',
  props: {
    question: {},
    index: '',
    isReportPage: Boolean,
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  computed: {
    isChecked() {
      if (this.isReportPage) {
        if (this.question.type === 'radiobutton')
          return this.question.model.answer[0]
        else return this.question.model.answer
      }

      return this.question.model.answer
    }
  },
  methods: {
    changes({ target }, index) {
      if (target.checked) {
        if (this.question.type === 'checkbox') {
          this.question.model.answer.push(index)
          this.question.model.answer.sort(function (a, b) {
            return a - b
          })
        }
        if (this.question.type === 'radiobutton') {
          this.question.answers.forEach((answer) => {
            answer.isSelected = answer.id === index ? index : false
          })

          this.question.model.answer = [index]
        }
      } else {
        this.question.model.answer = this.question.model.answer.filter(
          (item) => item !== index
        )
      }
      this.question.model.answer = Array.from(
        new Set(this.question.model.answer)
      )
      this.$emit('input', this.question.model.answer)
      this.$forceUpdate()
    }
  },
  beforeMount() {
    this.question.model = {
      task_id: this.question.id,
      taskType: this.question.type,
      answer: this.question?.model?.answer || []
    }
  },
  beforeUpdate() {
    this.question.model = {
      task_id: this.question.id,
      taskType: this.question.type,
      answer: this.question?.model?.answer || []
    }
  }
}
</script>
<style lang="scss" scoped>
@import './src/styles/vars.scss';

label {
  position: relative;
  cursor: pointer;
  width: 33.3%;

  input[type='checkbox']:checked + span {
    &::after {
      opacity: 1;
      transform: scale(1);
    }
  }

  input {
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
  }
  .checkbox {
    display: flex;
  }
  .span {
    font-family: $mr;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #191d2f;
    padding-left: 28px;
    position: relative;

    &::after {
      content: '✓';
      position: absolute;
      top: 0;
      left: 0;
      margin: auto;
      width: 20px;
      height: 20px;
      background: #1da1f2;
      border-radius: 3px;
      color: #fff;
      opacity: 0;
      padding-left: 2px;
      border: 1px solid #000;
      transform: scale(0);
      font-size: 19px;
      transition: 0.3s;
      font-weight: 600;
    }

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      top: 0;
      left: 0;
      border: 1px solid #191d2f;
    }

    &.selected {
      &:after {
        content: '';
        transform: none;
        opacity: 1;
      }
    }

    &.radio {
      &:after {
        content: '';
        border-radius: 10px;
        top: 5px;
        left: 5px;
        width: 10px;
        height: 10px;
        font-size: 14px;
        font-weight: normal;
      }

      &:before {
        border-radius: 100%;
      }
    }

    &.false {
      &::after {
        content: '✓';
        background: #e40a0a;
      }
    }

    &.correct {
      &::after {
        content: '✓';
        background: #00af07;
      }
    }
  }

  .report {
    color: #8c8e97;

    &:before {
      border-color: #8c8e97;
    }
  }
}
</style>
