<template>
  <div class="drop-box" v-click-outside="hiddenDropBox">
    <span class="drop-sub">{{ title }}</span>
    <div class="custom-dropdown">
      <button
        class="drop-toggle"
        :class="{ active: isDropped }"
        @click="isDropped = !isDropped"
      >
        {{ selectedItem.name }}
        <span class="caret" :class="{ rotate: isDropped }"></span>
      </button>
      <transition name="fade">
        <div class="drop-menu" v-show="isDropped">
          <span
            v-for="item in items"
            :key="item.value + item.name"
            class="drop-item"
            :class="{ active: selectedItem === item }"
            @click="onChange(item)"
            >{{ item.name }}</span
          >
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'

export default {
  name: 'VDDropdown',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    selectedItemIndex: {
      type: Number,
      default: 0
    },
    resetIndex: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isDropped: false,
      selectedItem: {}
    }
  },
  created() {
    this.selectedItem = this.items[this.selectedItemIndex]
  },
  methods: {
    onChange(item) {
      this.selectedItem = item
    },
    hiddenDropBox() {
      this.isDropped = false
    }
  },
  watch: {
    selectedItem() {
      this.$emit('change', {
        name: this.selectedItem.key,
        value: this.selectedItem.value
      })
      this.$emit('lessonsChange', {
        name: this.selectedItem.name,
        id: this.selectedItem.id
      })
      this.isDropped = false
    },
    resetIndex() {
      if (this.resetIndex) this.selectedItem = this.items[0]
    }
  },
  directives: {
    clickOutside: vClickOutside.directive
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.rotate {
  transform: rotate(180deg);
}

.drop-box {
  display: inline-block;
  max-width: 256px;
  width: 100%;
  margin-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.drop-sub {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 4px;
  font-family: $mr;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #191d2f;
  width: 100%;
  display: inline-block;
}

.custom-dropdown {
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: left;
}

.drop-toggle {
  width: 100%;
  display: inline-block;
  border: 1px solid #f0f0f0;
  border-radius: 13px;
  position: relative;
  text-align: left;
  background: #fff;
  padding: 13px 16px;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-family: $mr;
  color: #000000;
  outline: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &.active {
    z-index: 3;
  }

  .caret {
    position: absolute;
    top: 0;
    right: 13px;
    bottom: 0;
    background-image: url(../../../assets/svg/caret.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 11px;
    height: 11px;
    margin: auto;
    transition: 0.2s;
  }
}

.drop-menu {
  width: 100%;
  height: fit-content;
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0px;
  top: 40px;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid #f0f0f0;
  padding-top: 10px;
  box-shadow: 0px 2px 6px rgba(132, 132, 132, 0.05);
  border-radius: 0px 0px 23px 23px;
  z-index: 1000;
}

.drop-item {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  font-family: $mr;
  color: #000000;
  outline: none;
  background: #fff;
  text-decoration: none;
  display: inline-block;
  padding: 14px 16px;
  width: 100%;
  transition: 0.2s;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
  border: none;
  cursor: pointer;

  &:before {
    content: '';
    bottom: 0;
    left: 0px;
    right: 0;
    margin: auto;
    width: calc(100% - 32px);
    height: 1px;
    position: absolute;
    background: #f0f0f0;
  }

  &.active {
    color: #1da1f2 !important;
    user-select: none;
    pointer-events: none;
  }

  &:last-child {
    border-radius: 0px 0px 23px 23px;
    &:before {
      display: none;
    }
  }

  &:hover {
    color: #1da1f2;
  }
}

@media (max-width: 992px) {
  .drop-box {
    max-width: inherit;
    margin-right: 0;
  }
}
</style>
