<template>
  <pre class="text-msg vue-component" v-html="text"></pre>
</template>

<script>
export default {
  name: 'text-msg',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: {
    text: {
      required: true,
      type: String
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.text-msg {
  color: #000;
  font-family: Montserrat-Regular;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  margin-bottom: 0;
  white-space: pre-wrap;
}
</style>
