var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('section',{class:['sc-banner', _vm.bannerInfo ? _vm.backgroundColorBanner : ''],style:(_vm.isMobile ? '' : 'background-image: url(' + _vm.bgImg + ')')},[_c('div',{staticClass:"container"},[(_vm.bannerInfo)?_c('div',{staticClass:"banner-info w-100 w-md-40"},[_c('div',{staticClass:"title w-60"},[(_vm.bannerInfo.title)?_c('h4',{class:{
              'end-olympiad':
                _vm.bannerInfo.olympiadStatus == 987 ||
                _vm.bannerInfo.olympiadStatus == 685
            }},[_vm._v(" "+_vm._s(_vm.bannerInfo.title)+" ")]):_vm._e()]),(!_vm.bannerInfo.isRegister || _vm.bannerInfo.subtitle)?_c('p',{staticClass:"main--text",class:{
            'end-olympiad':
              _vm.bannerInfo.olympiadStatus == 987 ||
              _vm.bannerInfo.olympiadStatus == 685
          }},[_vm._v(" "+_vm._s(_vm.bannerInfo.subtitle)+" ")]):_vm._e(),_c('div',{staticClass:"w-100"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerInfo.type == 'upcomming'),expression:"bannerInfo.type == 'upcomming'"}]},[(
                !_vm.bannerInfo.isRegister &&
                _vm.bannerInfo.olympiadStatus != 2 &&
                _vm.bannerInfo.olympiadStatus != 987 &&
                _vm.bannerInfo.olympiadStatus != 0
              )?_c('button',{staticClass:"go-test banner-btn",on:{"click":function($event){return _vm.$emit('registerOlympiad', true)}}},[_vm._v(" Зарегистрироватся ")]):(
                _vm.bannerInfo.isRegister &&
                _vm.bannerInfo.timeToOlympiad == 0 &&
                _vm.bannerInfo.olympiadStatus == 678
              )?_c('button',{staticClass:"go-test banner-btn",on:{"click":function($event){return _vm.$emit('startOlympiad', true)}}},[_vm._v(" Начать олимпиаду ")]):(_vm.bannerInfo.olympiadStatus == 234)?_c('p',{staticClass:"main--text bold"},[_vm._v(" Вы пропустили олимпиаду ")]):(_vm.bannerInfo.olympiadStatus == 987)?_c('p',{staticClass:"main--text text-green bold"},[_vm._v(" Вы завершили олимпиаду ")]):(_vm.bannerInfo.olympiadStatus == 685)?_c('p',{staticClass:"main--text text-green bold"},[_vm._v(" Вы уже завершили текущий этап олимпиады ")]):(
                _vm.bannerInfo.isRegister &&
                _vm.bannerInfo.timeToOlympiad > 0 &&
                _vm.bannerInfo.olympiadStatus != 234 &&
                _vm.bannerInfo.olympiadStatus != 3
              )?_c('counter',{attrs:{"isOlympiad":true,"current-time":_vm.bannerInfo.timeToOlympiad},on:{"updateTimer":_vm.updateTimer}}):_vm._e()],1),(_vm.bannerInfo.places)?_c('ul',{staticClass:"places"},_vm._l((_vm.bannerInfo.places),function(place,index){return _c('li',{key:index},[_c('p',{class:'place-' + index},[_vm._v(" "+_vm._s(index + 1)+" место - "+_vm._s(place)+" ")])])}),0):_vm._e()])]):_c('div',{staticClass:"banner-info"},[(_vm.banner.subTitle)?_c('span',[_vm._v(_vm._s(_vm.banner.subTitle))]):_vm._e(),_c('div',{staticClass:"w-100"},[_c('h4',[_vm._v(_vm._s(_vm.banner.title))])]),_c('p',{staticClass:"main--text"},[_vm._v(_vm._s(_vm.banner.text))]),_c('div',{staticClass:"w-100"},[_c('a',{staticClass:"go-test banner-btn",style:(_vm.banner.buttonStyles),attrs:{"href":"https://t.me/xacademyieltsweb_bot","target":"_blank"}},[_vm._v(_vm._s(_vm.$t('data.14')))])])])])]),_c('div',{staticClass:"banner-bg d-block d-md-none",style:('background-image: url(' + _vm.bgImg + ')')})])}
var staticRenderFns = []

export { render, staticRenderFns }