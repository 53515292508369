<template>
  <div class="re-password vue-component form-box">
    <h2>{{ $t('data.78') }}</h2>
    <form @submit.prevent="sendPhone">
      <div class="field-box">
        <p>{{ $t('data.21') }}<span>*</span></p>
        <phone-input :info="phone" v-model="phone.model" />
      </div>
      <div class="submit-box">
        <a @click="$router.back()" class="secondary--button">{{
          $t('data.80')
        }}</a>
        <input
          type="submit"
          href="#"
          class="primary--button"
          :value="$t('data.81')"
        />
      </div>
    </form>
    <p v-if="!response.isSuccessful" class="text-danger">
      {{$t('data.235')}}
    </p>
  </div>
</template>

<script>
import LangSelect from '../../UI/small-components/LangSelect.vue'
import Api from '@/api/endpoints'

export default {
  name: 're-password',

  components: {
    LangSelect
  },

  data: function () {
    return {
      phone: {
        model: '',
        required: true,
        class: '',
        errorMessage: ''
      },

      response: {
        isSuccessful: true
      }
    }
  },

  computed: {
    phoneNumberFormatted() {
      const phoneNumber = this.phone.model

      const re = /\d/g

      const phoneNumberFormatted = phoneNumber.match(re).join('')

      return Number(phoneNumberFormatted)
    }
  },

  methods: {
    async sendPhone() {
      const phone = this.phoneNumberFormatted

      try {
        const resp = await Api.passwordRecovery({ phone: phone })

        const success = resp.success
        const timeToResendMessage = resp.resend_available_after

        if (success) {
          this.$router.push({
            name: 'code-in',
            params: {
              phone,
              timeToResendMessage
            }
          })
        } else {
          this.response.isSuccessful = false
        }
      } catch (err) {
        console.log('error occured')
        console.log(err)

        this.response.isSuccessful = false
        return
      }
    }
  }
}
</script>

<style scoped="scoped" lang="sass"></style>