<template class="test">
  <div ref="table-data">
    <b-table
      :per-page="perPage"
      :current-page="currentPage"
      :class="['table', 'table-data', isOlympiad ? 'table-olympiad' : '']"
      :fields="fields"
      :items="detailsItems"
      ref="b-table"
    >
      <template v-if="isLessons" #cell(courseSubjectName)="data">
        <strong>{{ data.item.subject.name }}</strong>
      </template>
      <template #cell(subject)="data">
        <strong>{{ data.value }}</strong>
      </template>
      <template #cell(status)="data">
        <span class="status_text" :class="getItemStatus(data.item.status)">
          {{ data.item.status }}
        </span>
      </template>
      <template #cell(updated_at)="data">
        <span v-if="data.item.updated_at">
          {{
            data.item.updated_at.split('T')[0].split('-').reverse().join('.')
          }}
        </span>
      </template>
      <template #cell(test_group_status)="data">
        <span
          :class="[checkMarkClass(data.item.mark)]"
          class="mark_text"
          v-if="data.item.hasOwnProperty('mark')"
        >
          {{ checkResult(data.item.mark) }}
        </span>
        <span
          v-else
          :style="'color:' + checkColor(data.item.test_group_status)"
          :class="['status_text']"
        >
          <img :src="checkIcon(data.item.test_group_status)" alt="status" />
          {{
            !data.item.test_group_status
              ? 'Ожидает выполнения'
              : data.item.test_group_status
          }}
        </span>
      </template>
      <template #cell(id)="data">
        <div v-if="isLessons" class="d-flex justify-content-end">
          <router-link
            :to="{ name: 'lesson-record', params: { id: data.item.id } }"
            href="#"
            class="checkout secondary--button"
          >
            {{ $t('posmotret-zapis') }}
          </router-link>
        </div>
        <div v-if="isExams || dz" class="d-flex justify-content-end">
          <router-link
            v-if="
              data.item.test_group_status === 'Ожидает выполнения' ||
              data.item.test_group_status === null
            "
            :to="{ name: 'testing', params: { id: data.item.id, type: 'hw' } }"
            href="#"
            class="checkout secondary--button"
          >
            {{ $t('pereiti-na-testirovanie') }}
          </router-link>
          <router-link
            v-else
            :to="{
              name: dz ? 'report-homework' : 'exams-homework',
              params: { id: data.item.id }
            }"
            href="#"
            class="checkout secondary--button"
          >
            {{ $t('posmotret-otchet') }}
          </router-link>
        </div>
      </template>
      <template #cell()="data">
        {{ data.value }}
      </template>
      <template v-if="isLessons" #row-details="data">
        <div class="details-wrap">
          <span class="time-info">
            <span v-if="data.item.video_duration" class="time-info">
              {{ videoDuration(data.item.video_duration) }}
            </span>
          </span>
          <span
            :class="[
              'homework-loading',
              data.item.dz_completed ? 'complete' : ''
            ]"
            style="float: right"
            @click="toDz(data.item)"
            v-if="data.item.test_groups_ids && data.item.test_groups_ids.length"
          >
            <router-link
              v-if="data.item.dz_completed"
              :to="{
                name: 'report-homework',
                params: { id: data.item.test_groups_ids[0] }
              }"
            >
              {{ $t('domashnee-zadanie') }}
              <img :src="require('@/assets/svg/double-checked.svg')" alt="" />
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'testing',
                params: { type: 'hw', id: data.item.test_groups_ids[0] }
              }"
            >
              {{ $t('domashnee-zadanie') }}
              <img :src="require('@/assets/svg/loading.svg')" alt="" />
            </router-link>
          </span>
        </div>
      </template>
    </b-table>
    <b-pagination
      v-if="pagination"
      class="pagination-box"
      v-model="currentPage"
      :total-rows="rows"
      :per-page="perPage"
      aria-controls="my-table"
      first-number
      last-number
    ></b-pagination>
  </div>
</template>

<script>
import StrTimeMixin from '@/mixins/getTimeStr'

export default {
  props: {
    items: Array,
    fields: Array,
    pagination: Boolean || null,
    itemPerPage: Number || null,
    isOlympiad: Boolean || null,
    isLessons: {
      type: Boolean,
      default: false
    },
    isExams: {
      type: Boolean,
      default: false
    },
    dz: Boolean
  },
  data: () => ({
    page: 0,
    currentPage: 1
  }),
  watch: {
    currentPage() {
      let table = this.$refs['table-data']
      if (!table) return
      console.log(table)
      table.scrollIntoView({
        behavior: 'smooth'
      })
    }
  },
  methods: {
    getItemStatus(status) {
      // === $t('uspeshno') ? 'green' : 'red']
      if (status === this.$t('uspeshno')) return 'green'
      if (status === this.$t('v-obrabotke')) return 'yellow'
      return 'red'
    },
    toDz(item) {
      const toTesting = {
        name: 'testing',
        params: { id: item.test_groups_ids[0], type: 'hw' }
      }
      const toReport = {
        name: 'report-homework',
        params: { id: item.test_groups_ids[0] }
      }
      this.$router.push(item.dz_completed ? toReport : toTesting)
    },
    checkResult(mark) {
      if (mark === 5) {
        return this.$t('result.1')
      }
      if (mark === 4) {
        return this.$t('result.2')
      }
      if (mark === 3) {
        return this.$t('result.3')
      }
      if (mark === 2) {
        return this.$t('result.4')
      }
      if (mark === 1) {
        return this.$t('result.5')
      }
    },
    checkMarkClass(mark) {
      if (mark === 5) {
        return `excellent`
      }
      if (mark === 4) {
        return `good`
      }
      if (mark === 3) {
        return `norm`
      }
      if (mark === 2) {
        return `bad`
      }
      if (mark === 1) {
        return `fail`
      }
    },
    getTimeString(seconds) {},
    videoDuration(time) {
      // return moment(time * 1000).format('HH:mm:ss')
      let timeMultiple = time * 1000
      timeMultiple = Math.floor(timeMultiple / 1000)
      return `${this._timeStrGetHours(timeMultiple)} ${this.$t(
        'chas'
      )} ${this._timeStrGetMinutes(timeMultiple)} ${this.$t(
        'min'
      )} ${this._timeStrGetSeconds(timeMultiple)} ${this.$t('sec')}`
    },
    checkIcon(status) {
      if (status === 'Ожидает выполнения') {
        return require('@/assets/svg/on-wait.svg')
      }
      if (status === 'На проверке') {
        return require('@/assets/svg/on-check.svg')
      }
      if (status === 'Не доделаная задача') {
        return require('@/assets/svg/on-loader.svg')
      }
      if (status === 'Отказ') {
        return require('@/assets/svg/on-error.svg')
      } else {
        return require('@/assets/svg/on-wait.svg')
      }
    },
    checkColor(status) {
      if (status === 'Ожидает выполнения') {
        return '#FFA800'
      }
      if (status === 'На проверке') {
        return '#00AF07'
      }
      if (status === 'Не доделаная задача') {
        return '#8C8E97'
      }
      if (status === 'Отказ') {
        return '#E40A0A'
      } else {
        return '#FFA800'
      }
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    detailsItems() {
      return this.items.map((item) => {
        return { ...item, _showDetails: true }
      })
    },
    perPage() {
      return this.itemPerPage
        ? (this.page = this.itemPerPage)
        : (this.page = 10)
    }
  },
  mixins: [StrTimeMixin],
  mounted() {}
}
</script>

<style lang="scss">
@import '../../../styles/vars';
.mark_text {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
}
.excellent {
  color: #00af07;
}

.good {
  color: #871df2;
}

.norm {
  color: #ffa800;
}

.bad {
  color: #e40a0a;
}

.fail {
  color: #8c8e97;
}

.pagination-box {
  display: flex;
  justify-content: center;
  width: 100%;
  list-style: none;
  padding-left: 0;
  margin-top: 32px;

  li {
    &:first-child,
    &:last-child {
      display: none;
    }

    margin: 0 8px;

    button {
      width: 46px;
      height: 46px;
      flex-shrink: 0;
      opacity: 0.3;
      border: 2px solid #000000;
      border-radius: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5px 10px;
      font-family: $mb;
      font-size: 14px;
      text-decoration: none;
      letter-spacing: -0.02em;
      color: #191d2f;
      transition: 0.1s;

      &:hover {
        border-color: #8ed0f9;
        background: #8ed0f9;
        color: #fff;
        opacity: 1;
      }
    }

    &.active {
      .page-link {
        background: #1da1f2;
        border-color: #1da1f2;
        color: #fff;
        opacity: 1;
        pointer-events: none;
      }
    }
  }
}

.table > :not(caption) > * > * {
  box-shadow: none !important;
}

.table {
  border-collapse: separate;
  border-spacing: 0 8px;

  &.table-olympiad {
    tr {
      &:first-child {
        td {
          background: #1da1f2;
          color: #fff;
        }
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        border: 2px solid #1da1f2;
        td {
          border-top: 1px solid #1da1f2;
        }
      }
    }
  }

  th {
    border: none !important;
  }

  thead {
    background: #f5f5f5;
    border-radius: 4px;
    margin-bottom: 5px !important;

    tr {
      th {
        padding: 10px;

        &:first-child {
          border-radius: 12px 0 0 12px;
          padding-left: 32px;
        }

        &:last-child {
          border-radius: 0 12px 12px 0;
          padding-right: 32px;
        }
      }
    }
  }

  tbody {
    tr {
      td {
        padding: 15px;

        &:first-child {
          border-left-width: 1px;
          border-radius: 12px 0 0 12px;
          padding-left: 32px;
        }

        &:last-child {
          border-right-width: 1px;
          border-radius: 0 12px 12px 0;
          padding-right: 32px;
        }
      }
    }

    .b-table-has-details {
      margin-bottom: 0;

      td {
        border-bottom-width: 0;

        &:first-child {
          border-radius: 12px 0 0 0;
        }

        &:last-child {
          border-radius: 0 12px 0 0;
        }
      }
    }

    .b-table-details {
      margin-top: 0 !important;
      transform: translate(0, -10px);

      td {
        border-radius: 0 0 12px 12px !important;
        padding-bottom: 0;
        padding-top: 1px;

        .details-wrap {
          border-top: 1px solid #f0f0f0;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .homework-loading {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.status_text {
  font-size: 13px;
}

.table tbody .checkout {
  padding: 15px 10px;
  font-size: 13px;
  min-width: 118px;
  text-align: center;
}
.table tbody tr td {
  font-size: 15px;
  padding-left: 5px;
  padding-right: 7px;
}
.table tbody tr td:first-child {
  padding-left: 15px;
}
.table tbody tr td:last-child {
  padding-right: 15px;
}
// .table tbody tr td:nth-child(2){
//   max-width: 250px;
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
// }

// .table tr td {
//   &.is-caption {
//     position: relative;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     max-width: 315px;
//     padding-left: 25px;
//     cursor: pointer;

//     &::before {
//       content: '';
//       position: absolute;
//       left: 0;
//       width: 18px;
//       height: 18px;
//       background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9 0C4.03746 0 0 4.03746 0 9C0 13.9625 4.03746 18 9 18C13.9625 18 18 13.9625 18 9C18 4.03746 13.9625 0 9 0ZM9.58542 14.3399C9.15784 14.4112 8.30704 14.5892 7.87499 14.625C7.50928 14.6553 7.16417 14.4459 6.9538 14.1454C6.74288 13.845 6.69233 13.4604 6.81756 13.1155L8.51879 8.43751H6.74998C6.74847 7.46276 7.47962 6.74603 8.41475 6.47097C8.86101 6.33967 9.69245 6.15999 10.125 6.18753C10.3841 6.20401 10.8358 6.36659 11.0462 6.6671C11.2571 6.96757 11.3076 7.35209 11.1824 7.69706L9.48118 12.375H11.2494C11.2498 13.3486 10.5459 14.1798 9.58542 14.3399ZM10.125 5.62501C9.50364 5.62501 8.99997 5.12127 8.99997 4.5C8.99997 3.87866 9.50364 3.37499 10.125 3.37499C10.7463 3.37499 11.25 3.87866 11.25 4.5C11.25 5.1213 10.7463 5.62501 10.125 5.62501Z' fill='%239199B5'/%3E%3C/svg%3E%0A");
//     }

//     &::after {
//       content: '';
//       position: absolute;
//       left: 25px;
//       bottom: 4px;
//       background: #fff;
//       width: 290px;
//       padding: 10px;
//       font-size: 12px;
//       height: 90px;
//       white-space: normal;
//       border: 1px solid #f0f0f0;
//       opacity: 0;
//       visibility: hidden;
//       transition: 0.2s;
//       box-shadow: -2px 4px 40px rgba(35, 52, 107, 0.05);
//     }

//     &:hover {
//       &::after {
//         opacity: 1;
//         visibility: visible;
//         transition: 0.2s;
//       }
//     }
//   }
// }

.status_text {
  font-weight: 700;
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
  }

  &.green {
    color: #00af07;
  }

  &.red {
    color: #e40a0a;
  }

  &.yellow {
    color: rgb(255, 168, 0);
  }
}

.time-info {
  color: #8c8e97;
}

.homework {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #00af07;
  display: flex;
  align-items: center;

  img {
    margin-left: 4px;
    margin-top: -5px;
  }
}

.homework-loading {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: center;
  a {
    color: #8c8e97;
    text-decoration: none;
  }
  &.complete a {
    color: #00af07;
  }

  img {
    margin-left: 4px;
  }
}

.result-btn {
}
</style>

<style lang="scss">
.table-data td {
  vertical-align: middle;
}

div,
p,
li,
span,
td,
th,
strong {
  font-family: 'Montserrat-Regular';
}

@media (max-width: 1300px) {
  .table {
    overflow: hidden !important;

    thead {
      display: none;
    }

    tbody {
      display: flex;
      flex-wrap: wrap;

      width: auto !important;

      margin: 0 -7px -15px;
    }

    tr {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      position: relative;

      // width: 50% !important;
      width: 100%;
      padding: 15px 7px;
      margin-bottom: 15px;
      z-index: 1;

      &::before {
        content: '';
        position: absolute;
        left: 7px;
        right: 0;
        top: 0;
        width: calc(100% - 14px);
        height: 100%;
        border: 1px solid #f0f0f0;
        border-radius: 5px;
        z-index: -1;
      }

      td {
        padding: 0 15px !important;
        border: 0;
        max-width: none !important;
        border-radius: 0 !important;

        &:first-child,
        &:last-child {
          padding: 0;
        }

        &.is-title {
          margin-bottom: 10px;
          padding-right: 95px !important;

          font-weight: bold;
          font-size: 18px;
          white-space: inherit;
        }

        &.is-caption {
          white-space: inherit !important;
          margin-bottom: 10px;
          width: auto;
          cursor: default;

          &:after,
          &::before {
            display: none;
          }
        }

        &.is-create {
          position: absolute;
          right: 10px;
          top: 20px;
          font-size: 14px;
          font-weight: bold;
          width: auto !important;
        }

        &.is-status {
          position: absolute;
          left: 10px;
          bottom: 35px;
          width: 70%;
        }

        &.is-theme {
          margin-bottom: 10px;
          white-space: inherit;
        }

        &.is-time {
          position: absolute;
          right: 85px;
          top: 20px;
          font-size: 14px;
          font-weight: bold;
          width: auto;
        }

        &.is-button {
          margin-top: auto;
        }
      }
    }

    .b-table-details {
      position: relative;
      padding: 0 10px !important;
      transform: none;
      background: #fff;
      margin-top: -3px;

      &::before {
        content: '';
        position: absolute;
        left: 7px;
        right: 0;
        top: 0;
        width: calc(100% - 14px);
        height: 100%;
        border: 1px solid #f0f0f0;
        border-radius: 0px 0px 5px 5px;
        z-index: -1;
      }

      .details-wrap {
        border: 0 !important;
      }
    }
  }
}

@media (max-width: 1170px) {
  .table {
    tr {
    }
  }
}

@media (max-width: 992px) {
  ::-webkit-scrollbar {
    height: 6px;
  }

  .task-box {
    padding-bottom: 24px;
  }
}

@media (max-width: 641px) {
  .table {
    tr {
      td {
        &:first-child {
          white-space: inherit;
          padding-right: 0 !important;
        }
        &.is-create {
          position: static;
          margin-bottom: 10px;
        }

        &.is-status {
          position: static;
          margin-top: auto;
          width: auto;
        }

        &.is-time {
          position: static;
          margin-bottom: 10px;
        }

        &.is-button {
          width: 100% !important;
          margin-top: 10px;

          .checkout {
            width: 100%;
            text-align: center;
          }
        }
      }
    }

    .b-table-details {
      padding-right: 25px !important;
    }

    .homework-loading {
      font-size: 12px;
    }

    .time-info {
      font-size: 12px;
    }
  }
}

@media (max-width: 641px) {
  .table {
    tr {
      width: 100% !important;
    }
  }
}

.secondary--button {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}
</style>
