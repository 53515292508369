<template>
  <div class="video-box" @mousemove="userActive">
    <video
      @click.prevent="controlsPlayVideo"
      :class="{ hovered: userActiveState }"
      class="video"
      disablepictureinpicture
      playsinline
      control='true' type='video/mp4'
      :autoplay="autoplay"
      controlslist="nodownload"
      :muted="mutedData"
      ref="videoTag"
    >
      <source ref="src1">
      <source ref="src2">
    </video>
    <div
      class="video-controls-wraper"
      :class="{ 'controls-active': userActiveState }"
    >
      <div v-if="playerMode == 'record'" class="video-time-line">
        <input
          type="range"
          min="0"
          :max="duration"
          v-model="currentTime"
          @input="setVideoTime($event.target.value)"
        />
      </div>
      <div class="video-controls">
        <div class="video-controls-left">
          <div class="play">
            <button @click.prevent="controlsPlayVideo">
              <img
                v-if="controls.play"
                src="@/assets/img/player/play.svg"
                alt=""
              />
              <img v-else src="@/assets/img/player/pause.svg" alt="" />
            </button>
          </div>
          <div class="volume">
            <button @click.prevent="controlsMuteSound">
              <img
                v-if="mutedData"
                src="@/assets/img/player/volume-off.svg"
                alt=""
              />
              <img v-else src="@/assets/img/player/volume-on.svg" alt="" />
            </button>
            <input
              class="form-range"
              type="range"
              min="0"
              max="100"
              step="1"
              v-model="volume"
            />
          </div>
          <div v-if="playerMode == 'record'" class="video-time">
            <div class="current-time">
              {{ videoCurrentTime }}
            </div>
            <span>/</span>
            <div class="remind-time">
              {{ videoTotalTime }}
            </div>
          </div>
        </div>
        <div class="video-controls-right">
          <div class="live" v-if="playerMode == 'stream'">
            <img src="@/assets/img/player/live.svg" alt="" />
            <span>Live</span>
          </div>
          <div class="full-screen">
            <button class="" @click.prevent="controlsFullScreenMode">
              <img src="@/assets/img/player/fullscreen.svg" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="video-time-unactive"
      v-if="playerMode == 'record' && !userActiveState"
    >
      <div class="current-time">
        {{ videoCurrentTime }}
      </div>
    </div>
    <substrate
      class="substrate"
      :class="{ 'stream-active': streamStatus == 'started' }"
      :style="{
        'z-index': streamStatus != 'started' ? 1 : -1,
        'background-color': streamStatus != 'started' ? '#00000' : '#1da1f2'
      }"
      >{{ substrateText }}</substrate
    >
  </div>
</template>

<script >
import HTTP from '@/api/endpoints'
import Substrate from '@/components/UI/small-components/Substrate'
import { mapGetters } from 'vuex'
export default {
  name: 'Player',
  components: {
    Substrate
  },
  props: {
    playerMode: String,
    videoInput: {
      type: [MediaStream, Object, null]
    },
    streamStatus: String,
    poster: {
      required: false,
      type: String
    },
    muted: {
      type: Boolean,
      default: false
    },
    src: {},
    autoplay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      controls: {
        play: true,
        fullScreen: false
      },
      volume: 100,
      duration: 0,
      beforeMuted: 30,
      currentTime: 0,
      paused: false,
      mutedData: false
    }
  },
  methods: {
    // Player control
    // Open - close fullscreen mode
    controlsFullScreenMode() {
      const current = this.$store.getters.fullScreenMode
      if (!current) {
        this.$store.commit('setFullScreenMode', true)
      } else {
        this.$store.commit('setFullScreenMode', false)
      }
    },
    userActive(e) {
      this.$store.commit('setUserActiveState', true)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.commit('setUserActiveState', false)
      }, 3000)
    },

    // control play - pause buttons
    controlsPlayVideo() {
      const video = this.$refs.videoTag
      const isPlaying = video.currentTime > 0 && !video.paused && !video.ended
      if (!isPlaying) {
        video.play()
        // this.videoInterval = setInterval(() => {
        //   this.currentTime = video.currentTime
        // }, 1000)
        this.controls.play = true
      } else {
        video.pause()
        this.controls.play = false
      }
    },
    // mute sound button
    controlsMuteSound() {
      const video = this.$refs.videoTag
      if (video.mutedData) {
        this.mutedData = false
        video.mutedData = false
        this.volume = this.beforeMuted
      } else {
        this.beforeMuted = this.volume
        this.volume = 0
        this.mutedData = true
        video.mutedData = true
      }
    },
    // Calculate time from seconds for player in format hh mm ss
    calcTime(videoDuration) {
      const h = Math.floor(videoDuration / 3600)
      const m = Math.floor((videoDuration % 3600) / 60)
      const s = Math.round(videoDuration % 60)
      return [h, m > 9 ? m : h ? '0' + m : m || '0', s > 9 ? s : '0' + s]
        .filter(Boolean)
        .join(':')
    },
    setVideoTime(time) {
      this.currentTime = time
      this.$refs.videoTag.currentTime = time
    }
  },
  computed: {
    ...mapGetters(['setterVideoTime']),
    //   total video length
    videoTotalTime() {
      return this.calcTime(this.duration)
    },
    // current time of video
    videoCurrentTime() {
      return this.calcTime(this.currentTime)
    },
    substrateText() {
      if (this.$props.streamStatus === 'before start')
        return this.$t('skoro-nachnetsya-urok')
      if (this.$props.streamStatus === 'ended') return this.$t('urok-okonchen')
      if (this.$props.streamStatus == 'norecord')
        return this.$t('zapis-eshe-ne-sozdana')
    },
    userActiveState() {
      return this.$store.getters.userActiveState
    }
  },
  mounted() {
    const video = this.$refs.videoTag
    video.addEventListener('play', () => {
      this.controls.play = false
    })
    video.addEventListener('pause', () => {
      this.controls.play = true
    })
    video.addEventListener(
      'durationchange',
      () => (this.duration = video.duration)
    )
    video.addEventListener(
      'timeupdate',
      () => (this.currentTime = video.currentTime)
    )
    if (this.$props.playerMode == 'record') {
      this.currentTime = video.currentTime
    }
    if (!this.$refs.videoTag) return
    const videoSrc = this.$props.videoInput
    const mode = this.$props.playerMode
    if (videoSrc != null && mode == 'stream') {
      video.srcObject = videoSrc
      video
        .play()
        .then(() => {
          this.controls.play = true
        })
        .catch((e) => {
          this.$toast.open({
            message: this.$t(
              'chtoby-prisoedinitsya-k-translyacie-zapustite-video'
            )
          })
        })
    }
    if (videoSrc != null && mode == 'record') {
      if (this.$props.poster) {
        video.poster = this.$props.poster
      }
      const src1 = this.$refs.src1
      const src2 = this.$refs.src2
      const parts = videoSrc.video_url.split("/stream-videos/")
      src1.src = `${parts[0]}/stream-videos/encoded/${parts[1]}`
      src2.src = videoSrc.video_url
      // When video is loaded set video duration
      video.addEventListener('loadeddata', () => {
        this.duration = video.duration
        // // Correct video duration on server
        // HTTP.correctDuration({
        //   lesson_id: this.$route.params.id,
        //   video_url: videoSrc.video_url,
        //   video_duration: video.duration,
        //   password: 'seyFE8JULDrCmPVU'
        // })
      })
    }
    if (videoSrc == null) {
      // Remove video src and reload player
      video.srcObject = null
      video.src = null
      video.currentTime = 0
      video.load()
      this.controls.play = false
      // this.mutedData = true
    }
  },
  watch: {
    muted() {
      this.mutedData = this.muted
    },
    // Volume change control
    volume() {
      const video = this.$refs.videoTag
      if (this.volume == 0) {
        this.mutedData = true
        this.video.volume = 0
      } else {
        video.volume = this.volume / 100
        this.mutedData = false
        video.mutedData = false
      }
    },
    currentTime() {
      // this.$refs.videoTag.currentTime = this.currentTime
    },
    // Check video input types and append to src or scrObj
    videoInput() {},
    setterVideoTime() {
      this.$refs.videoTag.currentTime = this.setterVideoTime
    }
  },
  //clean intervals before destroy component
  beforeDestroy() {
    clearInterval(this.videoInterval)
  }
}
</script>

<style lang='scss' scoped>
.video-box.paused {
  background: rgba(29, 160, 242, 0.205);
  cursor: pointer;
  &:hover {
    &:before {
      transform: scale(1.3);
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 28px;
    height: 31px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/svg/play.svg);
    background-size: cover;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transition: 0.2s;
  }
  video {
    opacity: 0.5;
  }
}
.hovered {
  filter: opacity(0.8);
}
.video-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: opacity 0.3s;

  video {
    width: 100%;
    cursor: pointer;
    transition: 0.2s;
  }
  .controls-active {
    opacity: 1 !important;
  }
  .video-controls-wraper {
    position: absolute;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }
  .video-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    background-color: transparent;
    color: white;
    height: 35px;
    background-color: black;
    transform: all 0.2s;

    button {
      &hover {
        transform: scale(1.1);
      }
    }
    img {
      fill: white;
      width: 20px;
      height: 20px;
    }
    .video-controls-left {
      display: flex;
      align-items: center;
      .play {
        display: inherit;
        margin: 0 20px;
        transition: all 0.1s;
        &:hover {
          transform: scale(1.05);
        }
      }
      .volume {
        display: flex;
        button {
          transition: all 0.1s;
          &:hover {
            transform: scale(1.05);
          }
        }
      }
      .video-time {
        display: flex;
        align-items: center;
        font-size: 12px;
        .current-time {
          margin: 0px 5px;
        }
        .remind-time {
          margin: 0px 5px;
        }
      }
    }
    .video-controls-right {
      display: flex;
      align-items: center;
      .live {
        display: flex;
        span {
          color: white;
          font-size: 14px;
          margin: 0 30px 0 10px;
        }
      }
      .full-screen {
        display: inherit;
        margin: 0 20px;
        transition: all 0.1s;
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  .video-time-unactive {
    position: absolute;
    bottom: 15px;
    left: 15px;
    color: white;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.02em;
    text-align: left;
  }
}
.video-time-line {
  input[type='range'] {
    -webkit-appearance: none;
    position: absolute;
    top: -5px;
    width: 100%;
    height: 5px;
    outline: none;
    background: grey;
    overflow: hidden;
    cursor: pointer;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 5px;
    background: #1da1f2;
    box-shadow: -100vw 0 0 calc(100vw) #1da1f2;
    cursor: pointer;
  }
}
.volume {
  //   input[type='range'] {
  //   -webkit-appearance: none;

  //   width: 100%;
  //   height: 5px;
  //   outline: none;
  //   background: grey;
  //   overflow: hidden;
  //   cursor: pointer;

  // }
  input[type='range'] {
    -webkit-appearance: none;
    margin-left: 5px;
    background: transparent;
    width: 100%;
  }
  input[type='range']:focus {
    outline: none;
  }
  input[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: #6c757c;
  }
  input[type='range']::-webkit-slider-thumb {
    border-radius: 50%;
    height: 12px;
    width: 12px;
    background: #1da1f2;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5px;
    transition: all 0.3s;
  }
}
input[type='range']::-webkit-slider-thumb:hover {
  background: #007ecc;
}

input[type='range']:hover::-webkit-slider-runnable-track {
  background: #a0a0a0;
}
.live-stream {
  position: absolute;
  bottom: 7.8%;
  right: 17%;
  z-index: 1;
  color: #fff;
  display: flex;
  align-items: center;
  user-select: none;
  pointer-events: none;
  img {
    margin-right: 10px;
  }
}

.substrate {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  background: #1da1f2;
}
.stream-active {
  background: #000000 !important;
}

.interact-buttons {
  text-align: left;
}
</style>