<template>
  <div>
    <label v-for="(option, index) in question.answer" :key="index">
      <input
        :value="option.id"
        type="radio"
        :name="question.question"
        v-model="data.answer[0]"
      />
      <span>{{ option.variant }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'RadioType',
  data() {
    return {
      data: {
        task_id: this.question.id,
        taskType: this.question.type,
        answer: []
      }
    }
  },
  props: {
    index: null,
    question: {}
  },
  watch: {
    'data.answer': function () {
      this.$emit('input', this.data)
      return this.data
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../styles/vars';

.sc-free-test-list {
  padding-top: 40px;
  h1 {
    margin-bottom: 16px;
  }
}

.test-container {
  max-width: 680px;
  width: 100%;
}

.fixed-top-right {
  position: fixed;
  top: 120px;
  right: 70px;
}

.countdown-line {
  width: 53px;
  height: 56px;
  border-radius: 100%;
  transform: rotateY(0) rotateZ(-90deg) scale(1.4);
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.complete {
  display: inline-block;
  margin-left: 15px;
}

.t-end-btn {
  width: 51px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 1px 1px;
  position: relative;
  box-shadow: none;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    background: #e40a0a;
  }
}

.more-quests {
  display: flex;
}

.test-list-box {
  display: inline-block;
  width: 100%;
  max-width: 630px;
  padding-left: 15px;
}

.quests {
  width: 100%;
  display: inline-block;
  padding-left: 0;
  li {
    padding-left: 15px;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  h4 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    font-family: $mr;
    margin-bottom: 25px;
  }
  label {
    position: relative;
    cursor: pointer;
    input[type='radio']:checked + span {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }
    span {
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #191d2f;
      padding-left: 28px;
      position: relative;
      &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        margin: auto;
        width: 10px;
        height: 10px;
        background: #1da1f2;
        border-radius: 10px;
        opacity: 0;
        transform: scale(0);
        transition: 0.3s;
      }
      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        top: 0;
        left: 0;
        border: 1px solid #191d2f;
      }
    }
  }
}

.my-modal {
  max-width: 487px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  border-radius: 24px;
  padding: 40px 48px;
  text-align: center;
  position: relative;
  h2 {
    margin-bottom: 12px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 16px;
  box-shadow: none;
  opacity: 1;
  font-weight: 100;
  font-size: 35px;
}

.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(25, 29, 47, 0.507);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.test-info {
  margin-top: 12px;
  display: inline-block;
  width: 100%;

  a {
    margin: 0 8px;
  }
}
</style>
