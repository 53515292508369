export default {
    getTimeStr(seconds) {
        return `<div class="counter-item">
                <div class="counter-num">${this._timeStrGetHours(seconds)}</div>
                <div class="counter-text">ЧАС</div>
            </div> 
            <div class="counter-item">
                <div class="counter-num">${this._timeStrGetMinutes(seconds)}</div>
                <div class="counter-text">МИН</div>
            </div> 
            <div class="counter-item">
                <div class="counter-num">${this._timeStrGetSeconds(seconds)}</div>
                <div class="counter-text">СЕК</div>
            </div>`
    },
    _timeStrUppendZeroToStartStr(str) {
        if (String(str).length == 1) return `0${str}`
        return str
    },
    _timeStrGetHours(seconds) {
        return this._timeStrUppendZeroToStartStr(Math.floor(seconds / 3600))
    },
    _timeStrGetMinutes(seconds) {
        return this._timeStrUppendZeroToStartStr(
            Math.floor((seconds % 3600) / 60)
        )
    },
    _timeStrGetSeconds(seconds) {
        return this._timeStrUppendZeroToStartStr((seconds % 3600) % 60)
    }
}
