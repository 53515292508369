<template>
  <div v-if="question.model">
    <label
        @dragover.prevent="dragoverHandler"
        @dragleave.prevent="dragleaveHandler"
        @drop.prevent="dropHandler"
        :for="'fileInput' + index"
        class="fileLabel"
    >
      <div
          @drop="dragFile"
          :class="[
          'file__container',
          isMoving ? 'dragg' : '',
          isReportPage ? 'report-page' : ''
        ]"
      >
        <div class="file__container--files">
          <div v-if="!files.length" class="empty-file">
            <b-icon class="file--image" icon="file-earmark-text"></b-icon>
            <p>{{ $t('dobavte-dokument-v-pole-dlya-otveta-0') }}</p>
          </div>
          <div
              v-else
              v-for="(file, index) in files"
              :key="index"
              class="file"
              @click.prevent="removeFile"
          >
            <b-icon class="file--image" icon="file-earmark-text"></b-icon>
            <div class="file--block">
              <p class="file--name">
                {{ file.name }}
              </p>
              <p class="file--data">
                {{ (file.size * 9.54E-7).toFixed(2) + 'Мб' }}, {{ file.type }},
                {{ yourDate.toISOString().split('T')[0] }}
              </p>
            </div>

            <div class="file--delete" :data-name="file.name">X</div>
          </div>
        </div>
        <div
            :class="['file__container--add', !data.answer.length ? 'center' : '']"
        >
          <p class="text-c">
            {{ $t('dobavit-fail') }}
            <b-icon class="plus" icon="plus"></b-icon>
          </p>

          <input
              ref="fileInput"
              multiple
              :id="'fileInput' + index"
              :type="isReportPage ? 'text' : 'file'"
              @change="fileChange"
              class="fileInput"
          />
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'FileType',
  props: {
    index: '',
    question: {},
    isReportPage: Boolean
  },
  data() {
    return {
      reader: null,
      data: {
        task_id: this.question.id,
        taskType: this.question.type,
        answer: []
      },
      isMoving: false,
      yourDate: new Date(),
      files: [],
      score: 0
    }
  },
  methods: {
    dragFile(e) {
      if (this.isReportPage) {
        e.preventDefault()
        e.stopPropagation()
      } else {
        Array.from(e.dataTransfer.files).map((item) => {
          let fileName = item.name.split('.');
          // Check if file already exist
          const check = this.files.filter((f) => f.name == item.name)
          if (!check.length) {
            this.files.push(item)
            this.reader = new FileReader()
            this.reader.readAsDataURL(item)
            this.reader.onload = () => {
              this.question.model.answer.push({file: this.reader.result, ext: fileName[fileName.length-1]})
              this.$emit('input', this.question.model.answer)
              console.log(this.question.model.answer)
            }
          }
        })
      }
    },
    // uploadFile
    fileChange(e) {
      let file = e.target.files[0];
      let fileName = file.name.split('.');
      // Check if file already exist
      const check = this.files.filter((f) => f.name == file.name)
      if (!check.length) {
        this.files.push(file)

        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => {
          this.question.model.answer.push({file:reader.result, ext: fileName[fileName.length-1]})
          console.log(this.question.model.answer)
          this.$emit('input', this.question.model.answer)
        }
        reader.onerror = function (error) {
          console.log('Error load file: ', error)
        }
      } else {
        this.$toast.open({
          message: this.$t('dannyi-fail-uzhe-dobavlen'),
          type: 'warning',
          duration: 3000,
          dismissible: true
        })
      }
    },
    // Remove file from list
    removeFile(event) {
      if (event.target.classList == 'file--delete') {
        const fileName = event.target.dataset.name
        const filtered = this.files.filter((file) => file.name != fileName)
        this.files = filtered
        // Clean input cache
        this.$refs.fileInput.value = ''
      }
    },
    dragoverHandler() {
      this.isMoving = true
    },
    dragleaveHandler() {
      this.isMoving = false
    },
    dropHandler() {
      this.isMoving = false
    }
  },
  beforeMount() {
    this.question.model = {
      task_id: this.question.id,
      taskType: this.question.type,
      answer: this.question?.model?.answer || []
    }
  },
  mounted() {
    this.question.model.answer = this.question.model.answer.filter((item) =>
        item.hasOwnProperty('name')
    )
  },
  beforeUpdate() {
    this.question.model = {
      task_id: this.question.id,
      taskType: this.question.type,
      answer: this.question?.model?.answer || []
    }
  }
}
</script>

<style lang="scss" scoped>
.file__container {
  width: 600px;
  border: 1px dashed rgba(140, 142, 151, 0.4);
  border-radius: 13px;
  padding: 40px;

  &.dragg {
    border-color: #1da1f2;
  }

  &--files {
    .empty-file {
      text-align: center;

      p {
        font-size: 18px;
        font-weight: 700;
      }

      .file--image {
        font-size: 48px;
        margin-bottom: 20px;
      }
    }

    .file {
      display: flex;

      &--image {
        margin-top: 7px;
        font-size: 32px;
      }

      &--block {
        margin-left: 20px;
        max-width: 350px;
      }

      &--name {
        margin: 0;
        padding: 0;
      }

      &--data {
        color: #ccc;
      }
    }
  }

  &--add {
    &.center {
      text-align: center;

      .text-c {
        margin-left: 0;
        justify-content: center;
      }
    }

    .text-c {
      margin-left: 50px;
      padding: 0;
      color: #1da1f2;
      margin-bottom: 0;
      width: 100%;
      display: flex;
      align-items: center;

      .plus {
        font-size: 32px;
      }
    }

    .fileLabel {
      color: #1da1f2;
      font-weight: 700;
    }

    .fileInput {
      display: none;
    }
  }
}

.report-page {
  background: rgba(0, 0, 0, 0.1);
  cursor: default;
}

@media (max-width: 1024px) {
  .file__container {
    width: 100%;
  }
}

.file--delete {
  justify-self: flex-end;
  text-align: center;
  color: rgb(131, 0, 0);
  margin-top: 7px;
  margin-left: 25px;
  height: 24px;
  width: 24px;
  transition: all 0.2s;
}

.file--delete:hover {
  transform: scale(1.1);
  background: rgb(211, 211, 211);
}

.removing {
  transform: scale(0);
  transition: transform 0.8s;
}
</style>
