import Vue from 'vue'

import TitleNav from './TitleNav'
import Title from './Title'
import SubTitle from './SubTitle'
import PhoneInput from './PhoneInput'
import Tabs from './Tabs'
import EmptyList from './EmptyList'

Vue.component('title-nav', TitleNav)
Vue.component('x-title', Title)
Vue.component('subtitle', SubTitle)
Vue.component('phone-input', PhoneInput)
Vue.component('tabs', Tabs)
Vue.component('empty-list', EmptyList)
