<template>
  <div id="landing-wrapper">
    <!-- banner  -->
    <section
      class="sc-banner"
      :style="
        '--path-to-img-top: url(' + d.imgs.top.path ||
        require('@/assets/mini-landing-img/mini-landing-banner.jpg') + ')'
      "
    >
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="banner-info">
              <span>{{ $t('data.52') }} «{{ d.name }}»</span>
              <div class="w-100">
                <h4 v-html="d.banner.title"></h4>
              </div>
              <p class="main--text">
                {{ d.banner.subtitle }}
              </p>
              <div class="w-100">
                <a
                  v-b-modal.buy-course
                  @click.prevent="openPayCourseModal($route.params.id)"
                  class="go-test secondary--button"
                  href="#"
                  >{{ $t('data.133') }}</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="banner-bg d-block d-md-none"
        :style="
          '--path-to-img-top: url(' + d.imgs.top.path ||
          require('@/assets/mini-landing-img/mini-landing-banner.jpg') + ')'
        "
      ></div>
    </section>
    <!-- {{ $t('data.134') }}  -->
    <section class="sc-start">
      <div class="container">
        <div class="row">
          <div class="start-box col-12">
            <div class="row">
              <div class="col-lg-6">
                <div class="text-box">
                  <h1>{{ $t('data.134') }}</h1>
                  <p>
                    {{ $t('data.135') }}
                  </p>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="img-box">
                  <img
                    src="../../../assets/mini-landing-img/start-img.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- {{ $t('data.136') }}?  -->
    <section class="sc-includes">
      <div class="container">
        <div class="row">
          <div class="title text-center">
            <h1>{{ $t('data.136') }}</h1>
          </div>
          <div class="col-12 p-0">
            <div class="row">
              <div class="col-lg-4 custom-my">
                <div class="course-box">
                  <div class="num"><span>1</span></div>
                  <div class="text-box">
                    <h3>{{ $t('data.137') }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 custom-my">
                <div class="course-box">
                  <div class="num"><span>2</span></div>
                  <div class="text-box">
                    <h3>{{ $t('data.138') }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 custom-my">
                <div class="course-box">
                  <div class="num"><span>3</span></div>
                  <div class="text-box">
                    <h3>{{ $t('data.139') }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 custom-my">
                <div class="course-box">
                  <div class="num"><span>4</span></div>
                  <div class="text-box">
                    <h3>{{ $t('data.140') }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 custom-my">
                <div class="course-box">
                  <div class="num"><span>5</span></div>
                  <div class="text-box">
                    <h3>{{ $t('data.141') }}</h3>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 custom-my">
                <div class="course-box">
                  <div class="num"><span>6</span></div>
                  <div class="text-box">
                    <h3>{{ $t('data.142') }}</h3>
                  </div>
                </div>
              </div>
              <!-- hidden courses  -->
              <transition name="slide">
                <div class="col-12" v-show="listShow">
                  <div class="row">
                    <div class="col-lg-4 custom-my">
                      <div class="course-box">
                        <div class="num"><span>7</span></div>
                        <div class="text-box">
                          <h3>{{ $t('data.143') }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 custom-my">
                      <div class="course-box">
                        <div class="num"><span>8</span></div>
                        <div class="text-box">
                          <h3>
                            {{ $t('data.144') }}
                          </h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 custom-my">
                      <div class="course-box">
                        <div class="num"><span>9</span></div>
                        <div class="text-box">
                          <h3>{{ $t('data.145') }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 custom-my">
                      <div class="course-box">
                        <div class="num"><span>10</span></div>
                        <div class="text-box">
                          <h3>{{ $t('data.146') }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 custom-my">
                      <div class="course-box">
                        <div class="num"><span>11</span></div>
                        <div class="text-box">
                          <h3>{{ $t('uchastie-v-olimpiadakh') }}</h3>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-4 custom-my">
                      <div class="course-box">
                        <div class="num"><span>12</span></div>
                        <div class="text-box">
                          <h3>{{ $t('data.148') }}</h3>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </transition>

              <div class="w-100 text-center">
                <button
                  class="btn open-full-list"
                  :class="{ rotateArrow: listShow }"
                  @click="listShow = !listShow"
                >
                  {{ titleShowMore }}
                  <span
                    ><img
                      src="../../../assets/mini-landing-img/arrow-white-down.svg"
                      alt=""
                  /></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- {{ $t('data.150') }}?  -->
    <section class="sc-course-info">
      <div class="container">
        <div class="row">
          <div class="title text-center">
            <h1>{{ $t('data.150') }}</h1>
          </div>
          <div class="col-12 p-0 text-center">
            <div class="squares-box">
              <span class="square sq-1"></span>
              <span class="square sq-2"></span>
              <span class="square sq-3"></span>
              <span class="square sq-4"></span>
              <span class="square sq-5"></span>
              <span class="square sq-6"></span>
              <span class="square sq-7"></span>
            </div>
            <div class="img-box">
              <a href="#" @click.prevent="showModal = true">
                <img :src="d.imgs.hoo.path" alt="" />
              </a>
            </div>
          </div>
          <div class="col-12 p-0">
            <div class="info-box">
              <div class="custom-bg"></div>
              <div class="row">
                <div class="col-lg-6">
                  <div class="single-info">
                    <div class="icon-box">
                      <img
                        src="../../../assets/mini-landing-img/c-info-1.png"
                        alt=""
                      />
                    </div>
                    <div class="text-box">
                      <h4>
                        {{ $t('data.151') }}
                      </h4>
                      <p>
                        {{ $t('data.152') }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="single-info">
                    <div class="icon-box">
                      <img
                        src="../../../assets/mini-landing-img/c-info-2.png"
                        alt=""
                      />
                    </div>
                    <div class="text-box">
                      <h4>{{ $t('data.153') }}</h4>
                      <p>
                        {{ $t('data.154') }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="single-info">
                    <div class="icon-box">
                      <img
                        src="../../../assets/mini-landing-img/c-info-3.png"
                        alt=""
                      />
                    </div>
                    <div class="text-box">
                      <h4>
                        {{ $t('data.155') }}
                      </h4>
                      <p>
                        {{ $t('data.156') }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="single-info">
                    <div class="icon-box">
                      <img
                        src="../../../assets/mini-landing-img/c-info-4.png"
                        alt=""
                      />
                    </div>
                    <div class="text-box">
                      <h4>{{ $t('data.157') }}</h4>
                      <p>
                        {{ $t('data.158') }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Преподаватель по профильной математике  -->
    <section class="sc-teach-info">
      <div class="container">
        <div class="row">
          <div class="squares-box">
            <span class="square sq-1"></span>
            <span class="square sq-2"></span>
            <span class="square sq-3"></span>
          </div>
          <div class="col-12 p-0">
            <div class="row">
              <div class="col-lg-5"></div>
              <div class="col-lg-7 text-lg-right">
                <div class="text-box">
                  <h4>
                    {{ d.teacher.title }}
                  </h4>
                  <p>
                    {{ d.teacher.descr }}
                  </p>
                  <span class="caret"></span>
                </div>
                <div class="teacher-info">
                  <h2>{{ d.teacher.name }}</h2>
                  <p class="main--text">
                    {{ d.teacher.post }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0">
            <div
              class="plus-box"
              :style="'--path-to-img: url(' + d.teacher.img + ')'"
            >
              <div class="row">
                <div
                  class="col-lg-4"
                  v-for="(item, i) in d.teacher.list"
                  :key="i"
                >
                  <div class="single-plus">
                    <div class="icon-box">
                      <img
                        src="../../../assets/mini-landing-img/plus-icon.svg"
                        alt=""
                      />
                    </div>
                    <h3>{{ item }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- {{ $t('data.204') }}  -->
    <section class="sc-course-program">
      <div class="container">
        <div class="row">
          <div class="title text-center">
            <h1>{{ $t('data.204') }}</h1>
          </div>
          <!-- <li class="active"><a href="#">{{ $t('data.176') }}ь</a></li>
            <li><a href="#">{{ $t('data.177') }}ь</a></li>
            <li><a href="#">{{ $t('data.178') }}ь</a></li>
            <li><a href="#">{{ $t('data.179') }}ь</a></li>
            <li><a href="#">{{ $t('data.180') }}ь</a></li>
            <li><a href="#">{{ $t('data.181') }}</a></li>
            <li><a href="#">{{ $t('data.182') }}ь</a></li>
            <li><a href="#">{{ $t('data.183') }}</a></li>
            <li><a href="#">{{ $t('data.184') }}ь</a></li>
            <li><a href="#">{{ $t('data.185') }}ь</a></li> -->
          <tabs
            :tabs="monthArray"
            :activeTabs="arrayOfIndexes"
            @change-tab-index="animatePoint"
          >
            <template v-for="(plan, i) in d.programm">
              <ul class="month-info" :key="i" :slot="monthArray[i]">
                <li v-for="(item, i) in plan" :key="i">
                  <div
                    class="sides gray_point"
                    :class="{
                      'left-side': i % 2 == 0,
                      'right-side': i % 2 == 1
                    }"
                    ref="side_points"
                  >
                    <h1>{{ item.date }}</h1>
                    <p v-html="item.text"></p>
                  </div>
                </li>
                <!-- <div class="text-center">
                <a href="#" class="next-moth"
                  >{{ $t('data.176') }}ь
                  <img
                    src="../../../assets/mini-landing-img/month-arrow.svg"
                    alt=""
                /></a>
              </div> -->
              </ul>
            </template>
          </tabs>
        </div>
      </div>
    </section>

    <!-- Видео  -->
    <!-- <section class="sc-video">
      <div class="container">
        <div class="row">
          <div class="squares-box">
            <span class="square sq-1"></span>
            <span class="square sq-2"></span>
            <span class="square sq-3"></span>
          </div>
          <div
            class="video-box"
            :class="{ paused: paused }"
            @click="paused = false"
          >
            <div class="play-btn" @click="paused = false"></div>
            <video
              class="video"
              controls
              disablepictureinpicture
              controlslist="nodownload"
            >
              <source src="@/assets/videos/lesson-video.mp4" type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    </section> -->

    <!-- {{ $t('data.159') }} {{ $t('data.160') }} ...  -->
    <section class="sc-buy-course">
      <div class="container">
        <div class="row">
          <div class="title text-center">
            <h4>
              {{ $t('data.159') }} {{ $t('data.160') }} {{ $t('data.161') }}
            </h4>
          </div>

          <div class="w-100 text-center">
            <a
              href="#"
              v-b-modal.buy-course
              @click.prevent="openPayCourseModal($route.params.id)"
              class="buy primary--button"
              >{{ $t('data.133') }}</a
            >
          </div>

          <div class="w-100 text-center">
            <div class="cost-box">
              <div class="inner-cost">
                <span>{{ $t('data.113') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Подарок  -->
    <div class="sc-prise">
      <div class="container">
        <div class="row">
          <div class="prise-box">
            <div class="text-box">
              <h1>{{ $t('data.164') }} {{ $t('data.165') }}</h1>
              <p class="main--text">
                {{ $t('data.166') }}
              </p>
            </div>
            <div class="img-box">
              <img src="../../../assets/mini-landing-img/prise.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- modal  -->
    <transition name="fade" appear>
      <div
        class="modal-overlay"
        v-if="showModal"
        @click="showModal = false"
      ></div>
    </transition>

    <transition name="fade">
      <div class="modal-box" v-if="showModal">
        <button class="close" @click="showModal = false">&times;</button>
        <h4>{{ $t('data.199') }}</h4>
        <a
          href="#"
          class="got-it primary--button"
          @click.prevent="showModal = false"
          >{{ $t('ponyatno') }}</a
        >
      </div>
    </transition>

    <!-- BUY COURSE MODAL -->
    <b-modal
      size="lg"
      no-stacking
      class="pay-modal"
      id="buy-course"
      :title="$t('data.226')"
      centered
    >
      <p class="label">{{ $t('data.227') }}</p>

      <div class="courses-box">
        <!--        <img :src="require('@/assets/img/buy-course.png')" alt="">-->
        <div v-if="courses.length" class="course-info">
          <!-- <p class="title">{{ $t('data.52') }}</p> -->
          <!-- <p class="title" v-if="courses[0]">
            {{ $t('data.52') }}ы по предмету «{{ courses[0].name }}»
          </p> -->
          <div v-for="course in courses" :key="course.id" class="radio-box">
            <input
              :id="course.id"
              name="course"
              :value="course"
              type="checkbox"
              v-model="buyCourse"
            />
            <label :for="course.id">{{ course.name }}</label>
            <p class="price">{{ course.price.toLocaleString() }} UZS</p>
          </div>
        </div>
        <div v-else class="empty">
          {{ $t('vy-kupili-vse-vozmozhnye-kursy') }}
        </div>
      </div>
      <div class="btn-box">
        <b-button
          @click="getOrder"
          :disabled="!buyCourse.length"
          v-b-modal.accept-buy
        >
          {{ $t('data.228') }}
        </b-button>
      </div>
    </b-modal>

    <b-modal
      no-stacking
      class="pay-modal"
      id="accept-buy"
      :title="$t('data.236')"
      size="lg"
      style="width: 700px"
      centered
    >
      <div class="pay-modal__balances">
        <div>
          <span class="my-balance-label">{{ $t('result.6') }}</span>
          <img
            class="my-balance-img"
            src="../../../assets/svg/balance.svg"
            alt="balance"
          />
          <p class="my-balance">{{ user.balance.toLocaleString() }} СУМ</p>
        </div>
        <div>
          <span class="my-bonus-label">{{ $t('data.27') }}</span>
          <img
            class="my-bonus-img"
            src="../../../assets/svg/bonus.svg"
            alt="bonus"
          />

          <p class="my-bonus">{{ user.bonus.toLocaleString() }}</p>
        </div>
      </div>
      <hr />
      <p class="label">{{ $t('data.237') }}</p>
      <div class="courses-box">
        <!--        <img :src="require('@/assets/img/buy-course.png')" alt="">-->
        <div class="course-info">
          <div v-for="course in buyCourse" :key="course.id" class="radio-box">
            <label class="not-input" :for="course.id">{{ course.name }}</label>
            <p class="price">{{ course.price.toLocaleString() }} UZS</p>
          </div>
        </div>
      </div>
      <div class="btn-box next-step-btn">
        <p>
          {{ $t('data.238') }}
          <span
            >{{ balancePaymentComputed.toLocaleString() }} UZS
            <span v-if="acceptPromo"> {{ $t('cena-so-skidkoi') }}</span>
          </span>
        </p>
        <b-button
          :disabled="acceptPromo"
          @click="showPromo = true"
          class="promo"
        >
          {{ $t('data.239') }}
          <img v-if="acceptPromo" src="@/assets/svg/promo-done.svg" alt="" />
        </b-button>
        <div class="use-bonuses">
          <input
            id="use_bonus"
            name="use_bonus"
            type="checkbox"
            v-model="useBonus"
          />
          <label for="use_bonus">Использовать бонусы</label>
        </div>
        <b-button
          v-b-modal.choose-pay-method
          :disabled="promo !== '' && showPromo && savePromo === 'no'"
        >
          {{ $t('oplatit') }}
        </b-button>
      </div>
      <div v-if="showPromo && !acceptPromo" class="promo-code">
        <input
          :placeholder="$t('vvedite-vash-promokod')"
          v-model="promo"
          type="text"
        />
        <b-button @click="tryToUsePromo" :disabled="!promo">
          {{ $t('sokhranit-0') }}
        </b-button>
      </div>
      <hr />
      <p class="minus-balance">
        {{ $t('result.7') }}:
        <span> {{ balancePaymentComputed.toLocaleString() }} UZS </span>
      </p>
      <hr />
      <div v-if="useBonus && !acceptPromo">
        <p class="minus-balance">
          С бонусов спишется:
          <span> {{ bonusPayment.toLocaleString() }} </span>
        </p>
        <hr />
      </div>
      <p class="need-pay">
        {{ $t('result.8') }}:
        <span> {{ needPayComputed.toLocaleString() }} UZS </span>
      </p>
    </b-modal>

    <b-modal
      ref="lastModal"
      size="lg"
      id="choose-pay-method"
      :title="$t('shag-3-iz-3')"
      centered
    >
      <div v-if="needPayComputed <= 0" class="">
        <div class="title">
          <h2>{{ $t('vy-podtverzhdaete-pokupku-kursov') }}</h2>
        </div>
        <div class="btn-box step_3 balance-btn">
          <b-button @click="payByBalanceMethod" class="btn-next">
            {{ $t('data.328') }}
          </b-button>
          <b-button
            @click="$bvModal.hide('choose-pay-method')"
            class="btn-next"
          >
            {{ $t('data.329') }}
          </b-button>
        </div>
      </div>

      <div v-else>
        <p class="label">{{ $t('data.240') }}</p>
        <div class="radio-box">
          <div
            v-for="pay in paymentsMethod"
            class="radio-content"
            :key="pay.name"
          >
            <input
              v-model="paymentMethod"
              :id="pay.name"
              type="radio"
              :value="pay.name"
              name="payment"
            />
            <label class="radio-label" :for="pay.name">
              <img :src="pay.img" alt="click-pay" />
            </label>
          </div>
        </div>
        <div class="btn-box">
          <b-button
            :disabled="!paymentMethod"
            @click="payCourse"
            class="btn-next"
            style="margin-top: 8px"
          >
            {{ $t('data.229') }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import HTTP from '@/api/endpoints'
import data from './data'
import { TinyCarousel } from '@frsource/tiny-carousel-core'

export default {
  name: 'MiniLanding',
  data() {
    return {
      listShow: false,
      paused: true,
      showModal: false,
      subjectId: 0,
      buyCourse: [],
      courses: [],
      useBonus: false,
      paymentsMethod: [
        { name: 'click.uz', img: require('@/assets/img/oClick.png') },
        { name: 'payme', img: require('@/assets/img/payme.png') },
        { name: 'apelsin', img: require('@/assets/img/apelsin.png') }
      ],
      promo: '',
      totalPayment: 0,
      balancePayment: 0,
      needPay: 0,
      paymentMethod: '',
      totalPayWithPromo: '',
      payByBalance: false,
      showPromo: false,
      savePromo: 'no',
      acceptPromo: false,
      subjectData: {},
      courseSum: 0,
      slider: false,
      subUserBalance: 0,
      isOrder: false,
      mounth: [
        this.$t('data.207'),
        this.$t('data.208'),
        this.$t('data.209'),
        this.$t('data.210'),
        this.$t('data.211'),
        this.$t('data.212'),
        this.$t('data.213'),
        this.$t('data.214'),
        this.$t('data.215'),
        this.$t('data.216'),
        this.$t('data.217'),
        this.$t('data.218')
      ],
      percentsHeight: 60
    }
  },
  computed: {
    ...mapGetters(['orders', 'payment', 'user']),
    monthArray() {
      //sort array start from index 10
      if (!this.d?.start_course_month) {
        return [...this.mounth.slice(9), ...this.mounth.slice(0, 9)]
      } else {
        return [
          ...this.mounth.slice(this.d.start_course_month - 1),
          ...this.mounth.slice(0, this.d.start_course_month - 1)
        ]
      }
    },

    allPrice() {
      if (this.acceptPromo) return this.totalPayWithPromo
      return this.isOrder ? this.orders.data.total : 0
    },
    needPayComputed() {
      if (this.acceptPromo) return this.needPay
      if (this.useBonus && this.isOrder && !this.acceptPromo) {
        return Math.max(0, this.needPay - this.bonusPayment)
        // return this.needPay - this.bonusPayment > 0 &&
        //   this.balancePayment - this.bonusPayment > 0
        //   ? this.needPay - this.bonusPayment
        //   : this.needPay
      }
      return this.needPay
    },
    bonusPayment() {
      if (this.acceptPromo) return 0
      const toPay = this.isOrder ? this.orders.data.total : 0
      if (toPay) {
        return toPay / 2 > this.user.bonus ? this.user.bonus : toPay / 2
      }
      return 0
    },
    balancePaymentComputed() {
      if (this.useBonus) {
        return Math.max(0, this.allPrice - this.bonusPayment)
        // if (
        //   this.balancePayment - this.bonusPayment > 0 &&
        //   this.needPay - this.bonusPayment <= 0
        // ) {
        //   return this.balancePayment - this.bonusPayment
        // }
      }
      return this.balancePayment
    },
    d() {
      return data[this.$route.params.id]
    },
    titleShowMore() {
      return this.listShow ? this.$t('svernut-spisok') : this.$t('data.149')
    },
    arrayOfIndexes() {
      return Array(this.d.programm.length)
        .fill()
        .map((e, i) => i)
    }
  },
  methods: {
    ...mapActions(['getOrders', 'getPayment']),
    payByBalanceMethod() {
      let data = {
        course_ids: this.buyCourse.map(({ id }) => id),
        use_bonuses: this.bonusPayment
      }
      if (this.promo) {
        data.promo_code = this.promo
      }
      HTTP.payByBalance(data)
        .then(async (data) => {
          this.$refs['lastModal'].hide()
          if (data.success) {
            await this.$store.dispatch('setUserBalance', this.subUserBalance)
            await this.$forceUpdate()
            this.$toast.open({
              message:
                this.buyCourse.length > 1
                  ? this.$t('vy-uspeshno-kupili-kursy')
                  : this.$t('vy-uspeshno-kupili-kurs'),
              type: 'success',
              duration: 5000,
              dismissible: true
            })
          } else {
            this.$toast.open({
              message:
                this.buyCourse.length > 1
                  ? this.$t('ne-udalos-kupit-kursy')
                  : this.$t('ne-udalos-kupit-kurs'),
              type: 'error',
              duration: 5000,
              dismissible: true
            })
          }
        })
        .catch(() => {
          this.$toast.open({
            message:
              this.buyCourse.length > 1
                ? this.$t('ne-udalos-kupit-kursy-0')
                : this.$t('ne-udalos-kupit-kurs-0'),
            type: 'error',
            duration: 5000,
            dismissible: true
          })
        })
    },
    openPayCourseModal(id) {
      this.buyCourse = []
      this.promo = ''
      this.paymentMethod = ''
      this.subjectId = id
      this.showPromo = false
      this.acceptPromo = false
      HTTP.getBySubjectDiffUser(id).then(({ data }) => {
        this.courses = data
      })
    },
    tryToUsePromo() {
      HTTP.checkPromo(this.promo).then((r) => {
        if (r.success) {
          this.savePromo = 'yes'
          this.acceptPromo = true
          HTTP.getActualPrice(
            this.buyCourse.map((i) => i.id),
            this.promo
          ).then((r) => {
            this.totalPayment = r.data.total
            this.balancePayment = r.data.balance
            this.subUserBalance = r.data.balance
            this.needPay = r.data.total
            this.totalPayWithPromo = r.data.total + r.data.balance
          })
        } else
          this.$toast.open({
            type: 'warning',
            message: this.$t('promokod-ne-naiden')
          })
      })
    },
    getOrder() {
      this.getOrders({
        type: 'course',
        course_ids: this.buyCourse.map(({ id }) => id)
      }).then(() => {
        this.needPay = Math.max(0, this.orders.data.total - this.user.balance)
        this.balancePayment =
          this.user.balance >= this.orders.data.total
            ? this.orders.data.total
            : this.user.balance
        this.totalPayment =
          parseInt(this.orders.data.total) - parseInt(this.user.balance)
        this.isOrder = true
        this.subUserBalance =
          this.user.balance >= this.orders.data.total
            ? this.orders.data.total
            : this.user.balance
      })
    },
    payCourse() {
      this.getPayment({
        order_id: this.orders.data.id,
        promo_code: this.promo,
        payment_method: this.paymentMethod,
        use_bonuses: this.bonusPayment
      }).then(async () => {
        // window.open(this.payment.data.url, '_blank')
        await this.$store.dispatch('setUserBalance', this.subUserBalance)
        await this.$forceUpdate()
        await document.location.assign(this.payment.data.url)
      })
    },
    animatePointSize() {
      const height = (window.innerHeight * this.percentsHeight) / 100

      this.$refs.side_points.forEach((point) => {
        const elemSizes = point.getBoundingClientRect()

        if (elemSizes.y > height) point.classList.add('gray_point')
        else point.classList.remove('gray_point')
      })
    },
    animatePoint() {
      setTimeout(this.animatePointSize, 0)
    }
  },
  mounted() {
    this.animatePointSize()

    window.document.addEventListener('scroll', this.animatePointSize)
  }
}
</script>

<style scoped lang="scss">
@import './src/styles/vars.scss';
.pay-modal__balances {
  display: flex;
  gap: 25px;
}
.my-balance,
.my-bonus {
  font-weight: 900;
  font-size: 24px;
}
.my-balance-label,
.my-bonus-label {
  font-size: 14px;
  text-transform: uppercase;
}
.my-balance-img,
.my-bonus-img {
  margin-bottom: 2px;
  margin-left: 5px;
}
.minus-balance {
  margin-top: 15px;
  span {
    font-weight: 700;
    font-size: 18px;
  }
}
.need-pay {
  span {
    font-weight: 700;
    font-size: 18px;
  }
}
// PAY MODAL
#choose-pay-method {
  .modal-footer {
    display: none !important;
  }

  .modal-header {
    border: none !important;
  }

  .radio-box {
    display: flex;
  }

  .radio-content {
    margin: 0 5px;
  }

  .title {
    text-align: center;
  }

  input {
    display: none;

    &:checked + .radio-label {
      &:after {
        display: block;
      }
    }
  }

  .radio-label {
    width: 120px;
    height: 130px;
    border: 1px solid #1da1f2;
    border-radius: 4px;
    position: relative;
    background: rgba(142, 208, 249, 0.108);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      border-radius: 50%;
      background: #fff;
      top: 10px;
      right: 10px;
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #1da1f2;
      top: 14px;
      right: 14px;
    }

    p {
      padding: 0;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  .btn-box {
    width: 100%;
    text-align: center;
  }

  .balance-btn {
    button {
      margin: 0 10px;
    }
  }

  .btn-next {
    background: #1da1f2;
    color: #fff;
    border-radius: 12px;
    border: none;
    padding: 10px 14px;
    cursor: pointer !important;
  }

  .btn-cancel {
    background: #fff;
    border: 1px solid rgba(140, 142, 151, 0.4);
    color: #000;
    border-radius: 12px;
    padding: 10px 14px;
    cursor: pointer !important;
  }
}

#buy-course,
#accept-buy {
  .modal-content {
    border-radius: 12px;
  }

  .modal-title {
    font-weight: 700;
  }

  .modal-footer {
    display: none !important;
  }

  .modal-header {
    border: none !important;
  }

  .promo-code {
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      width: 100%;
      border-radius: 12px;
      padding: 10px 24px;
      border: 1px solid #1da1f2;
      margin-right: 20px;
      outline: none;
    }

    button {
      border-radius: 12px;
      background: #1da1f2;
      color: #fff;
      border: none;
      padding: 12px 24px;
    }
  }

  .btn-box {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .use-bonuses {
      margin-top: 10px;
      input {
        margin-right: 10px;
      }
    }
    button {
      background: #1da1f2;
      border-radius: 12px;
      color: #fff;
      border: none;
      padding: 12px 24px;
    }

    &.next-step-btn {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        color: #8c8e97;
        text-align: left;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: column;

        span {
          color: black;
          font-weight: 700;
          font-size: 22px;
        }
      }

      button {
        padding: 10px 12px;
      }

      .promo {
        background: #fff;
        border: 1px solid rgba(140, 142, 151, 0.4);
        color: #000;
        display: flex;
        align-items: center;
      }
    }
  }

  .courses-box {
    background: rgba(142, 208, 249, 0.308);
    border: 1px solid #1da1f2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    border-radius: 12px;
    padding: 15px;

    img {
      margin-right: 25px;
    }

    .title {
      font-weight: 700;
      font-size: 24px;
    }
  }

  .radio-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;
    padding: 5px 10px 5px 20px;
    margin: 5px 0;
    border: 1px solid #1da1f2;
    border-radius: 12px;

    p,
    label {
      padding: 0;
      margin: 0;
    }

    label {
      cursor: pointer;
      font-size: 18px;
      position: relative;
      padding-left: 20px;

      &:after {
        content: '✓';
        position: absolute;
        top: 3px;
        left: -7px;
        margin: auto;
        width: 20px;
        height: 20px;
        background: #1da1f2;
        border-radius: 3px;
        color: #fff;
        opacity: 0;
        padding-left: 3px;
        border: 1px solid #000;
        transform: scale(0);
        font-size: 15px;
        transition: 0.3s;
        font-weight: 600;
      }

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        top: 3px;
        left: -7px;
        border: 1px solid #191d2f;
      }
    }

    p {
      color: #1da1f2;
      font-size: 20px;
      font-weight: 700;
      margin-left: 20px;
    }

    input {
      display: none;

      &:checked + label {
        &:after {
          transform: scale(1);
          opacity: 1;
          transition: 0.2s;
        }
      }
    }

    .not-input {
      padding-left: 0;

      &:before,
      &:after {
        content: none;
      }
    }
  }
}

.title {
  width: 100%;
  display: inline-block;
  margin-bottom: 20px;
}

// banner
.sc-banner {
  background: var(--path-to-img-top);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  // margin-left: -58px;
  // width: calc(100% + 116px);

  .banner-btn {
    font-family: 'Montserrat-Bold';
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
    display: inline-block;
    padding: 16px 14px;
    background: #ffffff;
    border-radius: 13px;
    border: 1px solid rgba(140, 142, 151, 0.4);
    overflow: none;
    text-decoration: none;
    transition: 0.2s;
    text-decoration: none;
  }

  .banner-info {
    display: flex;
    align-items: center;
    align-content: space-between;
    min-height: 348px;
    flex-wrap: wrap;
    padding: 50px 0px 60px;
  }

  span {
    font-family: $mr;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    display: inline-block;
    color: #ffffff;
    background: #191d2f;
    border-radius: 30px;
    padding: 8px 16px;
    margin: 5px 0;
  }

  h4 {
    font-family: $mb;
    font-size: 54px;
    line-height: 68px;
    color: #ffffff;
    margin: 5px 0 10px 0;
  }

  .title {
    h4 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  .places {
    padding: 0;
    margin: 0;
    list-style: none;

    p {
      text-transform: uppercase;
      padding: 0;
      color: #fff;
      opacity: 0.4;
      margin: 0;

      &.place-0 {
        opacity: 1;
      }
    }
  }

  p {
    color: #ffffff;
    max-width: 376px;
  }

  .go-test {
    margin: 5px 0;
  }
}

@media (max-width: 480px) {
  .sc-banner {
    // height: 350px;
    // margin: 0 -17px;
    position: relative;
    padding: 0 0 270px 0;
    overflow: hidden;

    background: #1da1f2;

    .container {
      position: relative;
      z-index: 2;
    }

    .banner-info {
      padding: 40px 0;
    }

    .banner-bg {
      width: 205%;
      height: 409px;

      // position: relative;
      z-index: 1;

      background: var(--path-to-img-top) no-repeat;
      background-position-x: center;
      background-position-y: bottom;
      background-repeat: no-repeat;
      background-size: cover;

      position: absolute;
      left: -483px;
      bottom: 0px;
      zoom: 0.7;

      // background-color: #1da1f2;
    }
  }
}

// Стартуем 4 октября
.sc-start {
  padding-top: 64px;
  padding-bottom: 37px;

  .start-box {
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(29, 161, 242, 0.1);
    border-radius: 24px;
    display: flex;
    align-items: center;
    padding: 0px 54px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      bottom: -18px;
      right: 32px;
      width: 56px;
      height: 56px;
      background: #b3daff;
      filter: blur(10px);
      border-radius: 12px;
    }

    h1 {
      margin-bottom: 24px;
    }

    p {
      font-family: $mr;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #8c8e97;
      margin-bottom: 0;
    }
  }

  .text-box {
    display: inline-block;
    width: 100%;
    padding: 57px 0;
  }

  .img-box {
    display: inline-block;
    width: 100%;
    text-align: right;

    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 1400px) {
  .sc-start {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1200px) {
  .sc-start {
    .col-lg-6 {
      width: 100%;
    }

    .text-box {
      padding: 30px 0 0 0;
    }
  }
}

@media (max-width: 992px) {
  .sc-start {
    .start-box {
      padding: 0 30px;

      p {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 768px) {
  .sc-start {
    padding: 30px 0;
  }
}

// Что входит в курс?
.sc-includes {
  padding-top: 37px;
  padding-bottom: 40px;

  .custom-my {
    margin: 10px 0;
  }

  .course-box {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(29, 161, 242, 0.1);
    border-radius: 12px;
    padding: 29px 24px;

    h3 {
      margin-bottom: 0;
    }

    .num {
      margin-right: 33px;
      width: 48px;
      height: 48px;
      border-radius: 100%;
      border: 1px dashed #1da1f2;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      color: #fff;
      flex-shrink: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
        width: 42px;
        height: 42px;
        background: #1da1f2;
        border-radius: 100%;
      }

      span {
        position: relative;
        z-index: 1;
        font-family: $mr;
        font-size: 18px;
        letter-spacing: -0.02em;
        color: #ffffff;
      }
    }
  }

  .rotateArrow {
    span {
      transform: rotate(180deg) !important;
    }
  }

  .open-full-list {
    font-family: $mb;
    font-size: 14px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #191d2f;
    box-shadow: none;
    margin-top: 40px;
    text-align: center;

    span {
      display: block;
      width: 32px;
      height: 32px;
      background: #1da1f2;
      border-radius: 12px;
      margin: 16px auto 0 auto;
      position: relative;

      img {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }
}

@media (max-width: 1400px) {
  .sc-includes {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1300px) {
  .sc-includes {
    .course-box {
      padding: 25px 15px;
    }

    .course-box .num {
      margin-right: 10px;
    }
  }
}

@media (max-width: 1200px) {
  .sc-includes {
    .col-lg-4 {
      flex: 0 0 50%;
      max-width: 50%;
      width: inherit;
    }
  }
}

@media (max-width: 992px) {
  .sc-includes {
    overflow: hidden;

    .row {
      margin-right: calc(var(--bs-gutter-x) * -0.5);
      margin-left: calc(var(--bs-gutter-x) * -0.5);
    }

    .col-lg-4 {
      margin: 0 0 25px 0;
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      padding-left: calc(var(--bs-gutter-x) * 0.5);
      flex: 0 0 100%;
      max-width: 100%;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .sc-includes {
    // margin: 0 -15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 0;
    padding-bottom: 30px;

    .open-full-list {
      margin-top: 20px;
    }
  }
}

.course-info {
  width: 100%;
}

// Кому подойдет данный курс?
.sc-course-info {
  padding-top: 40px;
  padding-bottom: 65px;
  position: relative;

  .square {
    position: absolute;
    display: inline-block;
    border-radius: 12px;
  }

  .sq-1 {
    width: 70px;
    height: 70px;
    background: rgba(179, 218, 255, 0.5);
    filter: blur(15px);
    top: -65%;
    left: 14%;
  }

  .sq-2 {
    width: 37px;
    height: 37px;
    background: rgba(179, 218, 255, 0.4);
    filter: blur(12px);
    left: 11%;
    top: 29%;
  }

  .sq-3 {
    width: 75px;
    height: 75px;
    background: #b3daff;
    filter: blur(3px);
    left: 34%;
    top: 79%;
    z-index: 1;
  }

  .sq-4 {
    width: 40px;
    height: 40px;
    background: #b3daff;
    right: 32%;
    top: 36%;
  }

  .sq-5 {
    width: 75px;
    height: 75px;
    background: #b3daff;
    filter: blur(10px);
    right: 6%;
    top: 25%;
  }

  .sq-6 {
    z-index: 1;
    width: 72px;
    height: 72px;
    background: #b3daff;
    filter: blur(13px);
    top: 84%;
    right: 23%;
    opacity: 0.7;
  }

  .sq-7 {
    width: 46px;
    height: 46px;
    background: #aa8ef9;
    filter: blur(5px);
    top: 168%;
    left: 24%;
  }

  .img-box {
    display: inline-block;
    text-align: center;
    width: 598px;
    height: 598px;
    background: rgba(29, 161, 242, 0.22);
    border-radius: 100%;
    margin-bottom: -330px;

    img {
      max-width: 100%;
      position: absolute;
      top: -19px;
      right: 0;
      left: 0;
      margin: auto;
    }
  }

  .info-box {
    border-radius: 12px;
    padding-top: 30px;
    padding-bottom: 30px;
    padding-left: 56px;
    padding-right: 56px;
    position: relative;
    width: 100%;
    backdrop-filter: blur(14px);
    background: linear-gradient(
      360deg,
      rgba(45, 136, 248, 0.308) 25.85%,
      rgba(29, 160, 242, 0.308) 68.06%
    );

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #00ffd1 25.85%, #1da1f2 68.06%);
      opacity: 0.3;
      border-radius: 12px;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(360deg, #1000c7 25.85%, #1da1f2 68.06%);
      opacity: 0.3;
      border-radius: 12px;
    }
  }

  .custom-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../../assets/mini-landing-img/info-oval-bg.png');
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    opacity: 0.3;
  }

  .single-info {
    display: flex;
    margin: 20px 0;
    position: relative;
    z-index: 1;
  }

  .icon-box {
    margin-top: -18px;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    position: relative;
    background: #fff;
    border-radius: 100%;
    display: inline-block;
    margin-right: 24px;

    img {
      position: absolute;
      top: -8px;
      right: 0;
      left: 0;
      margin: auto;
    }
  }

  .text-box {
    h4 {
      font-family: $mb;
      font-size: 14px;
      line-height: 17px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 16px;
    }

    p {
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #ffffff;
    }
  }
}

@media (max-width: 1400px) {
  .sc-course-info {
    overflow: hidden;
    padding: 0 15px;
  }
}

@media (max-width: 1300px) {
  .sc-course-info {
  }
}

@media (max-width: 1200px) {
  .sc-course-info {
    .col-lg-6 {
      flex: 0 0 auto;
      width: 100%;
    }
  }
}

@media (max-width: 992px) {
  .sc-course-info {
    .square.sq-3 {
      display: none;
    }

    .img-box {
      width: 300px;
      height: 300px;
      margin: 0 auto -25px;
    }

    .info-box {
      padding: 25px 15px;
    }

    .col-lg-6 {
      &:last-child {
        .single-info {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .sc-course-info {
    padding-left: 0;
    padding-right: 0;
  }
}

// modal
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 9999;
  background: rgba(25, 29, 47, 0.507);
}

.modal-box {
  position: fixed;
  top: 50%;
  display: inline-block;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  max-width: 592px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  text-align: center;
  padding: 68px 30px 40px 30px;

  .close {
    position: absolute;
    top: 25px;
    right: 33px;
    font-weight: 100;
    font-size: 38px;
    opacity: 1;
    color: #8c8e97;
    box-shadow: none;
    outline: none;
  }

  h4 {
    font-family: $mb;
    font-size: 32px;
    line-height: 40px;
    text-transform: uppercase;
    color: #191d2f;
    margin-bottom: 24px;
  }

  .got-it {
    min-width: 185px;
    margin-top: 24px;
  }
}

// Преподаватель по профильной математике
.sc-teach-info {
  padding-top: 65px;
  padding-bottom: 44px;
  position: relative;

  .square {
    position: absolute;
    display: inline-block;
    border-radius: 12px;
  }

  .sq-1 {
    width: 32px;
    height: 32px;
    background: #b3daff;
    filter: blur(14px);
    top: -1%;
    right: 26%;
  }

  .sq-2 {
    width: 40px;
    height: 40px;
    background: #b3daff;
    top: 39%;
    left: 7%;
  }

  .sq-3 {
    width: 83px;
    height: 83px;
    background: #b3daff;
    filter: blur(6px);
    top: 53%;
    left: 48%;
  }

  h2 {
    letter-spacing: -0.02em;
  }

  .text-box {
    display: inline-block;
    width: 100%;
    max-width: 485px;
    padding: 20px 32px;
    background: #f0f0f0;
    border-radius: 12px 12px 12px 0px;
    position: relative;
    text-align: left;
    margin-bottom: 20px;
    margin-right: 29px;
    z-index: 1;

    .caret {
      content: '';
      width: 31px;
      height: 42px;
      position: absolute;
      bottom: 0px;
      left: -31px;
      background-repeat: no-repeat;
      background: url('../../../assets/mini-landing-img/text-arrow.png');
      background-position: center left;
      z-index: -1;
    }

    h4 {
      font-family: $mr;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #000000;
      margin: 12px 0;
    }

    p {
      margin: 12px 0;
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #000000;
    }
  }

  .teacher-info {
    display: inline-block;
    width: 100%;
    margin-top: 80px;
    margin-bottom: 32px;
    padding-right: 29px;

    p {
      margin-bottom: 0;
    }
  }

  .plus-box {
    background: #1da1f2;
    border-radius: 12px;
    width: 100%;
    padding: 29px 58px;
    position: relative;
    margin-top: 32px;

    &:before {
      position: absolute;
      content: '';
      position: absolute;
      left: 0px;
      top: -600px;
      width: 552px;
      height: 600px;
      background: var(--path-to-img);
      background-position: top right;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: -1;
    }

    h3 {
      color: #fff;
      margin-bottom: 0;
    }
  }

  .single-plus {
    margin: 10px 0;
  }

  .icon-box {
    margin-bottom: 23px;
    display: inline-block;
    width: 100%;

    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 1400px) {
  .sc-teach-info {
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;

    .plus-box:before {
      top: -490px;
    }
  }
}

@media (max-width: 1300px) {
  .sc-teach-info {
  }
}

@media (max-width: 1200px) {
  .sc-teach-info {
    .plus-box:before {
      left: -140px;
    }
  }
}

@media (max-width: 992px) {
  .sc-teach-info {
    padding-bottom: 30px;
    padding-top: 30px;

    .caret {
      display: none !important;
    }

    .plus-box {
      margin-top: 325px;
      padding: 15px 30px;
    }

    .squares-box {
      display: none;
    }

    .plus-box:before {
      left: 0;
      right: 0;
      top: -390px;
      margin: 0 auto;

      width: 300px;
      height: 390px;
    }

    .teacher-info {
      margin-top: 0;
      margin-bottom: 45px;
      text-align: center;
    }

    .text-box {
      max-width: 100%;
      margin-right: 0;
    }

    .single-plus {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .icon-box {
        width: auto;
        margin-right: 10px;
        margin-bottom: 0;
      }
    }
  }
}

@media (max-width: 768px) {
  .sc-teach-info {
    // padding-left: 0;
    // padding-right: 0;
    // margin: 0 -15px;

    .plus-box {
      padding: 15px;
    }

    .icon-box {
      img {
        max-width: 30px;
        flex-shrink: 0;
      }
    }
  }
}

// Программа курса
.sc-course-program {
  padding-top: 44px;
  padding-bottom: 65px;

  .program-tab {
    display: flex;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 36px;
    padding-left: 0;
    list-style: none;
    justify-content: space-between;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    padding: 19px 25px;

    .active {
      a {
        color: #1da1f2;
      }
    }

    li {
      padding: 10px 10px;
    }

    a {
      font-family: $mb;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.02em;
      color: #191d2f;
      transition: 0.2s;
      text-decoration: none;

      &:hover {
        color: #1da1f2;
      }
    }
  }

  .month-info {
    margin-top: 36px;
    list-style: none;
    width: 100%;
    padding-left: 88px;
    padding-right: 80px;

    li {
      width: 100%;
      position: relative;
      min-height: 117px;
    }

    p {
      font-family: $mr;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: -0.02em;
      color: #191d2f;
      margin-bottom: 0;
    }
  }

  .sides {
    max-width: 320px;
    width: 100%;

    &:before {
      content: '';
      width: 28px;
      height: 28px;
      background: url('../../../assets/mini-landing-img/border-oval.svg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      right: 0;
      left: 0;
      top: 5px;
      margin: auto;
    }

    &::after {
      content: '';
      width: 1px;
      height: calc(100% - 39px);
      position: absolute;
      right: 0;
      left: 0;
      top: 39px;
      margin: auto;
      border: 1px dashed #1da1f2;
    }

    &.gray_point {
      &:before {
        content: '';
        background: url('../../../assets/mini-landing-img/border-oval-gray.svg');
      }

      &::after {
        content: '';
        border: 1px dashed #aeaeae;
      }
    }
  }

  .right-side {
    text-align: left;
    margin-left: auto;
  }

  .next-moth {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    text-decoration: none;
    color: #1da1f2;
    transition: 0.2s;
    margin-top: 40px;
    display: inline-block;
    position: relative;

    img {
      margin-left: 8px;
      transition: 0.2s;
    }

    &:before {
      content: '';
      width: 28px;
      height: 28px;
      background: url('../../../assets/mini-landing-img/border-oval.svg');
      background-position: center center;
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      top: -36px;
      right: 0;
      left: 0;
      margin: auto;
    }

    &:hover {
      color: rgba(29, 160, 242, 0.589);

      img {
        opacity: 0.5;
        transform: translateX(-5px);
      }
    }
  }
}

@media (max-width: 1400px) {
  .sc-course-program {
    overflow: hidden;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 1300px) {
  .sc-course-program {
    .program-tab {
      padding: 15px;
    }

    .program-tab li {
      padding: 10px 8px;
    }

    .sides {
      max-width: 265px;
    }
  }
}

@media (max-width: 1200px) {
  .sc-course-program {
    .program-tab {
      width: 100%;
      padding: 0 0 18px 0;

      border: 0;
      border-radius: 0;
      overflow-x: auto;
      white-space: nowrap;
      border-bottom: 1px solid #f0f0f0;

      &::-webkit-scrollbar {
        opacity: 0;
        visibility: hidden;
        display: none;
      }

      li {
        padding: 0;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          position: relative;

          &:after {
            content: '';
            position: absolute;
            left: 0;
            bottom: -18px;
            width: 100%;
            height: 2px;
            background: #1da1f2;
          }
        }
      }

      a {
        color: #000000 !important;
        font-size: 14px;
        letter-spacing: -0.02em;
      }
    }

    .month-info {
      padding-left: 0;
      padding-right: 0;
    }

    // .sides{
    //   max-width: 200px;
    // }
  }
}

@media (max-width: 992px) {
  .sc-course-program {
    padding-top: 30px;
    padding-bottom: 30px;

    .sides {
      max-width: 100%;
      padding-left: 50px;
      padding-bottom: 20px;

      &:before {
        left: 0;
        right: auto;
      }

      &:after {
        left: 13px;
        right: auto;
      }
    }

    .next-moth:before {
      right: auto;
    }

    .month-info {
      margin-top: 0;

      .text-center {
        text-align: right !important;
      }
    }
  }
}

@media (max-width: 768px) {
  .sc-course-program {
    padding-left: 0;
    padding-right: 0;
  }
}

// Видео
.sc-video {
  padding-top: 65px;
  padding-bottom: 168px;
  position: relative;

  .square {
    position: absolute;
    display: inline-block;
    border-radius: 12px;
    z-index: 2;
  }

  .sq-1 {
    width: 60px;
    height: 60px;
    background: #b3daff;
    filter: blur(12px);
    left: 7%;
    top: 40%;
  }

  .sq-2 {
    width: 109px;
    height: 109px;
    background: #b3daff;
    top: 71%;
    left: 62%;
  }

  .sq-3 {
    width: 42px;
    height: 42px;
    background: #b3daff;
    filter: blur(10px);
    top: 60%;
    right: 0%;
  }

  .video-box.paused {
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 14.3321px;
      // background: url('../../../assets/mini-landing-img/video-thumb.jpg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .play-btn {
      content: '';
      position: absolute;
      width: 152px;
      height: 152px;
      background: #ffffff;
      border: 1.19434px solid #f0f0f0;
      box-shadow: 0px 0px 47.7736px rgba(29, 161, 242, 0.1);
      background-position: center center;
      background-repeat: no-repeat;
      background-image: url('../../../assets/mini-landing-img/play.svg');
      background-size: 45px 45px;
      z-index: 1;
      top: 0;
      border-radius: 100%;
      right: 0;
      left: 0;
      bottom: 0;
      margin: auto;
      transition: 0.2s;

      &:hover {
        opacity: 0.7;
      }
    }

    video {
      opacity: 0 !important;
    }
  }

  .video-box {
    display: flex;
    width: 100%;
    max-width: 735px;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 1;
    border-radius: 12px;
    margin-top: 32px;
    margin-bottom: 8px;
    box-shadow: 0px 0px 47.7736px rgba(29, 161, 242, 0.1);
    border-radius: 14.3321px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 49px;
      width: 100%;
      height: 100%;
      background: #1da1f2;
      opacity: 0.1;
      border-radius: 14.3321px;
      transform: rotate(9.52deg);
    }

    video {
      width: 100%;
      border-radius: 12px;
      cursor: pointer;
      transition: 0.2s;
      opacity: 1;
    }
  }
}

@media (max-width: 1400px) {
  .sc-video {
    overflow: hidden;
  }
}

@media (max-width: 1200px) {
  .sc-video {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (max-width: 992px) {
  .sc-video {
    padding-top: 30px;
    padding-bottom: 30px;

    .squares-box {
      display: none;
    }

    .video-box.paused .play-btn {
      width: 100px;
      height: 100px;
    }
  }
}

@media (max-width: 768px) {
  .sc-video {
    padding-left: 0;
    padding-right: 0;
  }
}

// Поможем подготовиться в удобном формате ...
.sc-buy-course {
  background: radial-gradient(
    56.84% 567.78% at 50% 56.34%,
    rgba(73, 185, 255, 0.596) 0%,
    rgba(0, 149, 255, 0.603) 100%
  );
  padding-top: 64px;
  padding-bottom: 66px;
  // margin-left: -58px;
  // width: calc(100% + 116px);

  h4 {
    font-family: $mb;
    font-size: 56px;
    line-height: 56px;
    color: #ffffff;
  }

  .buy {
    min-width: 200px;
    margin-top: 28px;
    margin-bottom: 12px;
  }

  .cost-box {
    display: inline-block;
    border: 1px dashed #ffffff;
    border-radius: 49px;
    padding: 4px 4px;
    margin-top: 12px;
  }

  .inner-cost {
    background: #ffffff;
    border-radius: 49px;
    display: inline-block;
    width: 100%;
    padding: 0px 28px;

    span {
      display: inline-block;
      font-family: $mb;
      font-size: 32px;
      padding: 3px 0;
      letter-spacing: -0.02em;
      color: #1da1f2;
    }
  }
}

@media (max-width: 1400px) {
  .sc-buy-course {
    overflow: hidden;
  }
}

@media (max-width: 1200px) {
  .sc-buy-course {
  }
}

@media (max-width: 992px) {
  .sc-buy-course {
    h4 {
      font-size: 30px;
      line-height: 1.2;
    }

    .buy {
      margin-top: 0;
    }

    .inner-cost span {
      font-size: 20px !important;
    }
  }
}

@media (max-width: 768px) {
  .sc-buy-course {
    // margin: 0 -17px;
    padding: 30px 0;

    h4 {
      font-size: 25px;
    }
  }
}

// Подарок
.sc-prise {
  padding-top: 48px;
  padding-bottom: 48px;

  .prise-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background: #ffffff;
    border: 3px solid #00af07;
    border-radius: 24px;
    position: relative;
    overflow: hidden;
    padding-top: 31px;
    padding-left: 56px;
    padding-right: 24px;
  }

  .text-box {
    max-width: 526px;
    padding-top: 8px;
    position: relative;

    p {
      max-width: 405px;
      color: #8c8e97;
      margin-top: 20px;
    }
  }

  .img-box {
    flex-shrink: 0;

    img {
      max-width: 100%;
    }
  }
}

@media (max-width: 1400px) {
  .sc-prise {
    overflow: hidden;
  }
}

@media (max-width: 1200px) {
  .sc-prise {
    .prise-box {
      padding-bottom: 190px;
      flex-direction: column;
      z-index: 1;
    }

    .img-box {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: -1;
    }

    .text-box {
      max-width: inherit;
    }
  }
}

@media (max-width: 992px) {
  .sc-prise {
    .img-box {
      position: static;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
    }
    .prise-box {
      padding: 25px;
    }
  }
}

@media (max-width: 768px) {
  .btn-box {
    flex-direction: column;
    align-items: flex-start !important;
    justify-content: flex-start !important;
    margin-bottom: -15px;
    .btn {
      width: 100% !important;
      margin-top: 15px;

      &:last-child {
        margin-bottom: 15px;
      }
    }
  }
}

.step_3 {
  display: flex;
  justify-content: center;
  flex-direction: row !important;
  margin-bottom: 10px !important;
}

.modal-title,
.modal-content .form-control,
.modal-content label,
.modal-content p,
.modal-content button,
.modal-content .courses-box {
  font-family: 'Montserrat-Regular';
}

.modal-title,
.modal-content .form-control,
.modal-content label,
.modal-content p,
.modal-content button,
.modal-content .courses-box,
.modal-content span,
.modal-content input {
  font-family: 'Montserrat-Regular';
}
</style>
