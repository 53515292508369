<template>
  <section class="sc-homework">
    <div class="row" v-if="courses.length">
      <div class="col-12">
        <div class="row">
          <div class="col-6 custom--my">
            <h3 class="choose-course">{{ $t('data.129') }}</h3>
          </div>
          <div class="col-6 custom--my text-right">
            <router-link
              :to="{ name: 'homework-list' }"
              href="#"
              class="all-tasks"
              >{{ $t('data.109') }}
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L9 8.72L2 16"
                  stroke="#1DA1F2"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div
            class="col-lg-6 custom--my"
            v-for="course in courses"
            :key="course.index"
          >
            <div class="task-box">
              <router-link
                :to="{
                  name: 'homework-list',
                  params: {
                    id: course.id
                  }
                }"
                class="task-link"
                target="blank"
              ></router-link>
              <div class="task-info">
                <div class="text-info">
                  <h2>{{ course.lesson }}</h2>
                  <!-- <p class="failed-task">
                    <span class="failed-t-num">{{
                      course.failedTaskNumber
                    }}</span>
                    {{ course.failedTask }}
                  </p>
                  <p class="new-task">
                    <span class="new-t-num">{{ course.newTaskNumber }}</span>
                    {{ course.newTask }}
                  </p> -->
                </div>
              </div>
              <!-- <div class="task-img"><img :src="course.picture" alt="" /></div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="dataLoaded && !courses.length">
      <div class="task-box">
        <empty-list v-if="dataLoaded && !courses.length">{{
          $t('data.57')
        }}</empty-list>
        <!-- <pagination /> -->
      </div>
    </template>
    <!-- <table-data :fiedls="fiedls" :items="[['', '']]" /> -->
    <!-- <empty-list v-if="dataLoaded && !courses.length"
      >У вас еще нет ни одного домашнего задания</empty-list
    > -->
  </section>
</template>
<script>
import Api from '@/api/endpoints'

export default {
  name: 'HomeTask',
  data() {
    return {
      dataLoaded: false,
      imgs: [
        require('@/assets/img/hand-pen.jpg'),
        require('@/assets/img/hand-lamp.jpg'),
        require('@/assets/img/hand-hush.jpg'),
        require('@/assets/img/hand-flag.jpg')
      ],
      courses: [],
    }
  },
  async beforeMount() {
    this.dataLoaded = false
    let r = await Api.getCoursesOfUser()
    this.courses = r.data.map((c, i) => {
      c.lesson = c.name
      c.picture = this.imgs[(i + 1) % 4]
      return c
    })
    this.dataLoaded = true
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.custom--my {
  margin: 12px 0;
}

h1 {
  margin-bottom: 25px;
  a {
    color: #191d2f;
    text-decoration: none;
    &:hover {
      svg {
        transform: translateX(-10px) rotate(180deg);
      }
    }
  }
  svg {
    margin-right: 10px;
    margin-left: 0;
    transition: 0.2s;
    transform: rotate(180deg);
  }
}

// .task-box {
//   display: flex;
//   width: 100%;
//   background: #ffffff;
//   border: 1px solid #f0f0f0;
//   border-radius: 24px;
//   height: 100%;
//   padding: 5px 0;
//   justify-content: space-between;
// }

.task-info {
  display: flex;
  padding: 23px 0 35px 31px;

  h2 {
    margin-bottom: 40px;
  }
  p {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
    margin: 23px 0;
    position: relative;
    padding-left: 40px;
    span {
      width: 27px;
      height: 27px;
      border-radius: 100%;
      position: absolute;
      top: -5px;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      &:before {
        content: '';
        position: absolute;
        width: 31px;
        height: 31px;
        border-radius: 100%;
      }
    }
  }
}

.task-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.failed-t-num {
  background: #f7b6b6;
  &:before {
    border: 0.807018px dashed #e40a0a;
  }
}

.new-t-num {
  background: #ffe5b3;
  &:before {
    border: 0.807018px dashed #ffa800;
  }
}

.task-img {
  flex-shrink: 0;
  display: flex;
  align-self: center;
  img {
    max-width: 100%;
  }
}

.choose-course {
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #8c8e97;
}

.all-tasks {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  transition: 0.2s;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  svg {
    width: 7px;
    transition: 0.2s;
    margin-left: 8px;
  }
  &:hover {
    opacity: 0.5;
    svg {
      transform: translateX(5px);
    }
  }
}

.task-box {
  display: inline-block;
  width: 100%;
  margin-top: 8px;
}

.task-head {
  display: grid;
  width: 100%;
  grid-template-columns: 177px 172px 180px 196px auto;
  background: #f4f4f5;
  border-radius: 4px;
  margin-bottom: 4px;
  li {
    padding-left: 33px;
    padding-right: 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
  }
}

.task-info {
  display: inline-block;
  margin: 4px 0;
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  ul {
    display: grid;
    min-height: 80px;
    align-items: center;
    width: 100%;
    grid-template-columns: 177px 172px 180px 196px auto;
    li {
      padding-left: 33px;
      padding-right: 15px;
      padding-top: 15px;
      padding-bottom: 15px;
      white-space: nowrap;
      position: relative;
      overflow: hidden;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 40px;
        height: 100%;
        background-image: linear-gradient(to left, #fff, transparent);
      }
    }
  }
}

ul {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .choose-course{
    display: none !important;
  }

  .text-right{
    width: 100%;
  }
}
</style>
