<template>
  <div class="referal-program">
    <div class="container">
      <title-nav>{{ $t('data.325') }}</title-nav>
      <div class="row">
        <div class="col-sm-4 col-12 mb-2 mb-sm-0">
          <div class="data-block blue">
            <h2>{{ $t('data.324')}}</h2>
            <p
              class="text link"
              @click="
                mixinCopyToClipBoard(
                  `https://xacademy.uz/app/#/auth/register?referal_code=${referalLink.user_code}`
                )
              "
            >
              {{ $t('data.323')}}
              <img
                src="../../../../../../assets/svg/copy_icon_white.svg"
                alt="copy"
              />
            </p>
          </div>
        </div>
        <div class="col-sm-4 col-12 mb-2 mb-sm-0">
          <div class="data-block money">
            <h2>{{ $t('data.289')}}</h2>
            <div class="content">
              <p>
                {{ xpAndInvites ? xpAndInvites.total_points : 0 }}
                <span>XP</span>
              </p>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-12 mb-2 mb-sm-0">
          <div class="data-block money">
            <h2>{{ $t('data.292')}}</h2>
            <div class="content">
              <p>
                {{ xpAndInvites ? xpAndInvites.invited_amount : 0 }}
                <span>{{ $t('data.293')}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row info-table">
        <b-table responsive borderless  :items="items" :fields="fields" v-if='xpAndInvites'>
          <template #cell(id)="">
            <div class='info-cell data-cell' c:>
              <div class="text">От XP</div>
              <div class="text">До XP</div>
            </div>
          </template>
          <template #cell()="data" >
            <div class="data-cell " :class='{"current": xpAndInvites.total_points > data.value.from && xpAndInvites.total_points <= data.value.to } '>
              <div class="text text-range">{{ data.value.from }}</div>
              <!-- space -->
              <div class="text text-range">{{ data.value.to }}</div>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import TableData from '@/components/UI/table-data/TableData'
import { mapActions, mapGetters } from 'vuex'
import i18n from '@/i18n'

export default {
  data() {
    return {
      fields: [
        { key: 'id', label: ' ' },
        {
          key: 'a',
          label: i18n.t('data.294'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'b',
          label: i18n.t('data.295'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'c',
          label: i18n.t('data.296'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'd',
          label: i18n.t('data.297'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'e',
          label: i18n.t('data.298'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'f',
          label: i18n.t('data.299'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'g',
          label: i18n.t('data.300'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'h',
          label: i18n.t('data.301'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'i',
          label: i18n.t('data.302'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'j',
          label: i18n.t('data.303'),
          class: 'text-center text-nowrap '
        },
        {
          key: 'k',
          label: i18n.t('data.304'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'l',
          label: i18n.t('data.305'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'm',
          label: i18n.t('data.306'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'n',
          label: i18n.t('data.307'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'o',
          label: i18n.t('data.308'),
          class: 'text-center text-nowrap'
        },
        {
          key: 'p',
          label: i18n.t('data.309'),
          class: 'text-center text-nowrap'
        }
      ],
      items: [
        {
          id: '',
          a: { from: 0, to: 1000 },
          b: { from: 1000, to: 4000 },
          c: { from: 4000, to: 10000 },
          d: { from: 10000, to: 16000 },
          e: { from: 16000, to: 25000 },
          f: { from: 25000, to: 40000 },
          g: { from: 40000, to: 60000 },
          h: { from: 60000, to: 85000 },
          i: { from: 85000, to: 110000 },
          j: { from: 110000, to: 180000 },
          k: { from: 180000, to: 220000 },
          l: { from: 220000, to: 260000 },
          m: { from: 260000, to: 300000 },
          n: { from: 30000, to: 350000 },
          o: { from: 350000, to: 400000 },
          p: { from: 400000, to: 450000 }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['referalLink', 'xpAndInvites'])
  },
  methods: {
    ...mapActions(['getReferal', 'getXpAndInvites'])
  },
  mounted() {
    this.getReferal()
    this.getXpAndInvites()
  },
  components: { TableData }
}
</script>

<style>
.info-table tr {
  display: block;
}
.info-table table {
  overflow-x: auto !important;
}
.info-table tbody {
  width: initial !important;
}
</style>

<style lang="scss" scoped>
.referal-program {
  margin-top: 30px;

  .header-table {
    display: none;
    align-items: center;
    overflow: auto;
    span {
      width: 200px;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    // div{
    //   background: #FFA800;
    //   border-radius: 50%;
    //   width: 70px;
    //   height: 70px;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   span{
    //     font-weight: 700;
    //     font-size: 24px;
    //   }
    // }
    p {
      font-size: 48px !important;
      margin: 0;
      font-weight: 700;
      display: flex;
      align-items: baseline;
      span {
        font-weight: 400;
        font-size: 18px;
        margin-left: 10px;
        color: #191d2f;
      }
    }
  }

  .link {
    color: #fff;
    cursor: pointer;
    margin-bottom: 0;
    margin-top: 20px;
  }

  .data-block {
    border: 1px solid #ccc;
    border-radius: 12px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.blue {
      background: #1da1f2;

      h2 {
        color: #fff;
        font-size: 24px;
      }
    }

    &.money {
      background: rgba(142, 208, 249, 0.1);
      border: 1px solid #1da1f2;
      height: 100%;

      .subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        img {
          margin-left: 10px;
        }

        &.bonus {
          margin-top: 20px;
        }
      }
    }
  }
}
.info-table {
  margin-top: 60px !important;
  p {
    margin-top: 24px !important;
  }
}
.info-cell {
  width: 60px !important;
  border: 0 !important;
  color: grey;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 114% */

  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.02em;
}
.data-cell {
  width: 140px;
  height: 84px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .text {
    margin: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.02em;
  }
}
.borderless table {
  border-top-style: none;
  border-left-style: none;
  border-right-style: none;
  border-bottom-style: none;
  .table > .rowgroup > .row {
    display: flex !important;
    justify-content: center !important;
  }
  table::-webkit-scrollbar {
    height: 5px !important;
  }
}

.text-range {
  color: #191d2f !important;
}
.current {
  background: #1da1f2;
  color: white !important;
  div {
    color: white !important;
  }
}
@media (max-width: 1200px) {
  .data-block {
    padding: 15px;
  }
  h2 {
    font-size: 20px !important;
  }
}
.my-table {
}
</style>
