<template>
  <div class="file-msg">
    <div class="file-msg__content">
      <div class="file-msg__content__icon">
        <img
          class="attach"
          v-if="computedProps.hasOwnProperty('image_url')"
          :src="computedPreview"
          alt="file upload logo"
          @click="showImg(chatDomain + computedProps.image_url)"
        />
        <a v-else target="_blank" :href="chatDomain + computedProps.title_link">
          <img
            class="attach"
            :src="require('@/assets/img/file.png')"
            alt="file upload logo"
          />
        </a>
      </div>
      <div class="file-msg__content_info">
        <div class="file-msg__content_info__name">
          {{ computedProps.title }}
        </div>
        <div class="file-msg__content_info__footer">
          <div class="file-msg__content_info__footer__download">
            <a :href="computedDownloadLink">
              <svg
                width="12"
                height="13"
                viewBox="0 0 12 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 11.6667H12V13H0V11.6667ZM6.66667 7.78133L10.714 3.73333L11.6567 4.67599L6 10.3333L0.343333 4.67666L1.286 3.73333L5.33333 7.77999V0.333328H6.66667V7.78133Z"
                  fill="#1DA1F2"
                />
              </svg>
            </a>
          </div>
          <div class="file-msg__content_info__footer__info">
            {{ computedFileType }},
          </div>
          <div
            class="file-msg__content_info__footer__info"
            v-show="computedProps.hasOwnProperty('image_size')"
          >
            {{ bytesToSize(computedProps.image_size) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/components/chat/eventBus'

export default {
  name: 'file-msg',
  data: function () {
    return {
      chatDomain: process.env.CHAT_DOMAIN || 'https://rocket-xacademy.axiomica.io'
    }
  },
  methods: {
    //convert bytes to mb
    bytesToSize: function (bytes) {
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
      if (bytes === 0) return '0 Byte'
      const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
      return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i]
    },
    showImg(src) {
      EventBus.$emit('show-img-modal', src)
    }
  },
  computed: {
    // Base 64 for preview
    computedPreview() {
      const file = this.$props.attachment
      if (file) {
        return `data:${file.image_type}; base64, ${file.image_preview}`
      } else {
        return ''
      }
    },
    computedDownloadLink() {
      const file = this.$props.attachment
      if (file) {
        return `https://rocket.xacademy.uz${file.title_link}`
      } else {
        return ''
      }
    },
    computedFileType() {
      const file = this.$props.attachment
      return file.hasOwnProperty('image_type') ? 'IMAGE' : 'FILE'
    },
    computedProps() {
      return this.$props.attachment
    }
  },
  components: {},
  props: {
    attachment: {
      required: true
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.file-msg {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.file-msg__content {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
}
.file-msg__content__icon {
  width: 50px;
  height: 40px;
  border-radius: 5px;
  overflow: hidden;
}
.file-msg__content__icon img {
  width: 100%;
  height: 100%;
}
.file-msg__content_info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-left: 25px;
}
.file-msg__content_info__name {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #191d2f;
  margin-bottom: 10px;
}
.file-msg__content_info__footer {
  display: flex;
  align-items: baseline;
  font-size: 14px;
  color: #999;
  gap: 5px;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #191d2f;
}
.file-msg__content_info__footer__download {
  transition: 0.3s all;
  margin-right: 5px;
  &:hover {
    transform: scale(1.1);
  }
}
.file-msg__content_info__footer__info {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height, or 129% */
  letter-spacing: -0.02em;
  /* black */
  color: #191d2f;
  opacity: 0.3;
}

@media (max-width: 768px) {
  .file-msg__content_info {
    word-wrap: break-word;
    max-width: 130px;
  }
}
.attach {
  cursor: pointer;
}
</style>
