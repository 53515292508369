<template>
  <div class="auth-wrapper">
    <Header class="header" :show-logo="true"/>
    <div class="sc-forms">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// Components
import Header from '@/components/base/header/Header.vue'

export default {
  name: 'AuthTemplate',
  components: {
    Header
  }
}
</script>

<style scoped></style>

<style lang="scss">
@import './src/styles/vars.scss';

.auth-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  .header {
    padding-right: 40px;
    padding-left: 40px;
  }
}

.sc-forms {
  flex: 1 1 auto;
  background-color: #e9f6fe;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
  min-height: calc(100vh - 79px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .form-tab-navs {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;

    .active {
      background: #1da1f2;
      color: #fff;
      pointer-events: none;
      position: relative;
      z-index: 1;
    }

    a {
      font-family: $mb;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: -0.02em;
      color: #191d2f;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 13px;
      padding: 16px 32px;
      text-decoration: none;
      transition: 0.2s;
      margin: 0 -8px;

      &:hover {
        background: #1da0f294;
        color: #fff;
      }
    }
  }

  .form-box {
    margin: 20px auto;
    max-width: 439px;
    width: 100%;
    padding: 40px 56px;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(29, 160, 242, 0.1);
    border-radius: 12px;
  }

  .field-box {
    margin: 8px 0;
    display: inline-block;
    width: 100%;
    position: relative;

    p {
      font-family: $mr;
      font-size: 12px;
      letter-spacing: -0.02em;
      color: #191d2f;
      margin-bottom: 5px;
      padding-left: 16px;

      span {
        color: red;
      }
    }

    input {
      height: 48px;
      width: 100%;
      background: #ffffff;
      border: 1px solid #f0f0f0;
      border-radius: 13px;
      padding: 0 16px;
      font-family: $mr;
      font-size: 12px;
      color: #8c8e97;
      outline: none;
    }
  }

  .politics {
    padding-left: 30px;
    position: relative;
    margin: 12px 0;
    cursor: pointer;
    user-select: none;

    input {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: -1;
    }

    input[type='checkbox']:checked + p {
      &:before {
        background-color: #1da1f2;
        border-color: #1da1f2;
        background-image: url(../../assets/svg/checked.svg);
        background-size: 11px 8px;
        background-position: center center;
        background-repeat: no-repeat;
      }
    }

    p {
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #191d2f;
      position: relative;
      margin-bottom: 0;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: -30px;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        border: solid 1px rgba(25, 29, 47, 0.596);
      }

      a {
        color: #1da1f2;
        font-family: $mb;
      }
    }
  }

  .submit-box {
    margin-top: 15px;
    display: flex;
    justify-content: center;

    a,
    button {
      margin: 4px 4px;
    }
  }

  .code-timer {
    font-family: $mr;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #8c8e97;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 14px;
    margin: auto;
    display: flex;
    align-items: center;
    z-index: 1;

    button {
      padding: 0;
    }

    img {
      margin-left: 10px;
    }

    button:disabled {
      cursor: auto;
    }
  }

  .forget-pass {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #1da1f2;
    transition: 0.2s;
    margin-top: 20px;
    display: inline-block;

    &:hover {
      opacity: 0.5;
      text-decoration: none;
    }
  }

  .code-sended-msg {
    font-family: $mr;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #8c8e97;
    margin: 15px 0;
  }
}
@media (max-width: 768px){
  .form-box{
    padding: 40px 30px!important;
  }
}
</style>
