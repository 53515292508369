<template>
  <section class="sc-lessons-bud">
    <div class="container">
      <div class="row">
        <!-- // TODO: Разобраться с отоброжение кнопки ДЗ -->
        <!-- <div class="col-lg-6 text-right p-0">
          <a href="#" class="primary--button">Домашнее задание</a>
        </div> -->
        <div class="lesson-starts">
          <div class="d-flex justify-content-center">
            <counter
              class="counter"
              :current-time="timeToLesson"
              :isLesson="true"
            />
          </div>
          <div class="date-info">
            <span class="main--text"
              ><img src="@/assets/svg/cal_icon.svg" alt="" />
              {{ this.dateLesson }}</span
            >
            <span class="main--text"
              ><img src="@/assets/svg/time_icon.svg" alt="" />
              {{ this.timeLesson }}</span
            >
          </div>
        </div>
        <alert
          :text="$t('data.287')"
          type="warn"
          :show-close="false"
        >
          <router-link class="do-homework" :to="{ name: 'home' }">{{
            $t('vypolnit-domashnee-zadanie')
          }}</router-link></alert
        >
      </div>
    </div>
  </section>
</template>

<script>
import Counter from '@/components/UI/question/Timer/Counter'
import Alert from '@/components/UI/alerts/Alert'
import moment from 'moment'

import Api from '@/api/endpoints'
import dateFormat from 'dateformat'

export default {
  name: 'LessonBud',
  data() {
    return {
      timeToLesson: Infinity,
      dateLesson: String,
      timeLesson: String,
      timerId: false,
      lesson: {},
      DateLessonTime: new Date()
    }
  },
  methods: {
    startTimer() {
      if (!this.lesson) return
      this.timeToLesson = Math.floor(
        (+this.DateLessonTime - +new Date()) / 1000
      )
      this.timerId = setInterval(() => {
        this.redirectToLessonIfLessonStart()
        this.timeToLesson--
      }, 1000)
    },
    setDateAndTime() {
      let d = this.DateLessonTime
      this.dateLesson = dateFormat(d, 'yyyy.mm.dd')
      this.timeLesson = dateFormat(d, 'HH:MM')
      console.log(this.DateLessonTime)
    },
    async setLesson() {
      let r = await Api.getLesson(this.$route.params.id)
      if (!r.id) return
      this.lesson = r
      return true
    },
    redirectToLessonIfLessonStart() {
      let tenMinutes = 600
      if (tenMinutes > this.timeToLesson) {
        this.$router.push({
          name: 'lesson-online',
          params: {
            id: this.$route.params.id
          }
        })
        clearInterval(this.timerId)
      }
    }
  },
  components: {
    Counter,
    Alert
  },
  async beforeMount() {
    await this.setLesson()
    this.DateLessonTime = moment(this.lesson.start_time).valueOf()
    this.redirectToLessonIfLessonStart()
    this.startTimer()
    this.setDateAndTime()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.lesson-starts {
  margin-top: 10px;
  display: inline-block;
  width: 100%;
  background: #f8f8f8;
  border-radius: 12px;
  text-align: center;
  padding: 23px 50px 19px 50px;
  margin-bottom: 8px;
  h4 {
    padding: 7px 0;
  }
}

.date-info {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 12px 0;
  span {
    display: flex;
    align-items: center;
    margin: 0 12px;
    img {
      margin-right: 10px;
    }
  }
}

.tip-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 24px 12px 32px;
  background: #ddf1fd;
  border-radius: 12px;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px 24px 10px 32px;
  h4 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
    }
  }
}

.do-homework {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    color: #8ed0f9;
  }
}
</style>
