<template>
  <div class="msg-templ vue-component" :class="{ 'msg-templ--me': msg.itsMe }">
    <img
      class="msg-templ__avatar"
      src="../../../assets/svg/user.svg"
    />
    <div class="msg-templ__content msg-content">
      <div v-show="!msg.itsMe" class="msg-content__header">
        <span class="msg-content__name">{{ msg.u.name }}</span>
      </div>
      <div class="msg-content__body">
        <text-msg
          class="msg-content__msg"
          v-if="isText && msg.msg.length >= 1"
          :text="msg.msg"
        />
        <file-msg
          class="msg-content__msg"
          v-else-if="msg.hasOwnProperty('file')"
          :attachment="msg.attachments[0]"
        />
        <div class="msg-content__time">{{ time }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import FileMsg from './FileMsg.vue'
import TextMsg from './TextMsg'

export default {
  name: 'msgTempl',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    isText() {
      return this.type === 'text'
    },
    time() {
      let date = new Date(this.msg.ts.$date)
      return (
        String(date.getHours()).padStart(2, '0') +
        ':' +
        String(date.getMinutes()).padStart(2, '0')
      )
    }
  },
  components: {
    TextMsg,
    FileMsg
  },
  props: {
    type: {
      default: 'text'
    },
    msg: {
      required: true,
      type: Object
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.msg-templ {
  display: flex;
  align-items: flex-end;
  &__avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
  }
  &--me {
    // flex-direction: row-reverse;
    // .msg-templ__avatar {
    //   margin-right: 0;
    //   margin-left: 8px;
    // }
    .msg-content {
      background: #8ed0f9;
      &__msg {
        color: #191d2f;
      }
    }
  }
}
.msg-content {
  display: flex;
  max-width: 448px;
  flex-direction: column;
  padding: 16px 24px;
  background: #f0f0f0;
  border-radius: 12px;
  &__header {
    font-size: 12px;
    margin-bottom: 8px;
    text-transform: capitalize;
    color: #000305;
    font-weight: bold;
  }
  &__body {
    display: flex;
    align-items: flex-end;
  }
  &__msg {
    margin-right: 28px;
  }
  &__time {
    font-size: 10px;
    line-height: 18px;
    color: #191d2f;
  }
}
</style>
