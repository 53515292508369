<template>
  <div class="dtm-test vue-component">
    <div v-if="testsDataDtm.tests.length === 1" class="test-finish">
      <!-- <circular-timer
        @click.native="finish"
        class="circular"
        :all-time="1"
        :current-time="0"
      ></circular-timer> -->
    </div>
    <tests-wrapper
      :title="testsDataDtm.info.title"
      :subtitle="testsDataDtm.info.subtitle"
      :tests="testsDataDtm.tests"
      :all-time="10800"
      :test-id="'dtm'"
      :hard-mode="testsDataDtm.info.type === 'not-free'"
      @finish="onFinish"
    >
      <template v-slot:footer v-if="testsDataDtm.tests.length === 1">
        <div class="d-flex align-items-center">
          <div
            class="secondary--button more-btn"
            style="cursor: pointer"
            @click="addMoreQuestions(testsDataDtm.tests[0].subjectId)"
          >
            Показать еще {{ moreQuestionsNum }} вопросов
          </div>
        </div>
      </template>
    </tests-wrapper>
  </div>
</template>

<script>
import TestsWrapper from '@/components/UI/question/TestsWrapper'
import CircularTimer from '@/components/UI/question/Timer/CircularTimer'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import Api from '@/api/endpoints'

export default {
  name: 'dtm-test',
  data: function () {
    return {
      moreQuestionsNum: 10,
      data: {}
    }
  },
  methods: {
    ...mapActions(['regenerateTests']),
    ...mapMutations(['clearDtmResults', 'setAwaitTest', 'uppendQuestions']),
    onFinish() {
      this.$router.push({
        name: 'dtm-result'
      })
    },
    finish() {
      this.$emit('finishTest')
    },
    async addMoreQuestions(subjectId) {
      let r = await Api.generateDtm(subjectId, this.moreQuestionsNum)
      this.uppendQuestions({
        testGroupId: 'dtm',
        testId: 0,
        questions: r.data.dtm
      })
    }
  },
  computed: {
    ...mapGetters(['testsData', 'awaitTest']),
    testsDataDtm() {
      return this.testsData?.dtm || []
    },
    timeTotesting() {
      // На каждый вопрос по две минуты или 120 секунд
      return (
        this.testsDataDtm.tests.reduce(
          (p, c) => (p += c.test.length * 120),
          0
        ) || 900
      )
    }
  },
  components: {
    TestsWrapper,
    CircularTimer
  },
  props: [],
  mixins: {},
  beforeMount() {
    // Если мы не ждем от серврера теста и у нас нет сохранненого теста,
    // то переходим на страницу создания дтм теста
    if (!this.awaitTest && !window.objectStore?.testsData?.dtm)
      return this.$router.push({ name: 'dtm' })
    this.setAwaitTest(false)
  }
}
</script>

<style scoped="scoped" lang="scss">
.more-btn {
  margin-right: 48px;
}
.end-text {
  margin-left: 13px;
  cursor: pointer;
}
.circular {
  cursor: pointer;
}
.dtm-test {
  position: relative;
}
.test-finish {
  display: flex;
  align-items: center;
  position: fixed;
  right: 50px;
  top: 130px;
}
</style>
