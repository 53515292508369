<template>
  <h1 class="title vue-component"><slot /></h1>
</template>

<script>
export default {
  name: 'x-title',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
h1 {
  margin-bottom: 8px;
  cursor: pointer;
  color: #191d2f;
  text-decoration: none;
  display: flex;
}
</style>
