export default {
  104: {
    name: 'Математика',
    banner: {
      title: 'ДТМ на 90+',
      subtitle:
        'Подготовьтесь к экзаменам на высший балл даже тогда, когда времени почти не осталось!'
    },
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/guy.png')
      }
    },
    teacher: {
      name: 'Бабур Сотволдыев',
      post: 'Преподаватель по профильной математике',
      title: 'Свыше 3-ёх лет готовлю учеников к экзаменам ДТМ на высокие баллы',
      descr:
        'Студент 4-ого курса Ферганского Государственного Университета по направлению “Математика”, куда поступил с первого раза на бюджет. Свыше 3-ёх лет готовлю учеников к экзаменам ДТМ на высокие баллы. За все время преподавания выпустил свыше 160 учеников, 98% учеников сдали предмет “математика” на отлично, а поступить смогли свыше 88% учеников. А еще я обладатель кубка Узбекистана по Тхеквандо, очень люблю спорт, да и вам советую. Со мной выучить математику и быть готовым к экзамену будет легко и просто! ',
      list: [
        'Финалист студенческих олимпиад',
        '98 процентов учеников сдали предмет математика на отлично',
        'Участник Yet another Conference on Education от Яндекса'
      ],
      img: require('@/assets/mini-landing-img/girl.png')
    },
    programm: [
      [
        {
          date: '4 октября',
          text: 'Вычисление отрицательных чисел'
        },
        {
          date: '6 октября',
          text: 'Признаки делимости'
        },
        {
          date: '8 октября',
          text:
            'Разложение на простые множители, число натуральных делителей, сумма натуральных делителей, взаимно простые числа'
        },
        {
          date: '11 октября',
          text: 'НОД и НОК'
        },
        {
          date: '13 октября',
          text: 'Дроби (переход), сокращение'
        },
        {
          date: '15 октября',
          text: 'Сложение и вычитание дробей (обыкновенные и смешанные дроби)'
        },
        {
          date: '18 октября',
          text: 'Произведение и деление дробей (обыкновенные и смешанные дроби)'
        },
        {
          date: '20 октября',
          text: 'Десятичные дроби и период'
        },
        {
          date: '22 октября',
          text: 'Линейное уравнение'
        },
        {
          date: '25 октября',
          text: 'Пропорция и задачи на пропорцию'
        },
        {
          date: '27 октября',
          text: 'Система линейных уравнений (первый и второй способы)'
        },
        {
          date: '29 октября',
          text:
            'Неравенство и двойное неравенство; наименьшее или наибольшее целое; натуральное и простое число'
        }
      ],
      [
        {
          date: '1 ноября',
          text: 'Одночлены , многочлены'
        },
        {
          date: '3 ноября',
          text: 'Формулы сокращённого умножения'
        },
        {
          date: '5 ноября',
          text: 'Разложение многочлена на множители'
        },
        {
          date: '8 ноября',
          text: 'Рациональные выражения дробей'
        },
        {
          date: '10 ноября',
          text: 'Арифметические выражения'
        },
        {
          date: '12 ноября',
          text: 'Иррациональные выражения'
        },
        {
          date: '15 ноября',
          text: 'Корни высокого порядка'
        },
        {
          date: '17 ноября',
          text: 'Линейные уравнения. Пропорция'
        },
        {
          date: '19 ноября',
          text: 'Квадратные уравнения. Теорема Виета'
        },
        {
          date: '22 ноября',
          text: 'Рациоанальные уравнения'
        },
        {
          date: '24 ноября',
          text: 'Параметрические линейные уравнения'
        },
        {
          date: '26 ноября',
          text: 'Параметрические квадратные уравнения'
        },
        {
          date: '29 ноября',
          text: 'Система линейных уравнений'
        }
      ],
      [
        {
          date: '1 декабря',
          text: 'Уравнения высокого порядка'
        },
        {
          date: '3 декабря',
          text: 'Система параметрических уравнений'
        },
        {
          date: '6 декабря',
          text: 'Линейные неравенства. Система линейных неравенств'
        },
        {
          date: '8 декабря',
          text: 'Рациональные уравнения.Метод интервалов'
        },
        {
          date: '10 декабря',
          text: 'Параметрические неравенства'
        },
        {
          date: '13 декабря',
          text: 'Модульные выражения'
        },
        {
          date: '15 декабря',
          text: 'Модульные уравнения'
        },
        {
          date: '17 декабря',
          text: 'Модульные неравенства'
        },
        {
          date: '20 декабря',
          text: 'Система модульных уравнений и неравенств'
        },
        {
          date: '22 декабря',
          text: 'Иррациональные уравнения'
        },
        {
          date: '24 декабря',
          text: 'Иррациональные неравенства'
        },
        {
          date: '27 декабря',
          text: 'Арифметическая прогрессия'
        },
        {
          date: '29 декабря',
          text: 'Геометрическая прогрессия'
        }
      ],
      [
        {
          date: '10 января',
          text: 'Бесконечно убывающая геометрическая прогрессия'
        },
        {
          date: '12 января',
          text: 'Текстовые задачи'
        },
        {
          date: '14 января',
          text: 'Средне арифметическое и среднее геометрическое значение'
        },
        {
          date: '17 января',
          text: 'Процент, задачи на проценты'
        },
        {
          date: '19 января',
          text: 'Задачи на движение'
        },
        {
          date: '21 января',
          text: 'Задачи на работу'
        },
        {
          date: '24 января',
          text: 'Функции'
        },
        {
          date: '26 января',
          text: 'Линейная функция'
        },
        {
          date: '28 января',
          text: 'Квадратичная функция'
        },
        {
          date: '31 января',
          text: 'Функции другого типа'
        }
      ],
      [
        {
          date: '2 февраля',
          text: 'Обратная функция'
        },
        {
          date: '4 февраля',
          text: 'Показательная функция'
        },
        {
          date: '7 февраля',
          text: 'Показательные уравнения, система показательных уравнений'
        },
        {
          date: '9 февраля',
          text: 'Показательные неравенства'
        },
        {
          date: '11 февраля',
          text: 'Логарифмическая функция'
        },
        {
          date: '14 февраля',
          text: 'Упрощение логарифмических выражений'
        },
        {
          date: '16 февраля',
          text: 'Логарифмические уравнения и неравенства'
        },
        {
          date: '18 февраля',
          text: 'Начальные понятия тригонометрии'
        },
        {
          date: '21 февраля',
          text: 'Основные тригонометрические тождества'
        },
        {
          date: '23 февраля',
          text: 'Формула приведения'
        },
        {
          date: '25 февраля',
          text: 'Сумма и разность углов'
        },
        {
          date: '28 февраля',
          text: 'Формула двойного угла'
        }
      ],
      [
        {
          date: '2 март',
          text: 'Смешанный раздел'
        },
        {
          date: '4 март',
          text: 'Производная'
        },
        {
          date: '7 март',
          text: 'Производная сложной функции'
        },
        {
          date: '9 март',
          text: 'Возрастание и Убывание функций'
        },
        {
          date: '11 март',
          text: 'Экстремумы функций'
        },
        {
          date: '14 март',
          text: 'Максимум и минимум функций'
        },
        {
          date: '16 март',
          text: 'Геометрический смысл производной'
        },
        {
          date: '18 март',
          text: 'Механический смысл производной'
        },
        {
          date: '21 март',
          text: 'Первообразная'
        },
        {
          date: '23 март',
          text: 'Определенный и неопределенный интеграл'
        },
        {
          date: '25 март',
          text: 'Площадь криволинейной трапеции'
        },
        {
          date: '28 март',
          text: 'Угол. Отрезок . Параллельные линии'
        },
        {
          date: '30 март',
          text: 'Треугольник. Углы треугольника. Виды треугольника'
        }
      ],
      [
        {
          date: '1 апрель',
          text: 'Высота, Медиана, биссектриса и средняя линия в треугольнике'
        },
        {
          date: '4 апрель',
          text: 'Прямоугольный треугольник'
        },
        {
          date: '6 апрель',
          text: 'Теорема синусов и косинусов'
        },
        {
          date: '8 апрель',
          text: 'Свойство высоты треугольника'
        },
        {
          date: '11 апрель',
          text: 'Свойства медианы треугольника'
        },
        {
          date: '13 апрель',
          text: 'Свойство биссектрисы треугольника'
        },
        {
          date: '15 апрель',
          text: 'Площадь Треугольника'
        },
        {
          date: '18 апрель',
          text: 'Подобие треугольника'
        },
        {
          date: '20 апрель',
          text: 'Четырехугольник'
        },
        {
          date: '22 апрель',
          text: 'Прямоугольник и квадрат'
        },
        {
          date: '25 апрель',
          text: 'Параллелограмм , Ромб'
        },
        {
          date: '27 апрель',
          text: 'Трапеция'
        },
        {
          date: '29 апрель',
          text: 'Многоугольник'
        }
      ],
      [
        {
          date: '2 май',
          text: 'Окружность и его элементы'
        },
        {
          date: '4 май',
          text: 'Вписанный угол, и Центральный угол'
        },
        {
          date: '6 май',
          text: 'Пересекающиеся хорды, касаемая и секущая'
        },
        {
          date: '9 май',
          text: 'Круг'
        },
        {
          date: '11 май',
          text: 'Уравнения окружности'
        },
        {
          date: '13 май',
          text: 'Вписанный и описанная окружность в треугольнике'
        },
        {
          date: '16 май',
          text: 'Четырехугольник и окружность'
        },
        {
          date: '18 май',
          text: 'Трапеция и Окружность'
        },
        {
          date: '20 май',
          text: 'Многоугольник и Окружность'
        },
        {
          date: '23 май',
          text: 'Отрезки в пространстве. Плоскость.'
        },
        {
          date: '25 май',
          text: 'Куб и Паралеллипипед'
        },
        {
          date: '27 май',
          text: 'Призма'
        },
        {
          date: '30 май',
          text: 'Пирамиды. Объем пирамиды'
        }
      ],
      [
        {
          date: '1 июнь',
          text: 'Усечённая пирамида'
        },
        {
          date: '3 июнь',
          text: 'Цилиндр и Конус'
        },
        {
          date: '6 июнь',
          text: 'Усечённый конус'
        },
        {
          date: '8 июнь',
          text: 'Шар и сфера'
        },
        {
          date: '10 июнь',
          text: 'Призма и Шар'
        },
        {
          date: '13 июнь',
          text: 'Пирамида и Шар'
        },
        {
          date: '15 июнь',
          text: 'Цилиндр и Шар'
        },
        {
          date: '17 июнь',
          text: 'Конус и Шар'
        },
        {
          date: '20 июнь',
          text: 'Смешанный раздел'
        },
        {
          date: '22 июнь',
          text: 'Декартовая система координат'
        },
        {
          date: '24 июнь',
          text: 'Вектор. Координаты вектора'
        },
        {
          date: '27 июнь',
          text: 'Длина вектора'
        },
        {
          date: '29 июнь',
          text: 'Скалярное произведения векторов'
        }
      ],
      [
        {
          date: '2 июль',
          text: 'Колленеарность векторов'
        },
        {
          date: '4 июль',
          text: 'Угол между векторами'
        },
        {
          date: '6 июль',
          text: 'Нестандартные задачи'
        },
        {
          date: '11 июль',
          text: 'Комбинаторика'
        },
        {
          date: '13 июль',
          text: 'Теория вероятности'
        },
        {
          date: '15 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '18 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '20 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '22 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '25 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '27 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '29 июль',
          text: 'Повторение пройденного материала'
        },
        {
          date: '29 июль',
          text: 'Повторение пройденного материала'
        }
      ]
    ]
  },
  4: {
    name: 'Русский язык и Литература',
    banner: {
      title: 'ДТМ на 90+',
      subtitle:
        'Подготовьтесь к экзаменам на высший балл даже тогда, когда времени почти не осталось!'
    },
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/ru-hoo.png')
      }
    },
    teacher: {
      name: 'Евгений Ким',
      post: 'Преподаватель по русскому языку и литературе',
      title:
        'Свыше 15 лет готовлю учеников к экзаменам ДТМ по русскому языку и литературе на высокие баллы и за мою карьеру я выпустил свыше 550 учеников в оффлайне!',
      descr:
        'Являюсь победителем республиканского конкурса "Лучший учитель предметник" 2015 года. Обладаю званием "Отличник Народного Образования". Посетил не одну сотню конференций на тему образования. 99% моих учеников поступили в университеты. Со мной выучить предмет и быть готовым к экзамену будет легко и просто! ',
      list: [
        'Победитель конкурса «Лучший учитель предметник» 2015 года',
        'Звание «Отличник Народного Образования»',
        '99% учеников поступили в университеты'
      ],
      img: require('@/assets/img/tamila.png')
    },
    programm: [
      [
        {
          date: '4 октября',
          text: 'Обобщающее повторение основных разделов науки о языке.'
        },
        {
          date: '6 октября',
          text: 'Самостоятельные части речи.'
        },
        {
          date: '8 октября',
          text: 'Служебные части речи'
        },
        {
          date: '11 октября',
          text: 'Теория литературы. Роды и жанры литературы'
        },
        {
          date: '13 октября',
          text:
            'Грамматические (морфологические) нормы. Морфологические нормы имен прилагательных'
        },
        {
          date: '15 октября',
          text: 'Морфологические нормы местоимений'
        },
        {
          date: '18 октября',
          text: 'Морфологические нормы имен числительных'
        },
        {
          date: '20 октября',
          text: 'Литературные направления'
        },
        {
          date: '22 октября',
          text: 'Морфологические нормы причастий'
        },
        {
          date: '25 октября',
          text: 'Морфологические нормы деепричастий'
        },
        {
          date: '27 октября',
          text: 'Морфологические нормы наречий'
        },
        {
          date: '29 октября',
          text: 'Тропы. Стилистические фигуры (приёмы).'
        }
      ],
      [
        {
          date: '1 ноября',
          text: 'Имя числительное'
        },
        {
          date: '3 ноября',
          text: 'Правописание имён числительных'
        },
        {
          date: '5 ноября',
          text: 'Литература первой половины 19века: Грибоедов «Горе от ума»'
        },
        {
          date: '8 ноября',
          text: 'Местоимение. Правописание местоимений'
        },
        {
          date: '10 ноября',
          text:
            'Пушкин(биография) Лирика(Во глубине сибирских руд, Вновь я посетил)'
        },
        {
          date: '12 ноября',
          text: 'Творчество А.С. Пушкина'
        },
        {
          date: '15 ноября',
          text: 'Глагол'
        },
        {
          date: '17 ноября',
          text: 'Правописание глагола'
        },
        {
          date: '19 ноября',
          text: 'Личные окончания глагола'
        },
        {
          date: '22 ноября',
          text: 'Причастие'
        },
        {
          date: '24 ноября',
          text: 'Деепричастие'
        },
        {
          date: '26 ноября',
          text: 'Лермонтов(биография) Герой нашего времени, Мцыри, Лирика'
        },
        {
          date: '29 ноября',
          text: 'Наречие'
        }
      ],
      [
        {
          date: '1 декабря',
          text: 'Слова категории состояния'
        },
        {
          date: '3 декабря',
          text:
            'Гоголь(биография) Тарас Бульба, Нос, Ревизор, Шинель, Вечера на хуторе, Миргород'
        },
        {
          date: '6 декабря',
          text: 'Предлог. Союз'
        },
        {
          date: '8 декабря',
          text: 'Частицы. Междометия и звукоподражающие слова'
        },
        {
          date: '10 декабря',
          text: 'Гончаров'
        },
        {
          date: '13 декабря',
          text: 'Правописание служебных частей речи'
        },
        {
          date: '15 декабря',
          text: 'Островский'
        },
        {
          date: '17 декабря',
          text: 'Тургенев'
        },
        {
          date: '20 декабря',
          text: 'Словообразование'
        },
        {
          date: '22 декабря',
          text: 'Лексикология'
        },
        {
          date: '24 декабря',
          text: 'Тургенев(продолжение)'
        },
        {
          date: '27 декабря',
          text: 'Фразеология'
        },
        {
          date: '29 декабря',
          text: 'Салтыков-Щедрин'
        }
      ],
      [
        {
          date: '10 января',
          text: 'Графика и орфоэпия'
        },
        {
          date: '12 января',
          text: 'Достоевский биография'
        },
        {
          date: '14 января',
          text: 'Достоевский творчество'
        },
        {
          date: '17 января',
          text: 'Способы словообразования'
        },
        {
          date: '19 января',
          text: 'Словообразовательный разбор'
        },
        {
          date: '21 января',
          text: 'Преступление и наказание'
        },
        {
          date: '24 января',
          text: 'Лексическое значение слова'
        },
        {
          date: '26 января',
          text: 'Устаревшие слова'
        },
        {
          date: '28 января',
          text: 'Толстой биография'
        },
        {
          date: '31 января',
          text: 'Фразеологические обороты'
        }
      ],
      [
        {
          date: '2 февраля',
          text: 'Толстой творчество'
        },
        {
          date: '4 февраля',
          text: 'Война и мир'
        },
        {
          date: '7 февраля',
          text: 'Принципы русской орфографии'
        },
        {
          date: '9 февраля',
          text: 'Правописание глассных и согласных в корне слова'
        },
        {
          date: '11 февраля',
          text: 'Чехов биография'
        },
        {
          date: '14 февраля',
          text: 'Правописание глассных с чередованием в корне'
        },
        {
          date: '16 февраля',
          text: 'Правописание пристаовок'
        },
        {
          date: '18 февраля',
          text: 'Чехов творчество'
        },
        {
          date: '21 февраля',
          text: 'Правописание суффиксов'
        },
        {
          date: '23 февраля',
          text:
            'Падежные окончания имён существительных и прилагательных(причастий)'
        },
        {
          date: '25 февраля',
          text: 'Н.А.Некрасов'
        },
        {
          date: '28 февраля',
          text: 'Литературная критика 19 -го века'
        }
      ],
      [
        {
          date: '2 марта',
          text: 'Орфография'
        },
        {
          date: '4 марта',
          text: 'Поэты и писатели середины и второй половины 19 –го века'
        },
        {
          date: '7 марта',
          text: 'Правописание НЕ и НИ'
        },
        {
          date: '9 марта',
          text: 'Правописание Н и НН'
        },
        {
          date: '11 марта',
          text: 'И.А.Бунин'
        },
        {
          date: '14 марта',
          text: 'Правописание самостоятельных частей речи'
        },
        {
          date: '16 марта',
          text: 'Правописание служебных частей речи'
        },
        {
          date: '18 марта',
          text: 'А.И.Куприн'
        },
        {
          date: '21 марта',
          text: 'Синтаксис. Словосочетания'
        },
        {
          date: '23 марта',
          text: 'Простые предложения'
        },
        {
          date: '25 марта',
          text: 'Л.А.Андреев'
        },
        {
          date: '28 марта',
          text: 'Однородные члены предложения'
        },
        {
          date: '30 марта',
          text: 'А.М.Горький'
        }
      ],
      [
        {
          date: '1 апреля',
          text: 'Творчество Горького'
        },
        {
          date: '4 апреля',
          text: 'Главные члены предложения'
        },
        {
          date: '6 апреля',
          text: 'Сказуемое и его типы'
        },
        {
          date: '8 апреля',
          text: 'Поэзия «чистого искусства'
        },
        {
          date: '11 апреля',
          text: 'Второстепенные члены предложения'
        },
        {
          date: '13 апреля',
          text: 'Декаденты'
        },
        {
          date: '15 апреля',
          text: 'А.А.Блок'
        },
        {
          date: '18 апреля',
          text: 'Обособления и уточняющие части речи'
        },
        {
          date: '20 апреля',
          text: 'Н.С.Гумилёв'
        },
        {
          date: '22 апреля',
          text: 'В.В.Маяковский'
        },
        {
          date: '25 апреля',
          text: 'Вводные конструкции'
        },
        {
          date: '27 апреля',
          text: 'Прямая и косвенная речь'
        },
        {
          date: '29 апреля',
          text: 'С.А.Есенин'
        }
      ],
      [
        {
          date: '2 мая',
          text: 'Сложносочинённые предложения'
        },
        {
          date: '4 мая',
          text: 'Пунктуация в ССП'
        },
        {
          date: '6 мая',
          text: 'А.А.Ахматова'
        },
        {
          date: '9 мая',
          text: 'Сложноподчинённые предложения'
        },
        {
          date: '11 мая',
          text: 'М.И.Цветаева'
        },
        {
          date: '13 мая',
          text: 'Обобщающее повторение поэтов разных направлений'
        },
        {
          date: '16 мая',
          text: 'Виды придаточных предложений'
        },
        {
          date: '18 мая',
          text: 'Пунктуация в СПП'
        },
        {
          date: '20 мая',
          text: 'М.А.Булгаков'
        },
        {
          date: '23 мая',
          text: 'Бессоюзное предложение'
        },
        {
          date: '25 мая',
          text: 'Пунктуация в БСП'
        },
        {
          date: '27 мая',
          text: 'Мастер и Маргарита'
        },
        {
          date: '30 мая',
          text: 'Сложные предложения с различными видами связи'
        }
      ],
      [
        {
          date: '1 июня',
          text: 'Литература военных лет'
        },
        {
          date: '3 июня',
          text: 'А.Т.Твардовский'
        },
        {
          date: '6 июня',
          text: 'Пунктуация в предложениях с различными видами связи'
        },
        {
          date: '8 июня',
          text: 'В.П.Астафьев'
        },
        {
          date: '10 июня',
          text: 'В.Г.Распутин'
        },
        {
          date: '13 июня',
          text: 'Синтаксический разбор предложений'
        },
        {
          date: '15 июня',
          text: 'Ч.Айтматов'
        },
        {
          date: '17 июня',
          text: 'Творчество Айтматова'
        },
        {
          date: '20 июня',
          text: 'Cтилистика'
        },
        {
          date: '22 июня',
          text: 'А.Г.Алексин'
        },
        {
          date: '24 июня',
          text: '«Лагерная литература»'
        },
        {
          date: '27 июня',
          text: 'Культура речи'
        },
        {
          date: '29 июня',
          text: '	Поэты «шестидесятники»'
        }
      ],
      [
        {
          date: '1 июля',
          text: 'Барды'
        },
        {
          date: '4 июля',
          text: 'Определение частей речи'
        },
        {
          date: '6 июля',
          text: 'Литература 20 –го века'
        },
        {
          date: '11 июля',
          text: 'Орфограммы'
        },
        {
          date: '13 июля',
          text: 'Литература 20 –го века(продолжение)'
        },
        {
          date: '15 июля',
          text: 'Восточная литература'
        },
        {
          date: '18 июля',
          text: 'Характеристика предложения'
        },
        {
          date: '20 июля',
          text: 'Литература 14-15 –го веков'
        },
        {
          date: '22 июля',
          text: 'Литература 18-19 –го веков'
        },
        {
          date: '25 июля',
          text: 'Общие вопросы пунктуации'
        },
        {
          date: '27 июля',
          text: 'Узбекская литература 19-20 –го веков'
        },
        {
          date: '29 июля',
          text: 'Авторы мировой литературы'
        }
      ]
    ]
  },
  103: {
    name: 'Английский язык',
    banner: {
      title: 'ДТМ на 90+',
      subtitle:
        'Подготовьтесь к экзаменам на высший балл даже тогда, когда времени почти не осталось!'
    },
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/en-hoo.png')
      }
    },
    teacher: {
      name: 'Нигора Усмонова',
      post: 'Преподаватель по английскому языку и IELTS',
      title: 'Поступила на бюджет, хотя и не хотела поступать',
      descr:
        'На втором курсе обучение меня сильно увлекло и я начала изучать лингвистику, писать статьи. Преподаю английский и уже выпустила 165+ учеников, которые сдали на отлично различные экзамены от ДТМ до IELTS. Средний балл IELTS моих учеников 7.0',
      list: [
        'Подготовила 165 учеников к тестам по английскому',
        'Окончила школу на красный аттестат и получила по IELTS 8.0',
        'Публиковала статьи в международные журналы'
      ],
      img: require('@/assets/img/nigora.png')
    },
    programm: [
      [
        {
          date: '4 октября',
          text: 'Introduction to nouns '
        },
        {
          date: '6 октября',
          text: 'Gender'
        },
        {
          date: '8 октября',
          text: 'Cardinal numbers'
        },
        {
          date: '11 октября',
          text: 'Singular &amp; Plurals'
        },
        {
          date: '13 октября',
          text: 'Subject and Object pronouns'
        },
        {
          date: '15 октября',
          text: 'Verb <b>To be</b>'
        },
        {
          date: '18 октября',
          text: 'Prepositions of place'
        },
        {
          date: '20 октября',
          text: 'There is / are &amp; It'
        },
        {
          date: '22 октября',
          text: 'Demonstrative pronouns'
        },
        {
          date: '25 октября',
          text: 'Countable and Uncountable nouns'
        },
        {
          date: '27 октября',
          text: '<b>a/an and <b>the</b>'
        },
        {
          date: '29 октября',
          text: 'Collective nouns '
        }
      ],
      [
        {
          date: '1 ноября',
          text: 'Compound nouns'
        },
        {
          date: '3 ноября',
          text: 'Ordinal numbers'
        },
        {
          date: '5 ноября',
          text: 'Prepositions of time'
        },
        {
          date: '8 ноября',
          text: 'Past form of “To be”'
        },
        {
          date: '10 ноября',
          text: 'Reflexive pronouns'
        },
        {
          date: '12 ноября',
          text: 'Reciprocal pronouns'
        },
        {
          date: '15 ноября',
          text: 'Some &amp; Any'
        },
        {
          date: '17 ноября',
          text: 'Have got/has got'
        },
        {
          date: '19 ноября',
          text: 'Adjectives: Attributive adjectives'
        },
        {
          date: '22 ноября',
          text: 'Degrees of adjectives'
        },
        {
          date: '24 ноября',
          text: 'Irregular adjectives'
        },
        {
          date: '26 ноября',
          text: 'Possessive adjective &amp; Possessive pronouns'
        },
        {
          date: '29 ноября',
          text:
            'Possessive forms: Apostrophe <b>‘S</b> and preposition <b>Of</b>'
        }
      ],
      [
        {
          date: '1 декабря',
          text: 'Adverbs &amp; Their structures'
        },
        {
          date: '3 декабря',
          text: 'Degrees of adverbs'
        },
        {
          date: '6 декабря',
          text: 'Verb'
        },
        {
          date: '8 декабря',
          text: 'Modal verbs (1)'
        },
        {
          date: '10 декабря',
          text: 'Normal sentence structure'
        },
        {
          date: '13 декабря',
          text: 'Present Continuous'
        },
        {
          date: '15 декабря',
          text: 'Present Simple'
        },
        {
          date: '17 декабря',
          text: 'Regular &amp; Irregular verbs'
        },
        {
          date: '20 декабря',
          text: 'Past Simple Tense'
        },
        {
          date: '22 декабря',
          text: 'Past Continuous'
        },
        {
          date: '24 декабря',
          text:
            'Tense review (Present continuous | Present simple | Past simple)'
        },
        {
          date: '27 декабря',
          text: 'Future Simple'
        },
        {
          date: '29 декабря',
          text: 'Present for the future'
        }
      ],
      [
        {
          date: '10 января',
          text: 'Future Continuous'
        },
        {
          date: '12 января',
          text: 'Non-continuous verbs'
        },
        {
          date: '14 января',
          text: 'Tense review for future tenses'
        },
        {
          date: '17 января',
          text: 'To be going to'
        },
        {
          date: '19 января',
          text: 'Difference between <b>Have/Has got and Have/Has</b>'
        },
        {
          date: '21 января',
          text: 'Present perfect tense'
        },
        {
          date: '24 января',
          text: 'Present perfect or Past simple?'
        },
        {
          date: '26 января',
          text: 'Present perfect continuous'
        },
        {
          date: '28 января',
          text: 'Past perfect tense'
        },
        {
          date: '31 января',
          text: 'Past perfect continuous tense'
        }
      ],
      [
        {
          date: '2 февраля',
          text: 'Future perfect & Future perfect continuous tenses'
        },
        {
          date: '4 февраля',
          text: 'Prepositions of time: By | Until and By the time'
        },
        {
          date: '7 февраля',
          text: 'Tense review for perfect tenses'
        },
        {
          date: '9 февраля',
          text: 'Future in the past (Was/were going to)'
        },
        {
          date: '11 февраля',
          text: 'Used to | To be used to | Get used to'
        },
        {
          date: '14 февраля',
          text: 'Passive voice'
        },
        {
          date: '16 февраля',
          text: 'Must & Have to'
        },
        {
          date: '18 февраля',
          text: 'Must | Mustn’t | Needn’t and Needn’t have'
        },
        {
          date: '21 февраля',
          text: 'Could (do) & Could have (done) | Must (have) & Can’t (have)'
        },
        {
          date: '23 февраля',
          text: 'Passive forms of modal verbs'
        },
        {
          date: '25 февраля',
          text: 'Command | Advice | Request'
        },
        {
          date: '28 февраля',
          text: 'Additions to remarks'
        }
      ],
      [
        {
          date: '2 марта',
          text: 'Questions'
        },
        {
          date: '4 марта',
          text: 'Question tags'
        },
        {
          date: '7 марта',
          text: 'Embedded sentences'
        },
        {
          date: '9 марта',
          text: 'Have something done'
        },
        {
          date: '11 марта',
          text: 'Advanced indefinite articles'
        },
        {
          date: '14 марта',
          text: 'Another & Other | A number of & The number of'
        },
        {
          date: '16 марта',
          text: 'Advanced definite article THE'
        },
        {
          date: '18 марта',
          text: 'Nouns with and without “The”'
        },
        {
          date: '21 марта',
          text: 'Omission of The before certain words (work, home, office..)'
        },
        {
          date: '23 марта',
          text: 'Advanced plural nouns'
        },
        {
          date: '25 марта',
          text: 'On time & In time | At the end & In the end'
        },
        {
          date: '28 марта',
          text: 'Prepositions of place'
        },
        {
          date: '30 марта',
          text: 'But & except | By | Arrive at/in | Get |'
        }
      ],
      [
        {
          date: '1 апреля',
          text: 'Like & as'
        },
        {
          date: '4 апреля',
          text: 'As if & As though'
        },
        {
          date: '6 апреля',
          text: 'Some & any | No & none'
        },
        {
          date: '8 апреля',
          text: 'Each & every | All & whole'
        },
        {
          date: '11 апреля',
          text: 'Each, both & neither'
        },
        {
          date: '13 апреля',
          text: 'Noun + preposition (reason for, cause of..)ы'
        },
        {
          date: '15 апреля',
          text: 'Preposition + noun (by mistake, on television..)'
        },
        {
          date: '18 апреля',
          text: 'Advanced adjectives with their comparisons'
        },
        {
          date: '20 апреля',
          text: 'Adjectives ending in –ing and -ed'
        },
        {
          date: '22 апреля',
          text: 'Order of adjectives | Adjectives after verbs'
        },
        {
          date: '25 апреля',
          text: 'Adjective + preposition (excited about, pleased with..)'
        },
        {
          date: '27 апреля',
          text: 'Adjective + preposition | Sorry about & Sorry for..'
        },
        {
          date: '29 апреля',
          text: 'Verb + preposition ( Care about & Care for..)'
        }
      ],
      [
        {
          date: '2 мая',
          text: 'Verb + preposition (Hear about & Hear of..)'
        },
        {
          date: '4 мая',
          text: 'Verb + preposition (Shout at & Shout to..)'
        },
        {
          date: '6 мая',
          text: 'Verb + object + preposition (do something about..)'
        },
        {
          date: '9 мая',
          text: 'Position of adverbs'
        },
        {
          date: '11 мая',
          text: 'So & Such'
        },
        {
          date: '13 мая',
          text: 'Too & Enough'
        },
        {
          date: '16 мая',
          text: 'To + infinitive’ after adjectives'
        },
        {
          date: '18 мая',
          text: 'Quite & rather'
        },
        {
          date: '20 мая',
          text: 'Word order with adverbs'
        },
        {
          date: '23 мая',
          text: 'Reported speech'
        },
        {
          date: '25 мая',
          text: 'Questions in reported speech ( Wh and Yes/No questions)'
        },
        {
          date: '27 мая',
          text: 'Direct and indirect objects'
        },
        {
          date: '30 мая',
          text: 'Relative clauses: Who | Which | That'
        }
      ],
      [
        {
          date: '1 июня',
          text: 'Relative clauses: Sentences'
        },
        {
          date: '3 июня',
          text: 'Conditionals with if sentences'
        },
        {
          date: '6 июня',
          text: 'Expressing the wishes about the present/futur'
        },
        {
          date: '8 июня',
          text: 'Expressing the wishes about the past'
        },
        {
          date: '10 июня',
          text: 'In case'
        },
        {
          date: '13 июня',
          text: 'Conditionals with unless, as long as..'
        },
        {
          date: '15 июня',
          text: 'Gerunds'
        },
        {
          date: '17 июня',
          text: 'Infinitives'
        },
        {
          date: '20 июня',
          text: 'Литература	Verb + object + Infinitive'
        },
        {
          date: '22 июня',
          text: 'Gerund or Infinitive? (1) – Like, would like..'
        },
        {
          date: '24 июня',
          text:
            'Gerund or Infinitive? (2) – begin, start, intend, continue, try..'
        },
        {
          date: '27 июня',
          text: 'Gerund or Infinitive? (3) – Be afraid, need, help..'
        },
        {
          date: '29 июня',
          text: ' Preposition + Gerund '
        }
      ],
      [
        {
          date: '1 июля',
          text: 'Verb + Preposition + Gerund'
        },
        {
          date: '4 июля',
          text: 'Expressions + gerund'
        },
        {
          date: '6 июля',
          text: 'Prefer & Would rather'
        },
        {
          date: '11 июля',
          text: 'Had better do & It’s time someone did something'
        },
        {
          date: '13 июля',
          text: 'See somebody do & See somebody doing'
        },
        {
          date: '15 июля',
          text: 'Purpose: So that| In order to | To'
        },
        {
          date: '18 июля',
          text: 'Reason and Result: As | Since | As a result | Therefore'
        },
        {
          date: '20 июля',
          text:
            'Contrast: Although | Even though |In spite of | Despite| While | Whereas | Though..'
        },
        {
          date: '22 июля',
          text: 'Even| Even if'
        },
        {
          date: '25 июля',
          text: 'Phrasal verbs'
        },
        {
          date: '27 июля',
          text: 'Phrasal verbs'
        },
        {
          date: '29 июля',
          text: 'Краткое повторение изученного материала'
        }
      ]
    ]
  },
  105: {
    name: 'IELTS',
    banner: {
      title: 'ИКС Академия <br> запутило  IELTS',
      subtitle: ''
    },
    start_course_month: 1,
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/ru-hoo.png')
      }
    },
    teacher: {
      name: 'Нигора Усманова',
      post: 'Преподаватель по IELTS и Английскому языку',
      title: 'Сдала IELTS на 8.0',
      descr:
        'На втором курсе обучение меня сильно увлекло и я начала изучать лингвистику, писать статьи. Преподаю английский и уже выпустила 165+ учеников, которые сдали на отлично различные экзамены от ДТМ до IELTS. Средний балл IELTS моих учеников 7.0',
      list: [
        'Подготовила 165 учеников к тестам по английскому',
        'Окончила школу на красный аттестат и получила по IELTS 8.0',
        'Средний балл IELTS моих учеников 7.0'
      ],
      img: require('@/assets/mini-landing-img/tamila.png')
    },
    programm: [
      [
        {
          date: '25 января',
          text: 'Listening: Note Completion'
        },
        {
          date: '27 января',
          text: 'Reading: Summary Completion'
        },
        {
          date: '29 января',
          text: 'Writing: Bar Chart'
        }
      ],
      [
        {
          date: '1 февраля',
          text: 'Listening: Form Completion'
        },
        {
          date: '3 февраля',
          text: 'Reading: Sentence Completion'
        },
        {
          date: '5 февраля',
          text: 'Writing: Agree or Disagree'
        },
        {
          date: '8 февраля',
          text: 'Listening: Sentence Completion'
        },
        {
          date: '10 февраля',
          text: 'Reading: Table Completion'
        },
        {
          date: '12 февраля',
          text: 'Writing: Line Graph'
        },
        {
          date: '15 февраля',
          text: 'Listening: Table Completion'
        },
        {
          date: '17 февраля',
          text: 'Reading: Diagram Labelling'
        },
        {
          date: '19 февраля',
          text: 'Writing: Discussion'
        },
        {
          date: '22 февраля',
          text: 'Listening: Table Completion'
        },
        {
          date: '24 февраля',
          text: 'Reading: Short Answer'
        },
        {
          date: '26 февраля',
          text: 'Writing: Table Chart'
        }
      ],
      [
        {
          date: '1 марта',
          text: 'Listening: Summary Completion'
        },
        {
          date: '3 марта',
          text: 'Reading: Name Matching'
        },
        {
          date: '5 марта',
          text: 'Writing: Problem-Solution'
        },
        {
          date: '8 марта',
          text: 'Listening: Flowchart Completion'
        },
        {
          date: '10 марта',
          text: 'Reading: Matching Sentence Ending'
        },
        {
          date: '12 марта',
          text: 'Writing: Pie Chart'
        },
        {
          date: '15 марта',
          text: 'Listening: Short Answer'
        },
        {
          date: '17 марта',
          text: 'Reading: Multiple Choice'
        },
        {
          date: '19 марта',
          text: 'Writing: Advantage  Disadvantage'
        },
        {
          date: '22 марта',
          text: 'Listening: Matching'
        },
        {
          date: '24 марта',
          text: 'Reading: True  False  Not Given'
        },
        {
          date: '26 марта',
          text: 'Writing: Process Diagram'
        },
        {
          date: '29 марта',
          text: 'Listening: Multiple Choice'
        },
        {
          date: '31 марта',
          text: 'Reading: Yes  No  Not Given'
        }
      ],
      [
        {
          date: '2 апреля',
          text: 'Writing: Double Question'
        },
        {
          date: '5 апреля',
          text: 'Listening: Plan Labelling'
        },
        {
          date: '7 апреля',
          text: 'Reading: Matching Information to Paragraphs'
        },
        {
          date: '9 апреля',
          text: 'Writing: Map'
        },
        {
          date: '12 апреля',
          text: 'Listening: Map Labelling'
        },
        {
          date: '14 апреля',
          text: 'Reading: Matching Headings'
        },
        {
          date: '16 апреля',
          text: 'Writing: Multiple Graphs'
        },
        {
          date: '19 апреля',
          text: 'Listening: Diagram Labelling'
        },
        {
          date: '21 апреля',
          text: 'Reading: Matching Headings'
        },
        {
          date: '23 апреля',
          text: 'Writing: Comparison of Objects'
        }
      ]
    ]
  },
  3: {
    name: 'Matematika ',
    banner: {
      title: 'DTM 90+ga',
      subtitle:
        'Vaqtingiz deyarli tugab qolganida ham, yuqori ball uchun imtihonlarga tayyorlaning!'
    },
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/guy.png')
      }
    },
    teacher: {
      name: 'Xurshidbek Abdulboqiyev',
      post: "profilli matematika o'qituvchisi",
      title:
        "Fargʻona Davlat Universitetiga kirish imtihonida men matematika bo'yicha maksimal ball to'pladim.",
      descr:
        "Men 90 dan ortiq o'quvchilarni Offlayn rejimda DTMga tayyorladim va ularning barchasi matematika fanini mukammal darajada topshirishgan. Men bilan matematikani o'rganish va imtihonga tayyor bo'lish oson va sodda bo'ladi!",
      list: [
        "Matematika fanidan olimpiadalar g'olibi",
        'Oʻquvchilarimning 95 foizi grant asosida oʻqishga kirishgan',
        '3 yildan ortiq matematika fanidan dars beraman'
      ],
      img: require('@/assets/img/abdul.png')
    },
    programm: [
      [
        {
          date: '5 oktyabr',
          text: 'Manfiy sonlar va ular ustida amallar'
        },
        {
          date: '7 oktyabr',
          text: 'Bo’linish alomatlari'
        },
        {
          date: '9 oktyabr',
          text:
            'Tub ko’paytuvchilarga ajratish, sonning natural bo’luvchilari soni va yig’indisi'
        },
        {
          date: '12 oktyabr',
          text: 'EKUB va EKUK'
        },
        {
          date: '14 oktyabr',
          text: 'Kasr, aralash kasr va kasrlarni qisqartirish'
        },
        {
          date: '16 oktyabr',
          text: 'Kasrlarni qo’shish va ayirish (aralash kasr, oddiy kasr)'
        },
        {
          date: '19 oktyabr',
          text: 'Kasrlarni ko’paytrish va bo’lish(aralash kasr, oddiy kasr)'
        },
        {
          date: '21 oktyabr',
          text: 'O’nli kasrlar, davriy o’nli kasrlar'
        },
        {
          date: '23 oktyabr',
          text: 'Chiziqli tenglama'
        },
        {
          date: '26 oktyabr',
          text: 'Proporsiya va unga doir masalalar'
        },
        {
          date: '28 oktyabr',
          text: 'Chiziqli tenglamalar sistemasi'
        },
        {
          date: '30 oktyabr',
          text: 'Tengsizlik, qo’shtengsizlik'
        }
      ],
      [
        {
          date: '2 noyabr',
          text: 'Birhadlar,ko’phadlar'
        },
        {
          date: '4 noyabr',
          text: 'Qisqa ko’paytrish formulalarir'
        },
        {
          date: '6 noyabr',
          text: 'Ko’phadni ko’paytuvchilarga ajratish'
        },
        {
          date: '9 noyabr',
          text: 'Kasr ratsional ifodalar,ayniyatlar'
        },
        {
          date: '11 noyabr',
          text: 'Arifmetik ifodalar'
        },
        {
          date: '13 noyabr',
          text: 'Irratsional ifodalar'
        },
        {
          date: '16 noyabr',
          text: 'Yuqori darajali ildiz va ratsional ifoda qatnashgan ifodalar'
        },
        {
          date: '18 noyabr',
          text: 'Chiziqli tenglama.Proporsiya'
        },
        {
          date: '20 noyabr',
          text: 'Kvadrat tenglamalar,Viyet teoremasi'
        },
        {
          date: '23 noyabr',
          text: 'Ratsional tenglamalar'
        },
        {
          date: '25 noyabr',
          text: 'Parametrli chiziqli tenglamalar'
        },
        {
          date: '27 noyabr',
          text: 'Parametrli kvadrat tenglamalar'
        },
        {
          date: '30 noyabr',
          text: 'Chiziqli tenglamalar sistemasi'
        }
      ],
      [
        {
          date: '2 dekabr',
          text: '2- va undan yuqori darajali tenglamalar sistemasi'
        },
        {
          date: '4 dekabr',
          text: 'Parametrli tenglamalar sistemasi'
        },
        {
          date: '7 dekabr',
          text: 'Chiziqli tengsizliklar va ularning sistemasi'
        },
        {
          date: '9 dekabr',
          text: 'Kasr ratsional tengsizliklar.Oraliqlar usuli'
        },
        {
          date: '11 dekabr',
          text: 'Parametrli tengsizliklar'
        },
        {
          date: '14 dekabr',
          text: 'Modulli ifodalar'
        },
        {
          date: '16 dekabr',
          text: 'Modulli tenglamalar'
        },
        {
          date: '18 dekabr',
          text: 'Modulli tengsizliklar'
        },
        {
          date: '21 dekabr',
          text: 'Modulli tenglamalar va tengsizliklar sistemasi'
        },
        {
          date: '23 dekabr',
          text: 'Irratsional tenglamalar'
        },
        {
          date: '25 dekabr',
          text: 'Irratsional tengsizliklar'
        },
        {
          date: '28 dekabr',
          text: 'Arifmerik progressiya'
        },
        {
          date: '30 dekabr',
          text: 'Geometrik progressiya'
        }
      ],
      [
        {
          date: '11 yanvar',
          text: 'Cheksiz kamayuvchi geometrik progressiya'
        },
        {
          date: '13 yanvar',
          text: 'Hisoblashga doir masalalar'
        },
        {
          date: '15 yanvar',
          text: 'O‘rta arifmetik va o‘rta geometrik qiymat'
        },
        {
          date: '18 yanvar',
          text: 'Foiz va unga doir masalalar'
        },
        {
          date: '20 yanvar',
          text: 'Harakatga doir masalalar'
        },
        {
          date: '22 yanvar',
          text: 'Ishga doir masalalar'
        },
        {
          date: '25 yanvar',
          text: 'Funksiyalar'
        },
        {
          date: '27 yanvar',
          text: 'Chiziqli funksiya'
        },
        {
          date: '29 yanvar',
          text: 'Kvadrat funksiya'
        }
      ],
      [
        {
          date: '1 fevral',
          text: 'Boshqa funksiyalar.'
        },
        {
          date: '3 fevral',
          text: 'Teskari funksiya'
        },
        {
          date: '5 fevral',
          text: 'Ko‘rsatkichli funksiya'
        },
        {
          date: '8 fevral',
          text: 'Ko‘rsatkichli tenglama va ularning sistemasi'
        },
        {
          date: '10 fevral',
          text: 'Ko‘rsatkichli tengsizliklar.'
        },
        {
          date: '12 fevral',
          text: 'Logarifmik funksiya'
        },
        {
          date: '15 fevral',
          text: 'Logarifmik ifodalarni shakl almashtrish'
        },
        {
          date: '17 fevral',
          text: 'Logarifmik tenglamalar va tengsizliklar'
        },
        {
          date: '19 fevral',
          text: 'Trigonometriya. boshlang‘ich tushunchalar'
        },
        {
          date: '22 fevral',
          text: 'Trigonometriyaning asosiy ayniyatlari'
        },
        {
          date: '24 fevral',
          text: 'Ķeltrish formulalari'
        },
        {
          date: '26 fevral',
          text: 'Argumentlarni qo‘shish va ayirish formulalari'
        }
      ],
      [
        {
          date: '1 mart',
          text: 'Ikkilangan burchak formulalari.'
        },
        {
          date: '3 mart',
          text: 'Yig‘indi va ayirmani ko‘paytmaga keltrish formulalari'
        },
        {
          date: '5 mart',
          text: 'Darajani pasaytrish (yarim burchak) formulalari'
        },
        {
          date: '8 mart',
          text: 'Teskari trigonometrik fujksiyalar'
        },
        {
          date: '10 mart',
          text: 'Trigonometrik funksiyalarning xossalari'
        },
        {
          date: '12 mart',
          text: 'Trigonometrik tenglamalar'
        },
        {
          date: '15 mart',
          text: 'Trigonometrik tengsizliklar'
        },
        {
          date: '17 mart',
          text: 'Aralash bo‘lim'
        },
        {
          date: '19 mart',
          text: 'Hosila'
        },
        {
          date: '22 mart',
          text: 'Murakkab  funksiyaning hosilasi'
        },
        {
          date: '24 mart',
          text: 'Funksiyani o‘sish va kamayish oraliqlari'
        },
        {
          date: '26 mart',
          text: 'Funksiya ekstremumlari'
        },
        {
          date: '29 mart',
          text: 'Funksiyaning kesmadagi eng katta va eng kichik qiymati'
        },
        {
          date: '31 mart',
          text: 'Hosilaning geometrik ma‘nosi'
        }
      ],
      [
        {
          date: '2 aprel',
          text: 'Hosilaning mexanik ma‘nosi'
        },
        {
          date: '5 aprel',
          text: 'Boshlang‘ich funksiya.'
        },
        {
          date: '7 aprel',
          text: 'Aniqmas.Aniq integral'
        },
        {
          date: '9 aprel',
          text: 'Egri chiziqli trapetsiya yuzi'
        },
        {
          date: '12 aprel',
          text: 'Burchaklar.Masofa.Parallel to‘g‘ri chiziqlar'
        },
        {
          date: '14 aprel',
          text: 'Uchburchak,uning burchaklari va turlari'
        },
        {
          date: '16 aprel',
          text:
            'Uchburchak bisektrissasi,medianasi,balandligi va o‘rta chizig‘i'
        },
        {
          date: '19 aprel',
          text: 'To‘g‘ri burchakli uchburchak'
        },
        {
          date: '21 aprel',
          text: 'Sinuslar va kosinuslar teoremasi'
        },
        {
          date: '23 aprel',
          text: 'Uchburchak balandligini xossalari'
        },
        {
          date: '26 aprel',
          text: 'Uchburchak medianasining xossalari'
        },
        {
          date: '28 aprel',
          text: 'Uchburchak bisektrissasining xossalari'
        },
        {
          date: '30 aprel',
          text: 'Uchburchak yuzi'
        }
      ],
      [
        {
          date: '3 may',
          text: 'Uchburchaklar o‘xshashligi.'
        },
        {
          date: '5 may',
          text: 'To‘rtburchaklar'
        },
        {
          date: '7 may',
          text: 'To‘g‘ri to‘rtburchak,kvadrat'
        },
        {
          date: '10 may',
          text: 'Parallelogram, romb'
        },
        {
          date: '12 may',
          text: 'Trapetsiya'
        },
        {
          date: '14 may',
          text: 'Ko‘pburchaklar'
        },
        {
          date: '17 may',
          text: 'Aylana va uning elementlari'
        },
        {
          date: '19 may',
          text: 'Ichki chizilgan va markaziy burchaklar'
        },
        {
          date: '21 may',
          text: 'Kesishuvchi vatarlar.Urinma va kesuvchi'
        },
        {
          date: '24 may',
          text: 'Doira'
        },
        {
          date: '26 may',
          text: 'Aylana tenglamasi'
        },
        {
          date: '28 may',
          text: 'Uchburchakka ichki va tashqi chizilgan aylana'
        },
        {
          date: '31 may',
          text: 'To‘rtburchaklar va aylana'
        }
      ],
      [
        {
          date: '2 iyun',
          text: 'Trapetsiya va aylana'
        },
        {
          date: '4 iyun',
          text: 'Ko‘pburchak va aylana'
        },
        {
          date: '7 iyun',
          text: 'Fazoda to‘g‘ri chiziq va tekisliklar'
        },
        {
          date: '9 iyun',
          text: 'Kub, parallelopiped'
        },
        {
          date: '11 iyun',
          text: 'Prizma'
        },
        {
          date: '14 iyun',
          text: 'Piramida va uning hajmi'
        },
        {
          date: '16 iyun',
          text: 'Kesik piramida'
        },
        {
          date: '18 iyun',
          text: 'Slindr va konus'
        },
        {
          date: '21 iyun',
          text: 'Kesik konus'
        },
        {
          date: '23 iyun',
          text: 'Shar va sfera'
        },
        {
          date: '25 iyun',
          text: 'Prizma va shar'
        },
        {
          date: '28 iyun',
          text: 'Piramida va shar'
        },
        {
          date: '30 iyun',
          text: 'Slindr va shar'
        }
      ],
      [
        {
          date: '2 iyul',
          text: 'Konus va shar'
        },
        {
          date: '5 iyul',
          text: 'Aralash bo‘lim.'
        },
        {
          date: '7 iyul',
          text: 'Deekart koordinatalar sistemasi'
        },
        {
          date: '9 iyul',
          text: 'Vektor va uning koordinatalari'
        },
        {
          date: '12 iyul',
          text: 'Vektor uzunligi'
        },
        {
          date: '14 iyul',
          text: 'Vektorlarning skalyar ko‘paytmasi'
        },
        {
          date: '16 iyul',
          text: 'Vektorlarning kolleniarligi'
        },
        {
          date: '19 iyul',
          text: 'Ikki vektor orasidagi burchak'
        },
        {
          date: '21 iyul',
          text: 'Nostandart masalalar'
        },
        {
          date: '23 iyul',
          text: 'Kombinotorika elementlari'
        },
        {
          date: '26 iyul',
          text: 'Ehtimollar nazariyasi'
        },
        {
          date: '28 iyul',
          text: 'Mavzularni qaytarmoq'
        },
        {
          date: '30 iyul',
          text: 'Mavzularni qaytarmoq'
        }
      ]
    ]
  },
  7: {
    name: 'Ona tili va adabiyot',
    banner: {
      title: 'DTM 90+ga',
      subtitle:
        'Vaqtingiz deyarli tugab qolganida ham, yuqori ball uchun imtihonlarga tayyorlaning!'
    },
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/ru-hoo.png')
      }
    },
    teacher: {
      name: "Vosilxo'ja Nazirxo'jayev",
      post: "Ona tili va adabiyot o'qituvchisi",
      title:
        'Offlaynda repetitorlik ish faoliyatim davomida 135 dan ortiq oʻquvchilarga oʻqishga kirishda yordam berdim va ularning barchasi DTMni muvaffaqiyatli topshirishgan.',
      descr:
        "Siz monoton mavzulardan va zerikarli ma'ruzalardan charchaganingizni bilaman. Men nafaqat ona tili va adabiyotning barcha qoidalarini o'rganishga yordam beraman, balki bu jarayonni siz uchun samarali va qiziqarli boʻlishini kafolatlayman!",
      list: [
        "Ko'p sonli Olimpiada ishtirokchisi va sovrindori",
        "135 dan ortiq o'quvchilarni DTMga tayyorlaganman",
        "100% o'quvchilarim fanni mukammal topshirishgan"
      ],
      img: require('@/assets/img/nazir.png')
    },
    programm: [
      [
        {
          date: '5 oktyabr',
          text: "Til haqida umumiy ma'lumot"
        },
        {
          date: '7 oktyabr',
          text:
            "Til haqida umumiy ma'lumot 2. O'zbek adabiy tilining bo'limlari"
        },
        {
          date: '9 oktyabr',
          text: "Adabiyot so'z san'ati"
        },
        {
          date: '12 oktyabr',
          text: 'Fonetika'
        },
        {
          date: '14 oktyabr',
          text: 'Undoshlar tasnifi'
        },
        {
          date: '16 oktyabr',
          text: "Xalq o'g'zaki ijodi. \"Uch og'a-ini botirlar\" ertagi"
        },
        {
          date: '19 oktyabr',
          text: "Bo'g'in"
        },
        {
          date: '21 oktyabr',
          text: "Urg'u"
        },
        {
          date: '23 oktyabr',
          text: 'Ezop'
        },
        {
          date: '26 oktyabr',
          text: 'Fonetik hodisalar'
        },
        {
          date: '28 oktyabr',
          text: 'Orfografiya va orfoepiya'
        },
        {
          date: '30 oktyabr',
          text: 'Imom al buxoriy.'
        }
      ],
      [
        {
          date: '2 noyabr',
          text:
            "Grafika va leksikologiya. Bir ma'noli va ko'p ma'noli so'zlar. Ko'chma ma'no va uning turlari"
        },
        {
          date: '4 noyabr',
          text: "Ko'chma ma'no va uning turlari"
        },
        {
          date: '6 noyabr',
          text: 'Hamid Olimjonning hayoti va ijodi.'
        },
        {
          date: '9 noyabr',
          text:
            "So'zlarning shakl va ma'no munosabatiga ko'ra turlari. Omonimlar va sinonimlar"
        },
        {
          date: '11 noyabr',
          text: "Antonimlar va paronimlar. Eskirgan so'zlar"
        },
        {
          date: '13 noyabr',
          text: 'Muqimiyning hayoti va ijodi.'
        },
        {
          date: '16 noyabr',
          text: 'Tasviriy ifoda va iboralar (frazeologizmlar)'
        },
        {
          date: '18 noyabr',
          text: "Neologizmlar va atamalar. O'zbek tilining boyish manbalari"
        },
        {
          date: '20 noyabr',
          text: 'Sa’diy Sheroziyning hayoti va ijodi.'
        },
        {
          date: '23 noyabr',
          text: "Leksikografiya. morfologiyaga kirish. So'z tarkibi"
        },
        {
          date: '25 noyabr',
          text: "So'z tarkibi. Yetakchi morfema. Ko'makchi morfema"
        },
        {
          date: '27 noyabr',
          text: 'Abdulla Avloniyning hayoti va ijodi.'
        },
        {
          date: '30 noyabr',
          text: "Ko'makchi morfema"
        }
      ],
      [
        {
          date: '2 dekabr',
          text: "Ko'makchi morfema"
        },
        {
          date: '4 dekabr',
          text: 'E. Vohidovning hayoti va ijodi.'
        },
        {
          date: '7 dekabr',
          text: "So'z turkumlari. Ot so'z turkumi"
        },
        {
          date: '9 dekabr',
          text: "Ot so'z turkumi 2"
        },
        {
          date: '11 dekabr',
          text: 'Anvar Obidjon hayoti va ijodi.'
        },
        {
          date: '14 dekabr',
          text: "Ot so'z turkumi 3"
        },
        {
          date: '16 dekabr',
          text: "Ot so'z turkumi 4"
        },
        {
          date: '18 dekabr',
          text: 'Nodar Dumbadzening hayoti va ijodi.'
        },
        {
          date: '21 dekabr',
          text: 'Kelishiklar'
        },
        {
          date: '23 dekabr',
          text: "Egalik qo'shimchalari. Otlarning yasalishi"
        },
        {
          date: '25 dekabr',
          text: 'G‘afur G‘ulom.'
        },
        {
          date: '28 dekabr',
          text: 'Otlarning yasalishi 2'
        },
        {
          date: '30 dekabr',
          text: "Sifat so'z turkumi"
        }
      ],
      [
        {
          date: '11 yanvar',
          text: 'Turob To‘la.'
        },
        {
          date: '13 yanvar',
          text: "Sifatlarning lug'aviy ma'no turlari va yasalishi"
        },
        {
          date: '15 yanvar',
          text: "Son so'z turkumi"
        },
        {
          date: '18 yanvar',
          text: 'X.To‘xtaboev hayoti va ijodi.'
        },
        {
          date: '20 yanvar',
          text: "Son so'z turkumi 2"
        },
        {
          date: '22 yanvar',
          text: "Olmosh so'z turkumi"
        },
        {
          date: '25 yanvar',
          text: 'Abdulla Qodiriyning hayoti va ijodi.'
        },
        {
          date: '27 yanvar',
          text: "Olmosh so'z turkumi 2"
        },
        {
          date: '29 yanvar',
          text: "Olmosh so'z turkumi 3"
        }
      ],
      [
        {
          date: '1 fevral',
          text: 'Cho‘lponning hayoti va ijodi.'
        },
        {
          date: '3 fevral',
          text: "Fe'l so'z turkumi"
        },
        {
          date: '5 fevral',
          text: "Fe'l nisbatlari"
        },
        {
          date: '8 fevral',
          text: 'Zahiriddin Muhammad Bobur'
        },
        {
          date: '10 fevral',
          text: "Fe'lning shakillari"
        },
        {
          date: '12 fevral',
          text: "Fe'lning shakillari 2"
        },
        {
          date: '15 fevral',
          text: 'Lutfiy. Lirika'
        },
        {
          date: '17 fevral',
          text: "Fe'lning shakillari 3"
        },
        {
          date: '19 fevral',
          text: "Fe'lning shakillari 4"
        },
        {
          date: '22 fevral',
          text: 'Nodiraning hayoti va ijodi'
        },
        {
          date: '24 fevral',
          text: "Fe'lning yasalishi"
        },
        {
          date: '26 fevral',
          text: "Ravish so'z turkumi"
        }
      ],
      [
        {
          date: '1 mart',
          text: 'Zulfiyaning hayoti va ijodi'
        },
        {
          date: '3 mart',
          text: "Ravish so'z turkumi 2"
        },
        {
          date: '5 mart',
          text: 'Ravishlarning yasalishi'
        },
        {
          date: '8 mart',
          text: 'Muhammadsharif Gulxaniyning hayoti va ijodi.'
        },
        {
          date: '10 mart',
          text: "Yordamchi so'z turkumlari. Ko'makchi"
        },
        {
          date: '12 mart',
          text: "Bog'lovchi 1"
        },
        {
          date: '15 mart',
          text: 'Hamzaning hayoti va ijodi.'
        },
        {
          date: '17 mart',
          text: "Bog'lovchi 2"
        },
        {
          date: '19 mart',
          text: 'Yuklama'
        },
        {
          date: '22 mart',
          text: 'Zavqiyning hayoti va ijodi'
        },
        {
          date: '24 mart',
          text: "Undov modal va taqlid so'zlar"
        },
        {
          date: '26 mart',
          text: 'Sintaksis. Birikmalar'
        },
        {
          date: '29 mart',
          text: 'Alisher Navoiy. “Xamsa”'
        },
        {
          date: '31 mart',
          text: 'Birikma 2'
        }
      ],
      [
        {
          date: '2 aprel',
          text: 'Birikma 3'
        },
        {
          date: '5 aprel',
          text: 'Uvaysiyning hayoti va ijodi. Lirikasi'
        },
        {
          date: '7 aprel',
          text: 'Gap'
        },
        {
          date: '9 aprel',
          text: 'Gap2'
        },
        {
          date: '12 aprel',
          text: 'Anbar Otin.'
        },
        {
          date: '14 aprel',
          text: 'Gap3'
        },
        {
          date: '16 aprel',
          text: "Gap bo'laklari. Bosh bo'laklar. Kesim"
        },
        {
          date: '19 aprel',
          text: 'Saida Zunnunovaning hayoti va ijodi'
        },
        {
          date: '21 aprel',
          text: 'kesim 2'
        },
        {
          date: '23 aprel',
          text: 'Ega'
        },
        {
          date: '26 aprel',
          text: 'Maqsud Shayxzoda.'
        },
        {
          date: '28 aprel',
          text: "Ikkinchi darajali bo'laklar. Aniqlovchi"
        },
        {
          date: '30 aprel',
          text: "To'ldiruvchi "
        }
      ],
      [
        {
          date: '3 may',
          text: 'Turdi Farog‘iy lirikasi.'
        },
        {
          date: '5 may',
          text: 'Hol'
        },
        {
          date: '7 may',
          text: "Gapning uyushiq bo'laklari."
        },
        {
          date: '10 may',
          text: 'Ozod Sharafiddinov.'
        },
        {
          date: '12 may',
          text: "Gap bo'laklari bilan aloqaga kirishmaydigan bo'laklar"
        },
        {
          date: '14 may',
          text: "Gap bo'laklari bilan aloqaga kirishmaydigan bo'laklar 2"
        },
        {
          date: '17 may',
          text: 'Ahmad Yassaviy.'
        },
        {
          date: '19 may',
          text: "Qo'shma gap"
        },
        {
          date: '21 may',
          text: "Qo'shma gap 2"
        },
        {
          date: '24 may',
          text: 'O‘tkir Hoshimovning hayoti va ijodi.'
        },
        {
          date: '26 may',
          text: "Qo'shma gap 3"
        },
        {
          date: '28 may',
          text: "Qo'shma gap 4"
        },
        {
          date: '31 may',
          text: 'Maxtumqulining hayoti va ijodi.'
        }
      ],
      [
        {
          date: '2 iyun',
          text: "Qo'shma gap 5"
        },
        {
          date: '4 iyun',
          text: 'Tinish belgilari'
        },
        {
          date: '7 iyun',
          text: "Badiiy san'atlar va vaznlar."
        },
        {
          date: '9 iyun',
          text: 'Tinish belgilari 2'
        },
        {
          date: '11 iyun',
          text: 'Tinish belgilari 3'
        },
        {
          date: '14 iyun',
          text: 'Tog‘ay Murodning hayoti va ijodi'
        },
        {
          date: '16 iyun',
          text: 'Uslubiyat'
        },
        {
          date: '18 iyun',
          text: 'Uslubiyat 2'
        },
        {
          date: '21 iyun',
          text: 'Rasul Hamzatovning hayoti va ijodi.'
        },
        {
          date: '23 iyun',
          text: 'Uslubiyat 3'
        },
        {
          date: '25 iyun',
          text: 'Uslubiyat 4'
        },
        {
          date: '28 iyun',
          text: 'Ch. Aytmatovning hayoti va ijodi'
        },
        {
          date: '30 iyun',
          text: 'Ijodiy matn va uning turlari'
        }
      ],
      [
        {
          date: '2 iyul',
          text: 'X.To‘xtaboev hayoti va ijodi.'
        },
        {
          date: '5 iyul',
          text: 'Janni Rodari.'
        },
        {
          date: '7 iyul',
          text: 'Abdulla Qahhorning hayoti va ijodi.'
        },
        {
          date: '9 iyul',
          text: 'Odil Yoqubovning hayoti va ijodi.'
        },
        {
          date: '12 iyul',
          text: 'Said Ahmadning hayoti va ijodi.'
        },
        {
          date: '14 iyul',
          text: 'Musa Jalilning hayoti va ijodi.'
        },
        {
          date: '16 iyul',
          text: 'Avaz O‘tarning hayoti va ijodi'
        },
        {
          date: '19 iyul',
          text: 'Pirimqul Qodirovning hayoti va ijodi.'
        },
        {
          date: '21 iyul',
          text: 'Ibroyim Yusupovning hayoti va ijodi.'
        },
        {
          date: '23 iyul',
          text: 'Shuhratning hayoti va ijodi'
        },
        {
          date: '26 iyul',
          text: 'Asqad Muxtorning hayoti va ijodi'
        },
        {
          date: '28 iyul',
          text: 'Mirmuhsinning hayoti va ijodi.'
        },
        {
          date: '30 iyul',
          text: 'O‘lmas Umarbekovning hayoti va ijodi'
        }
      ]
    ]
  },
  8: {
    name: 'Ingliz tili',
    banner: {
      title: 'DTM 90+ga',
      subtitle:
        'Vaqtingiz deyarli tugab qolganida ham, yuqori ball uchun imtihonlarga tayyorlaning!'
    },
    imgs: {
      top: {
        path: require('@/assets/img/banner_bg.png')
      },
      hoo: {
        path: require('@/assets/mini-landing-img/en-hoo.png')
      }
    },
    teacher: {
      name: "Rustamjon Makhmudov",
      post: "Ingliz tili o'qituvchisi",
      title:
        "3 yil davomida offlayn rejimda DTMdan tortib, IELTSgacha bo'lgan turli imtihonlarni muvaffaqiyatli topshirishgan 100dan ortiq oʻqituvchilarni tayyorlaganman",
      descr:
        "Oson va tushunarli tilda tushuntiraman! Birgalikda ingliz tilining barcha qoidalarini noldan o'rganamiz va ingliz tili bilimlariga oid har qanday testni topshirishga tayyor boʻlamiz.",
      list: [
        "Ingliz tili fanidan maktab va universitet olimpiadalari g'olibi",
        "Ingliz tili boʻyicha turli testlarga 100dan ortiq o'quvchilarni tayyorladim",
        'IELTS band score 7.0'
      ],
      img: require('@/assets/img/nematul.png')
    },
    programm: [
      [
        {
          date: '5 oktyabr',
          text: 'Introduction to nouns – Otlar haqida umumiy tushuncha'
        },
        {
          date: '7 oktyabr',
          text: 'Gender – Jins'
        },
        {
          date: '9 oktyabr',
          text: 'Cardinal numbers'
        },
        {
          date: '12 oktyabr',
          text: 'Singular &amp; Plurals (1) – Ko’plik shakllari'
        },
        {
          date: '14 oktyabr',
          text: 'Subject and Object pronouns'
        },
        {
          date: '16 oktyabr',
          text: 'Verb To be – To be fe’li'
        },
        {
          date: '19 oktyabr',
          text: 'Prepositions of place (1)'
        },
        {
          date: '21 oktyabr',
          text: 'There is / are &amp; It'
        },
        {
          date: '23 oktyabr',
          text: 'Demonstrative pronouns'
        },
        {
          date: '26 oktyabr',
          text:
            'Countable and Uncountable nouns – Donalab sanaladigan va donalab sanalmaydigan otlar.'
        },
        {
          date: '28 oktyabr',
          text: 'a/an and the'
        },
        {
          date: '30 oktyabr',
          text: 'Collective nouns – Jamlanma otlar'
        }
      ],
      [
        {
          date: '2 noyabr',
          text: 'Compound nouns – Qo’shma otlar'
        },
        {
          date: '4 noyabr',
          text: 'Ordinal numbers – Tartib sonlar'
        },
        {
          date: '6 noyabr',
          text: 'Prepositions of time (1)'
        },
        {
          date: '9 noyabr',
          text: 'Past form of “To be”'
        },
        {
          date: '11 noyabr',
          text: 'Reflexive pronouns – O’zlik olmoshlari'
        },
        {
          date: '13 noyabr',
          text: 'Reciprocal pronouns – Ikki tomonlama olmoshlar'
        },
        {
          date: '16 noyabr',
          text: 'Some &amp; Any'
        },
        {
          date: '18 noyabr',
          text: 'Have got/ has got – Kimdadir nimadir bor..'
        },
        {
          date: '20 noyabr',
          text: 'Adjectives: Attributive adjectives'
        },
        {
          date: '23 noyabr',
          text: 'Degrees of adjectives – Sifatning darajalari'
        },
        {
          date: '25 noyabr',
          text: 'Irregular adjectives – Noto’g’ri sifatlar'
        },
        {
          date: '27 noyabr',
          text:
            'Possessive adjective &amp; Possessive pronouns – Sifatning egalik shakli va egalik olmoshlari.'
        },
        {
          date: '30 noyabr',
          text: 'Possessive forms: Apostrophe ‘S and preposition Of'
        }
      ],
      [
        {
          date: '2 dekabr',
          text:
            'Adverbs &amp; Their structures – Ravishlar va ularning yasalishi'
        },
        {
          date: '4 dekabr',
          text: 'Degrees of adverbs – Ravishning darajalari'
        },
        {
          date: '7 dekabr',
          text: 'Verb – Fe’l'
        },
        {
          date: '9 dekabr',
          text: 'Modal verbs (1)'
        },
        {
          date: '11 dekabr',
          text: 'Normal sentence structure'
        },
        {
          date: '14 dekabr',
          text: 'Present Continuous – Xozirgi davomli zamon'
        },
        {
          date: '16 dekabr',
          text: 'Present Simple – Xozirgi oddiy zamon'
        },
        {
          date: '18 dekabr',
          text: 'Regular &amp; Irregular verbs'
        },
        {
          date: '21 dekabr',
          text: 'Past Simple Tense – O’tgan zamon'
        },
        {
          date: '23 dekabr',
          text: 'Past Continuous – O’tgan davomli zamon'
        },
        {
          date: '25 dekabr',
          text:
            'Tense review (Present continuous | Present simple | Past simple)'
        },
        {
          date: '28 dekabr',
          text: 'Future Simple – Kelasi oddiy zamon'
        },
        {
          date: '30 dekabr',
          text: 'Present for the future'
        }
      ],
      [
        {
          date: '11 yanvar',
          text: 'Future Continuous – Kelasi davomli zamon'
        },
        {
          date: '13 yanvar',
          text: 'Non-continuous verbs – Nodavomiy fe’llar'
        },
        {
          date: '15 yanvar',
          text: 'Tense review for future tenses'
        },
        {
          date: '18 yanvar',
          text: 'To be going to'
        },
        {
          date: '20 yanvar',
          text: 'Difference between Have/Has got and Have/Has'
        },
        {
          date: '22 yanvar',
          text: 'Present perfect tense - Hozirgi tugallangan zamon'
        },
        {
          date: '25 yanvar',
          text: 'Present perfect or Past simple?'
        },
        {
          date: '27 yanvar',
          text: 'Present perfect continuous'
        },
        {
          date: '29 yanvar',
          text: 'Past perfect tense'
        }
      ],
      [
        {
          date: '1 fevral',
          text: 'Past perfect continuous tense'
        },
        {
          date: '3 fevral',
          text: 'Future perfect & Future perfect continuous tenses'
        },
        {
          date: '5 fevral',
          text: 'Prepositions of time: By | Until and By the time'
        },
        {
          date: '8 fevral',
          text: 'Tense review for perfect tenses'
        },
        {
          date: '10 fevral',
          text: 'Future in the past (Was/were going to)'
        },
        {
          date: '12 fevral',
          text: 'Used to | To be used to | Get used to'
        },
        {
          date: '15 fevral',
          text: 'Passive voice'
        },
        {
          date: '17 fevral',
          text: 'Must & Have to'
        },
        {
          date: '19 fevral',
          text: 'Must | Mustn’t | Needn’t and Needn’t have'
        },
        {
          date: '22 fevral',
          text: 'Could (do) & Could have (done) | Must (have) & Can’t (have)'
        },
        {
          date: '24 fevral',
          text: 'Passive forms of modal verbs'
        },
        {
          date: '26 fevral',
          text: 'Command | Advice | Request'
        }
      ],
      [
        {
          date: '1 mart',
          text: 'Additions to remarks'
        },
        {
          date: '3 mart',
          text: 'Questions'
        },
        {
          date: '5 mart',
          text: 'Question tags'
        },
        {
          date: '8 mart',
          text: 'Embedded sentences'
        },
        {
          date: '10 mart',
          text: 'Have something done'
        },
        {
          date: '12 mart',
          text: 'Advanced indefinite articles'
        },
        {
          date: '15 mart',
          text: 'Another & Other | A number of & The number of'
        },
        {
          date: '17 mart',
          text: 'Advanced definite article THE'
        },
        {
          date: '19 mart',
          text: 'Nouns with and without “The”'
        },
        {
          date: '22 mart',
          text: 'Omission of The before certain words (work, home, office..)'
        },
        {
          date: '24 mart',
          text: 'Advanced plural nouns'
        },
        {
          date: '26 mart',
          text: 'On time & In time | At the end & In the end'
        },
        {
          date: '29 mart',
          text: 'Prepositions of place'
        },
        {
          date: '31 mart',
          text: 'But & except | By | Arrive at/in | Get |'
        }
      ],
      [
        {
          date: '2 aprel',
          text: 'Like & as'
        },
        {
          date: '5 aprel',
          text: 'As if & As though'
        },
        {
          date: '7 aprel',
          text: 'Some & any | No & none'
        },
        {
          date: '9 aprel',
          text: 'Each & every | All & whole'
        },
        {
          date: '12 aprel',
          text: 'Each, both & neither'
        },
        {
          date: '14 aprel',
          text: 'Noun + preposition (reason for, cause of..)'
        },
        {
          date: '16 aprel',
          text: 'Preposition + noun (by mistake, on television..)'
        },
        {
          date: '19 aprel',
          text: 'Advanced adjectives with their comparisons'
        },
        {
          date: '21 aprel',
          text: 'Adjectives ending in –ing and -ed'
        },
        {
          date: '23 aprel',
          text: 'Order of adjectives | Adjectives after verbs'
        },
        {
          date: '26 aprel',
          text: 'Adjective + preposition (excited about, pleased with..)'
        },
        {
          date: '28 aprel',
          text: 'Adjective + preposition (2)| Sorry about & Sorry for..'
        },
        {
          date: '30 aprel',
          text: 'Verb + preposition ( Care about & Care for..)'
        }
      ],
      [
        {
          date: '3 may',
          text: 'Verb + preposition (Hear about & Hear of..)'
        },
        {
          date: '5 may',
          text: 'Verb + preposition (Shout at & Shout to..)'
        },
        {
          date: '7 may',
          text: 'Verb + object + preposition (do something about..)'
        },
        {
          date: '10 may',
          text: 'Position of adverbs'
        },
        {
          date: '12 may',
          text: 'So & Such'
        },
        {
          date: '14 may',
          text: 'Too & Enough'
        },
        {
          date: '17 may',
          text: 'To + infinitive’ after adjectives'
        },
        {
          date: '19 may',
          text: 'Quite & rather'
        },
        {
          date: '21 may',
          text: 'Word order with adverbs'
        },
        {
          date: '24 may',
          text: 'Reported speech'
        },
        {
          date: '26 may',
          text: 'Questions in reported speech ( Wh and Yes/No questions)'
        },
        {
          date: '28 may',
          text: 'Direct and indirect objects'
        },
        {
          date: '31 may',
          text: 'Relative clauses: Who | Which | That'
        }
      ],
      [
        {
          date: '2 iyun',
          text: 'Relative clauses: Sentences'
        },
        {
          date: '4 iyun',
          text: 'Conditionals with if sentences'
        },
        {
          date: '7 iyun',
          text: 'Expressing the wishes about the present/future'
        },
        {
          date: '9 iyun',
          text: 'Expressing the wishes about the past'
        },
        {
          date: '11 iyun',
          text: 'In case'
        },
        {
          date: '14 iyun',
          text: 'Conditionals with unless, as long as..'
        },
        {
          date: '16 iyun',
          text: 'Gerunds'
        },
        {
          date: '18 iyun',
          text: 'Infinitives'
        },
        {
          date: '21 iyun',
          text: 'Verb + object + Infinitive'
        },
        {
          date: '23 iyun',
          text: 'Gerund or Infinitive? (1) – Like, would like..'
        },
        {
          date: '25 iyun',
          text:
            'Gerund or Infinitive? (2) – begin, start, intend, continue, try..'
        },
        {
          date: '28 iyun',
          text: 'Gerund or Infinitive? (3) – Be afraid, need, help..'
        },
        {
          date: '30 iyun',
          text: 'Preposition + Gerund'
        }
      ],
      [
        {
          date: '2 iyul',
          text: 'Verb + Preposition + Gerund'
        },
        {
          date: '5 iyul',
          text: 'Expressions + gerund'
        },
        {
          date: '7 iyul',
          text: 'Prefer & Would rather'
        },
        {
          date: '9 iyul',
          text: 'Had better do & It’s time someone did something'
        },
        {
          date: '12 iyul',
          text: 'See somebody do & See somebody doing '
        },
        {
          date: '14 iyul',
          text: 'Purpose: So that| In order to | To'
        },
        {
          date: '16 iyul',
          text: 'Reason and Result: As | Since | As a result | Therefore'
        },
        {
          date: '19 iyul',
          text:
            'Contrast: Although | Even though |In spite of | Despite| While | Whereas | Though..'
        },
        {
          date: '21 iyul',
          text: 'Even| Even if'
        },
        {
          date: '23 iyul',
          text: 'Phrasal verbs'
        },
        {
          date: '26 iyul',
          text: 'Phrasal verbs'
        },
        {
          date: '28 iyul',
          text: 'Phrasal verbs'
        },
        {
          date: '30 iyul',
          text: 'Phrasal verbs'
        }
      ]
    ]
  }
}
