<template>
  <div class="lesson-table vue-component">
    <table-data :pagination="true" :isLessons="true" :fields="fields" :items="lessons" />
  </div>
</template>

<script>
import TableData from '@/components/UI/table-data/TableData'

export default {
  name: 'lesson-table',
  data: function () {
    return {
      fields: [
        { key: 'courseSubjectName', label: this.$t('data.52'), class: "is-title"},
        { key: 'date', label: this.$t('data.65'), class: "is-create" },
        { key: 'time', label: this.$t('vremya-0'), class: "is-time" },
        { key: 'theme', label: this.$t('data.53'), class: "is-theme" },
        { key: 'id', label: '', class: "is-button" }
      ]
    }
  },
  methods: {},
  computed: {},
  components: {
    TableData
  },
  props: {
    lessons: {
      type: Array,
      required: true
    }
  },
  mounted() {},
  mixins: {},
  beforeMount() {}
}
</script>

<style lang="scss">
.lesson-table {
  div,
  p,
  li,
  span,
  td,
  th,
  strong {
    font-family: 'Montserrat-Regular';
  }
}
</style>
