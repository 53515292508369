<template>
  <section class="sc-free-test-list">
    <div class="test-container">
      <div class="test-list-box">
        <ol v-if="!isSoloTest" class="quests">
          <!--          Проверяю что мы на стринице отчета по тестам и не в Свободном тестировании(а только дтм)-->
          <!--          если так то передаю объедененный массив, если нет то просто тесты-->
          <li
            v-for="(question, index) in isReportPage ? setResultData : test"
            :key="index"
          >
            <QuestionFactory
              :question="question"
              :index="index"
              @input="onchange"
              v-model="results[index]"
              :isReportPage="isReportPage"
              :isPreview="isPreview"
            />
          </li>
        </ol>
        <ol v-else class="quests">
          <!--          Проверяю что мы на стринице отчета по тестам и не в Свободном тестировании(а только дтм)-->
          <!--          если так то передаю объедененный массив, если нет то просто тесты-->
          <li
            v-for="(question, index) in isReportPage
              ? setResultDataAnswers
              : test"
            :key="index"
          >
            <QuestionFactory
              :question="question"
              :index="index"
              @input="onchange"
              v-model="results[index]"
              :isReportPage="isReportPage"
              :isPreview="isPreview"
              :testGroupID="answers.testGroupId"
              :testID="answers.id"
              @correct="toCorrect"
            />
          </li>
        </ol>
      </div>
    </div>
  </section>
</template>
<script>
import QuestionFactory from '@/components/UI/question/QuestionFactory'

export default {
  name: 'test',
  methods: {
    onchange() {
      this.$emit('change', this.results)
    },
    // setResultDataAnswers() {
    //   console.log('setResultDataAnswers')
    //   this.answerResults = this.answers.test.map((item, index) => {
    //     console.log(item)
    //     console.log({...item, ...this.answers.results[index], model: item.model})
    //     return {...item, ...this.answers.results[index], model: item.model}
    //   })
    // },
    toCorrect() {
      //this.setResultDataAnswers();
    }
  },
  components: {
    // CompleteButton,
    QuestionFactory
  },
  created() {
    //this.setResultDataAnswers();
  },
  computed: {
    // для дтм теста объедению в 1 массив тесты и ответы, что бы выводить вопрос/ответ и правильные ответы
    setResultData() {
      return this.test.test.map((item, index) => {
        return { ...item, ...this.test.results[index] }
      })
    },
    setResultDataAnswers() {
      console.log('setResultDataAnswers')
      return this.answers.test.map((item, index) => {
        console.log(item)
        console.log({
          ...item,
          ...this.answers.results[index],
          model: item.model
        })
        return { ...item, ...this.answers.results[index], model: item.model }
      })
    }
  },
  model: {
    prop: 'results',
    event: 'change'
  },
  data() {
    return {
      answerResults: []
    }
  },
  props: {
    test: {
      default: []
    },
    results: {
      default: () => []
    },
    isReportPage: Boolean,
    isSoloTest: {
      default: false
    },
    answers: Object,
    isPreview: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.test-container {
  max-width: 680px;
  width: 100%;
}

.fixed-top-right {
  position: fixed;
  top: 120px;
  right: 70px;
}

.countdown-line {
  width: 53px;
  height: 56px;
  border-radius: 100%;
  transform: rotateY(0) rotateZ(-90deg) scale(1.4);
  flex-shrink: 0;
  position: absolute;
  top: 0px;
  left: 0px;
}

.complete {
  display: inline-block;
  margin-left: 15px;
}

svg circle {
  stroke-dasharray: 113px;
  stroke-dashoffset: 0px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #1da1f2;
  fill: none;
  stroke-dashoffset: 50px;
  transform: translate(7.5px, 8px);
}

.t-end-btn {
  width: 51px;
  height: 51px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 100%;
  margin: 1px 1px;
  position: relative;
  box-shadow: none;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    background: #e40a0a;
  }
}

.more-quests {
  display: flex;
}

.test-list-box {
  display: inline-block;
  width: 100%;
  max-width: 780px;
  padding-left: 30px;
}

.quests {
  width: 100%;
  display: inline-block;
  padding-left: 0;

  li {
    padding-left: 15px;
    padding-top: 24px;
    padding-bottom: 24px;

    &:first-child {
      padding-top: 0;
    }
  }

  h4 {
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.02em;
    color: #000000;
    font-family: $mr;
    margin-bottom: 25px;
  }

  label {
    position: relative;
    cursor: pointer;

    input[type='radio']:checked + span {
      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    input {
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
      opacity: 0;
    }

    span {
      font-family: $mr;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: -0.02em;
      color: #191d2f;
      padding-left: 28px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        top: 5px;
        left: 5px;
        margin: auto;
        width: 10px;
        height: 10px;
        background: #1da1f2;
        border-radius: 10px;
        opacity: 0;
        transform: scale(0);
        transition: 0.3s;
      }

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 100%;
        top: 0;
        left: 0;
        border: 1px solid #191d2f;
      }
    }
  }
}

.my-modal {
  max-width: 487px;
  width: 100%;
  margin: 20px auto;
  background: #fff;
  border-radius: 24px;
  padding: 40px 48px;
  text-align: center;
  position: relative;

  h2 {
    margin-bottom: 12px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 16px;
  box-shadow: none;
  opacity: 1;
  font-weight: 100;
  font-size: 35px;
}

.backdrop {
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(25, 29, 47, 0.507);
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.test-info {
  margin-top: 12px;
  display: inline-block;
  width: 100%;

  a {
    margin: 0 8px;
  }
}
</style>
