<template>
  <div
    class="chat-prev vue-component"
    :class="{ 'chat-prev--active': this.active }"
  >
    <img src="../../../assets/svg/user.svg" alt="" class="chat-prev__avatar" />
    <div class="chat-prev__texts">
      <div class="chat-prev__name">{{ item.name }}</div>
    </div>
    <div class="chat-prev__end">
      <div class="chat-prev__time">{{ item.time }}</div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'chat-prev',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['driver']),
    msgComp() {
      let dop = this.item.msg.itsMe ? 'Вы:' : ''
      if (this.item.msg.msg) {
        return `${dop} ${this.item.msg.msg}`
      }else return `${dop} ""`
    }
  },
  components: {},
  props: {
    item: {
      required: true,
      type: Object
    },
    active: {
      type: Boolean
    }
  },
  mixins: {},
  beforeMount() {},
  mounted() {}
}
</script>

<style scoped='scoped' lang='scss'>
.chat-prev {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 16px;
  cursor: pointer;
  &--active {
    background: rgba(142, 208, 249, 0.5);
  }
  &__avatar {
    width: 56px;
    height: 56px;
    margin-right: 8px;
  }
  &__name {
    font-weight: bold;
  }
  &__texts {
    min-width: 215px;
    max-width: calc(100% - 64px);
  }
  &__msg {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__end {
    display: flex;
    justify-content: flex-end;
  }
  &__time {
    font-size: 10px;
    line-height: 18px;
  }
  // &__msg {
  //   text-overflow: ellipsis;
  //   width: 260px;
  //   white-space: nowrap;
  // }
  // &__texts {
  //   width: 260px;
  // }
}
</style>
