<template>
  <div class="testing vue-component">
    <div class="container">
      <tests-wrapper
        v-if="testsComputed && testsData[$route.params.id]"
        title=""
        subtitle=""
        :test-id="$route.params.id"
        :tests="testsComputed"
        :allTime="timeLimit"
        :hardMode="!!timeLimit"
        @finish="finish"
        ref="wrapper"
      >
        <template v-slot:footer>
          <div class="bottom">
            <button class="secondary--button save-btn" @click="saveAnswer">
              {{ $t('sokhranit-otvet') }}
            </button>
            <button class="primary--button" @click="finish">
              {{ $t('zavershit-test') }}
            </button>
          </div>
        </template>
      </tests-wrapper>
      <chat-modal />
    </div>
  </div>
</template>

<script>
import TestsWrapper from '@/components/UI/question/TestsWrapper.vue'
import ChatModal from '@/components/chat/ChatModal'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import i18n from '@/i18n'
export default {
  name: 'testing',
  data: function () {
    return {
      tests: [],
      testsIds: [],
      id: null,
      chatMode: 'mini'
    }
  },
  methods: {
    ...mapMutations(['setChats']),
    ...mapActions(['setTests', 'getProfile']),
    async finish() {
      // this.$Progress.start()
      await this.$refs.wrapper.checkTests()
      // this.$Progress.finish()
      this.$router.push({
        name:
          this.$route.params.type === 'hw'
            ? 'report-homework'
            : 'exams-homework',
        params: { type: 'testing', id: this.id }
      })
    },
    // Toast and redirect. Data already saved at localStorage
    saveAnswer() {
      this.$toast.open({
        message: i18n.t('data.249'),
        type: 'success',
        duration: 5000,
        dismissible: true
      })
      setTimeout(() => {
        this.$router.push({
          name: 'homework'
        })
      }, 1000)
    }
  },
  computed: {
    ...mapGetters(['testsData', 'user', 'driver']),
    //     currentTest() {
    //       if (this.testGroup) {
    //         const current = this.testGroup.find(
    //           (t) => t.id === this.$route.params.id
    //         )
    //         return current
    //       }
    //       return null
    // },
    testsComputed() {
      if (!this.testsData[this.$route.params.id]) return []
      return this.testsData[this.$route.params.id].tests.map((t, i) => {
        // subjectName - заглавные буквы английского алфавита
        t.subjectName = `${i18n.t('data.283')} ${String.fromCharCode(65 + i)}`
        if (t.tasks && !t.test) t.test = t.tasks
        return t
      })
    },
    timeLimit() {
      if (!this.testsData[this.$route.params.id]) return 0
      return this.testsData[this.$route.params.id].timeLimit / 1000
    }
  },
  components: { TestsWrapper, ChatModal },
  props: [],
  mixins: {},
  mounted() {
    this.id = this.$route.params.id
  },
  async beforeMount() {
    if (!this.testsData[this.$route.params.id]) {
      await this.setTests(this.$route.params.id)
    }
  }
}
</script>

<style scoped='scoped' lang='scss'>
.bottom {
  margin-top: 33px;
}
.save-btn {
  margin-right: 16px;
}
</style>
