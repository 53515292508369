<template>
  <div class="container-fluid mt-5">
    <div class="row">
      <div class="col-6">
        <div class="create-test-list-box">
          <label for="task-name" class="h5 mb-1">Название теста</label>
          <input
            placeholder="Название теста"
            class="form-control my-2 shadow"
            type="text"
            v-model="data.name"
            id="task-name"
          />
        </div>
        <draggable
          v-model="data.tasks"
          @start="drag = true"
          @end="drag = false"
        >
          <transition-group
            v-for="(question, i) in data.tasks"
            :key="i"
            :name="!drag ? 'flip-list' : null"
            type="transition"
          >
            <div :key="i" draggable>
              <create-panel
                class="shadow rounded"
                :question="question"
                @remove="onRemove"
                :index="i"
                @input="oninput"
                v-model="data.tasks[i]"
              />
            </div>
          </transition-group>
        </draggable>
        <div>
          <create-btn class="shadow rounded" @click.native="addNewQuestion" />
          <button
            v-if="data.name != ''"
            type="button"
            class="btn btn-success m-3"
            @click="createTestButton"
          >
            Сохранить тест
          </button>
        </div>
      </div>
      <div class="col-6">
        <div class="test-list-box">
          <h3>{{ data.name }}</h3>
          <ol class="quests">
            <li v-for="(question, index) in data.tasks" :key="index">
              <QuestionFactory
                :question="question"
                :index="index"
                :isPreview="true"
              />
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuestionFactory from '@/components/UI/question/QuestionFactory'
import CreatePanel from './factory/'
import CreateBtn from './UI/CreateBtn'
import draggable from 'vuedraggable'

import Api from '@/api/endpoints'

export default {
  name: 'Admin',
  components: {
    QuestionFactory,
    CreatePanel,
    CreateBtn,
    draggable
  },
  data() {
    return {
      data: {
        name: '',
        tasks: []
      },
      drag: false,
      updateMode: false
    }
  },
  methods: {
    addNewQuestion() {
      this.data.tasks.push({ type: 'radiobutton' })
    },
    async createTestButton() {
      let r

      if (!this.validation()) return false

      if (this.updateMode) {
        r = await Api.updateTest({
          data: [
            {
              test_id: this.$route.params.id,
              name: this.data.name,
              tasks: this.data.tasks.map((t) => {
                let answer = t.answer || t.answers
                t.test_id = t.id
                t.answer_comment = t.tip

                if (
                  (t.type == 'radiobutton' || t.type == 'checkbox') &&
                  answer.map
                ) {
                  t.answer = answer.map((a) => {
                    delete a.id
                    delete a.isSelected
                    return a
                  })
                } else if (t.type == 'text') {
                  t.answer = answer
                } else if (!t?.answer?.map) {
                  t.answer = []
                }

                return t
              })
            }
          ]
        })
      } else {
        let tasks = this.data.tasks.map((task) => {
          let answer = task.answer || task.answers

          if (task.type == 'radiobutton' || task.type == 'checkbox') {
            answer = task.answers.filter((answer) => answer.variant)
            answer = answer.map((data) => {
              delete data.isSelected
              return data
            })
          }
          return {
            type: task.type,
            question: task.question,
            mediaPath: task.mediaPath,
            answer,
            answer_comment: task.tip
          }
        })

        r = await Api.createTest({
          data: [
            {
              name: this.data.name,
              tasks
            }
          ]
        })
      }

      if (r[0]?.id || r.success) {
        this.data = {}
        window.objectStore.createTestData = this.data

        this.$router.push({
          name: 'test-view',
          params: {
            id: r[0]?.id || this.$route.params.id
          }
        })
      }
    },
    onRemove(index) {
      this.data.tasks.splice(index, 1)
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    oninput(updatedTask) {
      const task = this.data.tasks.find((task) => task.id === updatedTask.id)

      if (task) task.answer = updatedTask.answers

      window.objectStore.createTestData = this.data
    },
    async initUpdateMode() {
      this.updateMode = true
      let r = await Api.getTestById(this.$route.params.id)
      this.data = r.data
    },
    validation() {
      let error = false
      this.data.tasks.forEach((task) => {
        this.$set(task, 'error', {})

        if (!task.question?.replace(/<\/?[^>]+(>|$)/g, '')) {
          this.$set(
            task.error,
            'question',
            `Хотя бы 1 символ должен быть набран в строке`
          )
          error = true
        }

        if (task.type === 'radiobutton') {
          if (
            !task.answers ||
            !task.answers.every((answer) => answer.variant)
          ) {
            this.$set(
              task.error,
              'checkbox',
              `Хотя бы 1 символ должен быть набран в строке`
            )
            error = true
          }

          if (
            task.answers &&
            !task.answers.some((answer) => answer.isCorrect)
          ) {
            this.$set(
              task.error,
              'checkbox',
              `Хотя бы 1 чекбокс должен быть выбран`
            )
            error = true
          }
        }
      })

      if (error) return false
      return true
    }
  },
  beforeMount() {
    // if (window.objectStore.createTestData)
    //   this.data = window.objectStore.createTestData
    if (this.$route.params.id) this.initUpdateMode()
  }
}
</script>

<style lang="css">
@import '~vue2-editor/dist/vue2-editor.css';

/* Import the Quill styles you want */
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';
</style>
<style scoped lang="scss">
.flip-list-move {
  transition: transform 0.5s;
}
</style>
