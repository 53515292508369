<template>
  <div>
    <div class="form-box">
      <h2>{{ $t('data.97') }}</h2>
      <form>
        <input-form :item="user.firstName" />
        <!-- <input-form :item="user.lastName" /> -->
        <input-form :item="user.phone" />
        <!--        <div class="field-box">-->
        <!--          <p>{{ $t('data.21') }}<span>*</span></p>-->
        <!--          <phone-input :info="user.phone" v-model="user.phone.model" />-->
        <!--        </div>-->
        <!-- <input-form
          @validation="checkAge"
          type="birthday"
          class="age"
          :item="user.birthday"
        /> -->
        <input-form type="select" :item="user.lang" />
        <input-form @validation="checkPassword" :item="user.password" />
        <input-form
          @regOrAuth="regOrAuth"
          @validation="checkPassword"
          :item="user.password_confirmation"
        />
        <input-form
          v-show="user.referal_code.model.length > 0"
          :disabled="true"
          :item="user.referal_code"
        />
        <label class="politics">
          <input v-model="agree" type="checkbox" checked required />
          <p>
            {{
              $t(
                'otpravlyaya-zayavku-vy-podtverzhdaete-chto-oznakomleny-s-nashei'
              )
            }}
            <a :href="link" target="_blank">{{
              $t('politikoi-konfidencialnosti')
            }}</a>
          </p>
        </label>
        <div class="submit-box">
          <span class="secondary--button" @click="$router.back()">{{
            $t('data.80')
          }}</span>
          <button
            class="primary--button submit"
            @click.prevent="registerMethod"
          >
            {{ $t('data.77') }}
          </button>
        </div>
      </form>
      <alert
        @hideAlert="hideAlert"
        :show="isAlert"
        type="warn"
        :text="$t('data.105')"
      />
    </div>
    <b-modal
      ok-only
      id="modal-scrollable"
      scrollable
      :title="$t('politika-konfidencialnosti')"
    >
      <p class="my-4" v-for="i in 10" :key="i">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus ad
        aperiam consequuntur, cupiditate dicta doloribus illum, itaque magni
        nisi nobis optio pariatur perferendis, quod reiciendis rem saepe sequi
        veritatis voluptatum!
      </p>
    </b-modal>
    <b-modal
      id="modal-confirm"
      ref="modalConfirm"
      size="md"
      centered
      no-stacking
      :title="$t('podtverzhdenie-telefona')"
    >
      <div class="modal-confirm-container">
        <h2>{{ $t('odnorazovyi-kod') }}</h2>
        <form>
          <div class="form-group">
            <input
              type="text"
              required
              :placeholder="$t('kod')"
              v-model.trim="codeConfirm"
            />
            <span class="code-timer"
              >{{ timeToResendMessage }} сек
              <button
                class="btn"
                type="button"
                :disabled="timeToResendMessage > 0"
              >
                <img
                  @click.prevent="sendCode"
                  class="resend-img"
                  src="../../../assets/svg/repeat-icon-active.svg"
                  alt="reset"
                />
              </button>
            </span>
          </div>
          <p class="code-sended-msg">
            {{
              $t(
                'na-vash-nomer-telefona-otpravlen-odnorazovyi-kod-ne-soobshaite-ego-nikomu-a-prosto-vvedite-v-pole-vyshe'
              )
            }}
          </p>
          <div class="submit-box">
            <button
              class="primary--button"
              @click.prevent="confirmCode"
              :disabled="codeConfirm.length < 4"
              :class="{ disabled: codeConfirm.length < 4 }"
            >
              {{ $t('podtverdit') }}
            </button>
          </div>
        </form>
        <p v-if="!isCodeCorrect" class="text-danger mt-4">
          Введен не корректный код
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import InputForm from '@/components/UI/input-form/InputForm'
import Alert from '@/components/UI/alerts/Alert'
import moment from 'moment'

import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'
import { detectLanguage } from '@/i18n'

export default {
  name: 'Register',
  data: function () {
    return {
      isValidPassword: false,
      isValidAge: true,
      registration_token: null,
      timeToResendMessage: 0,
      isCodeCorrect: true,
      codeConfirm: '',
      codeSended: false,
      user: {
        firstName: {
          type: 'text',
          placeholder: this.$t('data.191'),
          model: '',
          required: true,
          label: this.$t('data.31'),
          class: '',
          errorMessage: '',
          typeValidation: 'firstName'
        },
        // lastName: {
        //   type: 'text',
        //   placeholder: this.$t('data.192'),
        //   model: '',
        //   required: true,
        //   label: this.$t('data.32'),
        //   class: '',
        //   errorMessage: '',
        //   typeValidation: 'lastName'
        // },
        phone: {
          type: 'text',
          placeholder: '+998 (XX) XXX-XX-XX',
          mask: '+998 (##) ###-##-##',
          model: '',
          isPhone: true,
          required: true,
          label: this.$t('vvedite-nomer-telefona-0'),
          class: '',
          errorMessage: ''
        },
        // birthday: {
        //   type: 'text',
        //   placeholder: this.$t('data.194'),
        //   model: new Date(Date.now() - 504921600000),
        //   required: true,
        //   label: this.$t('data.22'),
        //   class: '',
        //   typeValidation: 'birthday',
        //   errorMessage: ''
        // },
        lang: {
          type: 'text',
          model: detectLanguage(),
          options: [
            { id: 'ru', name: 'Русский язык' },
            { id: 'uz', name: "O'zbek tili" }
          ],
          required: true,
          label: this.$t('vyberite-vash-yazyk'),
          class: '',
          errorMessage: ''
        },
        password: {
          type: 'password',
          placeholder: '************',
          model: '',
          required: true,
          label: this.$t('data.102'),
          class: '',
          typeValidation: 'password',
          errorMessage: ''
        },
        password_confirmation: {
          type: 'password',
          placeholder: '************',
          model: '',
          required: true,
          label: this.$t('data.103'),
          class: '',
          errorMessage: '',
          typeValidation: 'password',
          event: 'submit'
        },
        referal_code: {
          type: 'referal',
          placeholder: '',
          model: '',
          required: false,
          label: 'Referal',
          class: '',
          errorMessage: ''
        }
      },
      agree: false,
      isAlert: false
    }
  },
  methods: {
    ...mapActions(['registerAndLogin', 'getProfile', 'sendConfirmation']),
    hideAlert(val) {
      this.isAlert = val
    },
    async regOrAuth(item) {
      if (item.event === 'submit') {
        await this.registerMethod()
      }
    },
    async registerMethod() {
      if (
        this.validationForm(this.user, this.isValidAge) &&
        this.checkConfirmationPassword(this.isValidPassword, this.user)
      ) {
        if (this.agree) {
          // If dont have registration token process phone confirmation
          if (!this.registration_token) {
            if (!this.codeSended) {
              this.codeSended = true
              await this.sendCode()
            } else {
              this.$bvModal.show('modal-confirm')
            }
          } else {
            await this.processRegister()
          }
        } else {
          this.isAlert = true
        }
      }
    },

    async sendCode() {
      try {
        this.isCodeCorrect = true
        const response = await this.sendConfirmation({
          phone: this.user.phone.model
        })
        if (response.success) {
          this.timerCount(parseInt(60))
          this.$bvModal.show('modal-confirm')
        }
      } catch (e) {
        console.log(`Error request confirmation code: ${e}`)
      }
    },
    async confirmCode() {
      if (this.codeConfirm.length && this.codeConfirm != null) {
        try {
          const response = await this.sendConfirmation({
            phone: this.user.phone.model,
            code: this.codeConfirm
          })
          if (response.success) {
            this.registration_token = response.registration_token
            this.successToast()
            this.$bvModal.hide('modal-confirm')
            await this.processRegister()
          } else if (
            response.success === false &&
            response.status.includes('error')
          ) {
            this.registration_token = null
            this.isCodeCorrect = false
          }
          console.log(response)
        } catch (e) {
          console.log(`Error in code confirmation: ${e}`)
        }
      }
    },
    successToast() {
      this.$toast.open({
        message: this.$t('g.1'),
        type: 'success',
        duration: 3000,
        dismissible: true
      })
    },
    timerCount(count) {
      this.timeToResendMessage = count
      const timerId = setInterval(() => {
        if (this.timeToResendMessage > 0) {
          this.timeToResendMessage--
        } else {
          clearInterval(timerId)
        }
      }, 1000)
    },
    async processRegister() {
      if (this.registration_token !== null) {
        let payload = {
          firstName: this.user.firstName.model,
          // lastName: this.user.lastName.model,
          middleName: '',
          phone: this.user.phone.model,
          // birthday: moment(this.user.birthday.model).format('YYYY-MM-DD'),
          password: this.user.password.model,
          password_confirmation: this.user.password_confirmation.model,
          registration_token: this.registration_token,
          lang: this.user.lang.model
        }
        if (this.user.referal_code?.model) {
          payload.referal_code = this.user.referal_code.model
        }
        await this.registerAndLogin(payload).then(() => {
          this.$router.push({ name: 'home' })
          // window.location.reload()
        })
      }
    },
    // Reactive check
    checkPassword(val) {
      this.isValidPassword = val
      this.checkConfirmationPassword(this.isValidPassword, this.user)
    },
    // Reactive check
    checkAge(val) {
      this.isValidAge = val
      this.validationAge(this.isValidAge, this.user)
    }
  },
  watch: {
    token(val) {
      this.getProfile(val).then(() => {
        this.$router.push({ name: 'home' })
      })
    }
  },
  computed: {
    ...mapGetters(['token']),
    link() {
      if (this.$detectLanguage() == 'ru') return '/files/politics.pdf'
      else return '/files/politics_uz.pdf'
    }
  },
  components: { Alert, InputForm, DatePicker },
  mounted() {
    if (this.$route.query?.referal_code) {
      this.user.referal_code.model = this.$route.query.referal_code
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';
button {
  font-family: 'Montserrat-Regular';
}
.form-group {
  display: flex;
  align-items: center;
  gap: 20px;
  input {
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    width: 100%;
    color: #8c8e97;
    padding: 15px 14px;
  }
  .primary--button {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

.resend-img {
  transition: transform 0.7s ease-in-out;
}

.resend-img:hover {
  transform: rotate(180deg);
}

#modal-scrollable___BV_modal_outer_ {
  z-index: 9999 !important;
}
.disabled {
  background: grey !important;
  cursor: not-allowed !important;
}
.disabled-input {
  cursor: not-allowed !important;
}
</style>
