import Api from '@/api/endpoints'
import moment from 'moment'
import i18n from '@/i18n'

export default {
  state: {
    loadingState: false,
    token: window.localStorage.getItem('token') || '',
    user: {},
    orders: null,
    payment: null,
    showPaymentModal: false,
    referalLink: null,
    xpAndInvites: null
  },
  mutations: {
    // Set user data loading state
    setLoadingState(state, payload) {
      state.loadingState = payload
    },
    setReferal(state, payload) {
      state.referalLink = payload
    },
    setUserBalance(s, balance) {
      s.user.data.balance = s.user.data.balance - balance
    },
    setUser(s, user) {
      s.user = user
      if (s.user.locale) window.localStorage.setItem('lang', 'ru')
      if (!s.user?.data) return
      if (!s.user?.data?.image)
        s.user.data.image = require('@/assets/svg/user.svg')
      if (s.user?.data.locale) {
        i18n.locale = 'ru'
        localStorage.setItem('lang', 'ru')
        moment.locale('ru')
      }
    },
    setToken(s, token) {
      s.token = token
      window.localStorage.setItem('token', token)
    },
    setXpAndInvites(s, data) {
      s.xpAndInvites = data
    },
    logout(s) {
      s.user = {}
      s.token = false
      window.localStorage.setItem('token', '')
    },
    setOrders(s, orders) {
      s.orders = orders
    },
    setPayment(s, payment) {
      s.payment = payment
    },
    isPaymentModal(s, data) {
      s.showPaymentModal = data
    }
  },
  actions: {
    async login({ dispatch, commit }, data) {
      console.log('login')
      commit('setLoadingState', true)
      let r = await Api.login(data.phone, data.password)
      if (r.errors) {
        commit('setLoadingState', false)
        return
      }
      if (r.token && r.user) {
        dispatch('setUser', r)
      }
      commit('setLoadingState', false)
    },
    async registerAndLogin({ dispatch, commit }, data) {
      commit('setLoadingState', true)
      let r = await Api.register(data)
      if (r.errors) {
        commit('setLoadingState', false)
        return
      }
      if (r.token && r.user) {
        dispatch('setUser', r)
      }
      commit('setLoadingState', false)
    },
    async getXpAndInvites({ commit }) {
      const xpAndInvites = await Api.getXpAndInvites()
      commit('setXpAndInvites', xpAndInvites)
    },
    // Get user referal link
    async getReferal({ commit, state }) {
      const referalLink = await Api.getMyReferalLink()
      console.log(referalLink)
      commit('setReferal', referalLink)
    },
    // Send phone for get sms
    async sendConfirmation({ dispatch, commit }, data) {
      let r = await Api.confirmation(data)
      if (r.errors) {
        return r.errors
      }
      return r
    },
    setUserBalance({ commit }, balance) {
      commit('setUserBalance', balance)
    },
    setUser({ commit }, userData) {
      console.log(userData)
      commit('setToken', userData.token)
      commit('setUser', userData.user)
    },
    updateUser({ commit }, userData) {
      commit('setUser', userData.user)
    },
    async getProfile({ commit, state }, token) {
      // Set state for loadin
      commit('setLoadingState', true)
      const data = await Api.getProfile(token)

      if (data.error == 401) {
        commit('setLoadingState', false)
      }
      commit('setUser', data)
      // Load finish, change loading state
      commit('setLoadingState', false)
      return data
    },
    async profileUpdate({ dispatch, commit }, data) {
      let r = await Api.profileUpdate(data)
      if (r.errors || r.error) return r
      if (r.token && r.user) dispatch('setUser', r)
      // Update profile after user save provile changes
      else commit('setUser', r)
    },
    async getOrders({ commit }, data) {
      let r = await Api.orders(data)
      if (r.errors) return
      commit('setOrders', r)
    },
    async getPayment(
      { commit },
      { payment_method, order_id, promo_code, use_bonuses }
    ) {
      let r = await Api.getPay({
        payment_method,
        order_id,
        promo_code,
        use_bonuses
      })
      if (r.errors) return
      commit('setPayment', r)
    },
    showPayment({ commit }, data) {
      commit('isPaymentModal', data)
    }
  },
  getters: {
    // this parametr show when loading profile process execute
    loading: state => state.loadingState,
    user: s => s.user?.data,
    token: s => s.token,
    orders: s => s.orders,
    payment: s => s.payment,
    showPaymentModal: s => s.showPaymentModal,
    referalLink: s => s.referalLink,
    xpAndInvites: s => s.xpAndInvites
  }
}
