<template>
  <div class="drop-box">
    <span class="drop-sub">{{ $t('vyberite-period') }}</span>
    <date-picker
      :editable="false"
      :default-value="defaultDate"
      v-model="date"
      range
      :lang="lang"
      @change="onChange"
    >
    </date-picker>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'

export default {
  name: 'Calendar',
  components: {
    DatePicker
  },
  props: {
    item: {
      type: Object,
      default: true
    },
    resetDateRange: {
      type: Boolean,
      default: false
    },
    defaultDate: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      date: null,
      lang: {
        monthFormat: 'MMMM'
      }
    }
  },
  created() {
    if (this.item.value.startDate && this.item.value.endDate) {
      this.date = [
        new Date(...this.item.value.startDate.split('.').reverse()),
        new Date(...this.item.value.endDate.split('.').reverse())
      ]
    }
  },
  methods: {
    onChange() {
      this.$emit('change', {
        name: this.item.name,
        value: {
          startDate: this.date[0]
            ? this.$moment(this.date[0]).format('DD.MM.YYYY')
            : '',
          endDate: this.date[1]
            ? this.$moment(this.date[1]).format('DD.MM.YYYY')
            : ''
        }
      })
    }
  },
  watch: {
    resetDateRange() {
      if (this.resetDateRange) {
        this.date = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.drop-box {
  display: inline-block;
  max-width: 256px;
  width: 100%;
  margin-right: 16px;
  padding-top: 12px;
  padding-bottom: 12px;

  ::v-deep .mx-datepicker svg {
    fill: #1da1f2;
  }

  ::v-deep .mx-datepicker-range {
    width: 256px;
  }

  ::v-deep .mx-input {
    height: 48px;
    border-radius: 13px;
    border: none;
    font-family: 'Montserrat-Regular';
    color: #000000;
    font-size: 14px;
  }
}

.drop-sub {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 4px;
  font-family: $mr;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #191d2f;
  width: 100%;
  display: inline-block;
}

@media (max-width: 992px) {
  .drop-box {
    max-width: inherit;
    margin-right: 0;

    .mx-datepicker-range {
      width: 100%;
    }
  }
}
</style>
