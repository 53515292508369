<template>
  <div class="lang-box">
    <div class="lang-selected" @click="showLang = !showLang">
      <img :src="activeLang.icon" alt="" />
    </div>
    <transition name="slide">
      <ul class="lang-list" v-show="showLang">
        <li v-for="lang in langs" :key="lang.label" @click="onclickLang(lang)">
          <img :src="lang.icon" alt="" />
        </li>
      </ul>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showLang: false,
      langs: [
        {
          label: 'uz',
          icon: require('@/assets/svg/uzbekistan.svg')
        },
        {
          label: 'ru',
          icon: require('@/assets/img/ru-lang.jpg')
        }
      ],
      selectedLang: false
    }
  },
  computed: {
    activeLang() {
      if (this.selectedLang) return this.selectedLang
      return this.langs.find(l => l == this.$detectLanguage()) || this.langs[0]
    }
  },
  methods: {
    selectLang(lang) {
      this.selectedLang = lang
    },
    onclickLang(lang) {
      this.selectLang(lang)
      this.showLang = false
      this.$emit('toggleLang', this.activeLang)
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-box {
  width: 33px;
  position: absolute;
  right: 16px;
  top: 34px;
  user-select: none;
  z-index: 1;
  img {
    max-width: 16px;
    border-radius: 100%;
  }
}

.lang-selected {
  width: 100%;
  cursor: pointer;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 10px;
    cursor: pointer;
    right: 0;
    width: 12px;
    height: 10px;
    background-image: url(../../../assets/svg/caret.svg);
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.lang-list {
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
  display: flex;
  flex-wrap: wrap;
  li {
    cursor: pointer;
    width: 100%;
  }
}
</style>
