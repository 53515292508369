
<template>
  <blog />
</template>

<script>
import DtmSoon from '@/components/views/dtm-tests-components/DtmSoon'
import Blog from '@/components/views/blog-components/BlogNews.vue'
export default {
  name: 'BlogMain',
  components: { Blog },
  data() {
    return {
      facTypeDatas: [
        {
          facTypeText: 'Физико-математический',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Естественно-географический',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Исторический',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Филологический',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Ин.Яз.',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Педагогика и психология',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Социально-экономический',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Военное образование',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Физическая культура',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Дошкольное и начальное образование',
          facTypeLink: 'http://www.google.com'
        },
        {
          facTypeText: 'Зоотехнический',
          facTypeLink: 'http://www.google.com'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.banner-blog-main {
  background: url(../../../assets/img/blog-images/uni-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  margin-bottom: 20px;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      0deg,
      #1da1f2 -75.29%,
      rgba(29, 161, 242, 0) 206.03%
    );
  }
  & > * {
    position: relative;
    z-index: 1;
  }
}

h1 {
  color: #fff;
}

.banner-info {
  display: flex;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 680px;
  min-height: 348px;
  flex-wrap: wrap;
  padding: 20px 56px;
}

.blog-slider-container {
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 20px;
  margin-bottom: 16px;
}

.blog-slider {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.slider-item {
  width: 100%;
  height: 398px;
  position: relative;
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
  }
}

.sl-prev {
  left: 0;
  svg {
    transform: rotate(180deg);
  }
}

.sl-next {
  right: 0;
}

.slider-arrows {
  width: 100%;
  button {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 32px;
    height: 32px;
    background: #1da1f2;
    border-radius: 12px;
    box-shadow: none;
    outline: none;
    margin: auto 16px;
    transition: 0.2s;
    &:hover {
      background: #000;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 8px;
    }
  }
}

.about-university {
  display: inline-block;
  width: 100%;
  padding-top: 12px;
  padding-bottom: 31px;
}

.contact-box {
  display: inline-block;
  width: 100%;
  background: rgba(142, 208, 249, 0.3);
  border: 1px solid #1da1f2;
  border-radius: 12px;
  padding: 32px 32px 16px 32px;
  h2 {
    margin-bottom: 6px;
  }
}

.contact-info {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.single-info {
  width: 50%;
  padding-right: 32px;
  margin: 16px 0;
  h4 {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 0;
    a {
      color: #191d2f;
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        color: #1da1f2;
      }
    }
  }
}

.faculties {
  display: inline-block;
  width: 100%;
  padding-top: 31px;
  padding-bottom: 26px;
  .title {
    width: 100%;
    display: inline-block;
    margin-bottom: 18px;
  }
}

.tpyes {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  a {
    padding: 18px 15px;
    display: inline-block;
    margin-right: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    font-family: $mr;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;
    transition: 0.2s;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 12px;
    text-decoration: none;
    &:hover {
      background: #f0f0f0;
    }
  }
}

.scores {
  display: inline-block;
  width: 100%;
  padding-top: 26px;
  padding-bottom: 30px;
  p .title {
    width: 100%;
    display: inline-block;
    margin-bottom: 18px;
  }
}

.score-table {
  display: inline-block;
  width: 100%;
}

.score-head {
  padding: 13px 10px 13px 24px;
  margin-bottom: 4px;
  list-style: none;
  background: #f4f4f5;
  border-radius: 4px;
  display: grid;
  align-items: center;
  grid-template-columns: 40px 150px 137px 122px 176px 176px 164px;
  li {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
    padding-right: 10px;
  }
}

.score-tr {
  padding-left: 0;
  margin: 8px 0;
  list-style: none;
  width: 100%;
  display: grid;
  padding: 14px 10px 15px 24px;
  grid-template-columns: 40px 150px 137px 122px 176px 176px 164px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 12px;
  .oval-info {
    min-width: 66px;
    text-align: center;
    margin-bottom: 0;
    margin-right: 4px;
    display: inline-block;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 24px;
    padding: 4px 11px;
    line-height: 18px;
    &:last-child {
      margin-right: 0;
    }
    span {
      display: flex;
      justify-content: center;
      width: 100%;
      line-height: 18px;
    }
  }
  .define {
    font-family: $mr;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #191d2f;
  }
  .value {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
  }
  li {
    padding-right: 10px;
    position: relative;
    span {
      display: flex;
    }
    &:first-child,
    &:last-child {
      &:before {
        display: none;
      }
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 13px;
      bottom: 0;
      width: 1px;
      height: 100%;
      background: #f0f0f0;
    }
  }
}
@media (max-width: 1340px) {
  .task-info ul li,
  .score-head li {
    white-space: inherit;
    // padding-left: 15px;
    // padding-right: 15px;
  }
}

@media (max-width: 1200px) {
  .score-table {
    overflow: auto;
  }

  .score-head {
    width: fit-content;
    display: flex;
  }

  .score-tr {
    width: fit-content;
  }

  .score-tr {
    display: flex;
  }

  .score-tr ul li,
  .score-head li {
    white-space: nowrap;
    min-width: 215px;

    font-size: 14px;

    &:first-child {
      min-width: 30px;
    }

    &:nth-child(2) {
      min-width: 235px;
    }
  }

  .score-tr li {
    min-width: 215px;

    &:first-child {
      min-width: 30px;
    }

    &:nth-child(2) {
      min-width: 235px;
    }
  }

  .about-university {
    .col-lg-6 {
      width: 100%;
    }
  }
}
</style>