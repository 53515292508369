<template>
  <div class="edit-profile">
    <div class="container">
      <title-nav>
        <h1>{{ $t('data.29') }}</h1>
      </title-nav>
      <div class="row change-block">
        <form class="col-3 avatar-block">
          <img
            v-if="user && user.image !== null && !cropImg"
            class="avatar"
            :src="user.image"
            alt="avatar"
          />
          <img v-if="cropImg" class="avatar" :src="cropImg" alt="avatar" />
          <img
            v-if="!cropImg && user && !user.image"
            class="avatar"
            src="../../../../../../assets/svg/add-avatar.svg"
            alt="avatar"
          />
          <input @change="uploadImage($event)" type="file" id="uploadImg" />
          <label for="uploadImg" class="change-avatar"> {{ $t('data.201') }} </label>
          <b-modal no-close-on-backdrop centered ref="modal">
            <div
              style="
                width: 100%;
                height: 300px;
                border: 1px solid gray;
                display: inline-block;
              "
            >
              <vue-cropper
                ref="cropper"
                :guides="true"
                :view-mode="2"
                drag-mode="crop"
                :auto-crop-area="0.5"
                :min-container-width="250"
                :min-container-height="180"
                :background="true"
                :rotatable="false"
                :src="image"
                alt="Source Image"
                :img-style="{ width: '100%', height: '300px' }"
                :aspect-ratio="1"
              >
              </vue-cropper>
            </div>
            <div class="custom-footer">
              <b-button variant="success" @click="cropImage"> OK</b-button>
              <b-button variant="danger" @click="$refs['modal'].hide()">
                {{ $t('data.40') }}
              </b-button>
            </div>
          </b-modal>
        </form>
        <div class="col-9">
          <h2>{{ $t('data.20') }}</h2>
          <div class="row personal-data-block">
            <div class="col-6">
              <input-form :item="form.firstName" />
              <input-form :item="form.lastName" />
              <input-form :item="form.middleName" />
              <input-form
                  class="age"
                @validation="checkAge"
                type="birthday"
                :item="form.birthday"
              />
            </div>
            <div class="col-6">
<!--              <div class="field-box">-->
<!--                <p>{{ $t('data.21') }}<span>*</span></p>-->
<!--                <phone-input-->
<!--                  :isEdit="true"-->
<!--                  :info="form.phone"-->
<!--                  v-model="form.phone.model"-->
<!--                />-->
<!--              </div>-->
              <input-form :item="form.phone" />
              <input-form :item="form.school" />
              <input-form :item="form.city" />
              <input-form @validation="checkEmail" :item="form.email" />
<!--              <input-form type='select' :item="form.locale" />-->
            </div>
          </div>
          <button @click="saveNewData" class="btn save-btn">
            {{ $t('data.38') }}
          </button>
          <button @click="clearForm" class="btn cancel-btn">
            {{ $t('data.46') }}
          </button>

          <h2 class="personal-title">{{ $t('data.30') }}</h2>

          <div class="row personal-data-block">
            <div class="col-6">
              <input-form :item="passwordForm.inputOldPass" />
              <input-form
                @validation="checkPassword"
                :item="passwordForm.password"
              />
            </div>
            <div class="col-6 align-self-end">
              <input-form :item="passwordForm.password_confirmation" />
            </div>
          </div>
          <button @click="saveNewPassword" class="btn save-btn">
            {{ $t('data.45') }}
          </button>
          <button @click="clearPassword" class="btn cancel-btn">
            {{ $t('data.40') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputForm from '@/components/UI/input-form/InputForm'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
import moment from 'moment'
import { formatPhoneNumber } from '@/utils/formatters'

import i18n from '@/i18n'

export default {
  components: { InputForm },
  data: () => ({
    isValidEmail: false,
    isValidPassword: false,
    image: '',
    cropImg: '',
    imageFile: null,
    isValidAge: true,
    form: {
      firstName: {
        type: 'text',
        placeholder: i18n.t('data.191'),
        model: '',
        required: true,
        label: i18n.t('data.191'),
        class: '',
        errorMessage: ''
      },
      lastName: {
        type: 'text',
        placeholder: i18n.t('data.192'),
        model: '',
        required: true,
        label: i18n.t('data.192'),
        class: '',
        errorMessage: ''
      },
      middleName: {
        type: 'text',
        placeholder: i18n.t('data.193'),
        model: '',
        required: false,
        label: i18n.t('data.193'),
        class: '',
        errorMessage: ''
      },
      birthday: {
        type: 'text',
        placeholder: i18n.t('data.194'),
        model: '',
        required: true,
        label: i18n.t('data.194'),
        class: '',
        errorMessage: '',
        typeValidation: 'birthday'
      },
      locale: {
        type: 'text',
        model: 'ru',
        options:[{id: 'ru', name: 'Русский язык'}, {id: 'uz', name: 'Қазақ тілі'}],
        required: true,
        label: 'Выберите ваш язык',
        class: '',
        errorMessage: ''
      },
      school: {
        type: 'text',
        placeholder: i18n.t('data.255'),
        model: '',
        required: false,
        label: i18n.t('data.36'),
        class: '',
        errorMessage: ''
      },
      city: {
        type: 'text',
        placeholder: i18n.t('data.196'),
        model: '',
        required: false,
        label: i18n.t('data.23'),
        class: '',
        errorMessage: ''
      },
      email: {
        type: 'email',
        placeholder: i18n.t('data.245'),
        model: '',
        required: false,
        label: 'Email',
        class: '',
        typeValidation: 'email',
        errorMessage: ''
      },
      phone: {
        type: 'text',
        placeholder: '+998 (XX) XXX-XX-XX',
        mask: '+998 (##) ###-##-##',
        model: '',
        isPhone: true,
        required: true,
        label: 'Введите номер телефона',
        class: '',
        errorMessage: '',
      },
    },
    passwordForm: {
      inputOldPass: {
        type: 'password',
        placeholder: '************',
        model: '',
        required: true,
        label: i18n.t('data.42'),
        class: '',
        errorMessage: ''
      },
      password: {
        type: 'password',
        placeholder: '************',
        model: '',
        required: true,
        label: i18n.t('data.43'),
        class: '',
        typeValidation: 'password',
        errorMessage: ''
      },
      password_confirmation: {
        type: 'password',
        placeholder: '************',
        model: '',
        required: true,
        label: i18n.t('data.190'),
        class: '',
        errorMessage: ''
      }
    }
  }),
  computed: {
    ...mapGetters(['user'])
  },
  methods: {
    ...mapActions(['profileUpdate', 'getProfile']),
    clearPassword() {
      this.passwordForm.password.model = ''
      this.passwordForm.inputOldPass.model = ''
      this.passwordForm.password_confirmation.model = ''
    },
    clearForm() {
      for (let key in this.form) {
        this.form[key].model = ''
      }
      this.fillForm()
    },
    uploadImage(e) {
      const file = e.target.files[0]
      this.imageFile = file
      if (!file.type.includes('image/')) {
        Vue.$toast.open({
          message: i18n.t('data.266'),
          type: 'warning',
          duration: 5000,
          dismissible: true
        })
        return
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader()
        reader.onload = async (event) => {
          this.image = event.target.result
          await this.$refs['modal'].show()
          await this.$refs.cropper.replace(event.target.result)
        }
        reader.readAsDataURL(file)
        e.target.value = null
      } else {
        alert('Sorry, FileReader API not supported')
      }
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      this.$refs['modal'].hide()
    },
    checkPassword(val) {
      this.isValidPassword = val
    },
    fillForm() {
      for (let key in this.user) {
        for (let keyForm in this.form) {
          if (key === keyForm) {
            if (this.user[key]) {
              if (key === 'birthday') {
                this.form[keyForm].model = new Date(this.user[key])
              } else if (key === 'phone') {
                this.form[keyForm].model = formatPhoneNumber(
                  String(this.user[key])
                )
              } else if (key === 'phone') {
                this.form[keyForm].model = formatPhoneNumber(
                  String(this.user[key])
                )
              } else {
                this.form[keyForm].model = String(this.user[key])
              }
            }
          }
        }
      }
    },
    checkAge(val) {
      this.isValidAge = val
    },
    checkEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    saveNewData() {
      if (
        this.validationForm(this.form) &&
        this.validationAge(this.isValidAge, this.form)
      ) {
        if (this.form.email.model) {
          if (!this.checkEmail(this.form.email.model)) {
            this.form.email.class = 'border-danger'
            this.form.email.errorMessage = 'Введите корректный email'
            console.log(this.form.email.errorMessage, this.form.email)
            return false
          } else {
            this.form.email.class = ''
            this.form.email.errorMessage = ''
          }
        }
        const formData = new FormData()
        formData.append('firstName', this.form.firstName.model)
        formData.append('lastName', this.form.lastName.model)
        formData.append('middleName', this.form.middleName.model)
        formData.append('email', this.form.email.model)
        formData.append('phone', this.form.phone.model.replace(/[\D]+/g, ''))
        formData.append('school', this.form.school.model)
        formData.append('city', this.form.city.model)
        // formData.append('locale', this.form.locale.model)
        formData.append(
          'birthday',
          moment(this.form.birthday.model).format('YYYY-MM-DD')
        )
        if (this.cropImg)
          formData.append('image', this.dataURLtoFile(this.cropImg, 'avatar'))
        formData.append('user_id', this.user.id)
        this.profileUpdate(formData).then(() => {
          this.$toast.open({
            message: i18n.t('data.190'),
            type: 'success',
            duration: 5000,
            dismissible: true
          })
        })
      }
    },

    saveNewPassword() {
      if (
        this.validationForm(this.passwordForm) &&
        this.checkConfirmationPassword(this.isValidPassword, this.passwordForm)
      ) {
        const formData = new FormData()
        formData.append('password', this.passwordForm.password.model)
        formData.append('old_password', this.passwordForm.inputOldPass.model)
        formData.append(
          'password_confirmation',
          this.passwordForm.password_confirmation.model
        )
        this.profileUpdate(formData).then((res) => {
          if (!res){
            this.$toast.open({
            message: `Пароль успешно сохранен`,
            type: 'success',
            duration: 5000,
            dismissible: true
          })
          }

        })
      }
    }
  },
  mounted() {
    this.getProfile().then(() => {
      this.fillForm()
    })
  },

}
</script>

<style lang="scss">
.edit-profile {
  margin-top: 40px;

  div,
  p,
  li,
  span,
  label {
    font-family: 'Montserrat-Regular';
  }

  #uploadImg {
    display: none;
  }
  .change-block {
    margin-top: 30px;
  }
  .avatar-block {
    display: flex;
    align-items: center;
    flex-direction: column;
    .avatar {
      width: 200px;
      height: 200px;
      border-radius: 50%;
      object-fit: contain;
    }
    .change-avatar {
      color: #1da1f2;
      cursor: pointer;
      margin-top: 30px;
      font-weight: 700;
    }
  }
  .personal-data-block {
    margin-bottom: 30px;
  }
  .personal-title {
    margin-top: 40px;
  }
  .btn {
    border-radius: 12px;
    padding: 10px;
    font-weight: 700;
  }
  .save-btn {
    background: #1da1f2;
    color: #fff;
  }
  .cancel-btn {
    border: 1px solid #ccc;
    margin-left: 20px;
  }
}
.custom-footer{
    display: flex;
    gap:5px;
  }
@media (max-width: 1200px) {
  .change-block {
    flex-direction: column;
    .col-9 {
      width: 100%;
    }
  }
  .edit-profile .avatar-block {
    flex-direction: row;
    align-items: center;
    flex: inherit;
    width: 100%;
    margin-bottom: 30px;
    .avatar {
      width: 70px;
      height: 70px;
    }
    .change-avatar {
      margin: 0 0 0 20px;
    }
  }
}
@media (max-width: 992px) {
  .edit-profile {
    .field-box {
      width: 100%;
    }
    .col-6 {
      width: 100% !important;
    }
  }
}
</style>
