<template>
  <input class="chat-search vue-component" type="text" placeholder="Поиск" />
</template>

<script>
export default {
  name: 'chat-search',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.chat-search {
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 17px 16px;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.02em;
  color: #8c8e97;
  width: calc(100% - 48px);
  margin: 16px 24px;
}
</style>
