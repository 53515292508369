<template>
  <div class="nav-bar" :class="{ 'nav-bar--mini': miniMode }">
    <ul>
      <li @click="closeNavBar">
        <router-link class="main" :to="{ name: 'home' }">
          <span class="m-icon">
            <svg
              width="18"
              height="19"
              viewBox="0 0 18 19"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 18C18 18.2652 17.8946 18.5196 17.7071 18.7071C17.5196 18.8947 17.2652 19 17 19H1C0.734784 19 0.48043 18.8947 0.292893 18.7071C0.105357 18.5196 2.4071e-07 18.2652 2.4071e-07 18V7.49003C-0.000105484 7.33764 0.0346172 7.18724 0.101516 7.05033C0.168415 6.91341 0.26572 6.79359 0.386 6.70003L8.386 0.478028C8.56154 0.341473 8.7776 0.267334 9 0.267334C9.2224 0.267334 9.43846 0.341473 9.614 0.478028L17.614 6.70003C17.7343 6.79359 17.8316 6.91341 17.8985 7.05033C17.9654 7.18724 18.0001 7.33764 18 7.49003V18V18ZM16 17V7.97803L9 2.53403L2 7.97803V17H16Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode">
            {{ $t('data.2') }}
          </template>
        </router-link>
      </li>
      <li @click="closeNavBar">
        <router-link :to="{ name: 'lessons-list' }">
          <span class="m-icon">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16.5V3C0 2.20435 0.316071 1.44129 0.87868 0.87868C1.44129 0.316071 2.20435 0 3 0H17C17.2652 0 17.5196 0.105357 17.7071 0.292893C17.8946 0.48043 18 0.734784 18 1V19C18 19.2652 17.8946 19.5196 17.7071 19.7071C17.5196 19.8946 17.2652 20 17 20H3.5C2.57174 20 1.6815 19.6313 1.02513 18.9749C0.368749 18.3185 0 17.4283 0 16.5ZM16 18V15H3.5C3.10218 15 2.72064 15.158 2.43934 15.4393C2.15804 15.7206 2 16.1022 2 16.5C2 16.8978 2.15804 17.2794 2.43934 17.5607C2.72064 17.842 3.10218 18 3.5 18H16ZM2 13.337C2.46869 13.1144 2.98115 12.9993 3.5 13H16V2H3C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V13.337Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode">
            {{ $t('data.3') }}
          </template>
        </router-link>
      </li>
      <li @click="closeNavBar">
        <router-link
          :to="{ name: 'homework' }"
          :class="{ 'router-link-active': $route.fullPath.includes('testing') }"
        >
          <span class="m-icon">
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20 18H22V20H0V18H2V1C2 0.734784 2.10536 0.48043 2.29289 0.292893C2.48043 0.105357 2.73478 0 3 0H19C19.2652 0 19.5196 0.105357 19.7071 0.292893C19.8946 0.48043 20 0.734784 20 1V18ZM18 18V2H4V18H18ZM7 9H10V11H7V9ZM7 5H10V7H7V5ZM7 13H10V15H7V13ZM12 13H15V15H12V13ZM12 9H15V11H12V9ZM12 5H15V7H12V5Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode">
            {{ $t('data.4') }}
          </template>
        </router-link>
        <!-- <span class="notification" v-show="homeworkNotifications.length">{{
          homeworkNotifications.length
        }}</span> -->
      </li>
      <li @click="closeNavBar">
        <router-link :to="{ name: 'dtm' }">
          <span class="m-icon">
            <svg
              width="18"
              height="22"
              viewBox="0 0 18 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 2H17C17.2652 2 17.5196 2.10536 17.7071 2.29289C17.8946 2.48043 18 2.73478 18 3V21C18 21.2652 17.8946 21.5196 17.7071 21.7071C17.5196 21.8946 17.2652 22 17 22H1C0.734784 22 0.48043 21.8946 0.292893 21.7071C0.105357 21.5196 0 21.2652 0 21V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H4V0H6V2H12V0H14V2ZM14 4V6H12V4H6V6H4V4H2V20H16V4H14ZM4 8H14V10H4V8ZM4 12H14V14H4V12Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode">
            {{ $t('data.5') }}
          </template>
        </router-link>
      </li>
      <li @click="closeNavBar">
        <router-link :to="{ name: 'exams' }">
          <span class="m-icon">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0.992V19.008C17.9979 19.2706 17.8926 19.5219 17.7068 19.7075C17.521 19.8931 17.2696 19.9982 17.007 20H0.993C0.729813 20 0.477391 19.8955 0.291196 19.7095C0.105001 19.5235 0.000265042 19.2712 0 19.008V0.992C0.00209465 0.72938 0.107418 0.478126 0.293218 0.292513C0.479017 0.1069 0.730378 0.00183004 0.993 0H17.007C17.555 0 18 0.444 18 0.992V0.992ZM16 2H2V18H16V2ZM8.293 11.121L12.536 6.879L13.95 8.293L8.293 13.95L4.403 10.06L5.818 8.646L8.293 11.121V11.121Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode">
            {{ $t('data.6') }}
          </template>
        </router-link>
      </li>
      <li @click="closeNavBar">
        <router-link :to="{ name: 'olympiads' }">
          <span class="m-icon">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10 0C15.523 0 20 4.477 20 10C20 15.523 15.523 20 10 20C4.477 20 0 15.523 0 10C0 4.477 4.477 0 10 0ZM10.366 11.366L6.897 17.376C8.30497 17.9651 9.85137 18.142 11.356 17.886C11.0903 16.0174 11.3622 14.1117 12.14 12.392L10.366 11.366ZM13.884 13.397C13.3651 14.6253 13.1633 15.9643 13.297 17.291C14.5108 16.7406 15.5683 15.8965 16.374 14.835L13.884 13.397V13.397ZM6.859 9.342C5.7586 10.8756 4.24469 12.0645 2.494 12.77C3.0229 14.2012 3.94958 15.4516 5.165 16.374L8.634 10.366L6.859 9.342V9.342ZM17.962 9.212L17.704 9.332C16.5953 9.87967 15.6297 10.6785 14.884 11.665L17.376 13.104C17.8936 11.8757 18.0951 10.5372 17.962 9.211V9.212ZM2 10C2 10.266 2.013 10.53 2.038 10.789C3.2535 10.242 4.31195 9.3981 5.116 8.335L2.624 6.897C2.21077 7.87926 1.9986 8.93436 2 10V10ZM14.835 3.626L11.366 9.634L13.141 10.659C14.2414 9.12456 15.7557 7.9349 17.507 7.229C17.0153 5.90124 16.1807 4.72743 15.088 3.827L14.835 3.626V3.626ZM10 2C9.537 2 9.084 2.04 8.643 2.115C8.90924 3.98363 8.63728 5.88943 7.859 7.609L9.634 8.634L13.103 2.624C12.1207 2.21077 11.0656 1.9986 10 2ZM6.703 2.71L6.512 2.798C5.3775 3.34946 4.38881 4.16035 3.626 5.165L6.116 6.603C6.63472 5.375 6.83657 4.03635 6.703 2.71V2.71Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode">
            {{ $t('data.7') }}
          </template>
        </router-link>
      </li>
      <li @click="closeNavBar">
        <router-link :to="{ name: 'chat' }">
          <span class="m-icon">
            <svg
              width="18"
              height="20"
              viewBox="0 0 18 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 0.992V19.008C17.9979 19.2706 17.8926 19.5219 17.7068 19.7075C17.521 19.8931 17.2696 19.9982 17.007 20H0.993C0.729813 20 0.477391 19.8955 0.291196 19.7095C0.105001 19.5235 0.000265042 19.2712 0 19.008V0.992C0.00209465 0.72938 0.107418 0.478126 0.293218 0.292513C0.479017 0.1069 0.730378 0.00183004 0.993 0H17.007C17.555 0 18 0.444 18 0.992V0.992ZM16 2H2V18H16V2ZM8.293 11.121L12.536 6.879L13.95 8.293L8.293 13.95L4.403 10.06L5.818 8.646L8.293 11.121V11.121Z"
                fill="#191D2F"
              />
            </svg>
          </span>
          <template v-if="!miniMode"> {{ $t('data.327') }} </template>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    // For future check periodicaly all user tasks and filter it for show how notification
    // how many uncolpleate task user have
    homeworkNotifications() {
      const allHomeTasksList = []
      return allHomeTasksList.filter(
        (task) =>
          task.statusKey == 'pending' || task.statusKey == 'not_complete'
      )
    },
    miniMode() {
      return this.mode === 'mini'
    },
    langComputed() {
      return this.$detectLanguage() == 'ru' ? 'ru' : ''
    }
  },
  methods: {
    closeNavBar() {
      this.$emit('close_nav', true)
    }
  },
  props: ['mode']
}
</script>

<style lang="scss" scoped>
@import './src/styles/vars.scss';

.nav-bar {
  h2 {
    margin-bottom: 30px;
  }
  ul {
    padding-left: 0;
    list-style: none;
    margin-bottom: 7px;
    .router-link-active:not(.main),
    .router-link-exact-active {
      opacity: 1;
      path {
        fill: #1da1f2;
      }
      a {
        opacity: 1;
      }
    }
    path {
      transition: 0.2s;
    }
    li {
      padding: 13px 0;
      transition: 0.2s;
      display: flex;
      &:hover {
        opacity: 1;
        path {
          fill: #1da1f2;
        }
        a {
          opacity: 1;
        }
      }
      .m-icon {
        display: flex;
        justify-content: center;
        margin-right: 20px;
        margin-top: -2px;
        flex-shrink: 0;
        width: 20px;
      }
      .notification {
        display: flex;
        flex-shrink: 0;
        min-width: 16px;
        padding: 2px 2px;
        height: 16px;
        border-radius: 100%;
        font-family: $mb;
        font-size: 10.6667px;
        line-height: 13px;
        letter-spacing: -0.02em;
        color: #ffffff;
        background: #1da1f2;
        justify-content: center;
        align-items: center;
        margin-left: 8px;
        align-self: center;
      }
      a {
        font-size: 16px;
        line-height: 20px;
        font-family: $mb;
        letter-spacing: 0.02em;
        color: #191d2f;
        text-decoration: none;
        position: relative;
        display: flex;
        opacity: 0.3;
        transition: 0.2s;
      }
    }
  }
}
@media (max-width: 768px) {
  .nav-bar ul li {
    padding: 10px 0 !important;
  }
}
</style>
