import axios from 'axios'
import Api from '@/api/endpoints'
import Vue from 'vue'

let setPropertyIfNotSet = (obj, key, value) => {
  if (!obj) return
  if (!obj[key]) Vue.set(obj, key, value)
}

export default {
  state: {
    testsData: {},
    testGroup: [],
    results: {},
    awaitTest: false,
    testLoaded: false
  },
  mutations: {
    setResultsFromLS: s => (s.results = window.objectStore.testResults || {}),
    setTestsDataFromLS: s => (s.testsData = window.objectStore.testsData || {}),
    pushResult: (s, data) => {
      setPropertyIfNotSet(s.results, data.testGroupId, [])

      // Set isCorrect
      data.test.forEach(test => {
        const resultData = data.results.find(
          result => result.task_id === test.id
        )

        if (!test.answers || !test.answers[0].id) return

        test.answers.forEach(answer => {
          answer.isCorrect = resultData.correctAnswer.includes(answer.id)
        })
      })

      if (!Array.isArray(s.results[data.testGroupId]))
        s.results[data.testGroupId] = []
      s.results[data.testGroupId].push(data)
      window.objectStore.testResults = s.results
    },
    setTimeLimit(s, { timeLimit, testGroupId }) {
      setPropertyIfNotSet(s.testsData, testGroupId, {})
      setPropertyIfNotSet(s.testsData[testGroupId], 'tests', [])
      s.testsData[testGroupId].timeLimit = timeLimit
    },
    pushTestData: (s, data) => {
      // Check if hade testgroup id
      if (!data.testGroupId && data.testGroupId !== 0)
        console.error('Не передан обязательный параметр id теста')
      setPropertyIfNotSet(s.testsData, data.testGroupId, {})
      setPropertyIfNotSet(s.testsData[data.testGroupId], 'tests', [])
      s.testsData[data.testGroupId].tests.push(data)
      // if test data already exist at localstorage need check for updates
      // const existingData = window.objectStore.testsData
      // if (
      //   existingData &&
      //   existingData[data.testGroupId] &&
      //   existingData[data.testGroupId]['tests']
      // ) {
      //   const tests = existingData[data.testGroupId]['tests']
      //   const lastUpdate = data?.updated_at
      //   const checker = tests.filter(test => test.updated_at != lastUpdate)
      //   // if have and test with old timestamp, clean data and write new, also rewrite localstorage object.
      //   if (checker.length) {
      //     s.testsData[data.testGroupId].tests = []
      //     s.testsData[data.testGroupId].tests.push(data)
      //     // to-do make check if test updated reet data in localstorage
      //     // window.objectStore.testsData = s.testsData
      //     console.log('Data have update and rewrited')
      //   }
      // } else {
      //   setPropertyIfNotSet(s.testsData, data.testGroupId, {})
      //   setPropertyIfNotSet(s.testsData[data.testGroupId], 'tests', [])
      //   s.testsData[data.testGroupId].tests.push(data)
      //   // console.log(data)
      //   // to-do make check if test updated reet data in localstorage
      //   // Данный функционал отключен из-за багов
      //   // window.objectStore.testsData = s.testsData
      // }
    },
    uppendQuestions: (s, { testGroupId, questions, testId }) => {
      if (!testGroupId && testGroupId !== 0)
        return console.error('Не передан обязательный параметр id теста')
      s.testsData[testGroupId].tests[testId].test = [
        ...s.testsData[testGroupId].tests[testId].test,
        ...questions
      ]
    },
    clearDtmResults: s => {
      if (s?.results?.dtm) s.results.dtm = {}
      if (window.objectStore?.testResults?.dtm)
        window.objectStore.testResults.dtm = {}
    },
    clearDtmTests: s => {
      if (s?.testsData?.dtm) s.testsData.dtm = {}
      if (window.objectStore?.testsData?.dtm)
        window.objectStore.testsData.dtm = {}
    },
    setTestInfo: (s, data) => {
      setPropertyIfNotSet(s.testsData, data.testGroupId, {})
      setPropertyIfNotSet(s.testsData[data.testGroupId], 'tests', [])
      s.testsData[data.testGroupId].info = data
      // window.objectStore.testsData = s.testsData
    },
    setAwaitTest: (s, val) => (s.awaitTest = val),
    allTestsIsLoaded: s => (s.testLoaded = true),
    setTestGroup: (s, data) => (s.testGroup = data)
  },
  actions: {
    async getTestGroup({ commit }, params) {
      let r = await Api.getTestGroup(params)
      commit('setTestGroup', r.data)
    },
    async checkTestGroup({ dispatch, commit }, testGroup) {
      console.log('TG', testGroup)
      if (!testGroup?.length) return
      let testGroupId = testGroup[0].testGroupId
      if (testGroupId === 'dtm') {
        return Promise.all(
          testGroup.map(async data => {
            let modelData = data.test
              .map(e => e.model)
              .filter(i => i)
              .map(i => {
                if (i.answer === '') i.answer = 'нет ответа'
                return i
              })
            let r = await Api.checkDtm({ data: modelData })
            console.log(r)
            commit('pushResult', {
              ...data,
              results: r.testCheckResult
            })
            return r
          })
        )
      }
      return await Api.checkTest({
        test_group_id: testGroupId,
        results: testGroup.map(test => {
          return {
            test_id: test.id,
            tasks: test.test
              .map(e => e.model)
              .filter(i => i)
              .map(i => {
                if (i.answer === '') i.answer = 'нет ответа'
                return i
              })
          }
        })
      })
    },
    async checkTests({ commit }, data) {
      let modelData = data.test
        .map(e => e.model)
        .filter(i => i)
        .map(i => {
          if (i.answer === '') i.answer = 'нет ответа'
          return i
        })
      let r

      if (!modelData.length) return
      if (data.testGroupId === 'dtm')
        r = await Api.checkDtm({ data: modelData })
      else
        r = await Api.checkTest({
          results: [{ test_id: data.id, tasks: modelData }],
          test_group_id: data.testGroupId
        })

      if (!r.testCheckResult) return
      commit('pushResult', {
        ...data,
        results: r.testCheckResult
      })
    },
    async saveTestsDataToLS({ getters }) {
      window.localStorage.setItem(
        'testsData',
        JSON.stringify(getters.testsData)
      )
    },
    async regenerateTests({ dispatch, commit }, tests) {
      commit('clearDtmTests')
      let loadedCounter = 0
      tests.forEach(t => {
        dispatch('generateAndPushDtmTest', t).then(() => {
          loadedCounter++
          if (loadedCounter === tests.length) commit('allTestsIsLoaded')
        })
      })
    },
    async generateAndPushDtmTest({ commit }, data) {
      let r = await Api.generateDtm(data.subjectId, data.numberQuestions || 30)
      if (!r.success) return
      // Сгенерированные дтм тесты всегда имеют id = dtm
      commit('pushTestData', {
        ...data,
        testGroupId: 'dtm',
        test: r.data.dtm
      })
    },
    async setTests({ commit }, testGroupId) {
      // Получаем группу тестов
      let r = await Api.getGroupTests(testGroupId)
      let testsIds = r.map(t => t.id)
      if (r[0].time_limit) {
        let timeLimit = r[0].time_limit
        let ps = timeLimit.split(':').map(p => parseInt(p))
        try {
          // Получаем время в миллисекундах
          const time = (ps[2] + ps[1] * 60 + ps[0] * 3600) * 1000
          if (time && !isNaN(time)) {
            commit('setTimeLimit', {
              timeLimit: time,
              testGroupId
            })
          }
        } catch (error) {}
      }
      for (const i in testsIds) {
        let id = testsIds[i]
        await Api.getTestById(id).then(r => {
          // Get timestamp when document updated
          let timeStamp = 0
          if (r.data.updated_at) {
            const date = new Date(r.data.updated_at)
            timeStamp = date.getTime()
          }
          // write data
          commit('pushTestData', {
            test: r.data.tasks,
            testGroupId,
            id,
            updated_at: timeStamp
          })
        })
      }
    },
    async createTest(ctx, data) {
      let headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
      try {
        let response = await fetch('https://xacademy.uz/api/tests/store', {
          headers,
          method: 'POST',
          body: JSON.stringify(data)
        }).then(response => response.json())
        return response
      } catch (err) {
        throw [err]
      }
    }
  },
  getters: {
    testsData: s => {
      return s.testsData
    },
    results: s => s.results,
    awaitTest: s => s.awaitTest,
    allTestsIsLoaded: s => s.testLoaded,
    testGroup: s => s.testGroup
  }
}
