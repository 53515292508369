<template>
  <section class="sc-lessons-pros">
    <div class="row">
      <div class="col-lg-6 p-0">
        <div class="date-info">
          <span class="main--text"
            ><img src="../../../assets/svg/cal_icon.svg" alt="" /> 19.07.2021</span
          >
          <span class="main--text"
            ><img src="../../../assets/svg/time_icon.svg" alt="" /> 19:00</span
          >
        </div>
      </div>
      <div
        class="video-box"
        :class="{ paused: paused }"
        @click="paused = false"
      >
        <video
          class="video"
          controls
          disablepictureinpicture
          controlslist="nodownload"
        >
          <source src="../../../assets/videos/lesson-video.mp4" type="video/mp4" />
        </video>
      </div>
      <div class="tip-box">
        <h4 class="main--text">
          <img src="../../../assets/svg/info_icon.svg" alt="" /> Для этого урока
          доступно домашнее задание
        </h4>
        <a href="#" class="do-homework">Выполнить домашнее задание</a>
      </div>
      <div class="about-box">
        <h2>О чем занятие?</h2>
        <div class="video-time-info">
          <div class="time-step"><a href="#">0:00</a></div>
          <div class="step-info">
            <p>Начало вебинара. Обсуждение организационных моментов.</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">4:40</a></div>
          <div class="step-info">
            <p>План занятий на ближайшие 3,5 месяца.</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">7:00</a></div>
          <div class="step-info">
            <p>План сегодняшнего занятия.</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">7:50</a></div>
          <div class="step-info">
            <p>
              Информация о курсе по основам профильной математики и
              дополнительным обязательным вебинарам
            </p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">11:10</a></div>
          <div class="step-info">
            <p>Теория. Определение и свойства степеней с примерами.</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">44:40</a></div>
          <div class="step-info">
            <p>
              Теория. Определение логарифма. Ограничения, связанные с логарифмом
            </p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">53:10</a></div>
          <div class="step-info">
            <p>Теория. Основное логарифмическое тождество</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">1:11:50</a></div>
          <div class="step-info">
            <p>Теория. Десятичный логарифм</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">1:13:10</a></div>
          <div class="step-info">
            <p>Теория. Натуральный логарифм</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">1:16:40</a></div>
          <div class="step-info">
            <p>Теория. Свойства логарифмов с примерами</p>
          </div>
        </div>
        <div class="video-time-info">
          <div class="time-step"><a href="#">1:57:50</a></div>
          <div class="step-info">
            <p>
              Все основные формулы, связанные с логарифмами и степенями.
              Подведение итогов вебинара
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'LessonPros',
  data() {
    return {
      paused: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';

.sc-lessons-pros {
  padding-top: 40px;
  padding-bottom: 30px;
}

.title {
  width: 100%;
  padding-top: 5px;
  margin-bottom: 25px;
}

.subtitle {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #8c8e97;
}

h1 {
  a {
    color: #191d2f;
    text-decoration: none;
    &:hover {
      svg {
        transform: translateX(-10px) rotate(180deg);
      }
    }
  }
  svg {
    margin-right: 10px;
    margin-left: 0;
    transition: 0.2s;
    transform: rotate(180deg);
  }
}

.date-info {
  display: flex;
  width: 100%;
  padding-left: 20px;
  span {
    display: flex;
    align-items: center;
    margin: 0 12px;
    img {
      margin-right: 10px;
    }
  }
}

.video-box.paused {
  background: rgba(29, 160, 242, 0.205);
  &:hover {
    &:before {
      transform: scale(1.3);
    }
  }
  &:before {
    content: '';
    position: absolute;
    width: 28px;
    height: 31px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../../../assets/svg/play.svg);
    background-size: cover;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    transition: 0.2s;
  }
  video {
    opacity: 0.5;
  }
}

.video-box {
  display: flex;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  margin-top: 32px;
  margin-bottom: 8px;

  video {
    width: 100%;
    border-radius: 12px;
    cursor: pointer;
    transition: 0.2s;
  }
}

.tip-box {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 12px 24px 12px 32px;
  background: #ddf1fd;
  border-radius: 12px;
  align-items: center;
  margin-bottom: 8px;
  padding: 10px 24px 10px 32px;
  h4 {
    margin-bottom: 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 14px;
    }
  }
}

.do-homework {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #1da1f2;
  text-decoration: none;
  transition: 0.2s;
  &:hover {
    color: #8ed0f9;
  }
}

.about-box {
  display: inline-block;
  width: 100%;
  margin-top: 28px;
  h2 {
    margin-bottom: 20px;
  }
}

.video-time-info {
  display: flex;
}

.time-step {
  width: 50px;
  flex-shrink: 0;
  margin-right: 12px;
  a {
    font-family: $mb;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #1da1f2;
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      color: #8ed0f9;
    }
  }
}

.step-info {
  p {
    font-family: $mr;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    color: #000000;
    margin: 5px 0;
  }
}
</style>
