<template>
  <b-modal ref="imgModal" id="chat-img-modal" class="modal vue-component">
    <div>
      <img class="img" :src="src" :alt="alt" />
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'modal',
  data: function () {
    return {}
  },
  methods: {
    show() {
      this.$refs.imgModal.show()
    }
  },
  computed: {},
  components: {},
  props: {
    src: {
      type: String,
      required: true
    },
    alt: {
      type: String
    }
  },
  mixins: {},
  beforeMount() {},
  mounted() {}
}
</script>

<style scoped='scoped' lang='scss'>
.img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}
</style>
