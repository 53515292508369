<template>
  <section class="sc-my-courses">
    <div class="row">
      <div class="col-lg-6">
        <title-nav :to="{ name: 'my-courses-detail' }">{{
          $t('data.11')
        }}</title-nav>
      </div>

      <div class="col-lg-6 d-none d-md-block">
        <div class="slider-rulers" v-if="pagesNum > 1">
          <ul class="slider-dots">
            <li
              v-for="(c, i) in pagesNum"
              :key="i"
              :class="{ 'active-slide': i === activePageIndex }"
              @click="toPage(i)"
            ></li>
          </ul>
          <div class="sl-arrows-box">
            <button
              class="slider-arrows slide-prev"
              @click="prev"
              :disabled="activePageIndex === 0"
            >
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L9 8.72L2 16"
                  stroke="#191D2F"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <button
              class="slider-arrows slide-next"
              @click="next"
              :disabled="activePageIndex === pagesNum - 1"
            >
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L9 8.72L2 16"
                  stroke="#191D2F"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      <div class="col-12">
        <div class="slider-box" v-show="sliderItems.length">
          <TinyCarousel
            class="slider-overflow d-flex flex-column flex-md-row"
            ref="sliderContainer"
            tag="div"
          >
            <my-course-card
              v-for="item in sliderItems"
              :key="item.index"
              class="slider-item"
              :course="item"
            />

            <!-- <li
              v-for="item in sliderItems"
              :key="item.index"
              class="slider-item"
              >
                <course-card
                  :course="item"
                />
            </li> -->
          </TinyCarousel>
        </div>
        <empty-list v-if="!sliderItems.length">{{ $t('data.12') }}</empty-list>
      </div>
    </div>
  </section>
</template>

<script>
import TinyCarousel from '@frsource/tiny-carousel-vue'

import Api from '@/api/endpoints'

import MyCourseCard from '@/components/views/courses-components/MyCourseCard'
export default {
  name: 'MyCourses',
  data() {
    return {
      sliderItems: [],
      slider: null,
      sliderIndex: 0,
      slideNum: 3,
      activePageIndex: 0,
      }
  },
  computed: {
    pagesNum() {
      if(this.sliderItems.length > 3){
        let additive = this.sliderItems.length % this.slideNum == 0 ? 0 : 1
        return Math.floor(this.sliderItems.length / this.slideNum) + additive
      }
      return 0 
    }
  },

  methods: {
    async setCourses() {
      let r = await Api.getCoursesOfUser()
        const res = r.data.map((course) => {
        course.lessonName = course.name
        course.lessonN = this.$t('urok-course-closest_lesson_number', [
          course.closest_lesson_number
        ])
        course.lessonDescription = course?.closest_lesson?.theme || ''
        course.to = { name: 'my-course', params: { id: course.id } }
        course.lessonTeacher = `${course.teacher?.firstName || ''} ${
          course.teacher?.lastName || ''
        } ${course.teacher?.middleName || ''}`
        return course
      })
        this.sliderItems = res
    },

    next() {
      const carousel = this.$refs.sliderContainer.carousel
      this.sliderIndex += this.slideNum
      carousel.goTo(this.sliderIndex)
      this.activePageIndex += 1
    },
    prev() {
      const carousel = this.$refs.sliderContainer.carousel
      this.sliderIndex -= this.slideNum
      carousel.goTo(this.sliderIndex)
      this.activePageIndex -= 1
    },
    toPage(i) {
      const carousel = this.$refs.sliderContainer.carousel
      this.activeIndex = i * this.slideNum
      this.activePageIndex = i
      carousel.goTo(this.activeIndex)
    }
  },

  components: {
    MyCourseCard,
    TinyCarousel
  },
  mounted() {
    
  },
  beforeMount() {
    this.setCourses()
  }
}
</script>

<style lang="scss" scoped>
@import 'https://cdn.skypack.dev/@frsource/tiny-carousel-core/dist/index.css';
@import '../../../styles/vars';

.sc-my-courses {
  padding-top: 46px;
  padding-bottom: 15px;
}

h1 {
  margin-bottom: 15px;
  a {
    color: #191d2f;
    text-decoration: none;
    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }
  svg {
    margin-left: 10px;
    transition: 0.2s;
  }
}

.slider-rulers {
  display: flex;
  justify-content: flex-end;
  margin-top: 2px;
}

.slider-dots {
  display: inline-block;
  margin: 5px 0;
  .active-slide {
    opacity: 1;
    background: #1da1f2;
  }
  li {
    background: #8c8e97;
    opacity: 0.3;
    border-radius: 3px;
    width: 8px;
    height: 8px;
    display: inline-block;
    margin: 0 2px;
    cursor: pointer;
  }
}

.sl-arrows-box {
  display: inline-block;
  margin-left: 24px;
}

.slider-arrows.slide-prev {
  svg {
    left: -2px;
    transform: rotate(180deg);
  }
}

.slider-arrows.slider-next {
  svg {
    right: -2px;
  }
}

.slider-arrows {
  width: 32px;
  height: 33px;
  border-radius: 12px;
  padding: 0;
  outline: none;
  box-shadow: none;
  display: inline-block;
  background: none;
  border: 2px solid #191d2f;
  margin-left: 8px;
  position: relative;
  background: #fff;
  transition: all 0.2s;
  &:disabled {
    opacity: 0.3;
    user-select: none;
    &:hover {
      background: #fff;
      path {
        stroke: #191d2f;
      }
    }
  }
  &:hover {
    background: #191d2f;
    path {
      stroke: #fff;
    }
  }
  svg {
    max-width: 8px;
    position: absolute;
    top: 1px;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }
}

.slider-box {
  width: 100%;
}

.slider-overflow {
  padding: 15px 0 30px 0;
  position: relative;
  overflow: hidden;
}

.slider-item {
  width: 310px;
  min-height: 179px;
  margin: 0 13px;
  position: relative;
}

.empty-list.vue-component {
  font-family: 'Montserrat-Regular';
}
.frs-tc--o:after,
.frs-tc-item {
  flex: none;
}
.frs-tc--o:before{
  flex:0;
}

@media (max-width: 768px) {
  .slider-overflow {
    overflow: visible;
  }

  .slider-item {
    width: 100%;
    margin: 30px 0;
  }
}
</style>
