<template>
  <h4 class="subtitle"><slot /></h4>
</template>

<script>
export default {
  name: 'subtitle',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
@import './src/styles/vars.scss';

.subtitle {
  font-family: $mb;
  padding-left: 32px;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #8c8e97;
}
</style>
