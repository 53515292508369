<template>
  <div class="view-test vue-component">
    <div class="row">
      <div class="col-12 col-md-8">
        <div>{{ $t('data.251') }}</div>
        <a href="http://164.90.237.254:3001/tests">{{ $t('data.267') }}</a>
        <test v-if="tests" :tests="testsComputed" :isPreview="true" />
      </div>
    </div>
  </div>
</template>

<script>
import Test from '@/components/UI/question/TestsWrapper'

import Api from '@/api/endpoints'

export default {
  name: 'view-test',
  data: function () {
    return {
      tests: []
    }
  },
  methods: {},
  computed: {
    testsComputed() {
      return [
        {
          subjectName: 'name',
          test: this.tests
        }
      ]
    }
  },
  components: {
    Test
  },
  props: [],
  mixins: {},
  async beforeMount() {
    let r = await Api.getTestById(this.$route.params.id)
    this.tests = r.data.tasks
  }
}
</script>

<style scoped='scoped' lang='sass'></style>
