import LessonsLists from '@/components/templates/LessonsLists'

import LessonTypicalBud from '@/components/views/lessons-components/LessonTypicalBud.vue'
import LessonTypicalPros from '@/components/views/lessons-components/LessonTypicalPros.vue'
import LessonOnline from '@/components/views/lessons-components/LessonOnline'
import LessonsRecord from '@/components/views/lessons-components/LessonsRecord'
import i18n from '@/i18n'

export default [
  {
    path: 'list',
    name: 'lessons-list',
    component: LessonsLists,
    meta: {
      title: i18n.t('data.47'),
      titleI: 'data.47'
    }
  },
  {
    name: 'lesson-upcoming',
    path: 'upcoming/:id',
    component: LessonTypicalBud
    // meta: {
    //     title: '№1 Present simple',
    //     subtitle: 'Курс по «Английскому языку»'
    // }
  },
  {
    name: 'lesson-online',
    path: 'online/:id',
    component: LessonOnline
  },
  {
    name: 'lesson-record',
    path: 'record/:id',
    component: LessonsRecord,
    meta: {
      title: 'Уроки по вашим курсам'
    }
  },
  {
    path: 'go',
    component: LessonTypicalPros,
    meta: {
      title: '№1 Present simple',
      subtitle: 'Курс по «Английскому языку»'
    }
  }
]
