<template>
  <div class="banner">
    <section
      :class="['sc-banner', bannerInfo ? backgroundColorBanner : '']"
      :style="isMobile ? '' : 'background-image: url(' + bgImg + ')'"
    >
      <div class="container">
        <div v-if="bannerInfo" class="banner-info w-100 w-md-40">
          <div class="title w-60">
            <h4
              :class="{
                'end-olympiad':
                  bannerInfo.olympiadStatus == 987 ||
                  bannerInfo.olympiadStatus == 685
              }"
              v-if="bannerInfo.title"
            >
              {{ bannerInfo.title }}
            </h4>
          </div>
          <p
            v-if="!bannerInfo.isRegister || bannerInfo.subtitle"
            class="main--text"
            :class="{
              'end-olympiad':
                bannerInfo.olympiadStatus == 987 ||
                bannerInfo.olympiadStatus == 685
            }"
          >
            {{ bannerInfo.subtitle }}
          </p>
          <div class="w-100">
            <div v-show="bannerInfo.type == 'upcomming'">
              <button
                @click="$emit('registerOlympiad', true)"
                v-if="
                  !bannerInfo.isRegister &&
                  bannerInfo.olympiadStatus != 2 &&
                  bannerInfo.olympiadStatus != 987 &&
                  bannerInfo.olympiadStatus != 0
                "
                class="go-test banner-btn"
              >
                Зарегистрироватся
              </button>
              <button
                @click="$emit('startOlympiad', true)"
                v-else-if="
                  bannerInfo.isRegister &&
                  bannerInfo.timeToOlympiad == 0 &&
                  bannerInfo.olympiadStatus == 678
                "
                class="go-test banner-btn"
              >
                Начать олимпиаду
              </button>
              <p
                class="main--text bold"
                v-else-if="bannerInfo.olympiadStatus == 234"
              >
                Вы пропустили олимпиаду
              </p>
              <p
                class="main--text text-green bold"
                v-else-if="bannerInfo.olympiadStatus == 987"
              >
                Вы завершили олимпиаду
              </p>
              <p
                class="main--text text-green bold"
                v-else-if="bannerInfo.olympiadStatus == 685"
              >
                Вы уже завершили текущий этап олимпиады
              </p>
              <counter
                v-else-if="
                  bannerInfo.isRegister &&
                  bannerInfo.timeToOlympiad > 0 &&
                  bannerInfo.olympiadStatus != 234 &&
                  bannerInfo.olympiadStatus != 3
                "
                @updateTimer="updateTimer"
                :isOlympiad="true"
                :current-time="bannerInfo.timeToOlympiad"
              />
            </div>
            <ul class="places" v-if="bannerInfo.places">
              <li v-for="(place, index) in bannerInfo.places" :key="index">
                <p :class="'place-' + index">
                  {{ index + 1 }} место - {{ place }}
                </p>
              </li>
            </ul>
          </div>
        </div>

        <div v-else class="banner-info">
          <span v-if="banner.subTitle">{{ banner.subTitle }}</span>
          <div class="w-100">
            <h4>{{ banner.title }}</h4>
          </div>
          <p class="main--text">{{ banner.text }}</p>
          <div class="w-100">
            <!-- <router-link
              to="/all-courses"
              
              >
            </router-link> -->
            <a
              href="https://t.me/xacademyieltsweb_bot"
              target="_blank"
              class="go-test banner-btn"
              :style="banner.buttonStyles"
              >{{ $t('data.14') }}</a
            >
            <!-- <a
              href="https://xacademy.uz/rus.html"
              class="go-test banner-btn"
              target="_blank"
              >{{ $t('data.14') }}</a
            > -->
          </div>
        </div>
      </div>
    </section>
    <div
      class="banner-bg d-block d-md-none"
      :style="'background-image: url(' + bgImg + ')'"
    />
  </div>
</template>

<script>
import Counter from '@/components/UI/question/Timer/Counter'

export default {
  components: {
    Counter
  },
  name: 'Banner',
  props: {
    bgImg: String || null,
    bannerInfo: Object || null
  },

  data() {
    return {
      banner: {
        subTitle: '',
        title: this.$t('data.172'),
        text: this.$t('data.10'),
        buttonStyles: {
          color: '#191D2F',
          'background-color': '#fff'
        },
        footerText: this.$t('data.14'),
        img: require('@/assets/img/banner_bg.png')
      },
      slider: false,
      currentSlide: 0
    }
  },
  methods: {
    updateTimer() {
      this.$emit('updateTimerOlympiad')
    }
  },
  computed: {
    backgroundColorBanner() {
      let bg = ''
      if (this.bannerInfo.olympiadStatus === 1) {
        bg = 'blue'
      }
      // User register
      if (
        this.bannerInfo.timeToOlympiad == 0 &&
        this.bannerInfo.olympiadStatus == 678
      ) {
        bg = 'green'
      }
      if (this.bannerInfo.olympiadStatus === 3) {
        bg = 'red'
      }
      // Завершил олимпиаду или этам
      if (
        this.bannerInfo.olympiadStatus === 987 ||
        this.bannerInfo.olympiadStatus === 685
      ) {
        bg = 'gray'
      }
      return bg
    },

    screenWidth() {
      return window.innerWidth
    },

    isMobile() {
      const breakpoint = 768

      return this.screenWidth < breakpoint
    }
  }
}

function getRandomInt(min, max) {
  return Math.floor(Math.random() * (max - min)) + min
}
</script>

<style lang="scss" scoped>
@import '../../styles/vars';

.sc-banner {
  background-color: #1da1f2;
  border-radius: 12px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;

  &.blue {
    background-color: #1da1f2;
  }

  &.green {
    background-color: #00af07;
  }

  &.red {
    background-color: #e40a0a;
  }

  &.gray {
    background-color: #f0f0f0;
  }
  // .container{
  //   .title{
  //     max-width: 360px;
  //   }
  // }
}
.end-olympiad {
  color: black !important;
}
.banner-btn {
  font-family: 'Montserrat-Bold';
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #191d2f;
  display: inline-block;
  padding: 16px 14px;
  background: #ffffff;
  border-radius: 13px;
  border: 1px solid rgba(140, 142, 151, 0.4);
  overflow: none;
  text-decoration: none;
  transition: 0.2s;
  text-decoration: none;
}

.banner-info {
  display: flex;
  flex-direction: column;
  // align-items: center;
  justify-content: space-evenly;
  min-height: 348px;
  flex-wrap: wrap;
  padding: 20px 0px;
  max-width: 360px;
}

span {
  font-family: $mr;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  display: inline-block;
  color: #ffffff;
  background: #191d2f;
  border-radius: 30px;
  padding: 8px 16px;
  margin: 5px 0;
}

h4 {
  font-family: $mb;
  font-size: 54px;
  line-height: 68px;
  color: #ffffff;
  margin: 5px 0 10px 0;
}

.title {
  h4 {
    font-size: 24px;
    line-height: 32px;
  }
}

.places {
  padding: 0;
  margin: 0;
  list-style: none;

  p {
    text-transform: uppercase;
    padding: 0;
    color: #fff;
    opacity: 0.4;
    margin: 0;

    &.place-0 {
      opacity: 1;
    }
  }
}

p {
  color: #ffffff;
  max-width: 376px;
}
.text-green {
  color: #00af07;
}
.bold {
  font-weight: bold;
}

.go-test {
  margin: 5px 0;
}

@media (max-width: 768px) {
  .sc-banner {
    border-radius: 0;
  }
  .banner {
    // margin: 0 -17px;
  }
  .sc-banner {
    height: 350px;
  }

  .banner-bg {
    width: 100%;
    height: 270px;

    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;

    background-color: #1da1f2;
  }
}
</style>
