<template>
  <div class="olympiads container vue-component">
    <vue-skeleton-loader
      v-if="loading"
      :duration="1000"
      :width="'100%'"
      :height="'100px'"
      :theme="'#f5f5f5'"
      :showing="true"
      :loading="true"
      :animation="'wave'"
    />
    <!-- <div v-else-if="olympiadDataLoadingState == 'error'" class="message-error">
      Ошибка загрузки результатов
    </div> -->
    <tabs
      v-else
      :tabs="[$t('data.272'), $t('data.271')]"
    >
      <template :slot="$t('data.272')">
        <div class="olympiads-row row">
          <banner
            @registerOlympiad="registerForOlympiad"
            @updateTimerOlympiad="updateTimer"
            @startOlympiad="startOlympiad"
            :bannerInfo="bannerInfo"
            :bg-img="require('@/assets/img/olympiad.png')"
          />
        </div>
      </template>
      <template :slot="$t('data.271')">
        <div class="olympiads-row row">
          <banner
            class="banner"
            :bannerInfo="bannerInfoResult"
            :bg-img="require('@/assets/img/olympiad.png')"
          />
          <tabs :tabs="results.tabs" v-if="winnersReady">
            <template v-for="(tab, i) in results.tabs" :slot="tab">
              <div class="olympiads-row row" :key="i">
                <table-data
                  :isOlympiad="true"
                  :pagination="true"
                  :items="results.items[i]"
                  :fields="fields"
                />
              </div>
            </template>
          </tabs>
        </div>
      </template>
    </tabs>
  </div>
</template>

<script>
import Api from '@/api/endpoints'
import Banner from '@/components/UI/Banner'
import TableData from '@/components/UI/table-data/TableData'
import { mapGetters, mapActions } from 'vuex'
import VueSkeletonLoader from 'skeleton-loader-vue'
import i18n from '@/i18n'

export default {
  components: {
    TableData,
    Banner,
    VueSkeletonLoader
  },
  data: function () {
    return {
      // olympiadStatus
      // 1 - ждем старта олимпиады (синий цвет)
      // 678 - регистрация успешная
      // 2 - олимпиада в процессе (зеленые цвет)
      // 3 - олимпиада пропущена (красный цвет)
      // 987 - ждем результатов (серый цвет)
      // 685 - Вы завершили текущий этап олимпиады
      loading: true,
      bannerInfo: {
        type: 'upcomming',
        title: i18n.t('data.270'),
        subtitle: '',
        footerText: i18n.t('data.273'),
        olympiadStatus: 1,
        isRegister: false,
        timeToOlympiad: 10
      },
      bannerInfoResult: {
        type: 'result',
        title: i18n.t('data.274'),
        subtitle: '',
        olympiadStatus: 0,
        isRegister: true,
        places: []
      },
      results: {
        tabs: [],
        items: {}
      },
      fields: [
        { key: 'num', label: i18n.t('data.276') },
        { key: 'user.fullName', label: i18n.t('data.275') },
        { key: 'user.school', label: i18n.t('data.277') },
        { key: 'user_score', label: i18n.t('data.278') }
      ],
      winnersReady: false
    }
  },
  methods: {
    ...mapActions(['getOlympiadData', 'getOlympiadResults']),
    updateTimer() {
      this.bannerInfo.timeToOlympiad = this.bannerInfo.timeToOlympiad - 1
    },
    startOlympiad() {
      try {
        const id = this.currentOlympiad.olympiad.stage.id
        this.$router.push({
          name: 'olympiad-test',
          params: {
            id: id
          }
        })
      } catch (e) {
        console.error('Error start olympiad', e)
      }
    },
    // Register for current olympiad and reload page
    async registerForOlympiad(val) {
      if (this.currentOlympiad && this.currentOlympiad.olympiad.id) {
        const id = this.currentOlympiad.olympiad.id
        console.log('Register for current olympiad', id)
        await Api.regForOlympiad(id)
          .then((res) => {
            window.location.reload()
          })
          .catch((err) => {
            console.error(err)
          })
      }
    },
    setOlympiadData() {
      // set loading for true
      this.loading = true
      const data = this.currentOlympiad
      if (data) {
        if (data?.status)
          try {
            const beforeStart = data.olympiad.start
              ? parseInt((Date.parse(data.olympiad.start) - Date.now()) / 1000)
              : 0
            this.bannerInfo.title = data.olympiad.name
            this.bannerInfo.subtitle =
              data.status == 1
                ? i18n.t('data.279')
                : ''
            this.bannerInfo.olympiadStatus = data.status
            this.bannerInfo.isRegister = data.registration
            this.bannerInfo.timeToOlympiad = beforeStart > 0 ? beforeStart : 0
          } catch (err) {
            console.error('Error set olympiad data', err)
          }
        else {
          this.bannerInfo.olympiadStatus = 0
        }
      }
      this.loading = false
    },
    // Create result object for current olympiad if results exist
    setOlympiadResults() {
      const data = this.olympiadResults
      const currentOlympiad = this.currentOlympiad
      // set title for banner
      this.bannerInfoResult.title = currentOlympiad?.olympiad?.name
        ? currentOlympiad.olympiad.name
        : i18n.t('data.270')

      // if have current olympiad but not yet havee results
      if (data && data.length > 0) {
        let partCounted = 1
        data.forEach((item) => {
          if (item.status == 'finished') {
            this.results.tabs.push(`${partCounted} этап`)
            this.results.items[partCounted - 1] = item.grid
            partCounted++
          }
        })
        // check if all stages finished , for show our winners from stage 3
        console.log(data)
        const winners_ready = data.some((item) => {
          if (item.status == 'finished') {
            return true
          }
          return false
        })
        this.winnersReady = winners_ready
        if (winners_ready) {
          this.bannerInfoResult.type = 'result'
          // Sort first for remove myself from 1st place if my scoe lower
          this.bannerInfoResult.places = data[0].grid
            .sort((a, b) => {
              return parseInt(a.num) - parseInt(b.num)
            })
            .map((item) => {
              return `${item.user.firstName} ${item.user.lastName}`
            })
        } else {
          this.bannerInfoResult.subtitle =
            i18n.t('data.290')
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentOlympiad',
      'olympiadResults',
      'olympiadResults',
      'olympiadDataLoadingState'
    ])
  },

  async mounted() {
    await this.getOlympiadData()
    await this.getOlympiadResults()
    this.setOlympiadData()
    this.setOlympiadResults()
    console.log('Olympiad.status', this.currentOlympiad.status)
    console.log('Olympiad result', this.olympiadResults)
  }
}
</script>

<style lang="scss">
.olympiads {
  .sc-banner {
    background-size: contain;
    background-position: right;
  }
  .banner {
    margin-bottom: 20px;
  }
}
</style>
