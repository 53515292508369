<template>
  <div class="chat-slider vue-component">
    <div class="slider-header">
      <arrow
        v-if="showPrevBtn"
        class="slider-header__prev slider-header__nav"
        @click.native="prevSlide"
      ></arrow>
      <div class="slider-header__items-wrap" ref="itemsWrap">
        <div style="white-space: nowrap">
          <div
            class="slider-header__item"
            :class="{ 'slider-header__item--active': activeIndex === i }"
            v-for="(item, i) in items"
            :key="i"
            @click="setActiveItem(i)"
          >
            {{ item.name }}
          </div>
        </div>
      </div>
      <arrow
        v-if="showNextBtn"
        class="slider-header__next slider-header__nav"
        @click.native="nextSlide"
      ></arrow>
    </div>
    <chat-prevs
      class="slider-content"
      :subject="activeItem.chats"
      :subjectName="activeItem.name"
      v-if="activeItem"
    />
  </div>
</template>

<script>
import ChatPrevs from './ChatPrevs'
import Arrow from '@/components/chat/icons/Arrow'

export default {
  name: 'chat-slider',
  data: function () {
    return {
      activeIndex: 0,
      showPrevBtn: false,
      showNextBtn: true
    }
  },
  methods: {
    setActiveItem(i) {
      this.activeIndex = i
    },
    nextSlide() {
      let el = this.$refs.itemsWrap
      el.scroll({
        left: el.scrollLeft + el.offsetWidth,
        behavior: 'smooth'
      })
      let maxLeft = [...el.querySelectorAll('.slider-header__item')].reduce(
        (acc, v) => acc + v.offsetWidth,
        0
      )
      if (el.scrollLeft + el.offsetWidth >= maxLeft) this.showNextBtn = false
      this.showPrevBtn = true
    },
    prevSlide() {
      let el = this.$refs.itemsWrap
      el.scroll({
        left: el.scrollLeft - el.offsetWidth,
        behavior: 'smooth'
      })
      if (el.scrollLeft === 0) this.showPrevBtn = false
      this.showNextBtn = true
    }
  },
  computed: {
    activeItem() {
      return this.items[this.activeIndex]
    }
  },
  components: {
    ChatPrevs,
    Arrow
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.slider-header {
  position: relative;
  &__item {
    margin-right: 12px;
    cursor: pointer;
    display: inline;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    &--active {
      font-weight: bold;
      &:before {
        content: '';
        height: 2px;
        background: #1da1f2;
        width: 100%;
        position: absolute;
        top: 34px;
      }
    }
    margin-bottom: 21px;
  }
  &__nav {
    position: absolute;
    top: 3px;
    cursor: pointer;
  }
  &__prev {
    transform: rotate(180deg);
    left: 7px;
  }
  &__next {
    right: 7px;
  }
  &__items-wrap {
    overflow: hidden;
    height: 38px;
    margin: 0 23px 0 23px;
    width: calc(100% - 46px);
  }
}
</style>
