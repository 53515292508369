import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import user from './modules/user'
import alerts from './modules/alerts'
import test from './modules/test'
import meta from './modules/meta'
import courses from './modules/courses'
import online from './modules/online'
import chat from './modules/chat'
import olympiad from './modules/olympiad'


Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  getters: {},
  actions: {},
  modules: {
    user,
    online,
    alerts,
    test,
    meta,
    courses,
    chat,
    olympiad
  }
  // plugins: [dataState]
})
