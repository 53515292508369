<template>
  <div class="chat-prevs">
    <prev
      v-for="(item, i) in subject"
      :key="i"
      class="chat-prevs__item"
      :active="item.chatId === activeChat.chatId"
      :item="item"
      @click.native="clickToChatPrev(i)"
    />
  </div>
</template>

<script>
import Prev from './Item'

import EventBus from '@/components/chat/eventBus'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'chat-prevs',
  data: function () {
    return {}
  },
  methods: {
    ...mapActions(['toggleChat']),
    clickToChatPrev(i) {
      if (i < 0 || i >= this.subject.length)
        return console.error('index is incorrect')
      this.setActiveChatByIndex(i)
    },
    setActiveChatByIndex(i) {
      EventBus.$emit('show-chat-window')
      this.toggleChat({
        ...this.subject[i],
        subject: this.subjectName
      })
    }
  },
  computed: {
    ...mapGetters(['activeChat', 'driver'])
  },
  components: {
    Prev
  },
  props: {
    subject: {
      required: true,
      type: Array
    },
    subjectName: {
      type: String
    }
  },
  mixins: {},
}
</script>

<style scoped='scoped' lang='scss'>
</style>
