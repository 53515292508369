<template>
  <div class="page-with-title container vue-component">
    <title-nav v-if="pageTitle">{{ pageTitle }}</title-nav>
    <h4 class="subtitle" v-if="pageSubTitle">{{ pageSubTitle }}</h4>
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'page-with-title',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['title', 'subtitle']),
    pageTitle() {
      return this.title || this.$route.meta?.title
    },
    pageSubTitle() {
      return this.subtitle || this.$route.meta?.subtitle
    }
  },
  components: {},
  props: [],
  mixins: {}
}
</script>

<style scoped="scoped" lang="scss">
@import './src/styles/vars.scss';

.page-with-title {
  padding-top: 40px;
  padding-bottom: 30px;
}
.subtitle {
  font-family: $mb;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #8c8e97;
}
.titles-wrap {
  margin-bottom: 31px;
}
</style>
