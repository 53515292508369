<template>
  <section class="sc-dtm-test">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <title-nav>{{ typeData.title }}</title-nav>
        </div>
        <div class="col-12">
          <alert text="Скоро в этом разделе появятся новые предметы" />
          <alert
            text="Вы можете выбрать только один предмет в свободном тестировании"
            type="warn"
          />
        </div>
        <div class="col-12">
          <div class="row">
            <div
              class="col-lg-6 custom--my"
              v-for="(test, i) in dtmTestDatas"
              :key="test.id"
            >
              <label v-if="test.show" class="test-box">
                <div class="test-info">
                  <input
                    :type="typeData.inputType"
                    :value="i"
                    v-model="selectedIndexes"
                    @change="showStart = true"
                    :name="test.subjectId + 'test'"
                  />
                  <div class="text-info">
                    <h2>{{ test.subjectName }}</h2>
                    <p class="main--text">
                      {{ test.text }}
                    </p>
                  </div>
                </div>
                <div class="test-img"><img :src="test.picture" alt="" /></div>
              </label>
            </div>
          </div>
        </div>
        <div class="col-12 text-center" v-if="showStart">
          <span
            @click="generateTestAndRedirect()"
            class="start-test primary--button"
            >{{ $t('nachat-testirovanie') }}</span
          >
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Alert from '@/components/UI/alerts/Alert'
import { mapActions, mapMutations } from 'vuex'
import Api from '@/api/endpoints'

export default {
  name: 'FreeTest',
  data() {
    return {
      dtmTestDatas: [],
      selectedIndexes: [],
      showStart: false
    }
  },
  methods: {
    ...mapActions(['regenerateTests', 'generateAndPushDtmTest']),
    ...mapMutations(['setTestInfo', 'setAwaitTest']),
    generateTestAndRedirect() {
      if (!Array.isArray(this.selectedIndexes))
        this.selectedIndexes = [this.selectedIndexes]
      // Если вид тестирования "ДТМ тестирование"
      // то мы примешиваем к тестированию и обязательные предметы
      let additionalIndexes = []
      if (this.isDtm) additionalIndexes = this.mixingRequiredSubjects()
      // По выбранным темам генерируем тесты
      this.regenerateTests(
        [...this.selectedIndexes, ...additionalIndexes].map(
          (i) => this.dtmTestDatas[i]
        )
      )
      // Обнавляем данные для страницы тестов
      this.setTestInfo({
        testGroupId: 'dtm',
        title: this.getTitleForNextPage(),
        type: this.isFree ? 'free' : 'not-free'
      })
      // Говорим, что мы ждем тест
      this.setAwaitTest(true)
      // Перекидываем на страницу результатов
      this.$router.push({
        name: 'dtm-testing'
      })
    },
    getTitleForNextPage() {
      let title = this.pageName
      if (!Array.isArray(this.selectedIndexes))
        this.selectedIndexes = [this.selectedIndexes]
      if (
        this.typeData.inputType == 'radio' ||
        this.selectedIndexes?.length == 1
      ) {
        return `${title} по предмету ${
          this.dtmTestDatas[this.selectedIndexes[0]].subjectName
        }`
      }
      return 'ДТМ тестирование'
    },
    mixingRequiredSubjects() {
      let mixing = this.dtmTestDatas
        .filter((s) => s.mix)
        .map((s) => {
          let c = { ...s }
          c.numberQuestions = 15
          c.required = true
          return c
        })
      let oLen = this.dtmTestDatas.length
      this.dtmTestDatas = [...this.dtmTestDatas, ...mixing]
      // Возврощаем массив индексов примешанных элементов
      return mixing.map((e, i) => oLen + i)
    },
    async setSubjects() {
      const subjects = await Api.getFullSubjects()
      console.log(subjects)
      // {
      //     subjectName: this.$t('data.222'),
      //     text: 'Значимость этих проблем настолько очевидна, что дальнейшее развитие различных форм деятельности требуют определения и уточнения систем массового участия.',
      //     picture: require('@/assets/img/hand-pen.jpg'),
      //     subjectId: 1,
      //     mix: true
      //   }
      this.dtmTestDatas = subjects.data
        .map((s) => {
          return {
            subjectName: s.name,
            text: s.description,
            picture: s.image,
            subjectId: s.id,
            mix: !!s.required_in_dtm,
            show: s.suggest_to_pass
          }
        })
    }
  },
  computed: {
    typeData() {
      let res = {
        inputType: 'checkbox',
        title: 'ДТМ тестирование'
      }
      if (this.$route.params?.type == 'free') {
        res.inputType = 'radio'
        res.title = 'Свободное тестирование'
      }
      return res
    },
    pageName() {
      if (this.$route.params?.type == 'free') {
        return 'Свободное тестирование'
      }
      return 'ДТМ тестирование'
    },
    isFree() {
      return this.$route.params?.type == 'free'
    },
    isDtm() {
      return this.$route.params?.type != 'free'
    }
  },
  components: {
    Alert
  },
  watch: {
    selectedIndexes(newVal) {
      if (Array.isArray(newVal)) {
        if (newVal.length == 0) {
          this.showStart = false
        }
      }
    }
  },
  beforeMount() {
    this.setSubjects()
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';
.sc-dtm-test {
  padding-top: 40px;
  padding-bottom: 30px;
}

.custom--my {
  margin: 12px 0;
}

.test-box {
  display: flex;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 24px;
  height: 100%;
  padding: 5px 0;
}
.test-info {
  display: flex;
  padding: 20px 0 20px 31px;
  input {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 16px;
    margin-top: 3px;
    outline: none;
  }
  h2 {
    margin-bottom: 10px;
  }
  p {
    color: #8c8e97;
    margin-bottom: 0;
  }
}

.test-img {
  flex-shrink: 0;
  display: flex;
  align-self: center;
  img {
    max-height: 120px;
  }
}
.start-test {
  margin-top: 30px;
}

@media (max-width: 1200px) {
  .test-box {
    overflow: hidden;
  }

  .col-lg-6 {
    width: 100% !important;
  }
}
</style>
