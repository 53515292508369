<template>
  <div class="chat-template vue-component">
    <div class="chat-template__content">
      <div style="border-bottom: 1px solid #f0f0f0">
        <div class="container">
          <header-my class="chat-template__header" />
        </div>
      </div>
      <router-view v-if="user" class="chat-template__router"></router-view>
    </div>
    <sidebar mode="mini" />
  </div>
</template>

<script>
import Sidebar from '@/components/base/SideBarMenu'
import HeaderMy from '@/components/base/header/Header'
import { mapGetters } from 'vuex'

export default {
  name: 'chatTemplate',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    ...mapGetters(['user'])
  },
  components: {
    Sidebar,
    HeaderMy
  },
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.chat-template {
  overflow-x: hidden;
  &__content {
    margin-left: 84px;
  }
  &__router {
    height: calc(100vh - 79px);
  }
}
</style>
