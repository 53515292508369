<template>
  <chat
    v-if="rocketIsInited && chatReady && chats.length"
    :chatmode="mode"
    :chats="chats"
    class="chat-page vue-component"
  />
  <div class="loader" v-else>
    <vue-skeleton-loader type="circle" :size="50" animation="wave" />
  </div>
</template>

<script lang="ts">
import Chat from './index.vue'
import { mapGetters, mapActions } from 'vuex'
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'chat-page',
  data: function () {
    return {
      mode: 'usual'
    }
  },
  methods: {
    ...mapActions(['getProfile', 'initRocket', 'unsubscribeFromChat'])
  },
  computed: {
    ...mapGetters(['chats', 'user', 'rocketIsInited', 'chatReady', 'rocketInitStart'])
  },
  components: {
    Chat,
    VueSkeletonLoader
  },
  props: [],
  async mounted() {
    // Init rocket if not yet inited
    // if (!this.rocketInitStart) {
    //   await this.getProfile()
    //   await this.initRocket(this.user.chat_token)
    // }
  },
  beforeDestroy() {
    // Unsubscribe from current chat when exit page
    this.unsubscribeFromChat(this.chat_id)
  }
}
</script>

<style scoped='scoped' lang='scss'>
.chat-page {
  height: calc(100vh - 79px);
}
.loader {
  height: calc(100vh - 79px);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
@media (max-width: 768px) {
  .chat-page,
  .loader {
    height: calc(100vh - 59px);
  }
}
</style>
