<template>
  <section class="courses-detail">
    <div class="courses-detail__block" v-if="myCourses.length">
      <my-course-card
          v-for="item in myCourses"
          :key="item.index"
          class="item"
          :course="item"
      />
    </div>
    <empty-list v-else>{{ $t('data.12') }}</empty-list>
  </section>
</template>

<script>
import HTTP from '@/api/endpoints'
import MyCourseCard from "@/components/views/courses-components/MyCourseCard";

export default {
  components: {
    MyCourseCard
  },

  data: () => ({
    myCourses: []
  }),
  mounted() {
    HTTP.getCoursesOfUser().then(({data}) => {
      this.myCourses = data.map((course) => {
        course.lessonName = course.name
        course.lessonN = `Урок №${course.closest_lesson_number}`
        course.lessonDescription = course?.closest_lesson?.theme || ''
        course.to = { name: 'my-course', params: { id: course.id } }
        course.lessonTeacher = `${course.teacher?.firstName || ''} ${
            course.teacher?.lastName || ''
        } ${course.teacher?.middleName || ''}`
        return course
      })
    })
  }
}
</script>

<style lang="scss">
.courses-detail{
  &__block{
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .item{
      width: 300px;
      margin: 20px 10px!important;
    }
  }
}
</style>
