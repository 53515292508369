
export default {
  state: {
    fullScreenPlayer: false,
    fullScreenMode: false,
    showChat: true,
    listener: null,
    userActive: false,
    videoTime: 0
  },
  mutations: {
    // When enter / exit  from fullscreen mode
    setFullScreenMode(state, payload) {
      // if exit from fullScreen mode also show back our chat
      if (!payload){
        state.showChat = true
      }
      state.fullScreenMode = payload
    },
    setFullScreenChatMode(state, payload) {
      state.showChat = payload
    },
    setPlayer(state, payload) {
      state.fullScreenPlayer = payload
    },
    setListener(state, payload) {
      state.listener = payload
    },
    setUserActiveState(state, payload){
      state.userActive = payload 
    },
    setTimeForVideo(state, payload){
      state.videoTime = payload
    }
  },
  actions: {
    
  },
  getters: {
    fullScreenMode: state => state.fullScreenMode,
    showPlayer: state => state.fullScreenPlayer,
    showChat: state => state.showChat,
    listener: state => state.listener,
    userActiveState: state => state.userActive,
    setterVideoTime: state => state.videoTime,

  }
}
