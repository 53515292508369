<template>
  <div class="lesons-list vue-component">
    <tabs :tabs="[$t('data.48'), $t('data.49')]" class="tabs" >
      <template :slot="$t('data.48')">
        <lessons-upcoming
          :courseById="courseById"
          class="lessons-upcoming"
          v-if="lessonsUpcoming.length"
          :lessons="lessonsUpcoming"
        />
        <empty-list v-else>{{ $t('data.50') }}</empty-list>
      </template>
      <template :slot="$t('data.49')">
        <lessons-table class="lessons-table" :lessons="lessonsPast" />
        <empty-list v-if="!lessonsPast.length">{{ $t('data.170') }}</empty-list>
      </template>
    </tabs>
  </div>
</template>

<script>
import LessonsTable from '@/components/views/lessons-components/LessonsTable'
import LessonsUpcoming from '@/components/views/lessons-components/LessonsUpcoming'
import moment from 'moment'

export default {
  name: 'lessons-list',
  data: function () {
    return {}
  },
  methods: {
    getDivByCurrentAndLessonTimes(date) {
      let dateStr = date.replace(' ', 'T')
      let currentDate = +new Date()
      let startLessonTime = +new Date(dateStr)
      return currentDate - startLessonTime - 30 * 60 * 1000
    },
    updateLessonData(lessons) {
      return lessons.map((l) => {
        l.date = moment(l.start_time).format('DD.MM.YYYY')
        l.time = moment(l.start_time).format('HH:mm')
        return l
      })
    }
  },
  computed: {
    lessonsPast() {
      return this.updateLessonData(
        this.lessons.filter(
          (lesson) => 0 <= this.getDivByCurrentAndLessonTimes(lesson.start_time)
        ) || []
      ).sort((a, b) =>
        +new Date(a.start_time) < +new Date(b.start_time) ? 1 : -1
      )
    },
    lessonsUpcoming() {
      return this.updateLessonData(
        this.lessons.filter(
          (lesson) => 0 > this.getDivByCurrentAndLessonTimes(lesson.start_time)
        ) || []
      ).sort((a, b) =>
        +new Date(a.start_time) < +new Date(b.start_time) ? -1 : 1
      )
    }
  },
  components: {
    LessonsTable,
    LessonsUpcoming
  },
  props: {
    lessons: {
      required: true,
      type: Array
    },
    courseById: Boolean || null
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style lang="scss" scoped>
.empty-list.vue-component {
  font-family: 'Montserrat-Regular';
}
</style>


