<template>
  <div class="substrate vue-component">
    <div class="substrate__logo">
      <img class="substrate__logo_rotating" src="@/assets/svg/soon-text-white.svg" alt="" />
      <img class="substrate__logo_x" src="@/assets/svg/soon-x-white.svg" alt="" />
    </div>
    <div class="substrate__title"><slot /></div>
  </div>
</template>

<script>
export default {
  name: 'substrate',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.substrate {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  &__title {
    font-size: 46px;
    text-align: center;
    line-height: 46px;
  }
  .substrate__logo {
  display: inline-block;
  position: relative;
  width: 90px;
  height: 90px;
  user-select: none;
    margin-bottom: 15px;

  img {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    max-width:90px;
  }
  .substrate__logo_x{
      width: 45px;
      height: 45px;
  }
}

@media (max-width: 992px){
.substrate {

  &__title {
    font-size: 32px;
    line-height: 32px;
  }
}
}

@media (max-width: 768px){
.substrate {

  &__title {
    font-size: 28px;
    line-height: 28px;
  }
}
}

@media (max-width: 586px){
.substrate {

  &__title {
    font-size: 24px;
    line-height: 24px;
  }
}
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.substrate__logo_rotating {
  -webkit-animation: rotating 10s linear infinite;
  -moz-animation: rotating 10s linear infinite;
  -ms-animation: rotating 10s linear infinite;
  -o-animation: rotating 10s linear infinite;
  animation: rotating 10s linear infinite;
}
}
</style>
