import Exams from '@/components/views/exams/'
import Report from '@/components/views/exams/Report'

import i18n from '@/i18n'

export default [
  {
    name: 'exams',
    path: '',
    component: Exams,
    meta: {
      title: i18n.t('data.6')
    }
  },
  {
    name: 'exams-homework',
    path: 'report/:id',
    component: Report,
    meta: {
      title: i18n.t('rezultaty-testirovaniya')
    }
  }
]
