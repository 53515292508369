import { render, staticRenderFns } from "./Substrate.vue?vue&type=template&id=00a03f1a&scoped=true&"
import script from "./Substrate.vue?vue&type=script&lang=js&"
export * from "./Substrate.vue?vue&type=script&lang=js&"
import style0 from "./Substrate.vue?vue&type=style&index=0&id=00a03f1a&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00a03f1a",
  null
  
)

export default component.exports