import axios from 'axios'
import Api from '@/api/endpoints'
import Vue from 'vue'
import i18n from '@/i18n'

let setPropertyIfNotSet = (obj, key, value) => {
  if (!obj) return
  if (!obj[key]) Vue.set(obj, key, value)
}

export default {
  state: {
    currentOlympiad: {},
    olympiadData: {},
    olympiadStage: {},
    olympiadTests: [],
    olympiadResults: []
  },
  mutations: {
    setCurrentOlympiad: (s, data) => {
      s.currentOlympiad = data
    },

    setCurrentStage(s, data) {
      s.olympiadStage = data
    },
    pushOlympiadTest(s, data) {
      s.olympiadTests.push(data)
      // setPropertyIfNotSet(s.olympiadData, data.id, data)
    },
    setOlympiadResults(s, data) {
      s.olympiadResults = data
    }
  },
  actions: {
    async getOlympiadData({ commit, state }) {
      try {
        const res = await Api.getOlympiadData()
        commit('setCurrentOlympiad', res)
      } catch (e) {
        console.log(`Error get olympiad data ${e}`)
      }
    },
    async getStageByStageId({ commit, state }, stageId) {
      const res = await Api.getStageByStageId(stageId)
      commit('setCurrentStage', res.data)
    },
    async getStageTests({ commit, dispatch, state }) {
      // First reset all previous test
      state.olympiadTests = []
      // if we have stage data take test id's and load them
      if (state.olympiadStage.id) {
        const testsIds = state['olympiadStage']['test_group']['tests'].map(
          t => t.id
        )
        let part = 0
        for (const i in testsIds) {
          const id = testsIds[i]
          await Api.getOlympiadTest(id).then(r => {
            r.data['updated_at'] = Date.now()
            r.data['subject'] = `${i18n.t('data.283')} ${String.fromCharCode(65 + part)}`
            r.data['testGroupId'] = state['olympiadStage']['test_group']['id']
            r.data['test'] = r.data['tasks']
            commit('pushOlympiadTest', r.data)
          })
          part++
        }
      }
    },
    async getOlympiadResults({ commit, dispatch, state }) {
      let id = null
      try {
        if (state.currentOlympiad.olympiad.id) {
          id = state.currentOlympiad.olympiad.id
        }
        if (id !== null) {
          console.log('Olympiad results')
          const res = await Api.getOlympiadResults(id)
          if (res) {
            commit('setOlympiadResults', res)
          }
        }
      } catch (e) {
        console.error(`Error get olympiad results ${e}`)
      }
    }
  },
  getters: {
    currentOlympiad: s => s.currentOlympiad,
    olympiadData: s => s.olympiadData,
    currentStage: s => s.olympiadStage,
    olympiadTests: s => s.olympiadTests,
    olympiadResults: s => s.olympiadResults
  }
}
