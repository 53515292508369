export default {
  mixinCopyToClipBoard(str) {
    navigator.clipboard.writeText(str).then(() => {
      this.$toast.open({
        message: `Скопировано в буфер обмена`,
        type: 'success',
        duration: 5000,
        dismissible: true
      })
    })
  }
}
