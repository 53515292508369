<template>
  <div class="field-box">
    <p>{{ item.label }}<span v-if="item.required">*</span></p>
    <date-picker
      :default-value="item.model"
      :editable="false"
      format="YYYY-MM-DD"
      :class="[item.class, 'dp']"
      v-model="item.model"
      v-if="type === 'birthday'"
    />
    <select v-model="item.model" v-if="type === 'select'">
      <option
        selected
        v-for="val in item.options"
        :key="val.id"
        :value="val.id"
      >
        {{ val.name }}
      </option>
    </select>
    <input
      v-if="item.isPhone"
      :class="item.class"
      :type="item.type"
      v-mask="item.mask"
      v-model="item.model"
      :placeholder="item.placeholder"
    />
    <input
      @keypress.enter.prevent.stop="registerOrAuthMethod(item)"
      v-if="type !== 'birthday' && !item.isPhone && type !== 'select'"
      :class="item.class"
      :type="itemType"
      v-model="item.model"
      :placeholder="item.placeholder"
      :disabled="disabled == 1"
    />
    <img
      class="field-box__eye"
      :src="getEyeIconPath()"
      @click="showPassword = !showPassword"
      v-if="item.type === 'password'"
    />
    <p class="error">{{ item.errorMessage }}</p>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    DatePicker
  },
  data() {
    return {
      showPassword: false
    }
  },
  props: {
    item: Object,
    type: String,
    updated: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        if (
          val.model &&
          !val.isCheckConfirm &&
          val.typeValidation !== 'password' &&
          val.typeValidation !== 'birthday'
        ) {
          val.class = ''
          val.errorMessage = ''
        }
        if (val.typeValidation === 'password') {
          this.checkPassword(val.model)
        }
        if (val.typeValidation === 'birthday') {
          this.checkAge(val.model)
        }
      }
    }
  },
  methods: {
    registerOrAuthMethod(item) {
      this.$emit('regOrAuth', item)
    },
    checkPassword(pass) {
      // const re = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/
      // this.$emit('validation', re.test(String(pass)))
      this.$emit('validation', pass.length >= 6)
    },
    checkAge(birthday) {
      const validAge = 378691200000
      const convertBirthday = new Date(birthday)
      const dateNow = new Date(Date.now())
      if (+dateNow - +convertBirthday <= validAge) {
        this.$emit('validation', false)
      } else {
        this.$emit('validation', true)
      }
    },
    getEyeIconPath() {
      if (this.showPassword) return require('@/assets/svg/eye.svg')
      return require('@/assets/svg/eye_close.svg')
    }
  },
  computed: {
    itemType() {
      if (this.item.type === 'password' && this.showPassword) return 'text'
      return this.item.type
    }
  }
}
</script>
<style lang="scss">
@import '../../../styles/vars';

.field-box {
  margin: 8px 0;
  display: inline-block;
  width: 100%;
  position: relative;

  .error {
    color: #dc3545 !important;
  }

  .border-danger-input {
    input {
      border: 1px solid #dc3545 !important;
    }
  }
  .dp {
    .border-danger {
      border: 1px solid #dc3545 !important;
    }
  }

  p {
    font-family: $mr;
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #191d2f;
    margin-bottom: 5px;
    padding-left: 16px;

    span {
      color: red;
    }
  }

  .mx-datepicker {
    width: 100%;

    input {
      box-shadow: none;

      &:hover {
        border-color: #f0f0f0;
      }
    }
  }

  input,
  select {
    height: 48px;
    width: 100%;
    background: #ffffff;
    border: 1px solid #f0f0f0;
    border-radius: 13px;
    padding: 0 16px;
    font-family: $mr;
    font-size: 12px;
    color: #8c8e97;
    outline: none;
  }

  &__eye {
    display: block;
    position: absolute;
    width: 20px;
    right: 15px;
    top: 37px;
    cursor: pointer;
  }
}
.age {
  input[placeholder="Tug'ilgan sanangizni kiriting"] {
    display: none;
  }
  input[placeholder='Введите дату рождения'] {
    display: none;
  }
}
</style>
