<template>
  <div class="course-page vue-component">
    <teacher-card
      v-if="courseInfo"
      class="teacher-card"
      :name="courseInfo.teacher.name"
      :picture="courseInfo.teacher.picture"
      :description="courseInfo.teacher.post"
    />
    <lessons-lists :courseById="true" class="lessons-list" :lessons="lessons" />
  </div>
</template>

<script>
import TeacherCard from '@/components/UI/small-components/TeacherCard'
import LessonsLists from '@/components/views/lessons-components/LessonsLists'

import Api from '@/api/endpoints'
import { mapMutations } from 'vuex'
export default {
  name: 'course-page',
  data: function () {
    return {
      // get course info by id
      courseInfo: {
        teacher: {
          name: '',
          picture: '',
          post: ''
        }
      },
      lessons: []
    }
  },
  methods: {
    ...mapMutations(['setPageTitle']),
    async setLessons() {
      let r = await Api.getCourseLessons(this.$route.params.id)
      this.lessons = r.data.lessons.map((l) => {
        l.course = r.data.course
        l.subject = r.data.subject
        return l
      })
      this.courseInfo = r.data
      this.courseInfo.teacher = {
        picture: r.data.responsible_teacher.image
      }
      this.courseInfo.teacher.name = `${
        r.data.responsible_teacher?.firstName || ''
      } ${r.data.responsible_teacher?.lastName || ''} ${
        r.data.responsible_teacher?.middleName || ''
      }`
      this.courseInfo.teacher.post = `${this.$t('g.2')} «${
        r.data.subject.name
      }»`
    }
  },
  computed: {
    teacherPost() {
      return `${this.$t('data.265')}  «${this.courseInfo.subject}»`
    }
  },
  components: {
    TeacherCard,
    LessonsLists
  },
  props: [],
  async mounted() {
    await this.setLessons()
    this.setPageTitle(
      `${this.$t('data.52')} по премету «${
        this.courseInfo.course.name || this.$t('ne-udalos-zagruzit-kurs-0')
      }»`
    )
  }
}
</script>

<style scoped='scoped' lang='scss'>
.teacher-card {
  margin-top: 31px;
  display: inline-flex;
  margin-bottom: 40px;
}
.tabs {
  margin-bottom: 24px;
}
.lessons-upcoming {
  margin-top: 40px;
}
</style>
