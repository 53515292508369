<template>
  <!--  Пока так, возможно уменьшить код и не использовать 2 раза компоненты ответов-->
  <!--  сделано для отображения картинок и изменения стилей по ответам-->
  <div v-if="question && !isReportPage">
    <div v-html="question.question" class="content_question"></div>
    <!-- show video or audio if have -->
    <div
      class="content_question__additional"
      v-if="question.hasOwnProperty('mediaPath')"
    >
      <div v-if="checkExt(question.mediaPath) === 'video'">
        <video-player
          :videoInput="{ video_url: question.mediaPath }"
          :autoplay="false"
          playerMode="record"
          streamStatus="started"
        >
        </video-player>
      </div>
      <div v-else-if="checkExt(question.mediaPath) === 'audio'">
        <audio
          :src="question.mediaPath"
          controls
          class="question_audio"
        ></audio>
      </div>
      <div v-else-if="checkExt(question.mediaPath) === 'image'">
        <img
          style="
            display: block;
            max-width: 100%;
            height: auto;
            max-height: min(30vh, 250px);
          "
          :src="question.mediaPath"
        />
      </div>
      <div v-else-if="question.mediaPath">
        <!-- download link -->
        <a
          :href="question.mediaPath"
          target="_blank"
          class="question_download-file"
        >
          <img
            width="45px"
            :src="require('@/assets/svg/download.svg')"
            alt="Download file"
          />
          <span>Доступен файл: Скачать</span></a
        >
      </div>
    </div>
    <file-type
      v-if="question.type == 'file'"
      :question="question"
      v-model="result"
      :index="index"
    />
    <text-type
      v-else-if="question.type == 'text'"
      :question="question"
      v-model="result"
      :isPreview="isPreview"
      :index="index"
    />
    <checkbox-type
      v-else-if="question.type == 'radiobutton'"
      :question="question"
      v-model="result"
      :index="index"
      :isPreview="isPreview"
      type="radio"
    />
    <checkbox-type
      v-else-if="question.type == 'checkbox'"
      :question="question"
      v-model="result"
      :index="index"
      type="checkbox"
    />
  </div>
  <div class="result" v-else>
    <div class="answer-block">
      <div
        v-html="question.question"
        class="content_question answer-result"
      ></div>
      <div class="img-block">
        <img v-if="question.isCorrect" src="@/assets/svg/testTrue.svg" alt="" />
        <img v-else src="@/assets/svg/testFalse.svg" alt="" />
      </div>
    </div>
    <file-type
      class="answer-result"
      v-if="question.type === 'file'"
      :question="question"
      v-model="result"
      :index="index"
      :isReportPage="isReportPage"
    />
    <text-type
      class="answer-result"
      v-if="question.type === 'text'"
      :question="question"
      v-model="result"
      :index="index"
      :isReportPage="isReportPage"
    />

    <checkbox-type
      class="answer-result"
      v-if="question.type === 'radiobutton'"
      :question="question"
      v-model="result"
      :index="index"
      :isReportPage="isReportPage"
    />

    <checkbox-type
      class="answer-result"
      v-if="question.type === 'checkbox'"
      :question="question"
      v-model="result"
      :index="index"
      :isReportPage="isReportPage"
    />
    <p v-if="question.hint" class="hint" v-b-toggle="'collapse-' + question.id">
      Решение <img src="@/assets/svg/arrow-answer.svg" alt="icon" />
    </p>
    <b-collapse
      v-if="question.hint"
      :id="'collapse-' + question.id"
      class="mt-2"
    >
      <b-card>
        <p class="card-text" v-html="question.hint"></p>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import FileType from './FileType'
import TextType from './TextType'
import CheckboxType from './CheckboxType'
import RadioType from './RadioType'
import VideoPlayer from '@/components/UI/small-components/Player'

export default {
  name: 'QuestionFactory',
  components: {
    FileType,
    TextType,
    CheckboxType,
    RadioType,
    VideoPlayer
  },
  props: {
    question: {},
    index: '',
    isReportPage: Boolean,
    playerMode: 'record',
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      result: null
    }
  },
  methods: {
    // get extentin type of file
    checkExt(path) {
      if (path) {
        // if extention is audio format
        if (path.match(/\.(mp3|wav|ogg|flac|aac)$/i)) {
          return 'audio'
        }
        // if extention is video format
        else if (path.match(/\.(mp4|avi|mkv|mov|webm)$/i)) {
          return 'video'
        }
        // if extention is image format
        else if (path.match(/\.(jpg|jpeg|png|gif|bmp)$/i)) {
          return 'image'
        }
        // if extention is pdf format
        else if (path.match(/\.(pdf)$/i)) {
          return 'pdf'
        } else return 'none'
      }
    }
  },
  watch: {
    result(val) {
      this.result = val
      this.$emit('input', this.result)
      return this.result
    },
    question() {
      if (!this.question.type === 'text' || !this.result) return
      if (typeof this.question.answer !== 'string') {
        this.question.answer = ''
        this.question.answers = ''
        this.result.answer = ''
      }

      this.result.answer =
        this.question?.answer ||
        this.question?.answers ||
        this.result.answer ||
        ''
    }
  }
}
</script>

<style scoped lang='scss'>
.content_question > p {
  width: 100px;
  height: 100px;
}
.content_question {
  margin-bottom: 24px;
}
.content_question__additional {
  margin-bottom: 25px;
}
.question_audio {
  width: 80% !important;
}
audio::-webkit-media-controls-panel {
  background-color: #ffffff;
}
audio::-webkit-media-controls-timeline {
  color: #1da1f2 !important;
  height: 2px !important;
}
.question_download-file {
  // text-transform: uppercase;
  cursor: pointer;
  font-weight: bold;
  display: flex;
  align-items: center;
}
</style>


<style lang="scss">
@import '../../../styles/vars';
.sc-dtm-test {
  padding-top: 40px;
  padding-bottom: 30px;
}
.custom--my {
  margin: 12px 0;
}
h1 {
  margin-bottom: 25px;
  a {
    color: #191d2f;
    text-decoration: none;
    &:hover {
      svg {
        transform: translateX(-10px) rotate(180deg);
      }
    }
  }
  svg {
    margin-right: 10px;
    margin-left: 0;
    transition: 0.2s;
    transform: rotate(180deg);
  }
}
.select-info {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  margin: 8px 0;
  padding: 12px 34px;
  p {
    padding-right: 15px;
    margin-bottom: 0;
  }
  .info-icon {
    flex-shrink: 0;
    margin-right: 14px;
    display: flex;
  }
  .close {
    display: flex;
    box-shadow: none;
    opacity: 1;
    margin-left: auto;
    text-shadow: none;
    padding: 0;
    width: 16px;
    height: 16px;
    line-height: 10px;
    margin-right: -12px;
  }
}
.test-box {
  display: flex;
  width: 100%;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 24px;
  height: 100%;
  padding: 5px 0;
}
.test-info {
  display: flex;
  padding: 20px 0 20px 31px;
  input {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 16px;
    margin-top: 3px;
    outline: none;
  }
  h2 {
    margin-bottom: 10px;
  }
  p {
    color: #8c8e97;
    margin-bottom: 0;
  }
}
.test-img {
  flex-shrink: 0;
  display: flex;
  align-self: center;
  img {
    max-width: 100%;
  }
}
.start-test {
  margin-top: 30px;
}
.answer-result {
  align-items: center;
  flex-wrap: wrap;
  max-width: 550px;
}
.answer-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .img-block {
    padding: 15px;
    border: 1px solid #f0f0f0;
    border-radius: 50%;
    img {
      width: 30px;
    }
  }
}
.hint {
  color: #1da1f2;
  cursor: pointer;
}
.collapse {
  .card {
    border: 1px solid #1da1f2 !important;
    background: rgba(142, 208, 249, 0.3) !important;
    border-radius: 12px !important;
  }
}

@media (max-width: 1200px) {
  .quests label {
    width: 100%;
  }

  .test-box {
    overflow: hidden;
  }
}
</style>