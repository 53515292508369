<template>
  <section class="sc-all-courses">
    <div class="row">
      <div class="col-12">
        <title-nav to="all-courses">{{ $t('data.188') }}</title-nav>
      </div>
      <div
        class="col-12"
        v-for="(chunk, index) in subjectsByLangAndChunked"
        :key="index"
      >
        <div class="row" :class="{ reverse: index % 2 != 0 }">
          <div
            v-for="(subject, index) in chunk"
            :key="index"
            :class="['custom--my', index % 2 != 0 ? 'col-xl-8 ' : 'col-xl-4']"
          >
            <div class="lesson-big-box">
              <div v-if="index % 2 != 0" class="img-box">
                <img
                  v-if="subject.image == null"
                  src="../../../assets/img/guy_laptop.jpg"
                  alt="image"
                />
                <img v-else :src="subject.image" alt="image" />
              </div>
              <div class="lesson-box" :class="{ styled: index % 2 == 0 }">
                <div class="lesson-info">
                  <div class="title">
                    <h2>{{ subject.name }}</h2>
                  </div>
                  <p class="main--text">
                    {{ subject.description }}
                  </p>
                  <div class="more-about" v-if="subject.id != 10">
                    <template>
                      <router-link
                        :to="subject.site_url"
                        class="primary--button"
                        >{{ $t('data.112') }}</router-link
                      >
                      <span>От 1 Р/мес</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AllCourses',
  components: {},
  data() {
    return {}
  },
  methods: {
    ...mapActions(['allCourse', 'courseById', 'getFullSubjects']),
    getCourseById(id) {
      this.$router.push({ name: 'my-course', params: { id } })
    }
  },
  mounted() {
    this.allCourse()
    this.getFullSubjects()
  },
  computed: {
    ...mapGetters(['courses', 'course', 'fullSubjects']),
    subjectsByLangAndChunked() {
      const filtered = this.fullSubjects.filter(
        (subject) => subject.lang == this.lang || subject.lang == null
      ).reverse()
      const chunk = (arr, chunkSize) => {
        let R = []
        for (let i = 0, len = arr.length; i < len; i += chunkSize) {
          R.push(arr.slice(i, i + chunkSize))
        }
        return R
      }
      console.log(filtered, this.lang)

      if (filtered.length > 0) {
        // remove first chunk ( technical block )
        console.log(chunk(filtered, 2))
        return chunk(filtered, 2)
      }
    },
    lang() {
      return localStorage.getItem('lang')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../styles/vars';
.reverse {
  flex-direction: row-reverse !important;
}
.sc-all-courses {
  padding-top: 25px;
  padding-bottom: 30px;
}

h1 {
  margin-bottom: 18px;

  a {
    color: #191d2f;
    text-decoration: none;

    &:hover {
      svg {
        transform: translateX(10px);
      }
    }
  }

  svg {
    margin-left: 10px;
    transition: 0.2s;
  }
}

h1.page-allcourses-title {
  margin-bottom: 18px;

  a {
    color: #191d2f;
    text-decoration: none;

    &:hover {
      svg {
        transform: translateX(-10px) rotate(180deg);
      }
    }
  }

  svg {
    margin-right: 10px;
    margin-left: 0;
    transition: 0.2s;
    transform: rotate(180deg);
  }
}

.custom--my {
  margin: 12px 0;
}

.styled {
  background-color: rgba(142, 208, 249, 0.1);
  border-radius: 24px;
}

.lesson-box {
  display: inline-block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

.title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;

  h2 {
    margin-right: 10px;
  }

  span {
    display: inline-block;
    font-family: $mb;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #191d2f;
    background: #ffffff;
    border-radius: 50px;
    padding: 6px 20px;
  }
}

.lesson-big-box {
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 24px;
  height: 100%;
  position: relative;
  overflow: hidden;

  .lesson-box {
    flex-shrink: 0;
    max-width: 313px;
  }

  .img-box {
    min-width: 335px;
    height: 100%;
    position: relative;
    border-radius: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    img {
      // width: 75%;
      max-width: 360px;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.lesson-info {
  padding: 32px 24px;
}

.timer-box {
  display: inline-block;
  width: 100%;
  margin-bottom: 11px;

  h4 {
    font-family: $mb;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #191d2f;
  }

  span {
    display: inline-block;
    text-align: center;
    padding: 13px 5px;
    font-size: 11px;
    line-height: 14px;
    margin: 0 3px;
    letter-spacing: -0.02em;
    font-family: $mb;
    color: #191d2f;
    width: 41px;
    height: 41px;
    background: #ffffff;
    box-shadow: 1.7125px 1.7125px 5.1375px rgba(132, 132, 132, 0.05);
    border-radius: 13.7px;
  }
}

.more-about {
  margin-top: 11px;
  display: inline-flex;
  align-items: center;
  width: 100%;

  a {
    margin-right: 24px;
  }

  span {
    display: inline-block;
    font-family: $mb;
    font-size: 14px;
    letter-spacing: -0.02em;
    color: #1da1f2;
  }
}

.lesson-info p {
  font-family: 'Montserrat-Regular';
}

@media (max-width: 768px) {
  .lesson-big-box {
    background-color: rgba(142, 208, 249, 0.1);
    border: 1px solid #1da1f2;

    .lesson-box {
      flex-shrink: 0;
      max-width: 100%;
    }
  }
}
</style>
