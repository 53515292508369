<template>
  <svg
    width="26"
    height="27"
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.4955 14.1963L5.45208 5.83925L8.31822 14.4377L5.12964 21.8783L20.4955 14.1963ZM25.0795 14.8846L1.43949 26.7055C1.3173 26.7668 1.17921 26.7891 1.04392 26.7694C0.90864 26.7496 0.782706 26.6887 0.683191 26.595C0.583675 26.5012 0.515392 26.3791 0.487593 26.2453C0.459795 26.1114 0.473829 25.9722 0.527789 25.8466L5.46811 14.3198L1.29618 1.80402C1.25367 1.6766 1.25064 1.53932 1.28749 1.41015C1.32433 1.28098 1.39934 1.16595 1.50268 1.08014C1.60602 0.994331 1.73287 0.941737 1.86662 0.929254C2.00036 0.916769 2.13475 0.944975 2.25219 1.01018L25.1059 13.706C25.212 13.7651 25.2999 13.852 25.3603 13.9574C25.4206 14.0628 25.451 14.1826 25.4482 14.304C25.4454 14.4254 25.4094 14.5437 25.3443 14.6462C25.2792 14.7487 25.1873 14.8314 25.0785 14.8855L25.0795 14.8846Z"
      :fill="color || '#1DA1F2'"
    />
  </svg>
</template>

<script>
export default {
  name: 'send',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: ['color'],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='sass'></style>
