<template>
  <section id="wrapper">
    <side-bar-menu />
    <div class="content-column ml-auto position-relative w-100-m">
      <div class="header-wrap">
        <div class="container" v-if="isAuthPage">
          <Header :show-logo="true" />
        </div>
      </div>
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
  </section>
</template>

<script>
// Base components
import Header from '@/components/base/header/Header.vue'
import SideBarMenu from '@/components/base/SideBarMenu.vue'

export default {
  name: 'App',
  components: {
    // base
    Header,
    SideBarMenu
  },
  methods: {
    isAuthPage() {
      return this.$route.path.split('/').find((v) => v === 'auth')
    }
  }
}
</script>

<style lang="scss" scoped>
.header-wrap {
  position: sticky;
  width: 100%;
  background: #fff;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid rgb(236, 236, 236);
}
@media (max-width: 768px) {
  .content-container {
    padding: 0 17px;
  }
}

@media (max-width: 992px) {
  .w-100-m {
    width: 100% !important;
  }
}
</style>
