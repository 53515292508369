<template>
  <div class="exams vue-component">
    <tabs :tabs="[$t('data.6'), $t('data.62'), $t('data.63')]">
      <template :slot="$t('data.61')">
        <table-data :pagination="true"
          :isExams="true"
          v-if="examsComputed.length"
          :fields="fieldsEx"
          :items="examsComputed"
        />
        <empty-list v-else>
          {{ $t('data.68') }}
        </empty-list>
      </template>
      <template :slot="$t('data.62')">
        <table-data :pagination="true"
          :isExams="true"
          v-if="controlsComputed.length"
          :fields="fieldsEx"
          :items="controlsComputed"
        />
        <empty-list v-else>
          {{ $t('data.168') }}
        </empty-list>
      </template>
      <template :slot="$t('data.63')">
        <table-data
          :isExams="true"
          :pagination="true"
          v-if="examsResult.length"
          :fields="fieldsEx"
          :items="examsResult"
        />
        
        <empty-list v-else>{{ $t('data.169') }} </empty-list>
      </template>
    </tabs>
  </div>
</template>

<script>
import Card from './Card'
import Api from '@/api/endpoints'
import TableData from '@/components/UI/table-data/TableData'

export default {
  name: 'exams',
  data: function () {
    return {
      exams: [],
      fieldsEx: [
        { key: 'course_name', label: this.$t('data.52'), class: "is-title" },
        // { key: 'updated_at', label: this.$t('data.65') },
        { key: 'lesson_theme', label: this.$t('data.53'), class: "is-theme" },
        { key: 'test_group_status', label: this.$t('data.56'), class: "is-status" },
        { key: 'id', label: '', class: 'is-button' }
      ]
    }
  },
  methods: {
    async setExams() {
      let r = await Api.getTestGroup({
        test_group_types: ['ex', 'kr']
      })
      this.exams = r.data
    }
  },
  mounted() {
    this.setExams()
  },
  computed: {
    examsComputed() {
      return this.exams.filter((e) => e.type === 'ex' && !e.mark).sort((a, b) => {
        if (a.created_at < b.created_at) return 1
        if (a.created_at > b.created_at) return -1
        return 0
      })
    },
    examsResult() {
      return this.exams.filter((e) => e.mark).sort((a, b) => {
        if (a.created_at < b.created_at) return 1
        if (a.created_at > b.created_at) return -1
        return 0
      })
    },
    controlsComputed() {
      return this.exams.filter((e) => e.type === 'kr' && !e.mark).sort((a, b) => {
        if (a.created_at < b.created_at) return 1
        if (a.created_at > b.created_at) return -1
        return 0
      })
    }
  },
  components: {
    TableData,
    Card
  },
  props: [],
  mixins: {},
}
</script>

<style scoped='scoped' lang='scss'>
.exams {
  margin-top: 42px;

  .exams-row {
    margin-top: 45px;
  }
}
</style>
