<template>
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.3105 7.83331L6.31055 11.8333C6.31055 11.8333 4.81058 13.3334 6.31055 14.8333C7.81052 16.3333 9.3105 14.8333 9.3105 14.8333L16.8105 7.83333C16.8105 7.83333 19.8105 5.33335 16.8105 2.33334C13.8105 -0.666671 11.3105 2.33333 11.3105 2.33333L2.31055 11.3333C2.31055 11.3333 -0.947608 14.0748 2.81059 17.8332C6.81055 21.8333 9.81055 18.8333 9.81055 18.8333L16.3105 12.8333"
      :stroke="color || '#1DA1F2'"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'attach',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: ['color'],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='sass'></style>
