export default () => {
  // объект, который будет хранить данные, пока окно браузера не перезагрузят
  // берем данные из хранилища в виде json и парсим их
  var _objectStore = JSON.parse(localStorage.getItem('objectStore')) || {},
    timer = null
  // определяем объект с именем objectStore в window и добавляем ему геттер и сеттер
  // во избежание недоразумений, мы не трогаем localStorage, он каким был, тактим остаётся
  Object.defineProperty(window, 'objectStore', {
    get: function() {
      // timer нужен для того, чтоб не вызывать стрингификацию при каждом запросе объекта
      // и для того, чтоб старые данные localStorage не переписали новые данные,
      // что было следствием асинхронности

      // setTimeout для сохранения объекта >после< присваивания, а не до
      if (timer === null) {
        timer = setTimeout(function() {
          var stringified = JSON.stringify(_objectStore)
          // некое подобие оптимизации: если данные в объекте не изменились,
          // значит присваивания никакого не было, сработал обычный гет
          if (stringified !== localStorage.getItem('objectStore')) {
            // сохраняем
            localStorage.setItem('objectStore', stringified)
          }
          timer = null
        }, 0)
      }

      return _objectStore
    },
    // на случай, если objectStore присвоили целый объект
    set: function(v) {
      _objectStore = v
      localStorage.setItem('objectStore', JSON.stringify(_objectStore))
    }
  })
}
