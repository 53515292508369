<template>
  <div class="archive-table vue-component">
    <table-data
      v-if="items.length"
      :pagination="true"
      :items="items"
      :fields="fields"
    />
    <empty-list v-else></empty-list>
  </div>
</template>

<script>
import TableData from '@/components/UI/table-data/TableData'
import EmptyList from '@/components/UI/EmptyList'
import moment from 'moment'
import Api from '@/api/endpoints'
import i18n from '@/i18n'

export default {
  name: 'archive-table',
  components: { TableData, EmptyList },
  data: () => ({
    items: []
  }),
  computed: {
    fields() {
      return [
        { key: 'date', label: this.$t('data.65') },
        { key: 'sum', label: this.$t('summa-0') },
        { key: 'type', label: this.$t('tip-0') },
        { key: 'status', label: this.$t('data.56') }
      ]
    }
  },
  created() {
    const getDescr = (item) => {
      if (item.type === 'balance' && item.status === 'added')
        return 'Пополение баланса'
      if (item.order.type === 'course' && item.order.status === 'paid')
        return 'Покупка курса'
      return ''
    }
    Api.getTransactionHistory().then((res) => {
      this.items = res.data.map((item) => {
        let status = 'Не успешно'
        console.log(item)
        if (item.status === 'pending') status = this.$t('v-obrabotke')
        else if (item.status === 'added') status = this.$t('uspeshno')
        return {
          sum: new Intl.NumberFormat('ru-RU').format(item.amount) + ' UZS',
          type: getDescr(item),
          date: moment(item.created_at).format('DD.MM.YYYY'),
          status: status
        }
      })
    })
  }
}
</script>
