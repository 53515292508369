<template>
  <div class="radio-type-create">
    <label class="h5 mb-3"> Варианты ответов </label><br />
    <div v-for="(option, index) in data.answers" class="py-2" :key="index">
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <label :for="'radio' + index" class="p-0 m-0">Правильный</label>
            <input
              :id="'radio' + index"
              class="ml-2"
              type="radio"
              name="radioButtonTypeCreate"
              value="1"
              @change="
                data.answers = data.answers.map((item, i) => ({
                  ...item,
                  isCorrect: Number(index == i)
                }))
              "
            />
          </div>
        </div>
        <input
          type="text"
          class="form-control"
          aria-label="Text input with checkbox"
          v-model="option.variant"
        />
        <div class="input-group-append">
          <button
            class="btn btn-danger"
            type="button"
            @click="data.answers.splice(index, 1)"
          >
            X
          </button>
        </div>
      </div>
    </div>
    <button @click="addNewVariant" class="btn btn-outline-primary">
      New Variant</button
    ><br />
  </div>
</template>

<script>
export default {
  name: 'RadioType',
  data() {
    return {
      data: {
        type: 'radiobutton',
        description: '',
        answer_comment: '',
        question: '',
        answers: [
          {
            variant: '',
            isCorrect: 0
          }
        ]
      }
    }
  },
  watch: {
    'data.question': function () {
      this.$emit('input', this.data)
    },
    'data.answer_comment': function () {
      this.$emit('input', this.data)
    },
    variant() {
      this.$emit('input', this.data)
    },
    isCorrect() {
      this.$emit('input', this.data)
    },
    'data.description': function () {
      this.$emit('input', this.data)
    }
  },
  methods: {
    addNewVariant() {
      this.data.answers.push({
        variant: '',
        isCorrect: 0
      })
    }
  }
}
</script>

<style scoped></style>
