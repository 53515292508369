<template>
    <div>
        <button class="border-0 bg-white" @click="resolveClick">
            <img v-if='this.$props.isOpened' :src="menuClosedImg" alt="menu">
            <div class='menu' v-else>{{menuOpenedImg}}</div>
        </button>
    </div>
</template>

<script>
import i18n from '@/i18n'
export default {
    name: "BurgerMenu",
    props: {
        isOpened: Boolean,
    },
    emits: ["toogleOpenNavBar"],
    data() {
        return {
            menuClosedImg: require("./../../assets/img/close-btn.svg"),
            menuOpenedImg: i18n.t('data.1'),
        }
    },
    methods: {
        resolveClick() {
            this.$emit('toogleOpenNavBar');
        }
    }
}
</script>
<style scoped>
    .menu {
        font-weight: bold;
        font-size: 1.2rem;
        color: #1da1f2;
    }
</style>
