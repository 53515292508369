<template>
  <div v-if="question.model">
    <textarea
      type="text"
      v-model="question.model.answer"
      @input="oninput"
      :disabled="isReportPage"
      :class="[
        isReportPage ? 'report' : '',
        question.isCorrect ? 'correct' : ''
      ]"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'TextType',
  props: {
    index: null,
    question: {},
    isReportPage: Boolean,
    isPreview: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {}
  },
  watch: {
    'question.model.answer': function () {
      this.$emit('input', this.question.model)
      return this.question.model
    },
    question() {
      if (!this.question || !this.question.model ) return

      this.question.model.answer =
        this.question.model.answer || 
        this.question.answers?.user_answer ||
        '';

      if ( this.isPreview ) this.question.model.answer = this.question.answers;

      this.question.isCorrect =
        this.question.isCorrect || this.question.answers?.isCorrect || false
    }
  },
  methods: {
    oninput({ target }) {
      this.size(target)
      this.$emit('input', this.question.model.answer)
    },
    size(target) {
      target.style.height = 'auto'
      target.style.height = target.scrollHeight + 'px'
    }
  },
  beforeUpdate() {
    this.question.model = {
      task_id: this.question.id,
      taskType: this.question.type,
      answer: this.question?.model?.answer || this.question?.answer || ''
    }
  },
  beforeMount() {
    this.$set(this.question, 'model', {
      task_id: this.question.id,
      taskType: this.question.type,
      answer: this.question?.model?.answer || this.question?.answer || ''
    })
  }
}
</script>

<style lang="scss" scoped>
textarea {
  border-style: dashed;
  max-width: 100%;
  min-width: 100%;
  border-radius: 10px;
  padding: 20px;
  outline: none;
  overflow: hidden;
  border: 1px solid #aeaeae;

  &.report {
    border: 1px solid #e40a0a;
  }
  &.correct {
    border: 1px solid #00af07;
  }
}
</style>
