<template>
  <div class="result-card vue-component">
    <div class="r-rate">
      <div class="circle">
        <span class="main--text"
          >{{ correctNumber }}/{{ numberQuestions }}</span
        >
      </div>
    </div>
    <div class="r-lesson">
      <h4>{{ subject }}</h4>
      <p class="main--text">
        {{ $t('correctnumber-pravilnykh', [correctNumber]) }}
      </p>
      <p class="main--text">
        {{
          $t('numberquestions-correctnumber-nevernykh', [
            numberQuestions - correctNumber
          ])
        }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'result-card',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: ['subject', 'number-questions', 'correct-number'],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
@import '@/styles/vars.scss';

.result-card {
  background: rgba(142, 208, 249, 0.3);
  border: 1px solid #1da1f2;
  border-radius: 12px;
  min-width: 200px;
  padding: 15px 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  height: 100%;
  text-align: left;
  &:before {
    content: '';
    height: 40px;
    top: 0;
    right: 0;
    width: 23px;
    position: absolute;
    z-index: 1;
    background-image: linear-gradient(
      to right,
      transparent,
      rgba(221, 241, 253, 0.884),
      rgb(221 241 253),
      #ddf1fd
    );
  }
}

.circle {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 1px solid #f0f0f0;
  background-color: #fff;
  flex-shrink: 0;
  display: flex;
  top: 2px;
  left: 2px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-right: 16px;
}

.r-lesson {
  h4 {
    font-family: $mb;
    white-space: nowrap;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: -0.02em;
    color: #000000;
  }
  p {
    margin: 2px 0;
  }
}
</style>
