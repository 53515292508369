 // Formtatter for telephone number
 function formatPhoneNumber(entry) {
    const match = entry
       .replace(/\D+/g, '')
       .match(/([^\d]*\d[^\d]*){1,13}$/)[0]
     const part0 = match.length > 0 ? `${match.substring(0,3)}` : ""
     const part1 = match.length > 3 ? `(${match.substring(3,5)})` : "" 
     const part2 = match.length > 5 ? ` ${match.substring(5, 8)}` : ''
     const part3 = match.length > 8 ? `-${match.substring(8, 10)}` : ''
     const part4 = match.length > 10 ? `-${match.substring(10, 12)}` : ''        
     return `+${part0} ${part1}${part2}${part3}${part4}`
   }

export {
    formatPhoneNumber
}