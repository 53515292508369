<template>
  <div class="file-v vue-component">
    <div class="file-v__ext file-v-ext">
      <file-icon class="file-v-ext__icon" />
      <span class="file-v-ext__name">{{ file.ext }}</span>
    </div>
    <div class="file-v__right">
      <div class="file-v__name">{{ file.name }}</div>
      <div class="file-v__delete" @click="$emit('delete')">Удалить</div>
    </div>
  </div>
</template>

<script>
import FileIcon from '@/components/chat/icons/File'

export default {
  name: 'file-item',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {
    FileIcon
  },
  props: {
    file: {
      required: true,
      type: Object
    }
  },
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.file-v {
  display: flex;
  align-items: flex-start;
  min-width: max-content;
  &__ext {
    margin-right: 25px;
  }
  &__right {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
  &__name {
    max-width: 200px;
  }
  &__delete {
    color: #e40a0a;
    margin-top: 8px;
    cursor: pointer;
  }
}
.file-v-ext {
  display: flex;
  align-items: center;
  &__name {
    margin-left: -50%;
    z-index: 1;
    color: #191d2f;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
  }
}
</style>
