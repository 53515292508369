<template>
  <div>
    <div ref="editor" class="text-editor vue-component" :id="editorId">
      <VueEditor v-model='res'></VueEditor>
      <!-- <textarea
        v-else
        ref="editor-textarea"
        class="text-editor__textarea"
        v-model="res"
      ></textarea> -->
    </div>
  </div>
</template>

<script>

export default {
  name: 'text-editor',
  data: function () {
    return {
      editor: false,
      observerInited: false,
      res: '',
      editorId: 'id-' + Math.random().toString(36).substring(2, 15)
    }
  },
  model: {
    prop: 'model',
    event: 'input'
  },
  computed: {
   
  },
  components: {},
  watch:{
    res: {
      handler: function(val) {
        this.$emit('input', val)
      },
      deep: true
    }
  },
  props: ['model', 'inline'],
  mixins: {},
  beforeMount() {
    this.res = this.model
  },
}
</script>

<style scoped='scoped' lang='scss'>
.text-editor {
  &__textarea {
    width: 100%;
    display: block;
    padding: 10px;
  }
}
</style>
