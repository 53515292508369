<template>
  <div class="information">
    <div class="person">
      <div class="img-block">
        <img v-if="user.image" src="http://xacademy.axiomica.io/favicon.svg" alt="avatar" />
        <img v-else src="../../../../../assets/svg/add-avatar.svg" alt="" />
      </div>
      <div class="fio-block">
        <div class="name">
          <h2>
            {{ user.firstName }} {{ user.lastName }} {{ user.middleName }}
          </h2>
        </div>
        <div class="id">
          <button @click="editProfile" class="btn">
            {{ $t('data.18') }}
          </button>
          <span @click="mixinCopyToClipBoard(user.id)"
            >ID: {{ user.id }}
            <img src="../../../../../assets/svg/copy_icon.svg" alt="copy"
          /></span>
        </div>
      </div>
    </div>
    <div class="personal-data">
      <div class="row">
        <div class="col-7">
          <div class="data data-block">
            <h2>
              {{ $t('data.19') }}
              <img
                @click="editProfile"
                src="../../../../../assets/svg/edit.svg"
                alt="edit"
              />
            </h2>
            <div>
              <div class="info-block">
                <p class="label">{{ $t('data.21') }}</p>
                <p v-if="user.hasOwnProperty('phone')" class="value">
                  {{ formatPhoneNumber(String(user.phone)) }}
                </p>
              </div>
              <div class="info-block">
                <p class="label">{{ $t('data.22') }}</p>
                <p v-if="user.hasOwnProperty('birthday')" class="value">
                  {{ user.birthday || $t('data.268')  }}
                </p>
              </div>
              <div class="info-block">
                <p class="label">{{ $t('data.23') }}</p>
                <p v-if="user.hasOwnProperty('city')" class="value">
                  {{ user.city || $t('data.268')  }}
                </p>
              </div>
              <div class="info-block">
                <p class="label">Email</p>
                <p v-if="user.hasOwnProperty('email')" class="value">
                  {{ user.email || $t('data.268')  }}
                </p>
              </div>
              <div
                class="info-block"
                v-if="referalLink && referalLink.hasOwnProperty('user_code')"
              >
                <p class="label">Реферальная ссылка</p>
                <a
                  class="info-block__referal-text value value-copy"
                  @click.prevent="
                    mixinCopyToClipBoard(
                      `https://xacademy.uz/app/#/auth/register?referal_code=${referalLink.user_code}`
                    )
                  "
                >
                  {{ referalLink.user_code }}
                </a>
              </div>
              <div
                class="info-block"
                v-if="user.hasOwnProperty('chat_username')"
              >
                <p class="label">{{ $t('data.243') }}</p>
                <a
                  class="value value-copy"
                  @click.prevent="mixinCopyToClipBoard(user.chat_username)"
                >
                  {{ user.chat_username || $t('data.268')  }}
                </a>
              </div>
              <div
                class="info-block"
                v-if="user.hasOwnProperty('chat_password')"
              >
                <p class="label">{{ $t('data.244') }}</p>
                <a
                  class="value value-copy"
                  @click.prevent="mixinCopyToClipBoard(user.chat_password)"
                >
                  {{ user.chat_password.substr(0, 10) || $t('data.268')  }}...
                </a>
              </div>
            </div>
            <p @click="editProfile" class="change-password blue-bold">
              {{ $t('data.25') }}
              <svg
                width="11"
                height="18"
                viewBox="0 0 11 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2 2L9 8.72L2 16"
                  stroke="#1DA1F2"
                  stroke-width="3"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </p>
          </div>
        </div>
        <div class="col-5">
          <div class="money data-block" style="height: 100%">
            <div>
              <p class="subtitle">
                {{ $t('data.26') }}
                <img
                  src="../../../../../assets/svg/balance.svg"
                  alt="balance"
                />
              </p>
              <span>{{ user.balance }}</span>
            </div>
            <div>
              <p class="subtitle bonus">
                {{ $t('data.27') }}
                <img src="../../../../../assets/svg/bonus.svg" alt="bonus" />
              </p>
              <span>{{ user.bonus }}</span>
            </div>
            <div>
              <router-link to="/bonus-program" class="link-bonus">
                {{ $t('data.326') }}?
              </router-link>
              <button @click="$bvModal.show('modal-multi-1')" class="btn">
                {{ $t('data.28') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <my-courses />
      <title-nav to="/archive">{{ $t('data.246') }}</title-nav>
      <!-- <table-data :pagination="true" :fields="fields" :items="items" /> -->
      <archive />
    </div>
    <b-modal
      id="modal-multi-1"
      size="md"
      :title="$t('data.219')"
      centered
      no-stacking
    >
      <p class="label">{{ $t('data.220') }}</p>
      <div class="form-group currency-block">
        <input
          v-mask="'###############'"
          v-model="sum"
          placeholder="1 000"
          type="text"
          class="form-control"
        />
        <span class="currency">UZS</span>
        <b-button
          @click="setOrder"
          :disabled="parseInt(sum) < 1000 || !sum"
          class="btn-next"
          v-b-modal.modal-multi-2
          >{{ $t('data.81') }}
        </b-button>
      </div>
      <span v-show="parseInt(sum) < 1000" style="font-size: 12px; color: red">{{
        $t('minimalnaya-summa-popolneniya-1-000-uzs')
      }}</span>
      <div class="alert" style="margin-top: 6px">
        <img src="@/assets/svg/info_icon.svg" alt="" />
        <div class="info">
          <p class="text">
            {{ $t('data.221') }}
          </p>
          <!-- <p class="instruction">
            <span>Инструкция по пополнению счета</span>
            <img src="@/assets/svg/arrow-small.svg" alt="" />
          </p> -->
        </div>
      </div>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>
    <b-modal
      id="modal-multi-1"
      size="md"
      :title="$t('data.219')"
      centered
      no-stacking
    >
      <p class="label">{{ $t('data.220') }}</p>
      <div class="form-group currency-block">
        <input
          v-mask="'###############'"
          v-model="sum"
          placeholder="1 000"
          type="text"
          class="form-control"
        />
        <span class="currency">UZS</span>
        <b-button
          @click="setOrder"
          :disabled="parseInt(sum) < 1000 || !sum"
          class="btn-next"
          v-b-modal.modal-multi-2
          >{{ $t('data.81') }}
        </b-button>
      </div>
      <span v-show="parseInt(sum) < 1000" style="font-size: 12px; color: red">{{
        $t('minimalnaya-summa-popolneniya-1-000-uzs')
      }}</span>
      <div class="alert" style="margin-top: 6px">
        <img src="@/assets/svg/info_icon.svg" alt="" />
        <div class="info">
          <p class="text">
            {{ $t('data.221') }}
          </p>
          <!-- <p class="instruction">
            <span>Инструкция по пополнению счета</span>
            <img src="@/assets/svg/arrow-small.svg" alt="" />
          </p> -->
        </div>
      </div>
      <template #modal-footer>
        <div></div>
      </template>
    </b-modal>

    <b-modal id="modal-multi-2" :title="$t('data.224')" centered>
      <p class="label">
        {{ $t('data.223') }}
      </p>

      <div class="radio-box">
        <div
          v-for="pay in paymentsMethod"
          class="radio-content"
          :key="pay.name"
        >
          <input
            v-model="paymentMethod"
            :id="pay.name"
            type="radio"
            :value="pay.name"
            name="payment"
          />
          <label class="radio-label" :for="pay.name">
            <img :src="pay.img" alt="click-pay" />
          </label>
        </div>
      </div>
      <div class="btn-box">
        <b-button
          :disabled="!paymentMethod"
          @click="getPayments"
          class="btn-next"
          style="margin-top: 8px"
          >{{ $t('data.229') }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MyCourses from '@/components/views/courses-components/MyCourses'
import TableData from '@/components/UI/table-data/TableData'
import { mapActions, mapGetters } from 'vuex'
import Api from '@/api/endpoints'
import moment from 'moment'
import Archive from '@/components/views/profile/ArchiveTable'

export default {
  components: { TableData, MyCourses, Archive },
  data: () => ({
    paymentsMethod: [
      { name: 'click.uz', img: require('@/assets/img/oClick.png') },
      { name: 'payme', img: require('@/assets/img/payme.png') },
      { name: 'apelsin', img: require('@/assets/img/apelsin.png') }
    ],
    sum: '1000',
    paymentMethod: '',
    items: []
  }),
  created() {
    Api.getTransactionHistory().then((res) => {
      try {
        this.items = res.data.map((item) => {
          let status = ''

          if (item.status === 'pendig') status = this.$t('v-obrabotke')
          else if (item.status === 'added') status = this.$t('uspeshno')

          return {
            sum: new Intl.NumberFormat('ru-RU').format(item.amount) + ' UZS',
            type: item.name,
            date: moment(item.created_at).format('DD.MM.YYYY'),
            status: status
          }
        })
      } catch (e) {
        console.log(e)
      }

    })
  },
  computed: {
    ...mapGetters([
      'orders',
      'payment',
      'user',
      'showPaymentModal',
      'referalLink'
    ]),
    // tableItems() {
    //   return this.userInfo.operationHistory.filter(
    //     (item, index) => index < 5 && item
    //   )
    // },
    fields() {
      return [
        { key: 'date', label: this.$t('data.65') },
        { key: 'sum', label: this.$t('summa-0') },
        { key: 'type', label: this.$t('tip-0') },
        { key: 'status', label: this.$t('data.56') }
      ]
    },
    isPayment() {
      return this.showPaymentModal
    }
  },
  watch: {
    isPayment: {
      deep: true,
      handler(val) {
        if (val) {
          this.$bvModal.show('modal-multi-1')
          this.$store.dispatch('showPayment', false)
        }
      }
    }
  },
  methods: {
    ...mapActions(['getOrders', 'getPayment']),

    editProfile() {
      this.$router.push({ name: 'profile-edit' })
    },
    setOrder() {
      this.getOrders({ type: 'balance', balance: parseInt(this.sum) })
    },
    formatPhoneNumber(entry) {
      const match = entry
        .replace(/\D+/g, '')
        .match(/([^\d]*\d[^\d]*){1,13}$/)[0]
      const part0 = match.length > 0 ? `${match.substring(0, 3)}` : ''
      const part1 = match.length > 3 ? `(${match.substring(3, 5)})` : ''
      const part2 = match.length > 5 ? ` ${match.substring(5, 8)}` : ''
      const part3 = match.length > 8 ? `-${match.substring(8, 10)}` : ''
      const part4 = match.length > 10 ? `-${match.substring(10, 12)}` : ''
      return `+${part0} ${part1}${part2}${part3}${part4}`
    },
    async getPayments() {
      await this.getPayment({
        payment_method: this.paymentMethod,
        order_id: this.orders.data.id
      })
      // window.open(await this.payment.data.url, '_blank')
      console.log(this.payment)
      document.location.assign(await this.payment.data.url)
    }
  }
}
</script>

<style lang="scss">
.modal-title,
.form-control {
  font-family: 'Montserrat-Regular';
}

.information {
  overflow-x: hidden !important;
  .person {
    display: flex;
    align-items: center;

    .img-block {
      width: 80px;
      margin-right: 20px;

      img {
        width: 100px;
        border-radius: 50%;
      }
    }

    .fio-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin-left: 25px;

      .id {
        display: flex;
        align-items: center;

        button {
          border: 1px solid #ccc;
          border-radius: 12px;
          margin-right: 20px;
          padding: 10px;
        }

        span {
          display: flex;
          align-items: center;
          color: #8c8e97;
          font-weight: 700;

          img {
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .personal-data {
    margin-top: 30px;

    .data-block {
      border: 1px solid #ccc;
      border-radius: 12px;
      padding: 30px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .link-bonus {
        color: #1da1f2;
        text-decoration: none;
        font-weight: bold;
      }
      span {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        /* identical to box height, or 125% */

        display: flex;
        align-items: flex-end;

        /* black */

        color: #191d2f;
      }

      &.data {
        h2 {
          display: flex;
          align-items: center;
          margin-bottom: 30px;

          img {
            margin-left: 10px;
            cursor: pointer;
          }
        }

        .info-block {
          display: flex;
          align-items: center;
          justify-content: flex-start;

          .label {
            font-weight: 700;
            width: 50%;
          }
          &__referal-text {
            margin-right: 10px;
            white-space: nowrap;
            max-width: 200px;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          img {
            align-self: baseline;
            margin-top: 5px;
            cursor: pointer;
          }
        }
      }
    }

    .money {
      background: rgba(142, 208, 249, 0.1);
      border: 1px solid #1da1f2;

      .subtitle {
        display: flex;
        align-items: center;
        margin-bottom: 5px;

        img {
          margin-left: 10px;
        }

        &.bonus {
          margin-top: 20px;
        }
      }

      button {
        background: #1da1f2;
        color: #fff;
        padding: 10px;
        border-radius: 10px;
        width: 170px;
        font-weight: 500;
        margin-top: 20px;
      }
    }

    .blue-bold {
      color: #1da1f2;
      display: flex;
      align-items: center;
      cursor: pointer;
      margin-bottom: 0;
      margin-top: 30px;
      font-weight: 700;

      svg {
        margin-left: 10px;
        margin-top: 3px;
      }
    }

    .slider-item {
      width: 290px;
    }
  }
}

.modal-header,
.modal-footer {
  border: none !important;
}

.modal-footer {
  display: none !important;
}

#modal-multi-2 {
  p,
  button {
    font-family: 'Montserrat-Regular';
  }
  .radio-box {
    display: flex;
  }

  .radio-content {
    margin: 0 5px;
  }

  input {
    display: none;

    &:checked + .radio-label {
      &:after {
        display: block;
      }
    }
  }

  .radio-label {
    width: 120px;
    height: 130px;
    border: 1px solid #1da1f2;
    border-radius: 4px;
    position: relative;
    background: rgba(142, 208, 249, 0.108);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      position: absolute;
      width: 20px;
      height: 20px;
      border: 1px solid #000;
      border-radius: 50%;
      background: #fff;
      top: 10px;
      right: 10px;
    }

    &:after {
      content: '';
      display: none;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #1da1f2;
      top: 14px;
      right: 14px;
    }

    p {
      padding: 0;
      margin-bottom: 5px;
      font-size: 14px;
    }
  }

  .btn-box {
    width: 100%;
    text-align: center;
  }

  .btn-next {
    background: #1da1f2;
    color: #fff;
    border-radius: 12px;
    border: none;
    padding: 10px 14px;
    cursor: pointer !important;
  }
}

#modal-multi-1 {
  .label {
    font-size: 14px;
    font-weight: 700;
  }
  p,
  button {
    font-family: 'Montserrat-Regular';
  }
  .form-group {
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      border: 1px solid #f0f0f0;
      border-radius: 12px;
      width: 320px;
      color: #8c8e97;
      padding: 20px 14px;
    }

    button {
      background: #1da1f2;
      color: #fff;
      border-radius: 12px;
      border: none;
      padding: 9px 10px;
      cursor: pointer !important;
    }
  }

  .alert {
    background: rgba(142, 208, 249, 0.308);
    display: flex;
    align-items: flex-start;
    padding: 15px;
    border-radius: 12px;

    img {
      margin-right: 15px;
      padding-top: 5px;
    }

    p {
      font-size: 14px;
      font-family: 'Montserrat-Regular';
    }

    .instruction {
      color: #1da1f2;
      font-weight: 700;
      cursor: pointer;

      img {
        margin-top: -5px;
        margin-left: 10px;
      }
    }
  }
}
@media (max-width: 576px) {
  #modal-multi-1 {
    button {
      font-size: 12px;
    }
  }

  .modal-content .form-group {
    display: flex;
    flex-direction: column;

    .btn {
      width: 100%;
      margin-top: 15px;
    }

    input {
      width: 100% !important;
    }
  }
}

.currency-block {
  position: relative;

  .currency {
    position: absolute;
    right: 160px;
  }
}

.value-copy {
  color: #1da1f2 !important;
  margin-bottom: 1rem;
  cursor: pointer;
}
</style>
