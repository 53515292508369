import RadioType from './RadioType'
import TextType from './TextType'
import CheckboxType from './CheckboxType'
import FileType from './FileType'

export default {
  render(h) {
    return this.questionComponent
  },
  data: function() {
    return {
      question: []
    }
  },
  methods: {
    onInput(d) {
      this.$emit('input', d)
    }
  },
  computed: {
    questionComponent() {
      if (this.type === 'checkbox')
        return (
          <checkbox-type vOn:input={this.onInput} initData={this.initData} />
        )
      if (this.type === 'text') {
        if ( typeof this.initData.answer !== 'string' ) {
          this.initData.answer = '';
          this.initData.answers = '';
        }
        return <text-type vOn:input={this.onInput} initData={this.initData} />
      }
    }
  },
  components: {
    RadioType,
    TextType,
    CheckboxType,
    FileType
  },
  props: ['type', 'initData'],
  mixins: {}
}
