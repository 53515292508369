<template>
  <div
    class="chat-input vue-component"
    :class="{ 'chat-input__minimize': chatmode == 'mini' }"
  >
    <input
      type="file"
      multiple="multiple"
      class="chat-input__files-input"
      ref="fileInput"
    />
    <attach @click.native="fileUpload" class="chat-input__attach"></attach>
    <textarea
      v-model="val"
      @keyup.ctrl.enter="submit"
      class="chat-input__input"
      rows="1"
    ></textarea>
    <send @click.native="submit" class="chat-input__send"></send>
  </div>
</template>

<script>
import Attach from '@/components/chat/icons/Attach'
import Send from '@/components/chat/icons/Send'

export default {
  name: 'chatInput',
  data: function () {
    return {
      val: ''
    }
  },
  methods: {
    submit() {
      this.$emit('send-msg', this.val)
      this.val = ''
    },
    fileUpload() {
      let inp = this.$refs.fileInput
      if (!inp) return
      inp.click()
    }
  },
  computed: {},
  components: {
    Attach,
    Send
  },
  props: ['chatmode', 'files'],
  mixins: {},
  mounted() {
    let inp = this.$refs.fileInput
    if (!inp) return
    inp.addEventListener('change', (e) => {
      console.log(e.target.files)
      this.$emit('on-file-input', e.target.files)
    })
  }
}
</script>

<style scoped='scoped' lang='scss'>
.chat-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background: #f0f0f0;
  padding: 4px 0;
  height: 56px;
  &__attach {
    margin: 0 19px;
    cursor: pointer;
  }
  &__minimize {
    background: #fff !important;
    input {
      width: auto !important;
    }
  }
  &__files-input {
    pointer-events: none;
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }
  &__input {
    background: #fff;
    border: 1px solid #f0f0f0;
    border-radius: 13px;
    padding: 10px;
    height: 100%;
    width: calc(100% - 150px);
  }
  &__send {
    margin-left: 19px;
    cursor: pointer;
  }
}
</style>
