<template>
  <div class="text-type-create">
    <label class="h5 my-3">Ответ</label>
    <textarea
      v-model="initData.answers"
      @input="$emit('input', initData)"
      class="text-type-create-textarea"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'TextType',
  props: {
    index: null,
    initData: {
      type: Object
    }
  },
  data() {
    return {
    }
  },
  mounted() {}
}
</script>

<style lang="scss" scoped>
.text-type-create-textarea {
  width: 100%;
  display: block;
  padding: 10px;
  border: 1px #999 dashed;
  border-radius: 7px;
}
</style>