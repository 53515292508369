import User from './../User/index.js'
import Connection from './../Connection/index.js'
import Channel from './../Channel/index.js'

export default class {
  constructor() {
    this.channels = {}
  }
  async connect(url) {
    if (!url) {
      console.error('url param is required')
    }
    this.connection = new Connection()
    let connection = await this.connection.connect(url)
    this.user = new User(this.connection)
    return connection
  }
  subscribe(chats) {
    // Get new ids, make unique list and filter out existing channels
    const inputUniqueIds = new Set()
    console.log(chats)
    chats.forEach(chat => {
      chat.curators.forEach(curator => {
        inputUniqueIds.add({
          id: curator.chat_id,
          isDirect: chat.isDirect
        })
      })
    })
    return Promise.all(
      Array.from(inputUniqueIds).map(async channel => {
        if (!this.channels[channel.id]) {
          this.channels[channel.id] = new Channel(
            this.connection,
            channel.id,
            channel.isDirect
          )
          return this.channels[channel.id].init()
        }
      })
    )
  }
  // Unsubscribe from channel
  unsubscribe(channelId) {
    if (this.channels[channelId]) {
      delete this.channels[channelId]
    }
  }
  get channels() {
    return this._channels
  }
  set channels(channels) {
    this._channels = channels
  }
}
