<template>
  <div class="exam-card vue-component">
    <div class="title">{{ title }}</div>
    <div class="description">{{ description }}</div>
    <router-link
      :to="{ name: 'testing', params: { id: testId, type: type } }"
      class="btn primary--button"
    >
      {{ btnText }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'exam-card',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {},
  props: ['title', 'description', 'btnText', 'test-id', 'type'],
  mixins: {},
  beforeMount() {}
}
</script>

<style scoped='scoped' lang='scss'>
.exam-card {
  border-radius: 24px;
  border: 1px solid #f0f0f0;
  padding: 32px;
  .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
  }
  .description {
    color: #8c8e97;
  }
  .btn {
    margin-top: 18px;
  }

  div, p, li, span, td, th, strong{
      font-family: 'Montserrat-Regular';
    }
}
</style>
