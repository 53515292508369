<template>
  <div class="auth-login vue-component">
    <transition name="fade">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
import LangSelect from '../UI/small-components/LangSelect.vue'

export default {
  name: 'auth-login',
  data: function () {
    return {}
  },
  methods: {},
  computed: {},
  components: {
    LangSelect
  },
  props: [],
  mixins: {},
  beforeMount() {}
}
</script>

<style lang="scss" scoped>
@import './src/styles/vars.scss';
</style>
