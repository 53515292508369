import SelectTheme from '@/components/views/dtm-tests-components/SelectTheme'
import SelectType from '@/components/views/dtm-tests-components/SelectType'
import Test from '@/components/views/dtm-tests-components/Test'
import Result from '@/components/views/dtm-tests-components/TestResult'
import TestReport from '@/components/views/dtm-tests-components/TestReport'

export default [
  {
    name: 'dtm',
    path: '',
    component: SelectType
  },
  {
    name: 'select-theme',
    path: 'select-theme/:type',
    component: SelectTheme
  },
  {
    name: 'dtm-testing',
    path: 'test',
    component: Test
  },
  {
    name: 'dtm-result',
    path: 'result',
    component: Result
  },
  {
    name: 'dtm-report',
    path: 'report',
    component: TestReport
  }
]
