<template>
  <section class="sc-test-type">
    <banner :bg-img="require('@/assets/img/banner_bg.jpg')" class="banner" />
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h1>Выберите вид тестирования</h1>
        </div>
        <div class="col-12">
          <div class="row">
            <div
              class="col-lg-6 custom--my"
              v-for="ttd in testTypeDatas"
              :key="ttd.index"
            >
              <div class="test-type-box">
                <h2>{{ ttd.title }}</h2>
                <p class="main--text">
                  {{ ttd.text }}
                </p>
                <router-link class="primary--button" :to="ttd.to">{{
                  ttd.linkText
                }}</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Banner from '@/components/UI/Banner'
import DtmSoon from '@/components/views/dtm-tests-components/DtmSoon'

export default {
  name: 'TestType',
  data() {
    return {
      testTypeDatas: [
        {
          title: this.$t('dtm-testirovanie-0'),
          text: this.$t('qsdvdfg'),
          linkText: this.$t('pereiti-k-vyboru-predmetov'),
          to: {
            name: 'select-theme',
            params: {
              type: 'not-free'
            }
          }
        },
        {
          title: this.$t('svobodnoe-testirovanie'),
          text: this.$t('ssadsdfdfghbb'),
          linkText: this.$t('pereiti-k-vyboru-predmetov'),
          to: {
            name: 'select-theme',
            params: {
              type: 'free'
            }
          }
        }
      ]
    }
  },
  components: {
    Banner,
    DtmSoon
  }
}
</script>

<style lang="scss" scoped>
.sc-test-type {
  padding-bottom: 30px;
}

.banner {
  margin-bottom: 40px;
}

h1 {
  margin-bottom: 20px;
}

.custom--my {
  margin: 12px 0;
}

.test-type-box {
  display: inline-block;
  width: 100%;
  padding: 32px 32px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 24px;
  h2 {
    margin-bottom: 8px;
  }
  .main--text {
    margin-bottom: 30px;
  }
}
</style>
