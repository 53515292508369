<template>
  <div class="countdown">
    <svg>
      <circle class="stroke" r="25" cx="26" cy="26"></circle>
      <circle
        :style="{
          'stroke-dashoffset': dashOffset + 'px',
          stroke: dashOffset >= 154 ? 'none' : '#1da1f2'
        }"
        class="progress-bar"
        r="25"
        cx="26"
        cy="26"
      ></circle>
      <rect x="19" y="19" width="13" height="13" fill="#E40A0A"></rect>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'circular-timer',
  data: function () {
    return {}
  },
  methods: {},
  computed: {
    dashOffset() {
      let all = 155
      let div = 1 - this.currentTime / this.allTime
      return all * div
    }
  },
  components: {},
  props: {
    allTime: {
      required: true
    },
    currentTime: {
      required: true
    }
  },
  mixins: {},
  beforeMount() {},
  mounted() {}
}
</script>

<style lang="scss" scoped>
.countdown {
  position: relative;
  height: 52px;
  width: 52px;
  text-align: center;
  cursor: pointer;
}
svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 52px;
  height: 52px;
  transform: rotateZ(-90deg);
}
svg .progress-bar {
  stroke-dasharray: 154px;
  stroke-linecap: round;
  stroke-width: 2px;
  stroke: #1da1f2;
  fill: none;
}
.stroke {
  stroke-dasharray: 154px;
  stroke-linecap: round;
  stroke-width: 1px;
  stroke: #f0f0f0;
  fill: none;
}
</style>
